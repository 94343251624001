import React from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useSelector } from "react-redux"
import { getAssistantConfiguration } from "state/assistantConfiguration/selectors"
import { PresetCategory } from "../PresetCategory"
import { StyledPresets } from "./Presets.style"
import { PresetsProps } from "./types"

export const Presets: React.FC<PresetsProps> = ({ subjectType }) => {
  const configuration = useSelector(getAssistantConfiguration)
  const filteredConfiguration = configuration.filter(item => (subjectType ? item.subject_type === subjectType : true))

  return (
    <StyledPresets>
      <Stack direction="column" spacing="xl">
        {filteredConfiguration.map((presetCategory, index) => (
          <PresetCategory presetCategory={presetCategory} key={index} />
        ))}
      </Stack>
    </StyledPresets>
  )
}
