import { Accordion, Stack } from "@paudigital/multidesk-ui-kit"
import React from "react"
import { useSelector } from "react-redux"
import { Form, useCreateFormConfig } from "app/components/Form"
import { TextField } from "app/components/Form/components/TextField"
import { Checkbox } from "app/components/Form/components/Checkbox"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { SaveButton } from "app/components/SaveButton"
import {
  getModuleIdByName,
  getModulePermissions,
  getRolesAndPermissionsPatchRequestStatus,
  getRolesByModule,
} from "state/management/selectors"
import { useTranslate } from "app/hooks/translate"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { patchRolesAndPermissionsThunk } from "state/management/thunks"
import { Permission, Role } from "api/management"
import { RolesAndPermissionsTabProps, UnformattedPayload, UnformattedRole } from "./types"

const ManagementRolesAndPermissionsTabPage: React.FC<RolesAndPermissionsTabProps> = ({ module }) => {
  const { t } = useTranslate()
  const { isPending } = useSelector(getRolesAndPermissionsPatchRequestStatus)
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const moduleId = useSelector(getModuleIdByName(module))
  const modulePermissions = useSelector(getModulePermissions(module))
  const moduleRoles = useSelector(getRolesByModule(module))
  const formConfig = useCreateFormConfig({
    defaultValues: {
      module_id: moduleId,
      roles: moduleRoles.map(role => {
        return {
          ...role,
          permissions: role.permissions.reduce((newObj, p) => {
            return {
              ...newObj,
              [p.permission_id]: p.name,
            }
          }, {}),
        }
      }),
    },
  })

  const { handleSubmit } = formConfig

  const formatPayload = (payload: UnformattedPayload) => {
    return {
      ...payload,
      roles: payload.roles.map((role: UnformattedRole) => {
        return {
          ...role,
          permissions: Object.entries(role.permissions).reduce((newArr, p) => {
            if (!p[1]) return newArr
            return [
              ...newArr,
              {
                permission_id: parseInt(p[0]),
                name: p[1],
              },
            ]
          }, []),
        }
      }),
    }
  }

  const updateRolesForModule = async (payload: UnformattedPayload) => {
    await handleSubmit(
      patchRolesAndPermissionsThunk({ customerGroupId: activeCustomerGroupId, data: formatPayload(payload) }),
    )
  }

  return (
    <Form config={formConfig} onSubmit={updateRolesForModule}>
      <Stack spacing="m" direction="column">
        <Stack spacing="s" direction="column">
          <Stack direction="column" spacing="s">
            {moduleRoles.map((role: Role, index: number) => (
              <Accordion key={index} title={role.name}>
                <Stack direction="column" spacing="m">
                  <Stack direction="row" spacing="l" justifyContent="space-between">
                    <Stack.Item grow={true}>
                      <TextField
                        id={`role-${role.role_id}-name`}
                        key={`role-${role.role_id}-name`}
                        name={`roles.${index}.name`}
                        label={t("system.name")}
                        defaultValue={role.name}
                      ></TextField>
                    </Stack.Item>
                    <Stack.Item grow={true}>
                      <TextField
                        id={`role-${index}-description`}
                        key={`role-${index}-description`}
                        name={`roles.${index}.description`}
                        label={t("system.description")}
                        defaultValue={role.description}
                      ></TextField>
                    </Stack.Item>
                  </Stack>
                  <Stack direction="column" spacing="2xs">
                    {modulePermissions.map((permission: Permission) => (
                      <Checkbox
                        id={`role-${role.role_id}-permission-${permission.permission_id}`}
                        key={`role-${role.role_id}-permission-${permission.permission_id}`}
                        name={`roles.${index}.permissions.${permission.permission_id}`}
                        label={t(`system.permissions.${permission.name}`)}
                        value={permission.name}
                        defaultChecked={role.permissions.some(p => p.permission_id === permission.permission_id)}
                      ></Checkbox>
                    ))}
                  </Stack>
                </Stack>
              </Accordion>
            ))}
          </Stack>
        </Stack>
        <ButtonWrapper>
          <SaveButton variant="primary" type="submit" saving={isPending}>
            {isPending ? t("system.saving") : t("system.save_changes")}
          </SaveButton>
        </ButtonWrapper>
      </Stack>
    </Form>
  )
}

export default ManagementRolesAndPermissionsTabPage
