import { SearchParams } from "api/types"
import { PaginatedResult } from "state/types"

export enum UrgencyEnum {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  URGENT = "urgent",
  BY_DEADLINE = "by_deadline",
}

export type Urgency = `${UrgencyEnum}`

export enum TicketStatusEnum {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  DONE = "done",
}

export type TicketStatus = `${TicketStatusEnum}`

interface SiteType {
  id: number
  name: string
  street: string
  number: number
  city: string
  postal_code: string
  country: string
}

interface DivisionType {
  id: number
  name: string
}

export interface SingleTicketHistoryType {
  event: string
  timestamp: string
  data: {
    name: string
    user_id: number
    new_state?: TicketStatusEnum
    previous_state?: TicketStatusEnum
    comment?: string
  }
}

export interface SingleTicketAttachmentType {
  id: number
  url: string
  file_name: string
  file_type: string
}

export interface SingleTicketParentTicketType {
  id: number
  title: string
  status: TicketStatusEnum
}

interface SingleTicketCustomerType {
  id: number
  name: string
}

interface SingleTicketUserType {
  id: number
  first_name: string
  last_name: string
}

export interface TicketItemType {
  id: number
  urgency: UrgencyEnum
  title: string
  deadline_on: string
  quote_requested: boolean
  created_at: string
  updated_at: string
  status: TicketStatusEnum
  site: SiteType
  division: DivisionType
}

export interface SingleTicketType extends TicketItemType {
  description: string
  "parent-ticket": SingleTicketParentTicketType
  customer: SingleTicketCustomerType
  user: SingleTicketUserType
  attachments: SingleTicketAttachmentType[]
  history: SingleTicketHistoryType[]
}

export interface CreateTicketType extends Pick<TicketItemType, "urgency" | "title" | "quote_requested"> {
  parent_id?: number
  site_id: number
  division_id: number
  description: string
  deadline_on: TicketItemType["deadline_on"] | null
  attachments: number[]
}

export interface FetchTicketsParams extends SearchParams {
  activeCustomerGroupId: number
  status: string
  urgency?: string
}

export interface PatchSupportTicketData {
  status?: TicketStatusEnum
}

export interface PatchSupportTicket {
  customerGroupId: number
  ticketId: number
  data: PatchSupportTicketData
}

export type AddCommentType = {
  user_id: number
  comment: string
}

export interface FetchSupportTicketStatisticsData {
  activeCustomerGroupId: number
}

export interface SupportTicketStatisticsType {
  not_started: number
  in_progress: number
  done: number
}

export type FetchSupportTicketsReturnValue = PaginatedResult<TicketItemType>
export type FetchSingleSupportTicketReturnValue = SingleTicketType
export type CreateSupportTicketReturnValue = SingleTicketType
export type AddSupportTicketCommentReturnValue = SingleTicketHistoryType[]
export type FetchSupportTicketStatisticsReturnValue = SupportTicketStatisticsType
