import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  CreateUserParams,
  postCreateUser,
  CreateUserReturnValue,
  fetchUsersSites,
  postAssignRolesToUser,
  AssignRolesToUserParams,
  AssignRolesToUserReturnValue,
  FetchUsersSitesReturnValue,
  FetchUserRightsReturnValue,
  FetchUserRightsParams,
  fetchUserRights,
} from "api/users"
import { StateError } from "state/types"

export const createUserThunk = createAsyncThunk<CreateUserReturnValue, CreateUserParams, StateError>(
  "app/createUser",
  async (payload, thunkAPI) => {
    try {
      const { data } = await postCreateUser(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const assignRolesToUserThunk = createAsyncThunk<
  AssignRolesToUserReturnValue,
  AssignRolesToUserParams,
  StateError
>("app/assignRolesToUser", async (payload, thunkAPI) => {
  try {
    const { data } = await postAssignRolesToUser(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const getUsersSitesThunk = createAsyncThunk<FetchUsersSitesReturnValue, number, StateError>(
  "app/getUsersSites",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchUsersSites(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getUserRightsThunk = createAsyncThunk<FetchUserRightsReturnValue, FetchUserRightsParams, StateError>(
  "app/getUserRights",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchUserRights(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
