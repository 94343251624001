import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchUsers, FetchUsersReturnValue, FetchUsersParams } from "api/users"
import { StateError } from "state/types"

export const getAutoCompleteUsersThunk = createAsyncThunk<FetchUsersReturnValue, FetchUsersParams, StateError>(
  "app/getAutoCompleteUsers",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchUsers(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
