import React, { SyntheticEvent } from "react"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { StyledVideo } from "./style"
import { AssistantExplainerVideoProps } from "./types"

export const AssistantExplainerVideo: React.FC<AssistantExplainerVideoProps> = ({
  width = "100%",
  height = "100%",
}) => {
  const { gtag } = useGoogleTagManager()
  const [progress, setProgress] = React.useState(-1)
  const PROGRESS_INCREMENT = 0.25

  const playHandler = () => {
    if (progress === -1) {
      setProgress(0)
      gtag("Video_played", {
        Video_title: "Assistent_tutorial",
      })
    }
  }

  const seekedHandler = (e: SyntheticEvent<HTMLVideoElement>) => {
    const { currentTime, duration } = e.target as HTMLVideoElement
    const newProgress = Math.floor(currentTime / duration / PROGRESS_INCREMENT)
    setProgress(newProgress)
  }

  const timeUpdateHandler = (e: SyntheticEvent<HTMLVideoElement>) => {
    const { currentTime, duration } = e.target as HTMLVideoElement
    const elapsed = currentTime / duration

    const min = progress * PROGRESS_INCREMENT + PROGRESS_INCREMENT
    const max = min + PROGRESS_INCREMENT

    if (elapsed >= min && elapsed < max) {
      setProgress(oldProgress => oldProgress + 1)
      const percentage = Math.floor(elapsed / PROGRESS_INCREMENT) * PROGRESS_INCREMENT * 100

      gtag("Video", {
        Video_title: "Assistent_tutorial",
        Video_percentage: percentage,
      })
    }
  }

  return (
    <StyledVideo
      onPlay={playHandler}
      onTimeUpdate={timeUpdateHandler}
      onSeeked={seekedHandler}
      width={width}
      height={height}
      controls
    >
      <source src="https://cdn.pau.be/multidesk/video/assistent-explainer.mp4" type="video/mp4"></source>
    </StyledVideo>
  )
}
