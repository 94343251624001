import React from "react"
import { Card, Text, Stack, LinkButton } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { Assistant } from "../Assistant"
import { SaveButton } from "../SaveButton"
import { StyledEditAssistantBox } from "./AssistantFilterSetCreation.style"
import { AssistantDetailFrameProps } from "./AssistantDetailFrame.types"

export const AssistantDetailFrame: React.FC<AssistantDetailFrameProps> = ({
  title,
  filterSet,
  onChange,
  onClickSubmit,
  disabledSubmit,
  saving,
}) => {
  const { t } = useTranslate()

  return (
    <Stack spacing="m" direction="column">
      <Card inset="l">
        {title && (
          <Text as="h2" space="m" variant="display" subdued>
            {title}
          </Text>
        )}
        <StyledEditAssistantBox>
          <Assistant filterSet={filterSet} onChange={onChange} />
        </StyledEditAssistantBox>
      </Card>
      <Stack spacing="m" justifyContent="flex-end">
        <LinkButton to="/assistant" variant="tertiary">
          {t("system.cancel")}
        </LinkButton>
        <SaveButton onClick={onClickSubmit} disabled={disabledSubmit} saving={saving}>
          {saving ? t("system.saving") : t("system.save")}
        </SaveButton>
      </Stack>
    </Stack>
  )
}
