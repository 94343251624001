import { compareDesc } from "date-fns"

export const checkExpiry = (expired_at: string | null | undefined) => {
  const now = new Date()

  if (!expired_at) {
    return false
  }

  const expiresAtDate = new Date(expired_at)
  const result = compareDesc(now, expiresAtDate)
  return result <= 0
}
