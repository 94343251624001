import React, { createContext, useCallback, useContext, useState } from "react"
import { ActiveColumnContextType, ActiveColumnProviderType, Column } from "./ActiveColumnProvider.types"

const ActiveColumnContext = createContext<ActiveColumnContextType>({} as ActiveColumnContextType)

export const useActiveColumnContext = () => useContext(ActiveColumnContext)

export const ActiveColumnContextProvider = ({ children }: ActiveColumnProviderType) => {
  const [columns, setColumns] = useState<Column[]>([])

  const registerColumn = useCallback(
    (column: string) => {
      if (columns.find(c => c.key === column)) return
      setColumns(currentColumns => [...currentColumns, { key: column, enabled: true }])
    },
    [columns, setColumns],
  )

  const toggleColumn = useCallback((column: string) => {
    setColumns(currentColumns => {
      return currentColumns.map(c =>
        column === c.key
          ? {
              ...c,
              enabled: !c.enabled,
            }
          : c,
      )
    })
  }, [])

  return (
    <ActiveColumnContext.Provider
      value={{
        columns,
        registerColumn,
        toggleColumn,
      }}
    >
      {children}
    </ActiveColumnContext.Provider>
  )
}
