import { ObjectToStringFlag } from "utils/types"

export const featureFlags = {
  albatross: {
    assistantPageChipsEnabled: false,
    assistantPageDisabledFilterSetsEnabled: false,
    assistantToggleActiveFilterSetEnabled: false,
  },
  zapata: {
    ticketEditButtonEnabled: false,
    ticketCreateUrgencyDeadlineEnabled: false,
    qmsResultsTableCheckboxesEnabled: false,
  },
}

export type FeatureFlags = {
  [version in keyof typeof featureFlags]: {
    [flag in keyof typeof featureFlags[version]]: boolean
  }
}

export type FeatureFlagsType = typeof featureFlags
export type Version = keyof FeatureFlagsType
export type Flag<VersionType extends Version> = keyof FeatureFlagsType[VersionType]
export type FeatureFlagStrings = ObjectToStringFlag<FeatureFlags>
