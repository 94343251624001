import React from "react"
import { useSelector } from "react-redux"
import { IconAdd, Stack } from "@paudigital/multidesk-ui-kit"
import TableOverview from "app/components/TableOverview"
import { useTranslate } from "app/hooks/translate"
import { getRequestStatus, getTickets } from "state/tickets/selectors"
import { FilterSetPicker } from "app/components/FilterSetPicker"
import { useDevice } from "utils/device"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useGetTicketsColumns, useGetTicketsRows, useGetSortOptions } from "./TicketsTableOverview.data"

const TicketsTableOverview: React.FC = () => {
  const { isDesktop } = useDevice()
  const tickets = useSelector(getTickets)
  const { t } = useTranslate()
  const sortOptions = useGetSortOptions()
  const columns = useGetTicketsColumns()
  const rows = useGetTicketsRows(tickets)
  const isPending = useGetPendingStatus(getRequestStatus)

  return (
    <Stack direction="column" spacing="2xs">
      <FilterSetPicker subjectType="support_tickets" />
      <TableOverview
        id="support_tickets"
        data={{
          columns,
          rows,
        }}
        heading={t("system.overview")}
        sortOptions={sortOptions}
        isLoading={isPending}
        pagination={{
          last: tickets.last,
          current: tickets.page,
          total: tickets.total,
        }}
        actionButton={{
          icon: IconAdd,
          to: "/tickets/create",
          label: isDesktop ? t("system.ticket.create") : t("system.new"),
        }}
      />
    </Stack>
  )
}

export default TicketsTableOverview
