import { api } from "api"
import { setSearchParams } from "api/utils/searchParams"
import { AxiosPromise } from "axios"
import { FetchInvoicesParams, FetchInvoicesReturnValue } from "./invoices.types"
import { FetchInvoiceStatisticsData } from "./invoices.types"

export const fetchInvoices = ({
  customerGroupId,
  ...searchParams
}: FetchInvoicesParams): AxiosPromise<FetchInvoicesReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/invoices`, {
    method: "GET",
    params: setSearchParams(searchParams),
  })
}

export const fetchInvoiceStatistics = ({ customerGroupId }: FetchInvoiceStatisticsData): AxiosPromise => {
  return api(`/customer_groups/${customerGroupId}/invoices/statistics`, {
    method: "GET",
  })
}
