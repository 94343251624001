import React, { useCallback, useEffect, useMemo } from "react"
import { Stack, Tab } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useRouterContext } from "app/providers/RouterProvider"
import { Outlet, RouteObject, useNavigate, useOutletContext, useLocation, matchRoutes } from "react-router-dom"
import { useModuleAccess } from "app/hooks/useModuleAccess"
import { useHasFeatureFlagActive } from "app/hooks/useHasFeatureFlagActive"
import { Tabs } from "./Tabs"
import { TabsRouterProps } from "./types"

export const TabsRouter: React.FC<TabsRouterProps> = ({ align, label, initialPath, depth = 1, children }) => {
  const { t } = useTranslate()
  const location = useLocation()
  const navigate = useNavigate()
  const outletContext = useOutletContext<{ depth: number }>()
  const currentDepth = outletContext?.depth || depth
  const { route, routes, matchingRoutes } = useRouterContext()
  const parentRouteMath = matchRoutes(routes, location.pathname)?.at(currentDepth)!
  const { hasAccessByModuleName } = useModuleAccess()
  const hasFeatureFlagActive = useHasFeatureFlagActive()

  const { route: parentRoute } = parentRouteMath

  const handleNavigate = useCallback((route: RouteObject) => navigate(route.path || "", { replace: true }), [navigate])

  const childrenWithAccess = useMemo(
    () =>
      parentRoute.children?.filter(
        (child: RouteObject) => (!child.module || hasAccessByModuleName(child.module)) && hasFeatureFlagActive(child),
      ) || [],
    [parentRoute, hasAccessByModuleName, hasFeatureFlagActive],
  )

  const getActiveTabIndex = () => {
    if (!matchingRoutes) return 0

    const routeMatch = matchingRoutes[currentDepth + 1]
    const routePath = routeMatch?.route.path || matchingRoutes[currentDepth].route.children![0].path

    const indexOfChild = childrenWithAccess?.findIndex(child => child?.path === routePath)

    return indexOfChild >= 0 ? indexOfChild : 0
  }

  useEffect(() => {
    const child = route.children?.find(({ path }) => path === initialPath)

    if (!child) return

    if (child.module && childrenWithAccess.length > 0) {
      handleNavigate(childrenWithAccess![0])
      return
    }

    handleNavigate(child)
  }, [route, handleNavigate, initialPath, childrenWithAccess])

  return (
    <Stack spacing="s" direction="column">
      <Tabs
        align={align}
        activeTab={getActiveTabIndex()}
        activeTabChanged={id => handleNavigate(childrenWithAccess[id])}
        title={label ? t(label) : ""}
      >
        {childrenWithAccess?.map((route, index) => (
          <Tab key={index} onClick={() => handleNavigate(route)}>
            {route.label ? t(route.label) : ""}
          </Tab>
        ))}
      </Tabs>
      {children}
      <Outlet context={{ depth: currentDepth + 1 }} />
    </Stack>
  )
}
