import React from "react"
import { StyledPageMainGridItem } from "./style"

export const PageMainGridItem: React.FC = ({ children }) => {
  return (
    <StyledPageMainGridItem colSpan={[1, { m: 2 }]} order={[-1, { m: 0 }]}>
      {children}
    </StyledPageMainGridItem>
  )
}
