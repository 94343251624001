import {
  Button,
  Pagination as UIPagination,
  PaginationItemClickEvent,
  SlideIn,
  Stack,
  useMediaQuery,
} from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useTheme } from "styled-components"
import { SelectItemsPerPage } from "./Pagination.style"
import { PaginationItemChangeEvent, PaginationProps } from "./Pagination.types"

const itemsPerPageOptions = [10, 25, 50]

const Pagination: React.FC<PaginationProps> = ({ last, current }) => {
  const { t } = useTranslate()
  const { queryParams, updateQueryParams } = useUpdateQueryParams()
  const theme = useTheme()
  const isMUp = useMediaQuery(theme.breakpoints.up("m"))
  const isSUp = useMediaQuery(theme.breakpoints.up("s"))
  const isXSUp = useMediaQuery(theme.breakpoints.up("xs"))
  const paginationItemCount = isMUp ? 7 : isXSUp ? 5 : 1
  const [slideInVisible, setSlideInVisible] = useState(false)
  const currentPerPageValue = queryParams.get("perPage") || itemsPerPageOptions[0]
  const currentPage = parseInt(queryParams.get("page") ?? "1")

  const handlePerPageChange: ChangeEventHandler<HTMLSelectElement> = event => {
    updateQueryParams({
      page: "1",
      perPage: event.target.value,
    })
  }

  const handlePaginationItemChange: PaginationItemClickEvent = ({ page }) => {
    if (!isXSUp && page === currentPage) {
      setSlideInVisible(true)
      return
    }

    handlePageChange(page)
  }

  const handlePageChange: PaginationItemChangeEvent = page => {
    updateQueryParams({
      page: page.toString(),
    })
  }

  useEffect(() => {
    if (current > last) {
      updateQueryParams({
        page: last.toString(),
      })
    }
  }, [current, last, updateQueryParams])

  return (
    <>
      {isSUp ? (
        <SelectItemsPerPage
          id={t("system.pagination")}
          label={t("system.pagination")}
          onChange={handlePerPageChange}
          value={currentPerPageValue}
          labelHidden
        >
          {itemsPerPageOptions.map((option: number) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </SelectItemsPerPage>
      ) : (
        <SlideIn title={t("system.select_page")} isOpen={slideInVisible} onClose={() => setSlideInVisible(false)}>
          <Stack direction="column" spacing="s">
            {Array.from(Array(last).keys(), i => i + 1).map(page => (
              <Button
                key={`pagination-page-${page}`}
                variant={currentPage === page ? "primary" : "secondary"}
                onClick={() => {
                  handlePageChange(page)
                  setSlideInVisible(false)
                }}
              >
                {page}
              </Button>
            ))}
          </Stack>
        </SlideIn>
      )}
      <UIPagination pages={last} page={currentPage} max={paginationItemCount} onChange={handlePaginationItemChange} />
    </>
  )
}

export default Pagination
