import { Icon, Stack } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledNoResults = styled(Stack)`
  flex: 1;
`

export const StyledSearchIcon = styled(Icon)`
  font-size: 2.5rem;
  --icon-color-primary: ${({ theme }) => theme.color.neutral["base-dark"]};
`
