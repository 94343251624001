import { useTranslate } from "app/hooks/translate"
import { FilterItemProps } from "../components/FilterItem/FilterItem.types"

export const useAssistantGrammar = ({ filterItem, isFirstItem }: Omit<FilterItemProps, "subject">) => {
  const { t } = useTranslate()
  const preposition = isFirstItem ? t("system.preposition") : t("system.plus")
  const name = `${preposition} ${t(`system.${filterItem.name}`).toLocaleLowerCase()}`
  const value = t(`system.${filterItem.name}.${filterItem.value}`)
  const sentence = ` ${name} ${value.toLocaleLowerCase()}`

  return { name, value, sentence }
}
