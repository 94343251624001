import styled from "styled-components"

export const StyledDocumentDetailFileThumbnailWrapper = styled.div`
  --items-amount: 1;
  grid-template-columns: repeat(var(--items-amount), 1fr);
  display: grid;
  gap: ${({ theme }) => theme.space.s};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    --items-amount: 2;
  }

  ${({ theme }) => theme.breakpoints.up("s")} {
    --items-amount: 4;
  }
`
