const statusGraphConfig = {
  chartWidth: 150,
  chartHeight: 16,
}

export const getConfig = () => {
  return {
    ...statusGraphConfig,
  }
}
