import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchCustomerGroups, FetchCustomerGroupsReturnValue } from "api/customerGroups"

import {
  fetchContactPersonForUser,
  FetchContactPersonReturnValue,
  fetchModulesFromCustomer,
  FetchModulesReturnValue,
  fetchProfile,
  FetchProfileReturnValue,
  updateProfile,
  UpdateProfileReturnValue,
  UpdateProfileThunkParameterType,
  updatePassword,
  UpdatePasswordReturnValue,
  UpdatePasswordThunkParameterType,
} from "api/user"
import { StateError } from "state/types"

export const getCustomerGroupsThunk = createAsyncThunk<FetchCustomerGroupsReturnValue, void, StateError>(
  "app/getCustomerGroups",
  async (_, thunkAPI) => {
    try {
      const { data } = await fetchCustomerGroups()
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getModulesThunk = createAsyncThunk<FetchModulesReturnValue, number, StateError>(
  "app/getModules",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchModulesFromCustomer(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getContactPersonThunk = createAsyncThunk<FetchContactPersonReturnValue, number, StateError>(
  "app/getContactPerson",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchContactPersonForUser(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getProfileThunk = createAsyncThunk<FetchProfileReturnValue, number, StateError>(
  "app/getProfile",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchProfile(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const updateProfileThunk = createAsyncThunk<
  UpdateProfileReturnValue,
  UpdateProfileThunkParameterType,
  StateError
>("app/updateProfile", async (payload, thunkAPI) => {
  const { userId, profile } = payload
  try {
    const { data } = await updateProfile({ userId, profile })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const updatePasswordThunk = createAsyncThunk<
  UpdatePasswordReturnValue,
  UpdatePasswordThunkParameterType,
  StateError
>("app/updatePassword", async (payload, thunkAPI) => {
  const { userId, "new-password": newPassword, "old-password": oldPassword } = payload

  try {
    const { data } = await updatePassword({ userId, "new-password": newPassword, "old-password": oldPassword })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
