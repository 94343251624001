import { FieldValues, Path, UseFormSetError } from "react-hook-form"
import { ApiInputError, ThunkErrors } from "state/types"

interface MapThunkErrorsParams<T extends FieldValues> {
  errors: ThunkErrors
  setError: UseFormSetError<T>
}

function isApiInputError(error: ThunkErrors): error is ApiInputError {
  return error.hasOwnProperty("errors") && typeof (error as ApiInputError).errors === "object"
}

export const parseThunkErrors = <T extends FieldValues>({ errors, setError }: MapThunkErrorsParams<T>) => {
  if (!isApiInputError(errors)) return
  Object.entries(errors.errors).forEach(([name, messages]: [string, string[]]) => {
    messages.forEach((message, index) => {
      setError(`${name}.${index}` as Path<T>, {
        type: "api",
        message: message,
      })
    })
  })
}
