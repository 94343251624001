import React from "react"
import { Checkbox as UICheckbox } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { CheckBoxProps } from "./Checkbox.types"

export const Checkbox: React.FC<CheckBoxProps> = ({ name, label, ...rest }) => {
  const { register } = useFormContext()
  const errorMessage = useGetErrorMessage(name)

  const fieldLabel = useCreateFormFieldLabel(name, label)

  return (
    <UICheckbox
      label={fieldLabel}
      errorMessage={errorMessage}
      hasError={!!errorMessage}
      {...register(name)}
      {...rest}
    />
  )
}
