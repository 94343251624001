import React from "react"
import { Dialog, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroup } from "state/user/selectors"
import { useCreateFormConfig } from "app/components/Form"
import { deleteUserThunk } from "state/users/thunks"
import { DeleteUserDialogProps } from "./types"

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ isOpen, setIsOpen, userId }) => {
  const { t } = useTranslate()
  const formConfig = useCreateFormConfig({})
  const dispatch = useDispatch()
  const { id: customerGroupId } = useSelector(getActiveCustomerGroup)

  const handleDeleteUser = async () => {
    if (!customerGroupId) return
    dispatch(deleteUserThunk({ userId, customerGroupId }))
  }

  return (
    <Dialog
      actions={[
        {
          content: t("system.cancel"),
          onClick: () => setIsOpen(false),
          variant: "tertiary",
        },
        {
          content: t("system.delete.confirm"),
          variant: "primary",
          onClick: () => formConfig.context.handleSubmit(handleDeleteUser)(),
        },
      ]}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t("system.users.delete")}
    >
      <Text>{t("user.delete.confirm.text")}</Text>
    </Dialog>
  )
}

export default DeleteUserDialog
