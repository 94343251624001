import { useKeycloak } from "@react-keycloak/web"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getActiveDivision, getActiveSite } from "state/sites/selectors"
import { getCustomerSitesThunk } from "state/sites/thunks"
import { getActiveCustomerGroup, getModulesRequestStatus } from "state/user/selectors"
import { getProfileThunk, getModulesThunk, getCustomerGroupsThunk } from "state/user/thunks"
import { getAssistantConfigurationThunk } from "state/assistantConfiguration/thunks"
import LoadingScreen from "app/components/LoadingScreen"
import { AppContextProps } from "./AppProvider.types"

const AppProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const activeSite = useSelector(getActiveSite)
  const activeDivision = useSelector(getActiveDivision)
  const activeCustomerGroup = useSelector(getActiveCustomerGroup)

  const { isPending: isLoadingModules } = useSelector(getModulesRequestStatus)

  const {
    keycloak: { idTokenParsed },
  } = useKeycloak()
  const { userid, name } = idTokenParsed!

  useEffect(() => {
    dispatch(getProfileThunk(userid))
  }, [userid, dispatch])

  useEffect(() => {
    if (!activeCustomerGroup) {
      dispatch(getCustomerGroupsThunk())
      return
    }

    dispatch(getCustomerSitesThunk(activeCustomerGroup.id))
    dispatch(getModulesThunk(activeCustomerGroup.id))
    dispatch(getAssistantConfigurationThunk())
  }, [activeCustomerGroup, dispatch])

  if (!activeSite || !activeDivision || isLoadingModules) return <LoadingScreen />

  return (
    <AppContext.Provider value={{ userName: name, userId: userid, site: activeSite, division: activeDivision }}>
      {children}
    </AppContext.Provider>
  )
}

export const AppContext = React.createContext<AppContextProps>({} as AppContextProps)
export const useAppContext = () => React.useContext(AppContext)

export default AppProvider
