import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getUserThunk, updateUserThunk } from "./thunks"
import { UserDetailState } from "./types"

const initialState: UserDetailState = {
  userDetail: { data: null, status: RequestStatus.IDLE },
}

export const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getUserThunk.pending, state => {
      state.userDetail.status = RequestStatus.PENDING
    })
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      state.userDetail.data = action.payload
      state.userDetail.status = RequestStatus.FULFILLED
    })
    builder.addCase(getUserThunk.rejected, (state, action) => {
      state.userDetail.error = action.payload?.message
      state.userDetail.status = RequestStatus.REJECTED
    })
    builder.addCase(updateUserThunk.pending, state => {
      state.userDetail.status = RequestStatus.PENDING
    })
    builder.addCase(updateUserThunk.fulfilled, state => {
      state.userDetail.status = RequestStatus.FULFILLED
    })
    builder.addCase(updateUserThunk.rejected, (state, action) => {
      state.userDetail.error = action.payload?.message
      state.userDetail.status = RequestStatus.REJECTED
    })
  },
})

export const { reset } = userDetailSlice.actions
export default userDetailSlice.reducer
