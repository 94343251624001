import React from "react"
import { Button, IconButton, IconMore, SlideIn, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { TableActionsProps } from "../TableActions.types"

export const ActionsSlideIn: React.FC<TableActionsProps> = ({ actions, disabled }) => {
  const { t } = useTranslate()
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  const [slideInVisible, setSlideInVisible] = React.useState(false)

  return (
    <>
      <IconButton
        ref={buttonRef}
        variant="secondary"
        icon={IconMore}
        disabled={disabled}
        onClick={() => setSlideInVisible(true)}
      />
      <SlideIn title={t("system.actions")} isOpen={slideInVisible} onClose={() => setSlideInVisible(false)}>
        <Stack direction="column" spacing="s">
          {actions.map(({ label, onClick }, idx) => (
            <Button
              key={`table_action_${idx}`}
              variant="secondary"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick?.(e)
                setSlideInVisible(false)
              }}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </SlideIn>
    </>
  )
}
