import { ToastNotification } from "app/components/ToastNotification"
import { Locales, translate } from "i18n"
import { toast } from "react-toastify"

export type NotificationMessageType = {
  translationKeys?: boolean
  message: string
  title?: string
  // This will be caught by parseThunkErrors - src/app/components/Form/utils/parseThunkErrors.ts:15
  errors?: Record<string, string[]>
}

export type NotificationType = "info" | "success" | "warning" | "error"

export const triggerNotification = (type: NotificationType, notification: NotificationMessageType) => {
  const { title, message } = notification

  toast(ToastNotification, {
    autoClose: 10000,
    closeButton: false,
    icon: false,
    data: { title, message, type },
    type,
  })
}

export const triggerSuccessNotification = (message: string, locale?: Locales) => {
  const title = translate("system.success", undefined, locale)

  triggerNotification("success", { title, message })
}

export const triggerErrorNotification = (message: string, locale?: Locales) => {
  const title = translate("system.error", undefined, locale)
  triggerNotification("error", { title, message })
}
