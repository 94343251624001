import React, { CSSProperties } from "react"
import { GenericBreakPointsProps, Text } from "@paudigital/multidesk-ui-kit"
import { ModuleIcon } from "app/components/ModuleIcon"
import { useTranslate } from "app/hooks/translate"
import { useModuleTheme } from "app/hooks/useModuleTheme"
import { DashboardCard } from "../DashboardCard"
import { ModuleCardProps } from "./types"
import { StyledNumber } from "./style"

export const ModuleCard: React.FC<ModuleCardProps> = ({ children, module, gridArea, data, highlightText, link }) => {
  const { t } = useTranslate()
  const hasHighlightText = typeof highlightText !== "undefined"
  const moduleTheme = useModuleTheme(module)
  const gridAreaData = gridArea
    ? ([undefined, { m: `${gridArea}` }] as GenericBreakPointsProps<CSSProperties["gridArea"]>)
    : undefined
  const moduleLink = link
    ? {
        ...link,
        label: t(`dashboard.module.${module}.link`),
      }
    : undefined

  return (
    <DashboardCard
      heading={t(`system.${module}`)}
      icon={<ModuleIcon module={module} />}
      area={gridAreaData}
      link={moduleLink}
    >
      <Text>{t(`dashboard.module.${module}.subtitle`, { ...data })}</Text>
      {hasHighlightText && (
        <StyledNumber variant="title" {...moduleTheme}>
          {highlightText}
        </StyledNumber>
      )}
      {children}
    </DashboardCard>
  )
}
