import React from "react"
import { Card, Grid, Text, Stack, Notification, Lozenge } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useFormatDate } from "app/hooks/useFormateDate"
import { ReadOnlyValue } from "app/components/ReadOnlyValue"
import { checkExpiry } from "utils/checkExpiry"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import { DocumentDetailFileField } from "../../DocumentDetailFileField"
import { LibraryViewProps } from "./LibraryView.types"

const LibraryView: React.VFC<LibraryViewProps> = ({ document }) => {
  const { t } = useTranslate()
  const { formatDateVocabulary } = useFormatDate()
  const isExpired = checkExpiry(document.expires_at)

  return (
    <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
      <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
        <Card inset="l">
          <Stack direction="column" spacing="l">
            <Stack.Item>
              <Text variant="sectionTitle" space="s">
                {t("system.publication_date")}
              </Text>
              <Text>{formatDateVocabulary(document.created_at)}</Text>
            </Stack.Item>
            <Stack.Item>
              <Text variant="sectionTitle" space="s">
                {t("system.expired")}
              </Text>
              <Stack direction="row" spacing="2xs">
                <Text>{formatDateVocabulary(document.expires_at!)}</Text>
                {isExpired && <Lozenge variant="error">{t("system.expired")}</Lozenge>}
              </Stack>
            </Stack.Item>
          </Stack>
        </Card>
        <Card inset="l">
          <Text variant="sectionTitle" space="s">
            {t("system.author")}
          </Text>
          <Text>{document.uploaded_by.author}</Text>
        </Card>
      </Grid.FlexItem>
      <PageMainGridItem>
        <Card inset="l">
          <Stack spacing="l" direction="column">
            {isExpired && (
              <Notification
                type="error"
                title={t("system.document_library.expired")}
                message={t("system.document_library.expired.helper_text", {
                  expired_on: formatDateVocabulary(document.expires_at!),
                })}
                hideIcon={true}
                hideCloseButton={true}
              />
            )}
            <DocumentDetailFileField />
            <ReadOnlyValue label={t("system.document.name")} value={document.name} />
            <ReadOnlyValue label={t("system.category")} value={t(`system.document_types.${document.category}`)} />
            <ReadOnlyValue label={t("system.visibility")} value={t(`system.visibility.${document.visibility}`)} />
          </Stack>
        </Card>
      </PageMainGridItem>
    </Grid>
  )
}

export default LibraryView
