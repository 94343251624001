import { SankeyLink, SankeyLinkMinimal, SankeyNode } from "d3-sankey"
import { SankeyChartDataLink, SankeyChartDataNode, ValidSankeyLink, ValidSankeyNode } from "../types"

export const isSankeyNode = (
  source: SankeyLinkMinimal<SankeyChartDataNode, SankeyChartDataLink>["source"],
): source is SankeyNode<SankeyChartDataNode, SankeyChartDataLink> => {
  return typeof source === "object"
}

export const isValidLink = (link: SankeyLink<SankeyChartDataNode, SankeyChartDataLink>): link is ValidSankeyLink => {
  return isSankeyNode(link.source) && isSankeyNode(link.target) && "width" in link && "y0" in link && "y1" in link
}

export const isValidNode = (node: SankeyNode<SankeyChartDataNode, SankeyChartDataLink>): node is ValidSankeyNode => {
  return "x0" in node && "x1" in node && "y0" in node && "y1" in node
}

export const sortNodes =
  (linkDomain: string[]) =>
  (
    a: SankeyNode<SankeyChartDataNode, SankeyChartDataLink>,
    b: SankeyNode<SankeyChartDataNode, SankeyChartDataLink>,
  ) => {
    // The last group of nodes should be the percentages
    // And those should be ordered from highest to lowest
    if (linkDomain.includes(a.name) && linkDomain.includes(b.name)) {
      return linkDomain.indexOf(b.name) - linkDomain.indexOf(a.name)
    }
    return 0
  }

export const sortLinks =
  (linkDomain: string[]) =>
  (
    a: SankeyLink<SankeyChartDataNode, SankeyChartDataLink>,
    b: SankeyLink<SankeyChartDataNode, SankeyChartDataLink>,
  ) => {
    if (a.source === b.source) {
      return linkDomain.indexOf(b.score) - linkDomain.indexOf(a.score)
    }
    if (a.target === b.target) {
      if (
        isSankeyNode(a.source) &&
        isSankeyNode(b.source) &&
        linkDomain.indexOf(b.score) - linkDomain.indexOf(a.score) === 0
      ) {
        return (a.source.index || 0) - (b.source.index || 0)
      }
      return linkDomain.indexOf(b.score) - linkDomain.indexOf(a.score)
    }

    return 0
  }
