import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSupportTicketStatistics } from "state/supportTicketStatistics/selectors"
import { getSupportTicketStatisticsThunk } from "state/supportTicketStatistics/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { ModuleCard } from "../../ModuleCard"

const SupportTicketsCard: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { in_progress } = useSelector(getSupportTicketStatistics)

  useEffect(() => {
    dispatch(getSupportTicketStatisticsThunk({ activeCustomerGroupId }))
  }, [dispatch, activeCustomerGroupId])

  return (
    <ModuleCard module="support_tickets" highlightText={in_progress} link={{ to: "/tickets?status=in_progress" }} />
  )
}

export default SupportTicketsCard
