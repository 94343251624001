import { Dropdown } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledFilterSetPickerList = styled.div`
  position: relative;
`

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
`

export const StyledOverFlowWrapper = styled.div`
  overflow-y: auto;
  max-height: 30rem;
  padding: ${({ theme }) => theme.space.m};
`
