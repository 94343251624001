import { api } from "api"
import { setSearchParams } from "api/utils/searchParams"
import { AxiosPromise } from "axios"
import {
  FetchSupportTicketsReturnValue,
  FetchSingleSupportTicketReturnValue,
  CreateSupportTicketReturnValue,
  FetchTicketsParams,
  PatchSupportTicket,
  CreateTicketType,
  AddCommentType,
  SingleTicketHistoryType,
  FetchSupportTicketStatisticsData,
} from "./tickets.types"

export const fetchSupportTickets = ({
  activeCustomerGroupId,
  ...searchParams
}: FetchTicketsParams): AxiosPromise<FetchSupportTicketsReturnValue> => {
  return api(`/customer_groups/${activeCustomerGroupId}/support_tickets`, {
    method: "GET",
    params: setSearchParams(searchParams),
  })
}

export const fetchSingleSupportTicket = (
  customerGroupId: number,
  ticketId: number,
): AxiosPromise<FetchSingleSupportTicketReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/support_tickets/${ticketId}`, {
    method: "GET",
  })
}

export const patchSupportTicket = ({
  customerGroupId,
  ticketId,
  data,
}: PatchSupportTicket): AxiosPromise<FetchSingleSupportTicketReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/support_tickets/${ticketId}`, {
    method: "PATCH",
    data,
  })
}

export const createSupportTicket = (
  customerGroupId: number,
  ticket: CreateTicketType,
): AxiosPromise<CreateSupportTicketReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/support_tickets`, {
    method: "POST",
    data: ticket,
  })
}

export const addCommentToTicketHistory = (
  customerGroupId: number,
  ticketId: number,
  comment: AddCommentType,
): AxiosPromise<[]> => {
  return api(`/customer_groups/${customerGroupId}/support_tickets/${ticketId}/comment`, {
    method: "POST",
    data: comment,
  })
}

export const fetchTicketHistory = (
  customerGroupId: number,
  ticketId: number,
): AxiosPromise<SingleTicketHistoryType[]> => {
  return api(`/customer_groups/${customerGroupId}/support_tickets/${ticketId}/history`, {
    method: "GET",
  })
}

export const fetchSupportTicketStatistics = ({
  activeCustomerGroupId,
}: FetchSupportTicketStatisticsData): AxiosPromise => {
  return api(`/customer_groups/${activeCustomerGroupId}/support_tickets/statistics`, {
    method: "GET",
  })
}
