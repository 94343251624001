import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { Layout } from "app/components/Layout"
import AppProvider from "app/providers/AppProvider"
import LoadingScreen from "../../components/LoadingScreen"

const ProtectedPage: React.FC = (): JSX.Element => {
  const { keycloak, initialized } = useKeycloak()
  const { authenticated, login } = keycloak

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600)
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {}
    }
  }, [initialized, keycloak])

  if (!initialized) {
    return <LoadingScreen />
  }

  if (!authenticated) {
    login()
    return <LoadingScreen />
  }

  return (
    <AppProvider>
      <Layout>
        <Outlet />
      </Layout>
    </AppProvider>
  )
}

export default ProtectedPage
