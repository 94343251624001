import React from "react"
import { AnyObjectSchema } from "yup"
import { FieldValues } from "react-hook-form"
import { ValidationContextProps, ValidationContextProviderProps } from "./types"

function ValidationContextProvider<T extends FieldValues>({ schema, children }: ValidationContextProviderProps<T>) {
  return (
    <ValidationContext.Provider value={{ schema: schema as AnyObjectSchema }}>{children}</ValidationContext.Provider>
  )
}

export const ValidationContext = React.createContext<ValidationContextProps>({} as ValidationContextProps)
export const useValidationContext = () => React.useContext(ValidationContext)

export default ValidationContextProvider
