import styled from "styled-components"
import { StyledTabsProps } from "./Tabs.types"

export const StyledTabs = styled.div<StyledTabsProps>`
  isolation: isolate;
  display: flex;
  justify-content: ${({ align }) => align || "space-between"};
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("m")} {
    flex-direction: row;
    align-items: center;
  }

  &:after {
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: ${({ theme }) => `${theme.borderWidth.s}`};
    z-index: -1;
    background-color: ${({ theme }) => `${theme.color.neutral.base}`};
  }
`
