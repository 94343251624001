import styled from "styled-components"
import { Grid, TimelineIcon } from "@paudigital/multidesk-ui-kit"

export const StyledInvoicesGrid = styled(Grid)`
  grid-template-columns: 1fr auto;
`

export const StyledInvoicesIcon = styled(TimelineIcon)`
  align-self: center;
  background: ${({ theme }) => theme.color.neutral["base-light"]};
  padding: ${({ theme }) => theme.space["2xs"]};
  border-radius: 50%;
  border: ${({ theme }) => theme.space["3xs"]} solid ${({ theme }) => theme.color.neutral["light"]};
  font-size: ${({ theme }) => theme.fontSize6};
  font-size: 2.5rem;
`
