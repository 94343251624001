import Keycloak from "keycloak-js"
import { getEnvVariable } from "./utils/getEnvVariable"

const KEYCLOACK_AUTH_URL = getEnvVariable("REACT_APP_KEYCLOAK_AUTH_URL")
const KEYCLOAK_AUTH_REALM = getEnvVariable("REACT_APP_KEYCLOAK_AUTH_REALM")
const KEYCLOAK_AUTH_CLIENT_ID = getEnvVariable("REACT_APP_KEYCLOAK_AUTH_CLIENT_ID")

const keycloak = Keycloak({
  url: KEYCLOACK_AUTH_URL,
  realm: KEYCLOAK_AUTH_REALM,
  clientId: KEYCLOAK_AUTH_CLIENT_ID,
})

export default keycloak
