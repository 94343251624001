import React from "react"
import { StatusGraphProps, TooltipState } from "./types"
import { StyledSvg, StyledWrapper } from "./style"
import { Tooltip } from "./Tooltip"
import { getConfig } from "./config"
import { Bars } from "./Bars"

export const StatusGraph: React.FC<StatusGraphProps> = ({ data }) => {
  const { chartWidth, chartHeight } = getConfig()
  const [tooltip, setTooltip] = React.useState<TooltipState>(null)

  return (
    <StyledWrapper>
      <StyledSvg width={chartWidth} height={chartHeight}>
        <Bars data={data} height={chartHeight} width={chartWidth} setTooltip={setTooltip} />
      </StyledSvg>
      {tooltip && (
        <Tooltip x={tooltip.x} y={tooltip.y}>
          {tooltip.text}
        </Tooltip>
      )}
    </StyledWrapper>
  )
}
