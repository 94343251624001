import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getAssistantConfigurationThunk } from "./thunks"
import { AssistantConfigurationState } from "./types"

const initialState: AssistantConfigurationState = {
  configuration: { data: [], status: RequestStatus.IDLE },
}

export const assistantConfigurationSlice = createSlice({
  name: "assistantConfiguration",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAssistantConfigurationThunk.pending, state => {
      state.configuration.status = RequestStatus.PENDING
    })
    builder.addCase(getAssistantConfigurationThunk.fulfilled, (state, action) => {
      state.configuration.data = action.payload
      state.configuration.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAssistantConfigurationThunk.rejected, (state, action) => {
      state.configuration.error = action.payload?.message
      state.configuration.status = RequestStatus.REJECTED
    })
  },
})

export default assistantConfigurationSlice.reducer
