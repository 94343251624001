import React from "react"
import { Select } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { getHygiLocations } from "state/hygiLocations/selectors"
import { useNavigate, useParams } from "react-router-dom"
import { useGetLocations } from "../../hooks/useGetLocation"

export const LocationSelect: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const locations = useSelector(getHygiLocations)
  const { inspectionId } = useParams()

  useGetLocations()

  return (
    <Select
      id="location"
      label={t("system.location")}
      onChange={e => {
        navigate(`/hygi/inspections/${inspectionId}/locations/${e.target.value}`)
      }}
    >
      {locations.map(location => (
        <option key={location.id} value={location.id}>
          {location.name}
        </option>
      ))}
    </Select>
  )
}
