import { FilePreview, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDocumentDetail, getDocumentStarRequestStatus } from "state/documentDetail/selectors"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { starDocumentFromDetailThunk } from "state/documentDetail/thunks"
import { downloadDocument } from "../utils/downloadDocument"
import { DocumentDetailFileThumbnail } from "../DocumentDetailFileThumbnail"
import { getProtectedImage } from "../utils/getProtectedImages"
import { StyledDocumentDetailFileThumbnailWrapper } from "./DocumentDetailFileField.style"

export function DocumentDetailFileField() {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { isPending } = useSelector(getDocumentStarRequestStatus)
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [protectedDesktopImage, setProtectedDesktopImage] = useState<string>()
  const document = useSelector(getDocumentDetail)
  const desktopURL = document?.preview_urls?.desktop

  useEffect(() => {
    if (!isVisible || protectedDesktopImage || !desktopURL) return
    setIsLoading(true)
    getProtectedImage(desktopURL)
      .then((value: string) => {
        setProtectedDesktopImage(value)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isVisible, protectedDesktopImage, desktopURL])

  const handleStar = () => {
    if (!document || isPending) return
    dispatch(
      starDocumentFromDetailThunk({
        customerGroupId,
        data: { "document-id": document.id, starred: !document.starred },
      }),
    )
  }

  return (
    <div>
      <Text variant="sectionTitle" space="s">
        {t("system.file")}
      </Text>
      {document && (
        <>
          <StyledDocumentDetailFileThumbnailWrapper>
            <DocumentDetailFileThumbnail onClickThumbnail={() => setIsVisible(true)} />
          </StyledDocumentDetailFileThumbnailWrapper>
          <FilePreview
            downloadAction={{
              content: t("file_preview.download"),
              onDownload: () => downloadDocument(document),
            }}
            onStar={handleStar}
            onClose={() => setIsVisible(false)}
            isVisible={isVisible}
            isLoading={isLoading}
            previewNotSupportedMessage={t("file_preview.not_supported")}
            previewNotFoundMessage={t("file_preview.not_found")}
            files={[
              {
                ...document,
                fileName: document.name,
                fileType: document.file.type,
                isStarred: document.starred,
                srcSet: protectedDesktopImage,
              },
            ]}
          />
        </>
      )}
    </div>
  )
}
