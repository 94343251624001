import React from "react"
import { Select } from "app/components/Form/components/Select"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslate } from "app/hooks/translate"
import { FormAssignRolesToUser } from "../../../../pages/UserCreation/types"
import { useUserFormContent } from "../.."
import { UserSiteRoleSelectProps } from "./UserSiteRoleSelect.types"

export const UserSiteRoleSelect: React.FC<UserSiteRoleSelectProps> = ({ division, module, siteIndex }) => {
  const { t } = useTranslate()
  const { disabled } = useUserFormContent()
  const { control } = useFormContext<FormAssignRolesToUser>()
  const { fields } = useFieldArray({
    control,
    name: `sites.${siteIndex}.roles`,
  })
  const index = fields.findIndex(field => field.division_id === division.id && field.module_id === module.id)

  return (
    <Select
      disabled={disabled}
      name={`sites[${siteIndex}].roles.[${index}].role_id`}
      id="role-select"
      label={t("system.a11y.role_select")}
      labelHidden
    >
      {module.available_roles.map(role => (
        <option key={role.id} value={role.id}>
          {role.name}
        </option>
      ))}
    </Select>
  )
}
