import { Dialog } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { CancelDialogProps } from "./types"

export const CancelDialog: React.FC<CancelDialogProps> = ({
  isOpen,
  setIsOpen,
  onConfirmCancel,
  cancelAction,
  children,
}) => {
  const { t } = useTranslate()
  return (
    <Dialog
      title={t("system.cancel")}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      actions={[
        cancelAction,
        {
          content: t("system.cancel.confirm"),
          onClick: onConfirmCancel,
          variant: "primary",
        },
      ]}
    >
      {children}
    </Dialog>
  )
}
