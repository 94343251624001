import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userCreateReducer.createdUser.status)
}

export const getUsersSites = (state: RootState) => {
  return state.userCreateReducer.usersSites.data
}

export const getCreatedUser = (state: RootState) => {
  return state.userCreateReducer.createdUser.data
}

export const getUserRights = (state: RootState) => {
  return state.userCreateReducer.userRights.data
}
