import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getTrapDetail = (state: RootState) => {
  return state.hygiTrapDetailReducer.trapDetail.data
}

export const getTrapHistory = (state: RootState) => {
  return state.hygiTrapDetailReducer.trapHistory.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.hygiTrapDetailReducer.trapDetail.status)
}
