import { boolean, object, string } from "yup"

export const defaultUserSchema = {
  email: string().required().email(),
  first_name: string().required().min(2).max(100),
  last_name: string().required().min(2).max(100),
  phonenumber: string().phone().required(),
  is_active: boolean().required(),
}

export const userSchema = object(defaultUserSchema)
