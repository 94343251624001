import React, { useEffect } from "react"
import { Form, useCreateFormConfig } from "app/components/Form"
import { useTranslate } from "app/hooks/translate"
import { SubmitHandler } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { assignRolesToUserThunk, createUserThunk, getUserRightsThunk } from "state/userCreate/thunks"
import { triggerSuccessNotification } from "utils/notifications"
import { getRequestStatus, getUserRights } from "state/userCreate/selectors"
import { UserFormContent } from "app/components/UserFormContent"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { userSchema } from "app/components/UserFormContent/schema"
import { resetUserRights } from "state/userCreate/slice"
import { Grid, LinkButton } from "@paudigital/multidesk-ui-kit"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { SaveButton } from "app/components/SaveButton"
import { FormCreateUserType } from "./types"

const UserManagementCreationPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { isPending } = useSelector(getRequestStatus)
  const navigate = useNavigate()
  const [queryParameters] = useSearchParams()
  const userWithRightsId = queryParameters.get("userWithRightsId")
  const userRights = useSelector(getUserRights)
  const config = useCreateFormConfig({
    schema: userSchema,
    defaultValues: { sites: userRights || [] },
  })
  const { handleSubmit } = config
  const dispatch = useDispatch()
  const saveUser: SubmitHandler<FormCreateUserType> = async payload => {
    const { email, first_name, is_active, last_name, phonenumber, ...siteProps } = payload
    const { sites } = siteProps
    const { id: user_id } = await handleSubmit(
      createUserThunk({
        customerGroupId,
        user: { email, first_name, is_active, last_name, phonenumber },
      }),
    )
    await handleSubmit(
      assignRolesToUserThunk({
        customerGroupId,
        data: {
          user_id,
          sites,
        },
      }),
    )
    triggerSuccessNotification(t("system.users.create.success"))
    navigate(`/management/users/${user_id}`)
  }

  useEffect(() => {
    if (!userWithRightsId) return
    dispatch(getUserRightsThunk({ customerGroupId, userId: userWithRightsId }))
    return () => {
      dispatch(resetUserRights())
    }
  }, [userWithRightsId, customerGroupId, dispatch])

  return (
    <Form config={config} onSubmit={saveUser}>
      <UserFormContent>
        <Grid.Item colSpan={[1, { m: 2 }]}>
          <ButtonWrapper>
            <LinkButton to="/management/users" variant="tertiary">
              {t("system.cancel")}
            </LinkButton>
            <SaveButton variant="primary" type="submit" saving={isPending}>
              {isPending ? t("system.saving") : t("system.users.save")}
            </SaveButton>
          </ButtonWrapper>
        </Grid.Item>
      </UserFormContent>
    </Form>
  )
}

export default UserManagementCreationPage
