import styled from "styled-components"
import { TooltipProps } from "./types"

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledSvg = styled.svg`
  border-radius: ${({ theme }) => theme.borderRadius.s};
`

export const StyledTooltip = styled.div<TooltipProps>`
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${({ x }) => x}px;
  top: calc(-50% - 12px);
  background-color: ${({ theme }) => theme.color.neutral["base-dark"]};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) => `${theme.space["3xs"]} ${theme.space.xs}`};
  color: ${({ theme }) => theme.color.neutral.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  width: max-content;

  &:before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: ${({ theme }) => `calc(50% - ${theme.space["2xs"]})`};
    border-width: ${({ theme }) => `${theme.space["2xs"]} ${theme.space["2xs"]} 0 ${theme.space["2xs"]};`};
    border-style: solid;
    border-color: ${({ theme }) => theme.color.neutral["base-dark"]} transparent transparent transparent;
  }
`
