import { Card, Stack } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledDashboardCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledDashboardCardSectionsStack = styled(Stack)`
  flex: 1;
`
