import React, { useState } from "react"
import { Dropdown, IconButton, IconMore, Stack } from "@paudigital/multidesk-ui-kit"
import { ActionButton } from "app/components/ActionButton"
import { TableActionsProps } from "../TableActions.types"

export const ActionsDropdown: React.FC<TableActionsProps> = ({ actions, disabled }) => {
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const [dropdownIsVisible, setDropdownIsVisible] = React.useState(false)
  const handleOnClose = () => setDropdownIsVisible(false)

  return (
    <>
      <IconButton
        ref={setButtonElement}
        variant="secondary"
        icon={IconMore}
        disabled={disabled}
        onClick={() => setDropdownIsVisible(!dropdownIsVisible)}
      />
      <Dropdown
        inset="2xs"
        shadow="dark"
        isVisible={dropdownIsVisible}
        referenceElement={buttonElement}
        placement="bottom-end"
        offset={[0, 8]}
        onClose={handleOnClose}
      >
        <Stack direction="column" alignItems="start">
          {actions.map(({ label, onClick }) => (
            <ActionButton
              key={label}
              onClick={e => {
                onClick?.(e)
                setDropdownIsVisible(false)
              }}
            >
              {label}
            </ActionButton>
          ))}
        </Stack>
      </Dropdown>
    </>
  )
}
