import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchTraps, FetchTrapsParams, FetchTrapsReturnValue } from "api/hygi"
import { StateError } from "state/types"

export const getTrapsThunk = createAsyncThunk<FetchTrapsReturnValue, FetchTrapsParams, StateError>(
  "app/getTraps",
  async ({ customerGroupId, locationId, inspectionId }, thunkAPI) => {
    try {
      const { data } = await fetchTraps({ customerGroupId, locationId, inspectionId })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
