import React from "react"
import { useModuleAccess } from "app/hooks/useModuleAccess"
import { Navigate } from "react-router-dom"
import { StyledMainWithAccess } from "./MainWithAccess.style"

export const MainWithAccess: React.FC = ({ children }) => {
  const { hasAccessByPathname } = useModuleAccess()

  return hasAccessByPathname ? (
    <StyledMainWithAccess>{children}</StyledMainWithAccess>
  ) : (
    <Navigate replace={true} to="/no-access" />
  )
}
