import styled, { css } from "styled-components"
import { StyledTextProps } from "./types"

const updateTextCSS = css<StyledTextProps>`
  display: inline;
  margin: 0 ${({ theme }) => theme.space["2xs"]} 0 0;
`

export const StyledText = styled.p<StyledTextProps>`
  margin: 0;
  ${({ type }) => type === "update" && updateTextCSS};
`

export const StyledDate = styled(StyledText)`
  color: ${({ theme }) => theme.color.neutral["base-dark"]};
`

export const StyledAuthor = styled(StyledText)`
  display: ${({ type }) => (!type ? "none" : "block")};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const StyledMessage = styled(StyledText)`
  overflow-wrap: anywhere;
  &:before {
    content: ${({ type }) => (!type ? `` : `"\\0022"`)};
  }
  &:after {
    content: ${({ type }) => (!type ? `` : `"\\0022"`)};
  }
`
