import { createAsyncThunk } from "@reduxjs/toolkit"
import { addCommentToTicketHistory, AddSupportTicketCommentReturnValue, fetchTicketHistory } from "api/tickets"
import { StateError } from "state/types"
import { AddCommentThunkParameterType } from "./types"

export const addSupportTicketCommentThunk = createAsyncThunk<
  AddSupportTicketCommentReturnValue,
  AddCommentThunkParameterType,
  StateError
>("app/addSupportTicketComment", async (payload, thunkAPI) => {
  const { customerGroupId, ticketId, comment } = payload
  try {
    await addCommentToTicketHistory(customerGroupId, ticketId, comment)
    const { data } = await fetchTicketHistory(customerGroupId, ticketId)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
