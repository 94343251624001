import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchUser, FetchUserParams, FetchUserReturnValue, updateUser, UpdateUserParams } from "api/users"
import { StateError } from "state/types"

export const getUserThunk = createAsyncThunk<FetchUserReturnValue, FetchUserParams, StateError>(
  "app/getUser",
  async ({ customerGroupId, userId }, thunkAPI) => {
    try {
      const { data } = await fetchUser({ customerGroupId, userId })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const updateUserThunk = createAsyncThunk<void, UpdateUserParams, StateError>(
  "app/updateUser",
  async (payload, thunkAPI) => {
    const { customerGroupId, user } = payload
    try {
      const { data } = await updateUser({ customerGroupId, user })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
