import { RootState } from "state/store"

export const getHasTranslationsExposed = (state: RootState) => {
  return state.appReducer.hasTranslationsExposed
}

export const getFeatureFlags = (state: RootState) => {
  return state.appReducer.featureFlags
}

export const getHiddenColumns = (state: RootState) => {
  return state.appReducer.hiddenColumns
}
