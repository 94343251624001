import React from "react"
import { useParams } from "react-router-dom"
import { StyledFloorPlanThumbnail, StyledImage, StyledText } from "./style"
import { FloorPlanThumbnailProps } from "./types"

export const FloorPlanThumbnail: React.FC<FloorPlanThumbnailProps> = ({ id, name, location_thumbnail_url }) => {
  const { inspectionId } = useParams()

  return (
    <StyledFloorPlanThumbnail to={`/hygi/inspections/${inspectionId}/locations/${id}`}>
      <StyledImage src={location_thumbnail_url} />
      <StyledText>{name}</StyledText>
    </StyledFloorPlanThumbnail>
  )
}
