import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getAssistantFilterSetThunk, patchAssistantEditThunk } from "./thunks"
import { AssistantEditState } from "./types"

const initialState: AssistantEditState = {
  assistantEdit: { data: undefined, status: RequestStatus.IDLE },
}

export const assistantEditSlice = createSlice({
  name: "assistantEdit",
  initialState,
  reducers: {
    setAssistantEdit: (state, action) => {
      state.assistantEdit.data = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getAssistantFilterSetThunk.pending, state => {
      state.assistantEdit.status = RequestStatus.PENDING
    })
    builder.addCase(getAssistantFilterSetThunk.fulfilled, (state, action) => {
      state.assistantEdit.data = action.payload
      state.assistantEdit.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAssistantFilterSetThunk.rejected, (state, action) => {
      state.assistantEdit.error = action.payload?.message
      state.assistantEdit.status = RequestStatus.REJECTED
    })
    builder.addCase(patchAssistantEditThunk.pending, state => {
      state.assistantEdit.status = RequestStatus.PENDING
    })
    builder.addCase(patchAssistantEditThunk.fulfilled, (state, action) => {
      state.assistantEdit.data = { ...action.payload, isSaved: true }
      state.assistantEdit.status = RequestStatus.FULFILLED
    })
    builder.addCase(patchAssistantEditThunk.rejected, (state, action) => {
      state.assistantEdit.error = action.payload?.message
      state.assistantEdit.status = RequestStatus.REJECTED
    })
  },
})

export const { setAssistantEdit, reset } = assistantEditSlice.actions
export default assistantEditSlice.reducer
