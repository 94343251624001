export const getDomainAndRange = (stacks: [string, string | number][]): [string[], (string | number)[]] => {
  return stacks.reduce(
    (prev, curr) => {
      const [domains, ranges] = prev
      const [domain, range] = curr
      return [
        [...domains, domain],
        [...ranges, range],
      ]
    },
    [[], []],
  )
}
