import React from "react"
import RouterProvider from "app/providers/RouterProvider"
import useKeyboardShortcut from "use-keyboard-shortcut"
import { useDispatch } from "react-redux"
import { toggleHasTranslationsExposed } from "state/app/slice"

const TRANSLATION_KEY_TOGGLE_SHORTCUT = ["Shift", "F8"]

const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const handleTranslationKeyToggle = () => {
    dispatch(toggleHasTranslationsExposed())
  }

  useKeyboardShortcut(TRANSLATION_KEY_TOGGLE_SHORTCUT, handleTranslationKeyToggle, {
    overrideSystem: true,
    repeatOnHold: false,
  })

  return <RouterProvider />
}

export default App
