import { AutoComplete } from "app/components/Form/components/AutoComplete"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getAutoCompleteUsers, getRequestStatus } from "state/userAutoComplete/selectors"
import { getAutoCompleteUsersThunk } from "state/userAutoComplete/thunks"

export function UserReplaceWithExistingUserField() {
  const { t } = useTranslate()
  const existingUsers = useSelector(getAutoCompleteUsers)
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { isPending } = useSelector(getRequestStatus)

  const handleSearchInputChange = (input: string) => {
    if (input.length <= 0) return
    dispatch(
      getAutoCompleteUsersThunk({
        activeCustomerGroupId,
        q: input,
      }),
    )
  }

  return (
    <AutoComplete
      label={t("system.user.select_user")}
      id="selectUser"
      name="data.user_id"
      onSearchInputChange={handleSearchInputChange}
      options={existingUsers.items.map(user => {
        return { value: user.id, label: `${user.first_name} ${user.last_name}` }
      })}
      noOptionsMessage={() => t("system.no_results.title", { subject: t("system.users").toLowerCase() })}
      placeholder={`${t("system.users.search")}...`}
      loadingMessage={() => t("system.searching", { subject: t("system.users").toLowerCase() })}
      isLoading={isPending}
    />
  )
}
