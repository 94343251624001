import styled from "styled-components"
import { Card, Grid, Stack } from "@paudigital/multidesk-ui-kit"
import { Marker } from "../Marker"
import { StyledMarkerProps } from "./types"

export const StyledHygiCard = styled(Card)`
  flex: 1;
  display: flex;
  min-height: 0;
`

export const StyledHygiSVGMapContainer = styled(Stack)`
  overflow: hidden;
  flex: 1;
  min-height: 0;
`

export const StyledHygiSVGMap = styled.svg`
  width: 100%;
  min-height: 0;
`

export const StyledHygiMarker = styled(Marker)<StyledMarkerProps>`
  cursor: pointer;
`

export const StyledHygiGrid = styled(Grid)`
  flex: 1;

  ${({ theme }) => theme.breakpoints.up("m")} {
    grid-template-columns: 20rem 1fr;
  }
`

export const StyledHygiAside = styled.div`
  overflow: auto;
`

export const StyledHygiMain = styled(Stack)`
  position: relative;
  border: ${({ theme }) => theme.borderWidth.s} ${({ theme }) => theme.color.neutral["base-light"]} solid;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: ${({ theme }) => theme.space.s};
  min-height: 0;
`
