import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQMSYearlyGraphDataThunk, getQMSYearlyTableDataThunk } from "state/qms/thunk"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getQMSYearlyGraphData, getQMSYearlyTableData, getRequestStatusYearlyTable } from "state/qms/selectors"
import { useTheme } from "styled-components"
import { Stack } from "@paudigital/multidesk-ui-kit"
import TableOverview from "app/components/TableOverview"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useTranslate } from "app/hooks/translate"
import { BarChart } from "app/components/Chart/BarChart"
import { useGetColumns, useGetRows } from "./YearsTable.data"

const QMSYearsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const yearlyGraphData = useSelector(getQMSYearlyGraphData)
  const yearlyTableData = useSelector(getQMSYearlyTableData)
  const theme = useTheme()

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getQMSYearlyGraphDataThunk(activeCustomerGroupId))
    dispatch(getQMSYearlyTableDataThunk(activeCustomerGroupId))
  }, [activeCustomerGroupId, dispatch])

  const columns = useGetColumns()
  const rows = useGetRows(yearlyTableData)
  const isPending = useGetPendingStatus(getRequestStatusYearlyTable)

  return (
    <Stack direction="column" spacing="m">
      <BarChart
        data={yearlyGraphData}
        stacks={[
          ["< 25%", theme.color.palette.red50],
          ["25-50%", theme.color.palette.orange50],
          ["50-85%", theme.color.palette.yellow50],
          ["> 85%", theme.color.palette.green30],
        ]}
        groupBy="year"
      />
      <TableOverview
        id="years"
        data={{
          columns,
          rows,
        }}
        heading={t("system.table.view")}
        isLoading={isPending}
        pagination={{
          last: yearlyTableData.last,
          current: yearlyTableData.page,
          total: yearlyTableData.total,
        }}
      />
    </Stack>
  )
}

export default QMSYearsPage
