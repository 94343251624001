import React, { PropsWithChildren } from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { StyledLegend, StyledLegendColor, StyledLegendText } from "../style"
import { BarChartLegendProps } from "../types"
import { ReactComponent as IconAverage } from "../icons/average.svg"

export const Legend = <T,>({ stacks }: PropsWithChildren<BarChartLegendProps<T>>) => {
  const { t } = useTranslate()

  return (
    <StyledLegend>
      <Stack spacing="m" wrap={true} alignItems="center">
        <Stack.Item>
          <Stack spacing="2xs" alignItems="center">
            <IconAverage />
            <StyledLegendText>{t("system.average_score")}</StyledLegendText>
          </Stack>
        </Stack.Item>
        {stacks.reverse().map(([label, color]) => (
          <Stack.Item key={`${label}-${color}`}>
            <Stack spacing="2xs" alignItems="center">
              <StyledLegendColor color={String(color)} />
              <StyledLegendText>{label}</StyledLegendText>
            </Stack>
          </Stack.Item>
        ))}
      </Stack>
    </StyledLegend>
  )
}
