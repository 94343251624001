import React from "react"
import { useTranslate } from "app/hooks/translate"
import { useNavigate } from "react-router-dom"
import { TableActions } from "app/components/TableOverview/TableActions"
import DeleteUserDialog from "../DeleteUserDialog"
import { UsersTableActionProps } from "./types"

export const UsersTableActions: React.FC<UsersTableActionProps> = ({ userId }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false)
  const showCloseTicketDialog: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsDeleteUserDialogOpen(true)
  }

  return (
    <>
      <TableActions
        actions={[
          {
            label: t("system.users.create_user_with_rights"),
            onClick: () => navigate(`create?userWithRightsId=${userId}`),
          },
          {
            label: t("system.users.replace"),
            onClick: () => navigate(`replace/${userId}`),
          },
          { label: t("system.users.delete"), onClick: showCloseTicketDialog },
        ]}
      />
      <DeleteUserDialog isOpen={isDeleteUserDialogOpen} setIsOpen={setIsDeleteUserDialogOpen} userId={userId} />
    </>
  )
}
