import { MakeRequired } from "@paudigital/multidesk-ui-kit"
import { Location, matchRoutes, RouteObject } from "react-router-dom"
import { FlatRouteObject } from "./types"

export type RouteObjectWithPathAndLabel = MakeRequired<RouteObject, "path" | "label">
export type GetExistingRoutesFromPathnameFunction = (
  routes: FlatRouteObject[],
  location: Location,
) => RouteObjectWithPathAndLabel[]
export type GetPossibleRoutesFromPathnameFunction = (pathname: string) => string[]
export type GetAllRoutesFunction = (config: RouteObject[]) => FlatRouteObject[]
export type FlattenRoutesFunction = (route: RouteObject) => RouteObject[]
type CheckPathInPossibleRoutesFunction = (route: string, routes: FlatRouteObject[]) => RouteObject

const flattenRoutes: FlattenRoutesFunction = route => {
  return [route, ...(route.children ? route.children.map(flattenRoutes).flat() : [])]
}

export const getAllRoutes: GetAllRoutesFunction = config => config.map(flattenRoutes).flat()

export const getPossibleRoutesFromPathname: GetPossibleRoutesFromPathnameFunction = pathname => {
  if (pathname === "/") return ["/"]
  return pathname.split("/").reduce((currentSegments, _, index, allSegments) => {
    const currentSegment = allSegments.slice(0, index + 1).join("/") || "/"
    return [...currentSegments, currentSegment]
  }, [] as string[])
}

const mapRouteToRouteObject: CheckPathInPossibleRoutesFunction = (possibleRoute, routes) => {
  const matchingRoutes = matchRoutes(routes, possibleRoute)
  const { route } = matchingRoutes!.find(r => r.pathname === possibleRoute)!

  return { ...route, path: possibleRoute }
}

export const getExistingRoutesFromPathname: GetExistingRoutesFromPathnameFunction = (routes, location) => {
  const possibleRoutes = getPossibleRoutesFromPathname(location.pathname)
  const items = possibleRoutes
    .map(route => mapRouteToRouteObject(route, routes))
    .filter(({ label, path }) => !!label && !!path)
  return items as RouteObjectWithPathAndLabel[]
}
