import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getInvoicesThunk } from "./thunks"
import { InvoiceState } from "./types"

const initialDocumentData: InvoiceState["invoices"]["data"] = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialState: InvoiceState = {
  invoices: { data: initialDocumentData, status: RequestStatus.IDLE },
}

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInvoicesThunk.pending, state => {
      state.invoices.status = RequestStatus.PENDING
    })
    builder.addCase(getInvoicesThunk.fulfilled, (state, action) => {
      state.invoices.data = action.payload
      state.invoices.status = RequestStatus.FULFILLED
    })
    builder.addCase(getInvoicesThunk.rejected, (state, action) => {
      state.invoices.error = action.payload?.message
      state.invoices.status = RequestStatus.REJECTED
    })
  },
})

export default invoicesSlice.reducer
