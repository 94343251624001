import { createIntl, IntlShape } from "react-intl"
import { store } from "state/store"
import nlTranslations from "./translations/nl_BE.translations.json"
import frTranslations from "./translations/fr_BE.translations.json"
import enTranslations from "./translations/en.translations.json"
import { getDefaultLocale } from "./utils/getDefaultLocale"

export enum Locales {
  NL = "nl",
  FR = "fr",
  EN = "en",
}

export const nativeLanguageMapping: Record<Locales, string> = {
  [Locales.NL]: "Nederlands",
  [Locales.FR]: "Français",
  [Locales.EN]: "English",
}

export const locales = Object.values(Locales)

export const translations = {
  [Locales.NL]: nlTranslations,
  [Locales.FR]: frTranslations,
  [Locales.EN]: enTranslations,
}

const intl: Record<Locales, IntlShape> = {
  [Locales.NL]: createIntl({
    locale: Locales.NL,
    messages: translations[Locales.NL],
  }),
  [Locales.FR]: createIntl({
    locale: Locales.FR,
    messages: translations[Locales.FR],
  }),
  [Locales.EN]: createIntl({
    locale: Locales.EN,
    messages: translations[Locales.EN],
  }),
}

export const translate = (message: string, values?: Record<string, string | number> | undefined, locale?: Locales) => {
  const defaultLocale = locale || store.getState().userReducer.profile.data?.preferred_language || getDefaultLocale()
  return intl[defaultLocale].formatMessage({ id: message }, values)
}

export default translations
