import { MAX_ALLOWED_FILE_SIZE, TOTAL_MAX_ALLOWED_FILE_SIZE } from "app/constants"
import { bytesToString } from "utils/bytesToString"
import * as yup from "yup"

export interface ErrorMessage {
  id: string
  values: object
}

yup.setLocale({
  mixed: {
    required: () => ({ id: "required" }),
  },
  string: {
    min: values => ({ id: "string.min", values }),
    max: values => ({ id: "string.max", values }),
  },
})

// Method signatures defined in @types/yup/index.d.ts
yup.addMethod<yup.AnySchema>(yup.mixed, "file", function () {
  return this.test("file", { id: "file.required" }, (files: FileList) => files && files.length === 1)
})

yup.addMethod<yup.AnySchema>(yup.mixed, "files", function (max: number) {
  return this.test("files", { id: "file.max", values: { max } }, (files: FileList) => files && files.length <= max)
})

yup.addMethod<yup.AnySchema>(
  yup.mixed,
  "fileSize",
  function (individualBytes: number = MAX_ALLOWED_FILE_SIZE, totalBytes: number = TOTAL_MAX_ALLOWED_FILE_SIZE) {
    return this.test({
      name: "fileSize",
      message: {
        id: "file.size",
        values: { max: bytesToString(individualBytes), multiple: 2 },
      },
      test: function (fileList: FileList) {
        const files = [...fileList]
        const multiple = files.length > 1 ? 2 : 1

        const someFilesAreTooLarge = files.some(file => file.size > individualBytes)
        if (someFilesAreTooLarge) {
          return this.createError({
            message: {
              id: "file.size",
              values: { max: bytesToString(individualBytes), multiple },
            },
          })
        }

        const combinedFileSize = files.reduce((total, file) => total + file.size, 0)
        if (combinedFileSize > totalBytes) {
          return this.createError({
            message: {
              id: "file.size.total",
              values: { maxTotal: bytesToString(totalBytes) },
            },
          })
        }

        return true
      },
    })
  },
)

yup.addMethod<yup.AnySchema>(yup.mixed, "phone", function () {
  return this.test("phone", { id: "phone" }, (input: string) => {
    const phoneRegex = /^\+[^0][0-9 ]{1,}$/g
    return phoneRegex.test(input)
  })
})
