import React from "react"
import { FilterSetSkeletonLoaderItem } from "../FilterSetSkeletonLoaderItem"

export const FilterSetSkeletonLoader: React.FC = () => {
  return (
    <>
      {[...Array(5)].map((_, i) => (
        <React.Fragment key={i}>
          <FilterSetSkeletonLoaderItem />
        </React.Fragment>
      ))}
    </>
  )
}
