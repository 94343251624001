import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteUser, DeleteUserPayload, fetchUsers, FetchUsersParams, FetchUsersReturnValue } from "api/users"
import { StateError } from "state/types"

export const getUsersThunk = createAsyncThunk<FetchUsersReturnValue, FetchUsersParams, StateError>(
  "app/getUsers",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchUsers(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const deleteUserThunk = createAsyncThunk<string, DeleteUserPayload, StateError>(
  "app/deleteUser",
  async (payload, thunkAPI) => {
    try {
      deleteUser(payload)
      // TODO:
      // When we implement the api, should fetch the users overview again?
      // Because we will have a page of of 19 or less items if we don't
      // And the pagination will probably be broken
      // because the first user of next page will be the last user of the page we deleted a user on
      return payload.userId
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
