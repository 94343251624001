import { createSlice } from "@reduxjs/toolkit"
import { DocumentStatisticsType } from "api/documents"
import { RequestStatus } from "state/types"
import { getDocumentStatisticsThunk } from "./thunks"
import { DocumentStatisticsState } from "./types"

const initialDocumentStatisticsData: DocumentStatisticsType = {
  new: 0,
}

const initialState: DocumentStatisticsState = {
  documentStatistics: { data: initialDocumentStatisticsData, status: RequestStatus.IDLE },
}

export const DocumentsStatisticsSlice = createSlice({
  name: "documentStatistics",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDocumentStatisticsThunk.pending, state => {
      state.documentStatistics.status = RequestStatus.PENDING
    })
    builder.addCase(getDocumentStatisticsThunk.fulfilled, (state, action) => {
      state.documentStatistics.data = action.payload
      state.documentStatistics.status = RequestStatus.FULFILLED
    })
    builder.addCase(getDocumentStatisticsThunk.rejected, (state, action) => {
      state.documentStatistics.error = action.payload?.message
      state.documentStatistics.status = RequestStatus.REJECTED
    })
  },
})

export default DocumentsStatisticsSlice.reducer
