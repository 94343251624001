import React, { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IconButton, Stack, IconChevronDown, IconChevronUp } from "@paudigital/multidesk-ui-kit"
import { AssistantFilterSet } from "api/assistant"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getAssistantFilterSetsThunk } from "state/assistant/thunks"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { getAssistantConfigurationBySubject } from "state/assistantConfiguration/selectors"
import { resetAssistantSlice } from "state/assistant/slice"
import { Assistant } from "../Assistant"
import { FilterSetPickerProps } from "./types"
import { StyledCard } from "./style"
import { FilterSetPickerList } from "./FilterSetPickerList"

export const FilterSetPicker: React.FC<FilterSetPickerProps> = ({ subjectType }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const subjectAssistantConfiguration = useSelector(getAssistantConfigurationBySubject(subjectType))
  const { setQueryParams, getAllQueryParams } = useUpdateQueryParams()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const [isFilterSetPickerOpen, setIsFilterSetPickerOpen] = useState(false)
  const { q, sort, page, perPage, ...rest } = getAllQueryParams()
  const report = Object.keys(rest).map(key => ({ name: key, value: rest[key] }))
  const filterSet = { subject_type: subjectType, report }
  const reportComponentNames = subjectAssistantConfiguration?.components.map(component => component.name) || []
  const existingFilterSetReports = filterSet.report.filter(report => {
    return reportComponentNames.includes(report.name)
  })
  const filterSetWithExistingComponents = {
    ...filterSet,
    filter_set: existingFilterSetReports,
  }

  const toggleDropdown = (e: SyntheticEvent) => {
    e.stopPropagation()
    setIsFilterSetPickerOpen(previousState => !previousState)
  }

  const handleCloseDropdown = () => setIsFilterSetPickerOpen(false)

  const selectFilterSet = (filterSet: AssistantFilterSet) => {
    const newQueryParams = filterSet.filter_set.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})
    setQueryParams({ q, sort, page, perPage, ...newQueryParams })
    setIsFilterSetPickerOpen(false)
  }

  useEffect(() => {
    activeCustomerGroupId &&
      dispatch(
        getAssistantFilterSetsThunk({
          customerGroupId: activeCustomerGroupId,
          params: { subject_type: subjectType },
        }),
      )
    return () => {
      dispatch(resetAssistantSlice())
    }
  }, [activeCustomerGroupId, dispatch, subjectType])

  return (
    <div ref={setReferenceElement} onClick={handleCloseDropdown}>
      <StyledCard inset="s">
        <Stack spacing="2xs">
          <Assistant filterSet={filterSetWithExistingComponents} onChange={selectFilterSet} />
          <IconButton
            variant="tertiary"
            icon={isFilterSetPickerOpen ? IconChevronUp : IconChevronDown}
            onClick={toggleDropdown}
          />
        </Stack>
      </StyledCard>
      <FilterSetPickerList
        referenceElement={referenceElement}
        isOpen={isFilterSetPickerOpen}
        onClose={handleCloseDropdown}
        onSelect={selectFilterSet}
      />
    </div>
  )
}
