import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import {
  getModulesThunk,
  getContactPersonThunk,
  getProfileThunk,
  updateProfileThunk,
  updatePasswordThunk,
  getCustomerGroupsThunk,
} from "./thunks"
import { UserState } from "./types"

const initialState: UserState = {
  customerGroups: { data: [], status: RequestStatus.IDLE },
  modules: { data: ["dashboard", "assistant", "user_management", "settings"], status: RequestStatus.IDLE },
  contactPerson: { data: null, status: RequestStatus.IDLE },
  profile: { data: null, status: RequestStatus.IDLE },
  password: { data: null, status: RequestStatus.IDLE },
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getModulesThunk.pending, state => {
      state.modules.status = RequestStatus.PENDING
    })
    builder.addCase(getModulesThunk.fulfilled, (state, action) => {
      state.modules.data = [...initialState.modules.data, ...action.payload]
      state.modules.status = RequestStatus.FULFILLED
    })
    builder.addCase(getModulesThunk.rejected, (state, action) => {
      state.modules.error = action.payload?.message
      state.modules.status = RequestStatus.REJECTED
    })
    builder.addCase(getCustomerGroupsThunk.pending, state => {
      state.customerGroups.status = RequestStatus.PENDING
    })
    builder.addCase(getCustomerGroupsThunk.fulfilled, (state, action) => {
      state.customerGroups.data = action.payload
      state.customerGroups.status = RequestStatus.FULFILLED
    })
    builder.addCase(getCustomerGroupsThunk.rejected, (state, action) => {
      state.customerGroups.error = action.payload?.message
      state.customerGroups.status = RequestStatus.REJECTED
    })
    builder.addCase(getContactPersonThunk.pending, state => {
      state.contactPerson.status = RequestStatus.PENDING
    })
    builder.addCase(getContactPersonThunk.fulfilled, (state, action) => {
      state.contactPerson.data = action.payload
      state.contactPerson.status = RequestStatus.FULFILLED
    })
    builder.addCase(getContactPersonThunk.rejected, (state, action) => {
      state.contactPerson.error = action.payload?.message
      state.contactPerson.status = RequestStatus.REJECTED
    })
    builder.addCase(getProfileThunk.pending, state => {
      state.profile.status = RequestStatus.PENDING
    })
    builder.addCase(getProfileThunk.fulfilled, (state, action) => {
      state.profile.data = action.payload
      state.profile.status = RequestStatus.FULFILLED
    })
    builder.addCase(getProfileThunk.rejected, (state, action) => {
      state.profile.error = action.payload?.message
      state.profile.status = RequestStatus.REJECTED
    })
    builder.addCase(updateProfileThunk.pending, state => {
      state.profile.status = RequestStatus.PENDING
    })
    builder.addCase(updateProfileThunk.fulfilled, (state, action) => {
      state.profile.data = action.payload
      state.profile.status = RequestStatus.FULFILLED
    })
    builder.addCase(updateProfileThunk.rejected, (state, action) => {
      state.profile.error = action.payload?.message
      state.profile.status = RequestStatus.REJECTED
    })
    builder.addCase(updatePasswordThunk.pending, state => {
      state.password.status = RequestStatus.PENDING
    })
    builder.addCase(updatePasswordThunk.fulfilled, state => {
      state.password.status = RequestStatus.FULFILLED
    })
    builder.addCase(updatePasswordThunk.rejected, (state, action) => {
      state.password.error = action.payload?.message
      state.password.status = RequestStatus.REJECTED
    })
  },
})

export default userSlice.reducer
