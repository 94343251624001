import React from "react"
import { Dialog, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { AssistantExplainerVideo } from "app/components/AssistantExplainerVideo"
import { useTranslate } from "app/hooks/translate"
import { AssistantDialogProps } from "./types"

export const AssistantDialog: React.FC<AssistantDialogProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslate()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      actions={[{ variant: "tertiary", content: t("system.back_to_overview"), onClick: () => setIsOpen(false) }]}
      title={t("dashboard.assistant.dialog.title")}
    >
      <Stack spacing="m" direction="column">
        <Text>{t("dashboard.assistant.dialog.subtitle")}</Text>
        <AssistantExplainerVideo height="450" />
      </Stack>
    </Dialog>
  )
}
