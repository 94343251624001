import { useTranslate } from "app/hooks/translate"
import { useFormContext, get, FieldError, Merge, FieldErrorsImpl } from "react-hook-form"
import { ErrorMessage } from "validation"

// An `error` can be any of the possible returned types of FieldErrors
// Which implements `FieldErrorsImpl`
const isFieldError = (error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>): error is FieldError => {
  return typeof (error as FieldError).message === "string"
}

export const useGetErrorMessage = (name: string): string | string[] | undefined => {
  const { t } = useTranslate()
  const { formState } = useFormContext()
  const error = get(formState.errors, name)

  if (!error || typeof error !== "object") return

  if (Array.isArray(error)) {
    return error.map(error => error.message)
  }

  if (isFieldError(error)) {
    const message = error.message || ""
    return message
  }

  const { message = {} } = error
  const { id, values } = message as unknown as ErrorMessage

  return t(`error.message.${id}`, { ...values })
}
