import React, { useEffect } from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { useDispatch, useSelector } from "react-redux"
import { getInvoicesThunk } from "state/invoices/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getInvoiceStatisticsThunk } from "state/invoiceStatistics/thunks"
import { useSetDefaultSorting } from "app/hooks/defaultQueryParams"
import { InvoicesOverview } from "./InvoicesOverview"
import { Statistics } from "./Statistics"

const InvoicesPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { getAllQueryParams } = useUpdateQueryParams()
  const queryParams = getAllQueryParams()
  const { sort, q, page, perPage, status, overdue } = queryParams

  useSetDefaultSorting("created_at:desc")

  useEffect(() => {
    if (!activeCustomerGroupId || !sort) return
    dispatch(getInvoicesThunk({ customerGroupId: activeCustomerGroupId, sort, q, page, perPage, status, overdue }))
  }, [activeCustomerGroupId, sort, q, page, perPage, status, overdue, dispatch])

  useEffect(() => {
    dispatch(getInvoiceStatisticsThunk({ customerGroupId: activeCustomerGroupId }))
  }, [dispatch, activeCustomerGroupId])

  return (
    <Stack spacing="m" direction="column">
      <Statistics />
      <InvoicesOverview />
    </Stack>
  )
}

export default InvoicesPage
