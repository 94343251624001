import { SVGComponentElement } from "@paudigital/multidesk-ui-kit"
import { navigationItems } from "router/navigation"
import { Modules } from "router/types"

export const getModuleIcon = (module: Modules): SVGComponentElement | undefined => {
  const { top, bottom } = navigationItems
  const navigationItem = [...top, ...bottom].find(item => item.module === module)

  if (!navigationItem) return
  return navigationItem.icon
}
