import { DocumentVisibilityEnum } from "api/documents"
import { mixed, object, string } from "yup"

export const baseDocumentSchema = object({
  name: string().required().max(200),
  category: string().required().nullable(),
  visibility: string().required().oneOf(Object.values(DocumentVisibilityEnum)),
  reason_promotion_to_library: string()
    .nullable()
    .when("visibility", {
      is: DocumentVisibilityEnum.AWAITING_PROMOTION_TO_LIBRARY,
      then: schema => schema.required().min(10).max(254),
      otherwise: schema => schema.notRequired(),
    }),
})

export const createDocumentSchema = baseDocumentSchema.concat(
  object().shape({
    files: mixed().file().fileSize(),
  }),
)
