import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getUserThunk } from "state/userDetail/thunks"
import { reset } from "state/userDetail/slice"
import { getUserDetail } from "state/userDetail/selectors"
import LoadingScreen from "app/components/LoadingScreen"
import { Form, useCreateFormConfig } from "app/components/Form"
import { getRequestStatus } from "state/userReplace/selectors"
import { UserFormContent } from "app/components/UserFormContent"
import { Grid, LinkButton, Notification } from "@paudigital/multidesk-ui-kit"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { SaveButton } from "app/components/SaveButton"
import { SubmitHandler } from "react-hook-form"
import { replaceUserThunk } from "state/userReplace/thunks"
import { useTranslate } from "app/hooks/translate"
import { ReplacingUser } from "api/users"
import { triggerSuccessNotification } from "utils/notifications"
import NotFound from "../NotFound"
import { UserReplaceFields } from "./components/UserReplaceFields"
import { userReplaceSchema } from "./schema"
import { FormReplaceUserType } from "./types"

const UserReplacePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { isRejected } = useSelector(getRequestStatus)
  const { isPending } = useSelector(getRequestStatus)
  const dispatch = useDispatch()
  const { userId: userIdString } = useParams()
  const userId = Number(userIdString)
  const userDetail = useSelector(getUserDetail)
  const { t } = useTranslate()
  const config = useCreateFormConfig({
    schema: userReplaceSchema,
    defaultValues: userDetail || {},
  })
  const { handleSubmit } = config

  const saveReplacingUser: SubmitHandler<FormReplaceUserType> = async payload => {
    const getReplacingUser = (payload: ReplacingUser): ReplacingUser | undefined => {
      const { type } = payload
      if (type === "replace_with_new") {
        return {
          type,
          data: {
            name: payload.data.name,
            email: payload.data.email,
            phonenumber: payload.data.phonenumber,
          },
        }
      } else if (type === "replace_with_existing") {
        return {
          type,
          data: {
            user_id: payload.data.user_id,
          },
        }
      }
    }

    const replacingUser = getReplacingUser(payload)

    if (!replacingUser) return

    const { id } = await handleSubmit(
      replaceUserThunk({
        customerGroupId,
        replacingUser,
      }),
    )

    triggerSuccessNotification(t("system.users.replace.success"))
    navigate(`/management/users/${id}`)
  }

  useEffect(() => {
    if (!customerGroupId) return
    dispatch(getUserThunk({ customerGroupId, userId }))

    return () => {
      dispatch(reset())
    }
  }, [customerGroupId, dispatch, userId])

  if (isRejected && !userDetail) return <NotFound />
  if (!userDetail) return <LoadingScreen />

  return (
    <>
      <Notification type="warning" message={t("system.users.replace.message")} hideCloseButton />
      <Form config={config} onSubmit={saveReplacingUser}>
        <UserFormContent disabled>
          <UserReplaceFields />
          <Grid.Item colSpan={[1, { m: 2 }]}>
            <ButtonWrapper>
              <LinkButton to="/management/users" variant="tertiary">
                {t("system.cancel")}
              </LinkButton>
              <SaveButton variant="primary" type="submit" saving={isPending}>
                {isPending ? t("system.saving") : t("system.users.save_replacing_user")}
              </SaveButton>
            </ButtonWrapper>
          </Grid.Item>
        </UserFormContent>
      </Form>
    </>
  )
}

export default UserReplacePage
