import React from "react"
import { Card, Grid, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { CONTACT_US_URL } from "app/constants"

export const WelcomeMessage: React.FC = () => {
  const { t } = useTranslate()
  return (
    <Grid.Item area={[undefined, { m: "message" }]}>
      <Card inset="l">
        <Stack spacing="m" direction="column">
          <Text variant="title">{t("dashboard.title")}</Text>
          <Text>{t("dashboard.intro")}</Text>
          <Text>{t("dashboard.text")}</Text>
          <a href={CONTACT_US_URL} target="_blank" rel="noreferrer">
            {t("dashboard.link")}
          </a>
        </Stack>
      </Card>
    </Grid.Item>
  )
}
