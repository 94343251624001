import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getHygiLocationsThunk } from "./thunk"
import { HygiState } from "./types"

const initialState: HygiState = { locations: { data: [], status: RequestStatus.IDLE } }

export const HygiLocationsSlice = createSlice({
  name: "hygiLocations",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHygiLocationsThunk.pending, state => {
      state.locations.status = RequestStatus.PENDING
    })
    builder.addCase(getHygiLocationsThunk.fulfilled, (state, action) => {
      state.locations.data = action.payload
      state.locations.status = RequestStatus.FULFILLED
    })
    builder.addCase(getHygiLocationsThunk.rejected, (state, action) => {
      state.locations.error = action.payload?.message
      state.locations.status = RequestStatus.REJECTED
    })
  },
})

export default HygiLocationsSlice.reducer
