import { useRef, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

type RequestStatusSelector = (state: RootState) => ReturnType<typeof getThunkStatus>

export const useGetPendingStatus = (requestStatusSelector: RequestStatusSelector) => {
  const status = useSelector(requestStatusSelector)
  const { isPending: requestIsPending } = status
  const hasBeenPending = useRef(false)
  const [isPending, setIsPending] = useState(true)

  useEffect(() => {
    if (!hasBeenPending.current && requestIsPending) {
      hasBeenPending.current = true
    }
    if (hasBeenPending.current && !requestIsPending) {
      setIsPending(false)
    }
  }, [requestIsPending])

  return isPending
}
