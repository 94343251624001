import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getAssistantFilterSetsThunk, patchAssistantFilterSetThunk } from "./thunks"
import { AssistantState } from "./types"

const initialState: AssistantState = {
  assistant: { data: [], status: RequestStatus.IDLE },
}

export const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    resetAssistantSlice: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getAssistantFilterSetsThunk.pending, state => {
      state.assistant.status = RequestStatus.PENDING
    })
    builder.addCase(getAssistantFilterSetsThunk.fulfilled, (state, action) => {
      state.assistant.data = action.payload
      state.assistant.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAssistantFilterSetsThunk.rejected, (state, action) => {
      state.assistant.error = action.payload?.message
      state.assistant.status = RequestStatus.REJECTED
    })
    builder.addCase(patchAssistantFilterSetThunk.pending, (state, action) => {
      state.assistant.status = RequestStatus.PENDING
      state.assistant.data = state.assistant.data.map(filterSet =>
        filterSet.id === action.meta.arg.newFilterSet.id ? { ...filterSet, isLoading: true } : filterSet,
      )
    })
    builder.addCase(patchAssistantFilterSetThunk.fulfilled, (state, action) => {
      state.assistant.data = state.assistant.data.map(filterSet =>
        filterSet.id === action.payload.id ? action.payload : filterSet,
      )
      state.assistant.status = RequestStatus.FULFILLED
    })
    builder.addCase(patchAssistantFilterSetThunk.rejected, (state, action) => {
      state.assistant.error = action.payload?.message
      state.assistant.status = RequestStatus.REJECTED
    })
  },
})

export const { resetAssistantSlice } = assistantSlice.actions
export default assistantSlice.reducer
