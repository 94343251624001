import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchSupportTicketStatistics,
  FetchSupportTicketStatisticsData,
  FetchSupportTicketStatisticsReturnValue,
} from "api/tickets"
import { StateError } from "state/types"

export const getSupportTicketStatisticsThunk = createAsyncThunk<
  FetchSupportTicketStatisticsReturnValue,
  FetchSupportTicketStatisticsData,
  StateError
>("app/getSupportTicketStatistics", async (payload, thunkAPI) => {
  try {
    const { data } = await fetchSupportTicketStatistics(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
