import { api } from "api"
import axios, { AxiosPromise } from "axios"
import { AssistantConfiguration } from "state/assistantConfiguration/types"
import { AssistantFilterSetReturnValue } from "api/assistant"
import { FetchAssistantFilterSetsParams } from "state/assistant/types"
import { CreateAssistantFilterSetParams } from "state/assistantCreate/types"
import { FetchAssistantFilterSetParams } from "state/assistantEdit/types"

export const fetchAssistantFilterSets = ({
  customerGroupId,
  params,
}: FetchAssistantFilterSetsParams): AxiosPromise<AssistantFilterSetReturnValue[]> => {
  return api(`/customer_groups/${customerGroupId}/assistant/filter_sets`, {
    method: "GET",
    params,
  })
}

export const fetchAssistantFilterSet = ({
  customerGroupId,
  filterSetId,
}: FetchAssistantFilterSetParams): AxiosPromise<AssistantFilterSetReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/assistant/filter_sets/${filterSetId}`, {
    method: "GET",
  })
}

export const fetchAssistantConfiguration = (): AxiosPromise<AssistantConfiguration[]> => {
  //Remove when making PR or when assistant configuration is updated in database
  return axios.get("/mock/assistant_configuration.mock.json").then(res => res)
  // return api(`/assistant/configuration`, {
  //   method: "GET",
  // })
}

export const fetchAssistantFilterSetItemValuesFromUrl = (url: string): AxiosPromise<string[]> => {
  return api(`${url}`, {
    method: "GET",
  })
}

export const createAssistantFilterSet = ({
  customerGroupId,
  filterSet,
}: CreateAssistantFilterSetParams): AxiosPromise<AssistantFilterSetReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/assistant/filter_sets`, {
    method: "POST",
    data: filterSet,
  })
}

export const updateAssistantFilterSet = (
  customerGroupId: number,
  filterSet: AssistantFilterSetReturnValue,
): AxiosPromise<AssistantFilterSetReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/assistant/filter_sets/${filterSet.id}`, {
    method: "PATCH",
    data: filterSet,
  })
}
