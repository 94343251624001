import React from "react"
import { Marker as UIMarker, Stack } from "@paudigital/multidesk-ui-kit"
import { useGetTrapStatusColor } from "./utils"
import { MarkerProps } from "./types"
import { StyledMarkerText } from "./style"

export const Marker: React.FC<MarkerProps> = ({ status, variant, type, indicator, ...rest }) => {
  const color = useGetTrapStatusColor(status)

  return (
    <div {...rest}>
      <Stack direction="column" alignItems="center">
        <UIMarker color={color} variant={variant} indicator={indicator}>
          {status}
        </UIMarker>
        <StyledMarkerText>{type}</StyledMarkerText>
      </Stack>
    </div>
  )
}
