import React from "react"
import { AutoComplete as UIAutoComplete, OnAutoCompleteChange } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { AutoCompleteProps } from "./AutoComplete.types"

export const AutoComplete: React.FC<AutoCompleteProps> = ({ name, label, onChange, ...rest }) => {
  const { setValue, resetField } = useFormContext()

  const errorMessage = useGetErrorMessage(name)

  const fieldLabel = useCreateFormFieldLabel(name, label)

  const handleOnChange: OnAutoCompleteChange = (option, actionMeta) => {
    if (!option) {
      resetField(name)
      setValue(name, undefined)
    } else {
      setValue(name, option.value)
      onChange?.(option, actionMeta)
    }
  }

  return (
    <UIAutoComplete
      label={fieldLabel}
      errorMessage={errorMessage}
      hasError={!!errorMessage}
      onChange={handleOnChange}
      {...rest}
    />
  )
}
