import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { IconButton, Stack } from "@paudigital/multidesk-ui-kit"
import { DocumentItemType } from "api/documents"
import { getDocumentById, getDocumentsStarRequestStatus } from "state/documents/selectors"
import { starDocumentThunk } from "state/documents/thunks"
import { TableActions } from "app/components/TableOverview/TableActions"
import { useTranslate } from "app/hooks/translate"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { downloadDocument } from "app/pages/DocumentDetail/utils/downloadDocument"
import { checkExpiry } from "utils/checkExpiry"
import { DocumentsTableActionsProps } from "./DocumentsTableActions.types"
import { StyledIconStar, StyledIconStarFill } from "./DocumentsTableActions.style"

const DocumentsTableActions: React.FC<DocumentsTableActionsProps> = ({ customerGroupId, documentId }) => {
  const { gtag } = useGoogleTagManager()
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const document = useSelector(getDocumentById(documentId))
  const { isPending } = useSelector(getDocumentsStarRequestStatus)

  const isExpired = checkExpiry(document?.expires_at)

  const handleStar = React.useCallback(
    (document: DocumentItemType) => {
      if (isPending) return
      dispatch(starDocumentThunk({ customerGroupId, data: { "document-id": document.id, starred: !document.starred } }))
    },
    [isPending, customerGroupId, dispatch],
  )

  if (!document) return null

  const handleDocumentDownload: React.MouseEventHandler<HTMLButtonElement> = () => {
    downloadDocument(document)
    gtag("Download_document")
  }

  return (
    <>
      <Stack spacing={"2xs"}>
        <IconButton
          variant="tertiary"
          icon={document.starred ? StyledIconStarFill : StyledIconStar}
          onClick={() => handleStar(document)}
        />
        <TableActions
          actions={[{ label: t("document.download"), onClick: handleDocumentDownload }]}
          disabled={isExpired}
        />
      </Stack>
    </>
  )
}

export default DocumentsTableActions
