import { FieldValues } from "react-hook-form"

export const stringToNumber = <T extends FieldValues>(
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>,
) => {
  const name = e.target.name as keyof T
  const value = parseInt(e.target.value)

  return {
    name,
    value,
  }
}
