import React, { useEffect } from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { getHygiInspectionsThunk } from "state/hygiInspections/thunk"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import InspectionsTableOverview from "./components/InspectionsTableOverview"

const HygiPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const customerGroupId = useSelector(getActiveCustomerGroupId)

  useEffect(() => {
    dispatch(getHygiInspectionsThunk({ customerGroupId }))
  }, [dispatch, customerGroupId])

  return (
    <Stack direction="column" spacing="m">
      <InspectionsTableOverview />
    </Stack>
  )
}

export default HygiPage
