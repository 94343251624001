import { Indicator, Tag } from "@paudigital/multidesk-ui-kit"
import { getBaitIsSelected } from "app/components/Hygi/utils"
import { useGetTrapStatusColor } from "app/components/Marker/utils"
import React from "react"
import { StyledOrderedButton } from "../style"
import { checkIsETrap, getIndicatorColor } from "../utils"
import { BaitButtonProps } from "./types"

export const BaitButton: React.FC<BaitButtonProps> = ({ trap, onTrapClick, selection }) => {
  const isVisible = trap.visible ?? true
  const color = useGetTrapStatusColor(trap.state)

  return (
    <StyledOrderedButton
      key={trap.id}
      onClick={() => onTrapClick?.(trap)}
      isActive={getBaitIsSelected(trap, selection)}
      isVisible={isVisible}
      suffix={
        <>
          {checkIsETrap(trap) ? <Indicator color={getIndicatorColor(trap)} /> : undefined}
          <Tag variant="default" color={color}>
            {trap.state}
          </Tag>
        </>
      }
    >
      {trap.name}
    </StyledOrderedButton>
  )
}
