import {
  FilesChangedErrorMessage,
  FilesChangedEvent,
  FilesChangedMessage,
  FileUploadProps,
} from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { NotificationErrorProps } from "./FileUpload.types"

export const isNotificationError = (error: NotificationErrorProps | string) => typeof error === "object"
export const isError = (error: FilesChangedMessage): error is FilesChangedErrorMessage => error?.type === "error"
export const isErrorMessage = (
  error: NotificationErrorProps | string | undefined,
): error is NotificationErrorProps | string => !!error

export const useMapErrorsToMessages = (props: FileUploadProps) => {
  const { t } = useTranslate()

  const mapErrorToMessage = (error: FilesChangedErrorMessage): NotificationErrorProps | string | undefined => {
    const { reason, rejected } = error
    const invalidFileNames = rejected.map(({ name }) => name).join(", ")

    switch (reason) {
      case "invalidFileTypes":
        return {
          title: t("system.file_upload.invalid_file_type.title"),
          message: t("error.message.file_type", {
            acceptedTypes: props.accept!,
            files: invalidFileNames,
          }),
        }

      case "tooManyFiles":
        return {
          title: t("system.file_upload.too_many_files.title"),
          message: t("error.message.file.max", {
            max: props.max || 1,
            multiple: props.multiple ? 2 : 1,
          }),
        }

      case "duplicateFiles":
        return t("error.message.files.duplicate", {
          multiple: rejected.length > 1 ? 2 : 1,
          files: invalidFileNames,
        })

      default:
        return
    }
  }

  return { mapErrorToMessage }
}

export const useMapErrorsToMessageTypes = (props: FileUploadProps) => {
  const { mapErrorToMessage } = useMapErrorsToMessages(props)

  const mapErrorsToMessageTypes = (event: FilesChangedEvent) => {
    const errors = event.filter(isError)
    const errorMessages = errors.map(err => mapErrorToMessage(err)).filter(isErrorMessage)

    const errorMessagesByType = errorMessages.reduce(
      ([notificationErrors, toastErrors], error) => {
        return isNotificationError(error)
          ? [[...notificationErrors, error], toastErrors]
          : [notificationErrors, [...toastErrors, error]]
      },
      [[], []] as [NotificationErrorProps[], string[]],
    )

    return errorMessagesByType as [NotificationErrorProps[], string[]]
  }

  return { mapErrorsToMessageTypes }
}
