import { TableColumnProps } from "@paudigital/multidesk-ui-kit"
import { QMSYearlyTableData } from "api/qms"
import { useTranslate } from "app/hooks/translate"
import { PaginatedResult } from "state/types"

export const useGetColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "year",
      content: t("system.year"),
      width: "200px",
      sortable: true,
    },
    {
      key: "performed-audits",
      content: t("system.performed_audits"),
      width: "200px",
      sortable: true,
    },
    {
      key: "location",
      content: t("system.location"),
      width: "300px",
      sortable: true,
    },
    {
      key: "score",
      content: t("system.score"),
      sortable: true,
      alignment: "right",
    },
  ]
}

export const useGetRows = (yearlyTableData: PaginatedResult<QMSYearlyTableData>) => {
  return yearlyTableData.items.map(row => ({
    key: `row-${row.id}`,
    cells: [
      {
        key: `cell-${row.id}-${row.year}`,
        content: row.year,
      },
      {
        key: `cell-${row.id}-${row.performed_audits}`,
        content: `${row.performed_audits}/4`,
      },
      {
        key: `cell-${row.id}-${row.location}`,
        content: row.location,
      },
      {
        key: `cell-${row.id}-${row.score}%`,
        content: `${row.score}%`,
      },
    ],
  }))
}
