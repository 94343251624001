import { SingleTicketHistoryType } from "api/tickets"
import { useTimeline } from "app/hooks/timeline"
import { useFormatDate } from "app/hooks/useFormateDate"
import React from "react"
import { StyledAuthor, StyledDate, StyledMessage } from "./style"

export const TicketHistoryTimelineItem: React.FC<SingleTicketHistoryType> = historyItem => {
  const { formatTimelineMessage } = useTimeline()
  const { formatDateVocabulary } = useFormatDate()

  const {
    data: { comment, name },
    timestamp,
  } = historyItem

  const date = formatDateVocabulary(timestamp, { precise: true })
  const message = formatTimelineMessage(historyItem)

  return (
    <div>
      <StyledDate type={comment}>{date}</StyledDate>
      <StyledAuthor type={comment}>{name}</StyledAuthor>
      <StyledMessage type={comment}>{message}</StyledMessage>
    </div>
  )
}
