import { Card } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"
import { StyledCardProps } from "./types"

export const StyledQuotationText = styled.div`
  margin-top: ${({ theme }) => theme.space.s};
`

export const StyledCard = styled(Card)<StyledCardProps>`
  opacity: ${({ disabled }) => disabled && 0.5};
  pointer-events: ${({ disabled }) => disabled && "none"};
`
