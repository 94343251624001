import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getInvoices = (state: RootState) => {
  return state.invoicesReducer.invoices.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.invoicesReducer.invoices.status)
}
