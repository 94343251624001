import { Button, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getTrapHistory } from "state/hygiTrapDetail/selectors"
import { reset } from "state/hygiTrapDetail/slice"
import { getTrapHistoryThunk } from "state/hygiTrapDetail/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { convertToUTCString } from "utils/convertToUTCString"
import { StyledBoldTrapDetailText, StyledTrapDetailDivider } from "../../style"
import { TrapHistoryProps } from "./types"

export const TrapHistory: React.FC<TrapHistoryProps> = ({ selection }) => {
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { inspectionId, locationId } = useParams()
  const dispatch = useDispatch()
  const trapHistory = useSelector(getTrapHistory)
  const { t } = useTranslate()

  const onClickHistory = () => {
    if (!customerGroupId || !inspectionId || !locationId || !selection) return
    dispatch(getTrapHistoryThunk({ customerGroupId, inspectionId, locationId, trapId: selection }))
  }

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <div>
      {trapHistory ? (
        <Stack spacing="m" direction="column">
          <StyledTrapDetailDivider />
          <Text variant="sectionTitle" subdued>
            {t("system.hygi.trap.history")}
          </Text>
          <div>
            {trapHistory.map((historyItem, index) => (
              <Stack key={index} spacing="l">
                <StyledBoldTrapDetailText>
                  {convertToUTCString(new Date(historyItem.inspection_date), "dd/MM/yyyy")}
                </StyledBoldTrapDetailText>
                <Text>{historyItem.comment}</Text>
              </Stack>
            ))}
          </div>
        </Stack>
      ) : (
        <Button onClick={onClickHistory} variant="secondary">
          {t("system.hygi.trap.view_history")}
        </Button>
      )}
    </div>
  )
}
