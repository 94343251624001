import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getUserThunk } from "state/userDetail/thunks"
import { reset } from "state/userDetail/slice"
import { getUserDetail } from "state/userDetail/selectors"
import LoadingScreen from "app/components/LoadingScreen"
import { Form, useCreateFormConfig } from "app/components/Form"
import { getRequestStatus } from "state/userDetail/selectors"
import { userSchema } from "app/components/UserFormContent/schema"
import { UserFormContent } from "app/components/UserFormContent"
import { SubmitHandler } from "react-hook-form"
import { useTranslate } from "app/hooks/translate"
import { triggerSuccessNotification } from "utils/notifications"
import { assignRolesToUserThunk } from "state/userCreate/thunks"
import { updateUserThunk } from "state/userDetail/thunks"
import { Grid, LinkButton } from "@paudigital/multidesk-ui-kit"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { SaveButton } from "app/components/SaveButton"
import NotFound from "../NotFound"
import { FormUpdateUserType } from "./types"

const UserDetailPage: React.FC = (): JSX.Element => {
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { isRejected, isPending } = useSelector(getRequestStatus)
  const dispatch = useDispatch()
  const { userId: userIdString } = useParams()
  const userId = Number(userIdString)
  const { t } = useTranslate()
  const userDetail = useSelector(getUserDetail)
  const config = useCreateFormConfig({
    schema: userSchema,
    defaultValues: userDetail,
  })
  const { handleSubmit } = config

  useEffect(() => {
    if (!customerGroupId) return
    dispatch(getUserThunk({ customerGroupId, userId }))

    return () => {
      dispatch(reset())
    }
  }, [customerGroupId, dispatch, userId])

  const updateUser: SubmitHandler<FormUpdateUserType> = async payload => {
    const { email, first_name, is_active, last_name, phonenumber, id, ...siteProps } = payload
    await Promise.all([
      handleSubmit(
        updateUserThunk({
          customerGroupId,
          user: { email, first_name, is_active, last_name, phonenumber, id },
        }),
      ),
      handleSubmit(
        assignRolesToUserThunk({
          customerGroupId,
          data: {
            user_id: id,
            sites: siteProps.sites,
          },
        }),
      ),
    ])
    triggerSuccessNotification(t("system.users.edit.success"))
  }

  if (isRejected && !userDetail) return <NotFound />
  if (!userDetail) return <LoadingScreen />
  return (
    <Form config={config} onSubmit={updateUser}>
      <UserFormContent>
        <Grid.Item colSpan={[1, { m: 2 }]}>
          <ButtonWrapper>
            <LinkButton to="/management/users" variant="tertiary">
              {t("system.cancel")}
            </LinkButton>
            <SaveButton variant="primary" type="submit" saving={isPending}>
              {isPending ? t("system.saving") : t("system.save_changes")}
            </SaveButton>
          </ButtonWrapper>
        </Grid.Item>
      </UserFormContent>
    </Form>
  )
}

export default UserDetailPage
