import React from "react"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { getActiveSitesByModule } from "state/sites/selectors"
import { Select } from "app/components/Form/components/Select"
import { useFormContext } from "react-hook-form"
import { FormCreateTicketType } from "app/pages/TicketCreation/types"
import { stringToNumber } from "utils/stringToNumber"

export const TicketSiteField: React.FC = () => {
  const { t } = useTranslate()
  const sites = useSelector(getActiveSitesByModule("support_tickets"))
  const { setValue } = useFormContext<FormCreateTicketType>()

  return (
    <Select
      name="site_id"
      id="site"
      label={t("system.site")}
      onChange={e => {
        const { name, value } = stringToNumber<FormCreateTicketType>(e)
        setValue(name, value)
      }}
    >
      {sites.map(site => (
        <option key={site.id} value={site.id}>
          {site.name}
        </option>
      ))}
    </Select>
  )
}
