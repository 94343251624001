import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getUserDetail = (state: RootState) => {
  const { access_management, ...rest } = state.userDetailReducer.userDetail.data || {}
  return {
    ...rest,
    sites: access_management,
  }
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userDetailReducer.userDetail.status)
}
