import styled from "styled-components"
import { TextField } from "@paudigital/multidesk-ui-kit"

export const StyledSearchTextField = styled(TextField)`
  ${({ theme }) => theme.breakpoints.up("xs")} {
    max-width: 6.25rem;
    &:focus {
      max-width: 40rem;
    }
  }
`
