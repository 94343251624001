import { Card, Text } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledTrapDetail = styled(Card)`
  overflow-wrap: anywhere;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    overflow: auto;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexes.slideIn};
    right: ${({ theme }) => theme.space["2xs"]};
    top: ${({ theme }) => theme.space["2xs"]};
    bottom: ${({ theme }) => theme.space["2xs"]};
    width: 20rem;
    max-width: calc(100% - ${({ theme }) => theme.space["2xl"]} * 2);
  }
`

export const StyledTrapDetailTitle = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.color.secondary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSize4};
`

export const StyledBoldTrapDetailText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const StyledTrapDetailDivider = styled.div`
  height: ${({ theme }) => theme.borderWidth.s};
  width: 100%;
  background: ${({ theme }) => theme.color.neutral["base-light"]};
`
