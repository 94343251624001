import React, { useState } from "react"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { IconSortFill, SlideIn, useMediaQuery } from "@paudigital/multidesk-ui-kit"
import { useCreateFormConfig } from "app/components/Form"
import { useTranslate } from "app/hooks/translate"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { useTheme } from "styled-components"
import { StyledSortIcon } from "./style"
import { FormSortPayload, SortProps } from "./types"
import { SortSelectField } from "./SortSelectField"
import { SortRadiosField } from "./SortRadiosField"

const Sort: React.FC<SortProps> = ({ sortOptions }) => {
  const { t } = useTranslate()
  const { gtag } = useGoogleTagManager()
  const { queryParams, updateQueryParams } = useUpdateQueryParams()
  const theme = useTheme()
  const showSortToggle = useMediaQuery(theme.breakpoints.down("xs"))
  const [isOpen, setIsOpen] = useState(false)

  const currentSortValue = queryParams.get("sort") ?? ""
  const defaultValues = { "table-overview-sort": currentSortValue }
  const formConfig = useCreateFormConfig({ defaultValues })
  const {
    context: { handleSubmit },
  } = formConfig

  const handleSort = (data: FormSortPayload) => {
    const sort = data["table-overview-sort"]
    updateQueryParams({
      page: "1",
      sort,
    })
    gtag("Sort", {
      Sort_type: sort,
    })
    setIsOpen(false)
  }

  return (
    <>
      {showSortToggle ? (
        <>
          <StyledSortIcon variant="tertiary" icon={IconSortFill} onClick={() => setIsOpen(true)} />
          <SlideIn
            title={t("system.sort")}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            actions={[
              {
                label: t("system.sort.confirm"),
                onClick: handleSubmit(handleSort),
                variant: "primary",
              },
            ]}
          >
            <SortRadiosField formConfig={formConfig} sortOptions={sortOptions} />
          </SlideIn>
        </>
      ) : (
        <SortSelectField sortOptions={sortOptions} />
      )}
    </>
  )
}

export default Sort
