import { Button, ellipsisCSS, Text } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledTicketHeader = styled.div`
  min-width: 0;
`

export const StyledTicketTitle = styled(Text)`
  ${ellipsisCSS};
  margin-bottom: 0;
`

export const StyledCloseTicketButton = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("s")} {
    width: max-content;
  }
`
