import { DivisionType } from "api/divisions"
import { SiteType } from "api/sites"
import { Modules } from "router/types"
import { RootState } from "state/store"

export const getSites = (state: RootState) => {
  return state.sitesReducer.sites.data
}

export const getActiveSite = (state: RootState): SiteType | undefined => {
  return getSites(state).find(site => !!site)
}

export const getActiveSitesByModule = (module: Modules) => (state: RootState) => {
  return getSites(state).filter(site => {
    return site.divisions.find(division => division.modules.includes(module))
  })
}

export const getSiteById = (siteId: number) => (state: RootState) => {
  return getSites(state).find(site => site.id === siteId)
}

export const getActiveDivision = (state: RootState): DivisionType => {
  return getActiveSite(state)?.divisions.find(division => !!division)!
}

export const getSiteDivisionsByModule = (siteId: number, module: Modules) => (state: RootState) => {
  const site = getSites(state).find(site => site.id === siteId)
  if (site) return site.divisions.filter(division => division.modules.includes(module))
  return []
}
