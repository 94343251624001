import { configureStore } from "@reduxjs/toolkit"
import appReducer from "./app/slice"
import userReducer from "./user/slice"
import userAutoCompleteReducer from "./userAutoComplete/slice"
import userCreateReducer from "./userCreate/slice"
import userReplaceReducer from "./userReplace/slice"
import usersReducer from "./users/slice"
import ticketsReducer from "./tickets/slice"
import ticketAutoCompleteReducer from "./ticketAutoComplete/slice"
import ticketDetailReducer from "./ticketDetail/slice"
import assistantReducer from "./assistant/slice"
import documentsReducer from "./documents/slice"
import ticketCreateReducer from "./ticketCreate/slice"
import ticketAddCommentReducer from "./ticketComment/slice"
import sitesReducer from "./sites/slice"
import assistantConfigurationReducer from "./assistantConfiguration/slice"
import invoicesReducer from "./invoices/slice"
import invoiceStatisticsReducer from "./invoiceStatistics/slice"
import assistantCreateReducer from "./assistantCreate/slice"
import supportTicketStatisticsReducer from "./supportTicketStatistics/slice"
import documentStatisticsReducer from "./documentStatistics/slice"
import assistantEditReducer from "./assistantEdit/slice"
import documentCreateReducer from "./documentCreate/slice"
import documentCategoriesReducer from "./documentCategories/slice"
import documentDetailReducer from "./documentDetail/slice"
import fileCreateReducer from "./fileCreate/slice"
import QMSReducer from "./qms/slice"
import hygiInspectionsReducer from "./hygiInspections/slice"
import hygiLocationsReducer from "./hygiLocations/slice"
import hygiTrapDetailReducer from "./hygiTrapDetail/slice"
import hygiTrapsReducer from "./hygiTraps/slice"
import middleware from "./middleware"
import userDetailReducer from "./userDetail/slice"
import managementReducer from "./management/slice"

// Order these alphabetically.
export const store = configureStore({
  reducer: {
    appReducer,
    assistantConfigurationReducer,
    assistantCreateReducer,
    assistantEditReducer,
    assistantReducer,
    documentCategoriesReducer,
    documentCreateReducer,
    documentDetailReducer,
    documentsReducer,
    documentStatisticsReducer,
    fileCreateReducer,
    hygiInspectionsReducer,
    hygiLocationsReducer,
    hygiTrapDetailReducer,
    hygiTrapsReducer,
    invoicesReducer,
    invoiceStatisticsReducer,
    QMSReducer,
    sitesReducer,
    supportTicketStatisticsReducer,
    ticketAddCommentReducer,
    ticketAutoCompleteReducer,
    ticketCreateReducer,
    ticketDetailReducer,
    ticketsReducer,
    userAutoCompleteReducer,
    userCreateReducer,
    userDetailReducer,
    userReducer,
    userReplaceReducer,
    usersReducer,
    managementReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
