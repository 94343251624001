import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchHygiInspections, FetchHygiInspectionsDataReturnValue, FetchHygiInspectionsParams } from "api/hygi"
import { StateError } from "state/types"

export const getHygiInspectionsThunk = createAsyncThunk<
  FetchHygiInspectionsDataReturnValue,
  FetchHygiInspectionsParams,
  StateError
>("app/getHygiInspections", async (payload, thunkAPI) => {
  const { customerGroupId } = payload
  try {
    const { data } = await fetchHygiInspections({ customerGroupId })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
