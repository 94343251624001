import { createGlobalStyle } from "styled-components"
import { Theme } from "@paudigital/multidesk-ui-kit"

export default createGlobalStyle<{ theme: Theme }>`
  :root {
    --toastify-toast-min-height: 0;
    --toastify-toast-width: 520px;
    --toastify-text-color-light: ${({ theme }) => theme.color.neutral.black};
    --toastify-text-color-dark: ${({ theme }) => theme.color.neutral.black};
    --toastify-color-progress-success: var(--notification-color-secondary-succcess);
    --toastify-color-progress-error: var(--notification-color-secondary-error);
    --toastify-color-progress-warning: var(--notification-color-secondary-warning);
  }

  html {
    overflow-x: hidden;
  }

  body {
    position: relative;
    height: 100vh;
    height: 100lvh;
    background: ${({ theme }) => theme.color.neutral.light};
    overflow-x: hidden;
  }

  .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    right: 0;
    top: 0;
    padding: 0;
    gap: ${({ theme }) => theme.space.s};

    ${({ theme }) => theme.breakpoints.down("s")} {
      padding: ${({ theme }) => theme.space.s};
      bottom: 0;
      top: auto;
    }

    ${({ theme }) => theme.breakpoints.up("s")} {
      margin: ${({ theme }) => theme.space.l};
    }
    ${({ theme }) => theme.breakpoints.up("m")} {
      margin: ${({ theme }) => `${theme.space.l} ${theme.space.xl}`};
    }

    .Toastify__toast {
      border-radius: ${({ theme }) => theme.borderRadius.m};
      padding: 0;
      margin: 0;

      &-body {
        padding: 0;
      }
    }
  }
`
