import React from "react"
import { Button, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useSelector } from "react-redux"
import { getAssistantActiveFilterSets } from "state/assistant/selectors"
import { useTranslate } from "app/hooks/translate"
import { Assistant } from "app/components/Assistant"
import { FilterSetPickerListProps } from "./types"
import { StyledOverFlowWrapper, StyledDropdown, StyledFilterSetPickerList } from "./style"

export const FilterSetPickerList: React.FC<FilterSetPickerListProps> = ({
  isOpen,
  onSelect,
  onClose,
  referenceElement,
}) => {
  const { t } = useTranslate()
  const activeFilterSets = useSelector(getAssistantActiveFilterSets)

  return (
    <StyledFilterSetPickerList>
      <StyledDropdown
        shadow="dark"
        offset={[0, 0]}
        placement="bottom-start"
        onClose={onClose}
        referenceElement={referenceElement}
        isVisible={isOpen}
        zIndex={10}
      >
        <StyledOverFlowWrapper>
          <Stack spacing="s" direction="column">
            <Text variant="sectionTitle">{t("system.assistant.subjects")}</Text>
            {activeFilterSets.map(filterSet => (
              <Button variant="tertiary" align="left" key={filterSet.id} onClick={() => onSelect(filterSet)}>
                <Assistant filterSet={filterSet} disabled />
              </Button>
            ))}
          </Stack>
        </StyledOverFlowWrapper>
      </StyledDropdown>
    </StyledFilterSetPickerList>
  )
}
