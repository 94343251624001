import React from "react"
import { Card, IconButtonSkeleton, Stack } from "@paudigital/multidesk-ui-kit"
import { ButtonSkeleton } from "@paudigital/multidesk-ui-kit"
import { StyledAssistantIconSkeleton, StyledAssistantText } from "./style"

export const FilterSetSkeletonLoaderItem: React.FC = () => {
  return (
    <Card inset="m">
      <Stack alignItems={["stretch", { m: "center" }]} spacing="s" direction={["column", { m: "row" }]}>
        <StyledAssistantIconSkeleton borderRadius="full" />
        <Stack.Item grow>
          <Stack spacing="2xs" alignItems="center">
            {[...Array(2)].map((_, i) => (
              <React.Fragment key={i}>
                <ButtonSkeleton />
                <StyledAssistantText />
              </React.Fragment>
            ))}
          </Stack>
        </Stack.Item>
        <Stack spacing="m" justifyContent="space-between">
          <IconButtonSkeleton />
        </Stack>
      </Stack>
    </Card>
  )
}
