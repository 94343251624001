import React from "react"
import { useTranslate } from "app/hooks/translate"
import { FileUpload } from "app/components/Form/components/FileUpload"

export const TicketAttachments: React.FC = () => {
  const { t } = useTranslate()

  return (
    <FileUpload
      name="attachments"
      helperText={t("system.file_upload.help_text")}
      id="file_upload"
      label={`${t("system.attachments")}`}
      multiple
      max={10}
    >
      {t("system.file_upload.text")}
    </FileUpload>
  )
}
