import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { createFileThunk } from "./thunks"
import { FileCreateState } from "./types"

const initialState: FileCreateState = {
  createdFile: { data: null, status: RequestStatus.IDLE },
}

export const fileCreateSlice = createSlice({
  name: "fileCreate",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(createFileThunk.pending, state => {
      state.createdFile.status = RequestStatus.PENDING
    })
    builder.addCase(createFileThunk.fulfilled, (state, action) => {
      state.createdFile.data = action.payload
      state.createdFile.status = RequestStatus.FULFILLED
    })
    builder.addCase(createFileThunk.rejected, (state, action) => {
      state.createdFile.error = action.payload?.message
      state.createdFile.status = RequestStatus.REJECTED
    })
  },
})

export default fileCreateSlice.reducer
