import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createAssistantFilterSetThunk } from "state/assistantCreate/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { AssistantFilterSet } from "api/assistant"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "app/hooks/translate"
import { assistantCreateSlice } from "state/assistantCreate/slice"
import { AssistantDetailFrame } from "app/components/AssistantDetailFrame"
import { getRequestStatus, getCreatedAssistant } from "state/assistantCreate/selectors"
import { useGoogleTagManager } from "app/hooks/googleTagManager"

const AssistantFilterSetCreation: React.FC = (): JSX.Element => {
  const { gtag } = useGoogleTagManager()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const createdAssistant = useSelector(getCreatedAssistant)
  const { isPending } = useSelector(getRequestStatus)
  const [filterSet, setFilterSet] = useState<AssistantFilterSet>()
  const { t } = useTranslate()

  const handleClickSubmit = () => {
    if (!filterSet) return

    dispatch(createAssistantFilterSetThunk({ filterSet, customerGroupId }))
  }

  useEffect(() => {
    if (!createdAssistant) return
    dispatch(assistantCreateSlice.actions.reset())
    gtag("New_filter_set")
    navigate("/assistant")
  }, [createdAssistant, navigate, dispatch, gtag])

  return (
    <AssistantDetailFrame
      title={t("system.assistant.create_assistant")}
      filterSet={filterSet}
      onChange={setFilterSet}
      disabledSubmit={isPending || !filterSet}
      onClickSubmit={handleClickSubmit}
      saving={isPending}
    />
  )
}

export default AssistantFilterSetCreation
