import styled from "styled-components"
import { StyledLegendColorProps } from "./types"

export const BarChartWrapper = styled.div`
  flex: 0 0 auto;
  max-width: 100%;
  overflow-y: auto;
`

export const StyledLegend = styled.div`
  ${({ theme }) => theme.bodySmall};
  margin-top: ${({ theme }) => theme.space.xl};
`

export const StyledLegendColor = styled.div<StyledLegendColorProps>`
  width: ${({ theme }) => theme.space.s};
  aspect-ratio: 1 / 1;
  background: ${({ color }) => color};
`

export const StyledLegendText = styled.span`
  width: max-content;
`
