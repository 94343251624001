import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getTicketDetail = (state: RootState) => {
  return state.ticketDetailReducer.ticketDetail.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.ticketDetailReducer.ticketDetail.status)
}
