import { useTheme } from "styled-components"

export const useGetTrapStatusColor = (status: string | undefined) => {
  const theme = useTheme()
  switch (status) {
    case "OK":
    case "EOK":
      return theme.color.hygi.green
    case "PG1":
    case "PG2":
    case "PG3":
    case "EPG":
      return theme.color.hygi.red
    case "ENK":
      return theme.color.hygi.orange
    case "GC":
      return theme.color.hygi.grey
    case "JO":
      return theme.color.hygi.black
    case "PV":
      return theme.color.hygi.pink
    case "DT":
      return theme.color.hygi.purple
    case "LK":
      return theme.color.hygi.darkred
    case "LV":
      return theme.color.hygi.peach
    case "NL":
      return theme.color.hygi.chill
    case "LN":
      return theme.color.hygi.azure
    case "OL":
      return theme.color.hygi.brown
    case "BL":
      return theme.color.hygi.olive
    case "SV":
      return theme.color.hygi.aubergine
    case "BU":
    default:
      return theme.color.hygi.fallback
  }
}
