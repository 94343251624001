import React from "react"
import { Lozenge, TableColumnProps } from "@paudigital/multidesk-ui-kit"
import { FetchSupportTicketsReturnValue, TicketItemType } from "api/tickets"
import { Link } from "react-router-dom"
import { useTranslate } from "app/hooks/translate"
import { getTicketStatusVariant } from "utils/getTicketStatusVariant"
import { parseTags } from "i18n/utils/parseTags"
import { SortType } from "app/components/TableOverview/TableSort/types"
import DeadLine from "../Deadline/Deadline"
import { TicketsTableActions } from "../TicketsTableActions"

export const useGetTicketsColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "id",
      content: t("system.id"),
      width: "80px",
    },
    {
      key: "status",
      content: t("system.status"),
      width: "160px",
      canHide: true,
    },
    {
      key: "title",
      content: t("system.title"),
    },
    {
      key: "remaining_time",
      content: t(
        "system.time_remaining",
        parseTags({
          abbr: {
            title: t("system.time_remaining.full"),
          },
        }),
      ),
      width: "15%",
      canHide: true,
    },
    {
      key: "urgency",
      content: t("system.urgency"),
      width: "15%",
      canHide: true,
    },
    {
      key: "site",
      content: t("system.site"),
      width: "15%",
      canHide: true,
    },
    {
      key: "disivsion",
      content: t("system.division"),
      width: "15%",
      canHide: true,
    },
    {
      key: `actions`,
      content: t("system.actions"),
      width: 0,
      sortable: false,
      alignment: "right",
    },
  ]
}

export const useGetTicketsRows = (tickets: FetchSupportTicketsReturnValue) => {
  const { t } = useTranslate()

  return tickets.items.map((ticket: TicketItemType) => ({
    key: `row-${ticket.id}`,
    cells: [
      {
        key: `cell-${ticket.id}-id`,
        content: ticket.id,
      },
      {
        key: `cell-${ticket.id}-status`,
        content: (
          <Lozenge variant={getTicketStatusVariant(ticket.status)}>{t(`system.status.${ticket.status}`)}</Lozenge>
        ),
      },
      {
        key: `cell-${ticket.id}-title`,
        content: <Link to={`/tickets/${ticket.id}`}>{ticket.title}</Link>,
      },
      {
        key: `cell-${ticket.id}-deadline`,
        content: <DeadLine date={new Date(ticket.deadline_on)} />,
      },
      {
        key: `cell-${ticket.id}-urgency`,
        content: t(`system.urgency.${ticket.urgency}`),
      },
      {
        key: `cell-${ticket.id}-site-name`,
        content: ticket.site.name,
      },
      {
        key: `cell-${ticket.id}-division`,
        content: ticket.division.name,
      },
      {
        key: `cell-${ticket.id}-actions`,
        content: <TicketsTableActions ticketId={ticket.id} ticketStatus={ticket.status} />,
      },
    ],
  }))
}

export const useGetSortOptions = (): SortType[] => {
  const { t } = useTranslate()
  return [
    {
      label: t("system.sort.created_at_asc"),
      sortKey: "created_at:asc",
    },
    {
      label: t("system.sort.created_at_desc"),
      sortKey: "created_at:desc",
    },
    {
      label: t("system.sort.title_asc"),
      sortKey: "title:asc",
    },
    {
      label: t("system.sort.title_desc"),
      sortKey: "title:desc",
    },
    {
      label: t("system.sort.status"),
      sortKey: "status",
    },
    {
      label: t("system.sort.urgency"),
      sortKey: "urgency",
    },
  ]
}
