import React, { useEffect } from "react"
import { Fieldset, Notification, Stack } from "@paudigital/multidesk-ui-kit"
import { Radios } from "app/components/Form/components/Radios"
import { useTranslate } from "app/hooks/translate"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { DocumentVisibilityEnum } from "api/documents"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { TextField } from "app/components/Form/components/TextField"
import { FormCreateDocumentType } from "../types"

export const VisibilitySelector: React.FC = () => {
  const { watch, setValue } = useFormContext<FormCreateDocumentType>()
  const { t } = useTranslate()
  const visibility = watch("visibility")

  const documentVisibilityKeys = Object.values(DocumentVisibilityEnum).filter(
    key => key !== DocumentVisibilityEnum.LIBRARY,
  )
  const fieldLabel = useCreateFormFieldLabel("visibility", t("system.visibility"))

  useEffect(() => {
    if (visibility !== DocumentVisibilityEnum.PERSONAL) return
    setValue("reason_promotion_to_library", null)
  }, [visibility, setValue])

  return (
    <Fieldset legend={fieldLabel}>
      <Radios name="visibility">
        <Stack direction="column" spacing="m">
          <Stack direction="column" spacing="s">
            {documentVisibilityKeys.map(visibility => (
              <Radio
                key={visibility}
                id={`visibility-${visibility}`}
                label={t(`system.visibility.${visibility}`)}
                value={visibility}
              />
            ))}
          </Stack>
          {visibility === DocumentVisibilityEnum.AWAITING_PROMOTION_TO_LIBRARY && (
            <TextField
              id="document-reason-promotion"
              name="reason_promotion_to_library"
              label={t("system.document.reason_promotion_to_library")}
              helperText={t("system.document.reason_promotion_to_library.helper_text")}
            />
          )}
          {visibility === DocumentVisibilityEnum.AWAITING_PROMOTION_TO_LIBRARY && (
            <Notification
              type="warning"
              title={t("system.document_library")}
              message={t("system.visibility.awaiting_promotion_to_library_warning")}
              hideIcon={true}
              hideCloseButton={true}
            />
          )}
        </Stack>
      </Radios>
    </Fieldset>
  )
}
