import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { createAssistantFilterSetThunk } from "./thunks"
import { AssistantCreateState } from "./types"

const initialState: AssistantCreateState = {
  createdAssistantFilterSet: { data: null, status: RequestStatus.IDLE },
}

export const assistantCreateSlice = createSlice({
  name: "assistantCreate",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(createAssistantFilterSetThunk.pending, state => {
      state.createdAssistantFilterSet.status = RequestStatus.PENDING
    })
    builder.addCase(createAssistantFilterSetThunk.fulfilled, (state, action) => {
      state.createdAssistantFilterSet.data = action.payload
      state.createdAssistantFilterSet.status = RequestStatus.FULFILLED
    })
    builder.addCase(createAssistantFilterSetThunk.rejected, (state, action) => {
      state.createdAssistantFilterSet.error = action.payload?.message
      state.createdAssistantFilterSet.status = RequestStatus.REJECTED
    })
  },
})

export default assistantCreateSlice.reducer
