import React, { useState, createContext, useContext } from "react"
import { FilterSetProps, PresetDropdownContextProps } from "./Assistant.types"
import { Consumer } from "./Consumer"

export const Assistant: React.FC<FilterSetProps> = ({ filterSet, disabled, onChange }) => {
  const [presetDropdownIsOpen, setPresetDropdownIsOpen] = useState(false)

  return (
    <AssistantContext.Provider value={{ filterSet, disabled, onChange }}>
      <PresetDropdownContext.Provider value={{ setPresetDropdownIsOpen, presetDropdownIsOpen }}>
        <Consumer />
      </PresetDropdownContext.Provider>
    </AssistantContext.Provider>
  )
}

export const AssistantContext = createContext<FilterSetProps>({} as FilterSetProps)
export const useAssistant = () => useContext(AssistantContext)

export const PresetDropdownContext = createContext<PresetDropdownContextProps>({} as PresetDropdownContextProps)
export const usePresetDropdown = () => useContext(PresetDropdownContext)
