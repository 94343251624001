import styled from "styled-components"

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`

export const StyledLink = styled.a`
  cursor: pointer;
`
