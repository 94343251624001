import React, { PropsWithChildren } from "react"
import * as d3 from "d3"
import { BarChartData, BarChartProps } from "./types"
// Use config because we might want to pass this down as a prop if we need variations
import { getConfig } from "./config"
import { getDomainAndRange } from "./utils"
import { Bars } from "./components/Bars"
import { Points } from "./components/Points"
import { Labels } from "./components/Labels"
import { Legend } from "./components/Legend"
import { BarChartWrapper } from "./style"

export const BarChart = <T,>({ data, stacks, groupBy, className }: PropsWithChildren<BarChartProps<T>>) => {
  const { graphHeight, graphPaddingTop, graphPaddingX, graphPaddingY, barWidth, barSpacing, barLabelHeight } =
    getConfig()

  const [domain, range] = getDomainAndRange(stacks)
  const bars = data.map(d => d[groupBy])

  const graphWidth = barWidth * bars.length + Math.max(bars.length - 1, 0) * barSpacing
  const barPadding = barSpacing / (barWidth + barSpacing)
  const areaWidth = graphWidth + graphPaddingX * 2
  const areaHeight = graphHeight + barLabelHeight + graphPaddingTop + graphPaddingY * 2

  const color = d3.scaleOrdinal().domain(domain).range(range)
  const stackData = d3.stack<BarChartData<T>>().keys(domain)(data)
  const scaleX = d3
    .scaleBand<BarChartData<T>[Extract<keyof T, string>]>()
    .domain(bars)
    .rangeRound([0, graphWidth])
    .paddingInner(barPadding)
  const scaleY = d3.scaleLinear().domain([0, 100]).range([graphHeight, 0])

  return (
    <div className={className}>
      <BarChartContext.Provider value={{ scaleX, scaleY, areaHeight, areaWidth }}>
        <BarChartWrapper>
          <svg overflow="visible" width={areaWidth} height={areaHeight}>
            <g transform={`translate(${graphPaddingX}, ${graphPaddingTop + graphPaddingY})`}>
              <Bars bars={stackData} color={color} groupBy={groupBy} />
              <Points data={data} groupBy={groupBy} />
              <Labels data={data} groupBy={groupBy} />
            </g>
          </svg>
        </BarChartWrapper>
        <Legend stacks={stacks} />
      </BarChartContext.Provider>
    </div>
  )
}

interface BarChartContextProps {
  areaWidth: number
  areaHeight: number
  scaleX: d3.ScaleBand<BarChartData<() => string>>
  scaleY: d3.ScaleLinear<number, number>
}

export const BarChartContext = React.createContext<BarChartContextProps>({} as BarChartContextProps)
export const useBarChartContext = () => React.useContext(BarChartContext)
