import { Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { useSelector } from "react-redux"
import { getTrapDetail } from "state/hygiTrapDetail/selectors"
import { StyledTrapDetailDivider } from "../../style"
import { checkIsETrapDetail } from "../../utils"
import { StyledBugImage } from "./EfkDetails.style"

export const EfkDetails = () => {
  const trap = useSelector(getTrapDetail)
  const { t } = useTranslate()
  const isETrap = checkIsETrapDetail(trap)

  if (isETrap || !trap || trap?.details.counted_insects.length === 0) return null

  return (
    <Stack direction="column" spacing="m">
      <StyledTrapDetailDivider />
      <Text variant="display" subdued={false}>
        {t("system.hygi.trap.efk_details")}
      </Text>
      <Stack direction="column" spacing="2xs">
        {trap.details.counted_insects.map(insect => {
          return (
            <Stack key={insect.type} justifyContent="space-between" alignItems="center">
              <Stack spacing="s">
                <StyledBugImage
                  src={`/assets/hygi/${insect.type}.png`}
                  loading="lazy"
                  decoding="async"
                  alt={t(`system.hygi.trap.insect.${insect.type}`)}
                />
                <Text>{t(`system.hygi.trap.insect.${insect.type}`)}</Text>
              </Stack>
              <Text subdued>{insect.percentage}%</Text>
              <Text subdued>{insect.amount}</Text>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
