import { Card, Grid, Text, LinkButton, Lozenge, Stack } from "@paudigital/multidesk-ui-kit"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useParams } from "react-router-dom"
import { getSingleSupportTicketThunk } from "state/ticketDetail/thunks"
import { getRequestStatus, getTicketDetail } from "state/ticketDetail/selectors"
import { useTranslate } from "app/hooks/translate"
import { TicketHistoryTimeline } from "app/components/TicketHistoryTimeline"
import { getTicketStatusVariant } from "utils/getTicketStatusVariant"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import CloseTicketDialog from "app/components/CloseTicketDialog"
import { TicketStatusEnum } from "api/tickets"
import { TicketHistoryCommentForm } from "app/components/TicketDetail/TicketHistoryCommentForm"
import { useFeatureFlags } from "app/hooks/featureFlags"
import LoadingScreen from "app/components/LoadingScreen"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import NotFound from "../NotFound"
import { ParentTicket } from "./ParentTicket"
import { StyledCloseTicketButton, StyledTicketHeader, StyledTicketTitle } from "./style"
import { TicketDetailAttachmentsField } from "./TicketDetailAttachmentsField"

const TicketDetailPage: React.FC = (): JSX.Element => {
  const {
    zapata: { ticketEditButtonEnabled },
  } = useFeatureFlags()
  const dispatch = useDispatch()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const ticket = useSelector(getTicketDetail)
  const { ticketId } = useParams()
  const { t } = useTranslate()
  const [closeModalOpen, setCloseModalOpen] = useState(false)
  const { isRejected } = useSelector(getRequestStatus)
  const parentTicket = ticket && ticket["parent-ticket"]

  useEffect(() => {
    if (!customerGroupId) return
    dispatch(getSingleSupportTicketThunk({ customerGroupId, ticketId: Number(ticketId) }))
  }, [customerGroupId, ticketId, dispatch])

  const toggleCloseModalOpen = () => setCloseModalOpen(!closeModalOpen)

  const isActiveTicket = ticket && ticket?.status !== TicketStatusEnum.DONE

  if (isRejected && !ticket) return <NotFound />
  if (!ticket) return <LoadingScreen />

  return (
    <>
      <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
        <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.urgency")}
            </Text>
            <Text>{t("system.urgency." + ticket.urgency)}</Text>
          </Card>
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.quotation")}
            </Text>
            <Text>{t("system.needed." + ticket.quote_requested)}</Text>
          </Card>
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.ticket.status")}
            </Text>
            <Lozenge variant={getTicketStatusVariant(ticket.status)}>{t("system.status." + ticket.status)}</Lozenge>
          </Card>
        </Grid.FlexItem>
        <PageMainGridItem>
          <Card inset="l">
            <Stack spacing="l" direction="column">
              <Stack
                spacing="m"
                justifyContent="space-between"
                alignItems="flex-start"
                direction={["column-reverse", { s: "row" }]}
              >
                <StyledTicketHeader>
                  <Text variant="sectionTitle" space="2xs">
                    {t("system.ticket.name")}
                  </Text>
                  <StyledTicketTitle variant="title">{ticket.title}</StyledTicketTitle>
                </StyledTicketHeader>
                {isActiveTicket && (
                  <Stack.Item alignSelf="stretch">
                    <StyledCloseTicketButton onClick={toggleCloseModalOpen} variant="secondary">
                      {t("system.close_ticket")}
                    </StyledCloseTicketButton>
                  </Stack.Item>
                )}
              </Stack>

              <div>
                <Text variant="sectionTitle" space="2xs">
                  {t("system.site")}
                </Text>
                <Text>{ticket.site.name}</Text>
              </div>

              <div>
                <Text variant="sectionTitle" space="2xs">
                  {t("system.division")}
                </Text>
                <Text>{ticket.division.name}</Text>
              </div>

              <div>
                <Text variant="sectionTitle" space="2xs">
                  {t("system.id")}
                </Text>
                <Text>{ticket.id}</Text>
              </div>

              <div>
                <Text variant="sectionTitle" space="s">
                  {t("system.ticket.description")}
                </Text>
                <Text>{ticket.description}</Text>
              </div>
              {parentTicket && <ParentTicket {...parentTicket} />}
              <TicketDetailAttachmentsField attachments={ticket.attachments} />
              <div>
                <Stack direction="column" spacing="s">
                  {isActiveTicket && (
                    <TicketHistoryCommentForm customerGroupId={customerGroupId} ticketId={ticket.id} />
                  )}
                  <div>
                    <Text variant="sectionTitle" space="s">
                      {t("system.ticket.history")}
                    </Text>
                    <TicketHistoryTimeline history={ticket.history} />
                  </div>
                </Stack>
              </div>
            </Stack>
          </Card>
        </PageMainGridItem>
        <Grid.Item colSpan={[1, { m: 2 }]}>
          <ButtonWrapper>
            <LinkButton to="/tickets" variant="tertiary" onClick={() => {}}>
              {t("system.back_to_overview")}
            </LinkButton>
            {ticketEditButtonEnabled && (
              <>
                {isActiveTicket && (
                  <LinkButton to={`/tickets/${ticketId}/edit`} variant="primary">
                    {t("system.ticket.edit")}
                  </LinkButton>
                )}
              </>
            )}
          </ButtonWrapper>
        </Grid.Item>
      </Grid>
      <CloseTicketDialog isOpen={closeModalOpen} setIsOpen={setCloseModalOpen} ticketId={ticket.id} />
    </>
  )
}

export default TicketDetailPage
