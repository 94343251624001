import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import {
  getQMSYearlyGraphDataThunk,
  getQMSYearlyTableDataThunk,
  getQMSAuditsGraphDataThunk,
  getQMSAuditsTableDataThunk,
  getQMSResultsTableDataThunk,
  getQMSResultsGraphDataThunk,
} from "./thunk"
import { QMSState } from "./types"

const initialTableData = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialResultsGraphData = { nodes: [], links: [] }

const initialState: QMSState = {
  yearly: {
    graph: { data: [], status: RequestStatus.IDLE },
    table: { data: initialTableData, status: RequestStatus.IDLE },
  },
  results: {
    graph: { data: initialResultsGraphData, status: RequestStatus.IDLE },
    table: { data: initialTableData, status: RequestStatus.IDLE },
  },
  audits: {
    graph: { data: [], status: RequestStatus.IDLE },
    table: { data: initialTableData, status: RequestStatus.IDLE },
  },
}

export const qmsSlice = createSlice({
  name: "qms",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getQMSYearlyGraphDataThunk.pending, state => {
      state.yearly.graph.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSYearlyGraphDataThunk.fulfilled, (state, action) => {
      state.yearly.graph.data = action.payload
      state.yearly.graph.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSYearlyGraphDataThunk.rejected, (state, action) => {
      state.yearly.graph.error = action.payload?.message
      state.yearly.graph.status = RequestStatus.REJECTED
    })
    builder.addCase(getQMSYearlyTableDataThunk.pending, state => {
      state.yearly.table.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSYearlyTableDataThunk.fulfilled, (state, action) => {
      state.yearly.table.data = action.payload
      state.yearly.table.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSYearlyTableDataThunk.rejected, (state, action) => {
      state.yearly.table.error = action.payload?.message
      state.yearly.table.status = RequestStatus.REJECTED
    })
    builder.addCase(getQMSAuditsGraphDataThunk.pending, state => {
      state.audits.graph.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSAuditsGraphDataThunk.fulfilled, (state, action) => {
      state.audits.graph.data = action.payload
      state.audits.graph.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSAuditsGraphDataThunk.rejected, (state, action) => {
      state.audits.graph.error = action.payload?.message
      state.audits.graph.status = RequestStatus.REJECTED
    })
    builder.addCase(getQMSAuditsTableDataThunk.pending, state => {
      state.audits.table.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSAuditsTableDataThunk.fulfilled, (state, action) => {
      state.audits.table.data = action.payload
      state.audits.table.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSAuditsTableDataThunk.rejected, (state, action) => {
      state.audits.table.error = action.payload?.message
      state.audits.table.status = RequestStatus.REJECTED
    })
    builder.addCase(getQMSResultsTableDataThunk.pending, state => {
      state.results.table.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSResultsTableDataThunk.fulfilled, (state, action) => {
      state.results.table.data = action.payload
      state.results.table.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSResultsTableDataThunk.rejected, (state, action) => {
      state.results.table.error = action.payload?.message
      state.results.table.status = RequestStatus.REJECTED
    })
    builder.addCase(getQMSResultsGraphDataThunk.pending, state => {
      state.results.graph.status = RequestStatus.PENDING
    })
    builder.addCase(getQMSResultsGraphDataThunk.fulfilled, (state, action) => {
      state.results.graph.data = action.payload
      state.results.graph.status = RequestStatus.FULFILLED
    })
    builder.addCase(getQMSResultsGraphDataThunk.rejected, (state, action) => {
      state.results.graph.error = action.payload?.message
      state.results.graph.status = RequestStatus.REJECTED
    })
  },
})

export default qmsSlice.reducer
