import React from "react"
import { Button, Card, Grid, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { DocumentVisibilityEnum } from "api/documents"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { Form, useCreateFormConfig } from "app/components/Form"
import { useTranslate } from "app/hooks/translate"
import { SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { createDocumentThunk } from "state/documentCreate/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { documentCreateSlice } from "state/documentCreate/slice"
import { createFileThunk } from "state/fileCreate/thunks"
import { useAppContext } from "app/providers/AppProvider"
import { triggerSuccessNotification } from "utils/notifications"
import { getRequestStatus as getFileRequestStatus } from "state/fileCreate/selectors"
import { getRequestStatus as getDocumentRequestStatus } from "state/documentCreate/selectors"
import { SaveButton } from "app/components/SaveButton"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import { CategorySelector } from "./CategorySelector"
import { DocumentFile } from "./DocumentAttachment"
import { DocumentNameField } from "./DocumentNameField"
import { createDocumentSchema } from "./schema"
import { FormCreateDocumentDefaultValues, FormCreateDocumentType } from "./types"
import { VisibilitySelector } from "./VisibilitySelector"

const DocumentCreationPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const { userName } = useAppContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const { isPending: isFilePending } = useSelector(getFileRequestStatus)
  const { isPending: isDocumentPending } = useSelector(getDocumentRequestStatus)
  const isPending = isDocumentPending || isFilePending

  const defaultValues: FormCreateDocumentDefaultValues = {
    visibility: DocumentVisibilityEnum.PERSONAL,
  }

  const goBack = () => navigate(`/documents`)

  const formConfig = useCreateFormConfig({ defaultValues, schema: createDocumentSchema })
  const { handleSubmit } = formConfig

  const saveNewDocument: SubmitHandler<FormCreateDocumentType> = async payload => {
    const { files, ...document } = payload
    const { id: fileId } = await handleSubmit(createFileThunk({ subject_type: "Document", file: files[0] }))
    const { id } = await handleSubmit(
      createDocumentThunk({ customerGroupId, document: { ...document, "file-id": fileId } }),
    )
    dispatch(documentCreateSlice.actions.reset())
    triggerSuccessNotification(t("system.documents.create.success"))
    navigate(`/documents/${id}`)
  }

  return (
    <Form config={formConfig} onSubmit={saveNewDocument}>
      <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
        <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.publication_date")}
            </Text>
            {t("system.today")}
          </Card>
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.author")}
            </Text>
            {userName}
          </Card>
        </Grid.FlexItem>
        <PageMainGridItem>
          <Card inset="l">
            <Stack direction="column" spacing="l">
              <DocumentFile />
              <DocumentNameField />
              <CategorySelector />
              <VisibilitySelector />
            </Stack>
          </Card>
        </PageMainGridItem>
        <Grid.Item colSpan={[1, { m: 2 }]}>
          <ButtonWrapper>
            <Button variant="tertiary" onClick={goBack}>
              {t("system.cancel")}
            </Button>
            <SaveButton variant="primary" type="submit" saving={isPending}>
              {isPending ? t("system.saving") : t("system.save")}
            </SaveButton>
          </ButtonWrapper>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default DocumentCreationPage
