import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getCreatedDocument = (state: RootState) => {
  return state.documentCreateReducer.createdDocument.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentCreateReducer.createdDocument.status)
}
