import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import {
  getAllPermissionsThunk,
  getCustomerGroupActiveModulesThunk,
  getCustomerGroupRolesThunk,
  patchRolesAndPermissionsThunk,
} from "./thunks"
import { ManagementState } from "./types"

const initialState: ManagementState = {
  modules: { data: [], status: RequestStatus.IDLE },
  permissionsByModule: { data: [], status: RequestStatus.IDLE },
  rolesAndPermissions: { data: [], status: RequestStatus.IDLE },
}

export const managementSlice = createSlice({
  name: "management",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCustomerGroupActiveModulesThunk.pending, state => {
      state.modules.status = RequestStatus.PENDING
    })
    builder.addCase(getCustomerGroupActiveModulesThunk.fulfilled, (state, action) => {
      state.modules.data = action.payload
      state.modules.status = RequestStatus.FULFILLED
    })
    builder.addCase(getCustomerGroupActiveModulesThunk.rejected, (state, action) => {
      state.modules.error = action.payload?.message
      state.modules.status = RequestStatus.REJECTED
    })
    builder.addCase(getAllPermissionsThunk.pending, state => {
      state.permissionsByModule.status = RequestStatus.PENDING
    })
    builder.addCase(getAllPermissionsThunk.fulfilled, (state, action) => {
      state.permissionsByModule.data = action.payload
      state.permissionsByModule.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAllPermissionsThunk.rejected, (state, action) => {
      state.permissionsByModule.error = action.payload?.message
      state.permissionsByModule.status = RequestStatus.REJECTED
    })
    builder.addCase(getCustomerGroupRolesThunk.pending, state => {
      state.rolesAndPermissions.status = RequestStatus.PENDING
    })
    builder.addCase(getCustomerGroupRolesThunk.fulfilled, (state, action) => {
      state.rolesAndPermissions.data = action.payload
      state.rolesAndPermissions.status = RequestStatus.FULFILLED
    })
    builder.addCase(getCustomerGroupRolesThunk.rejected, (state, action) => {
      state.rolesAndPermissions.error = action.payload?.message
      state.rolesAndPermissions.status = RequestStatus.REJECTED
    })
    builder.addCase(patchRolesAndPermissionsThunk.fulfilled, state => {
      state.rolesAndPermissions.status = RequestStatus.FULFILLED
    })
    builder.addCase(patchRolesAndPermissionsThunk.rejected, (state, action) => {
      state.rolesAndPermissions.error = action.payload?.message
      state.rolesAndPermissions.status = RequestStatus.REJECTED
    })
    builder.addCase(patchRolesAndPermissionsThunk.pending, state => {
      state.rolesAndPermissions.status = RequestStatus.PENDING
    })
  },
})

export default managementSlice.reducer
