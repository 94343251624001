import { Stack } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledLoadingScreen = styled(Stack)`
  height: 100vh;
  background-color: ${({ theme }) => `${theme.color.neutral.light}`};
`

export const StyledImage = styled.img`
  max-width: 240px;
  border-radius: 100%;
  background-color: ${({ theme }) => `${theme.color.neutral.white}`};
`
