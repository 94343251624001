import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getInvoiceStatisticsThunk } from "state/invoiceStatistics/thunks"
import { getInvoiceStatistics } from "state/invoiceStatistics/selectors"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useLocaleNumberFormat } from "app/hooks/useLocaleNumberFormat"
import { ModuleCard } from "../../ModuleCard"

const InvoiceStatisticsCard: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { not_paid_invoices, total_due_amount } = useSelector(getInvoiceStatistics)
  const totalDueAmount = useLocaleNumberFormat(total_due_amount)

  React.useEffect(() => {
    dispatch(getInvoiceStatisticsThunk({ customerGroupId: activeCustomerGroupId }))
  }, [dispatch, activeCustomerGroupId])

  return (
    <ModuleCard
      module="invoices"
      data={{ not_paid_invoices }}
      highlightText={totalDueAmount}
      link={{
        to: "/invoices?status=not_paid",
      }}
    />
  )
}

export default InvoiceStatisticsCard
