import { FileThumbnail, Grid, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { downloadFile } from "utils/downloadFile"
import { TicketDetailAttachmentsFieldProps } from "./TicketDetailAttachmentsField.types"

export function TicketDetailAttachmentsField({ attachments }: TicketDetailAttachmentsFieldProps) {
  const { t } = useTranslate()

  return attachments.length === 0 ? null : (
    <div>
      <Text variant="sectionTitle" space="s">
        {t("system.attachments")}
      </Text>
      <Grid gap={["xs", { s: "xs" }]} columns={[1, { s: 2, l: 3, xl: 4 }]}>
        {attachments.map(attachment => (
          <FileThumbnail
            showThumbnail={false}
            key={attachment.id}
            fileName={attachment.file_name}
            onDownload={() => downloadFile(attachment.file_name, attachment.url)}
            fileType={attachment.file_type}
          />
        ))}
      </Grid>
    </div>
  )
}
