import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getDocumentsThunk, starDocumentThunk } from "./thunks"
import { DocumentState } from "./types"

const initialDocumentData: DocumentState["documents"]["data"] = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialState: DocumentState = {
  documents: { data: initialDocumentData, status: RequestStatus.IDLE },
  documentStar: { data: null, status: RequestStatus.IDLE },
}

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDocumentsThunk.pending, state => {
      state.documents.status = RequestStatus.PENDING
    })
    builder.addCase(getDocumentsThunk.fulfilled, (state, action) => {
      state.documents.data = action.payload
      state.documents.status = RequestStatus.FULFILLED
    })
    builder.addCase(getDocumentsThunk.rejected, (state, action) => {
      state.documents.error = action.payload?.message
      state.documents.status = RequestStatus.REJECTED
    })
    builder.addCase(starDocumentThunk.pending, state => {
      state.documentStar.status = RequestStatus.PENDING
    })
    builder.addCase(starDocumentThunk.fulfilled, (state, action) => {
      const document = state.documents.data.items.find(item => item.id === action.payload["document-id"])
      if (document) document.starred = action.payload.starred
      state.documentStar.status = RequestStatus.FULFILLED
    })
    builder.addCase(starDocumentThunk.rejected, (state, action) => {
      state.documentStar.error = action.payload?.message
      state.documentStar.status = RequestStatus.REJECTED
    })
    builder.addMatcher(isAnyOf(starDocumentThunk.pending, starDocumentThunk.rejected), (state, action) => {
      const documentId = action.meta.arg.data["document-id"]
      const document = state.documents.data.items.find(item => item.id === documentId)
      if (document) document.starred = !document.starred
    })
  },
})

export default documentsSlice.reducer
