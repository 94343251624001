import React from "react"
import { Accordion, Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { UserSiteDivision } from "../UserSiteDivision"
import { UserSiteProps } from "./UserSite.types"

export function UserSite({ site, siteIndex }: UserSiteProps) {
  const { t } = useTranslate()
  return (
    <Accordion title={site.name}>
      <Fieldset id={`${site.id}-divisions`} legend={t("system.divisions")}>
        <Stack direction="column" spacing="s">
          {site.activated_divisions.map(division => (
            <UserSiteDivision siteIndex={siteIndex} key={division.id} division={division} />
          ))}
        </Stack>
      </Fieldset>
    </Accordion>
  )
}
