import React from "react"
import { Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { getInvoiceStatistics } from "state/invoiceStatistics/selectors"
import { useFormatDate } from "app/hooks/useFormateDate"
import { useLocaleNumberFormat } from "app/hooks/useLocaleNumberFormat"
import { InvoiceIcon } from "./InvoiceIcon"
import { StyledCard, StyledNumber } from "./style"

export const Statistics: React.FC = () => {
  const { t } = useTranslate()
  const { formatDateString } = useFormatDate()
  const { next_invoice_due_date, total_due_amount, not_paid_invoices } = useSelector(getInvoiceStatistics)
  const totalDueAmount = useLocaleNumberFormat(total_due_amount)
  const date = next_invoice_due_date && formatDateString(next_invoice_due_date, "dd/MM/yyyy")

  return (
    <Stack direction={["column", { s: "row" }]} spacing="xl">
      <StyledCard inset="m">
        <Stack alignItems="center" justifyContent="center" direction="column" spacing="xs">
          <InvoiceIcon />
          <Text alignment="center" variant="bodyBase">
            {t("system.open_invoices")}
          </Text>
          <StyledNumber variant="title">{not_paid_invoices}</StyledNumber>
        </Stack>
      </StyledCard>
      <StyledCard inset="m">
        <Stack alignItems="center" justifyContent="center" direction="column" spacing="xs">
          <Text alignment="center" variant="bodyBase">
            {t("system.open_amount")}
          </Text>
          <StyledNumber variant="title">{totalDueAmount}</StyledNumber>
          {date && (
            <Text alignment="center" variant="bodyBase">
              {t("system.open_amount_due_date", { date })}
            </Text>
          )}
        </Stack>
      </StyledCard>
    </Stack>
  )
}
