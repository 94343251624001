import React from "react"
import { Grid, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { Link } from "react-router-dom"
import { StyledDashboardCard, StyledDashboardCardSectionsStack } from "./DashboardCard.style"
import { DashboardCardProps } from "./DashboardCard.types"

export const DashboardCard: React.FC<DashboardCardProps> = ({ heading, icon, area, link, children }) => {
  return (
    <Grid.Item area={area}>
      <StyledDashboardCard inset="l">
        <StyledDashboardCardSectionsStack spacing="s" direction="column" justifyContent="space-between">
          <Stack spacing="m" direction="column">
            <Stack spacing="s" alignItems="center">
              {icon}
              <Text variant="display" subdued={false}>
                {heading}
              </Text>
            </Stack>
            {children}
          </Stack>
          {link && <Link to={link.to}>{link.label}</Link>}
        </StyledDashboardCardSectionsStack>
      </StyledDashboardCard>
    </Grid.Item>
  )
}
