import React from "react"
import { getRequestStatus, getUsers } from "state/users/selectors"
import { useTranslate } from "app/hooks/translate"
import TableOverview from "app/components/TableOverview"
import { useSelector } from "react-redux"
import { IconAdd, Stack } from "@paudigital/multidesk-ui-kit"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useDevice } from "utils/device"
import { useGetUsersColumns, useGetUsersRows } from "./UsersTableOverview.data"

export const UsersTableOverview: React.FC = () => {
  const { t } = useTranslate()
  const columns = useGetUsersColumns()
  const rows = useGetUsersRows()
  const isPending = useGetPendingStatus(getRequestStatus)
  const users = useSelector(getUsers)
  const { isDesktop } = useDevice()

  return (
    <Stack direction="column" spacing="2xs">
      <TableOverview
        id="users"
        data={{
          columns,
          rows,
        }}
        heading={t("system.multidesk_users")}
        isLoading={isPending}
        pagination={{
          last: users.last,
          current: users.page,
          total: users.total,
        }}
        actionButton={{
          icon: IconAdd,
          to: "/management/users/create",
          label: isDesktop ? t("system.users.create") : t("system.new"),
        }}
      />
    </Stack>
  )
}
