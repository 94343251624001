import React from "react"
import { useTranslate } from "app/hooks/translate"
import { DocumentItemType } from "api/documents"
import { DocIcon, Lozenge, Stack, TableColumnProps } from "@paudigital/multidesk-ui-kit"
import { Link } from "react-router-dom"
import { checkExpiry } from "utils/checkExpiry"
import { useFormatDate } from "app/hooks/useFormateDate"
import { SortType } from "app/components/TableOverview/TableSort/types"
import DocumentsTableActions from "../DocumentsTableActions"
import { GetDocumentRowsProps } from "./types"

export const useGetDocumentsColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "file_type",
      content: t("system.file_type"),
      width: "80px",
    },
    {
      key: "name",
      content: t("system.name"),
    },
    {
      key: "created_at",
      content: t("system.date"),
      width: "15%",
      sortable: true,
      canHide: true,
    },
    {
      key: "author",
      content: t("system.author"),
      width: "15%",
      canHide: true,
    },
    {
      key: "actions",
      content: t("system.actions"),
      width: 0,
      sortable: false,
      alignment: "right",
    },
  ]
}

export const useGetDocumentsRows = ({ customerGroupId, documents }: GetDocumentRowsProps) => {
  const { t } = useTranslate()
  const { formatDateString } = useFormatDate()

  return documents.items.map((document: DocumentItemType) => ({
    key: `row-${document.id}`,
    cells: [
      {
        key: `cell-${document.id}-file-type`,
        content: <DocIcon fileType={document.file.type} />,
      },
      {
        key: `cell-${document.id}-name`,
        content: (
          <Stack spacing="2xs" direction="row" alignItems="center">
            <Link to={`/documents/${document.id}`}>{document.name}</Link>
            {checkExpiry(document.expires_at) && <Lozenge variant="error"> {t("system.expired")}</Lozenge>}
          </Stack>
        ),
      },
      {
        key: `cell-${document.id}-created_at`,
        content: formatDateString(document.created_at, "dd/MM/yyyy"),
      },
      {
        key: `cell-${document.id}-author`,
        content: `${document.uploaded_by.author}`,
      },
      {
        key: `cell-${document.id}-actions`,
        content: <DocumentsTableActions customerGroupId={customerGroupId} documentId={document.id} />,
      },
    ],
  }))
}

export const useGetSortOptions = (): SortType[] => {
  const { t } = useTranslate()
  return [
    {
      label: t("system.sort.name_asc"),
      sortKey: "name:asc",
    },
    {
      label: t("system.sort.name_desc"),
      sortKey: "name:desc",
    },
    {
      label: t("system.sort.file_type"),
      sortKey: "file_type",
    },
    {
      label: t("system.sort.uploaded_by_asc"),
      sortKey: "uploaded_by:asc",
    },
    {
      label: t("system.sort.uploaded_by_desc"),
      sortKey: "uploaded_by:desc",
    },
    {
      label: t("system.sort.uploaded_at_asc"),
      sortKey: "created_at:asc",
    },
    {
      label: t("system.sort.uploaded_at_desc"),
      sortKey: "created_at:desc",
    },
  ]
}
