import React from "react"
import { useTranslate } from "app/hooks/translate"
import { AutoComplete } from "app/components/Form/components/AutoComplete"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getAutoCompleteSupportTicketsThunk } from "state/ticketAutoComplete/thunks"
import { getAutoCompleteTickets, getRequestStatus } from "state/ticketAutoComplete/selectors"
import { TicketStatusEnum } from "api/tickets"

export const TicketParentField: React.FC = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)

  const tickets = useSelector(getAutoCompleteTickets)
  const { isPending } = useSelector(getRequestStatus)

  const handleSearchInputChange = (input: string) => {
    if (input.length <= 0) return

    dispatch(
      getAutoCompleteSupportTicketsThunk({
        activeCustomerGroupId,
        q: input,
        status: TicketStatusEnum.DONE,
      }),
    )
  }

  return (
    <AutoComplete
      options={[
        {
          label: t("system.closed_tickets"),
          options: tickets,
        },
      ]}
      noOptionsMessage={() => {
        const subject = t("system.tickets").toLowerCase()
        return t("system.no_results.title", { subject })
      }}
      placeholder={`${t("system.tickets.search")}...`}
      loadingMessage={() => {
        const subject = t("system.tickets").toLowerCase()
        return t("system.searching", { subject })
      }}
      onSearchInputChange={handleSearchInputChange}
      isLoading={isPending}
      id="parent_id"
      name="parent_id"
      label={t("system.ticket.parent")}
      debounceTime={300}
    />
  )
}
