import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { deleteUserThunk, getUsersThunk } from "./thunks"
import { UsersState } from "./types"

const initialUsersData: UsersState["users"]["data"] = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialState: UsersState = {
  users: { data: initialUsersData, status: RequestStatus.IDLE },
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.users.data = action.payload
    })
    builder.addCase(deleteUserThunk.fulfilled, (state, action) => {
      state.users.data.items = state.users.data.items.filter(user => user.id !== action.payload)
    })
    builder.addMatcher(isAnyOf(getUsersThunk.fulfilled, deleteUserThunk.fulfilled), state => {
      state.users.status = RequestStatus.FULFILLED
    })
    builder.addMatcher(isAnyOf(getUsersThunk.pending, deleteUserThunk.pending), state => {
      state.users.status = RequestStatus.PENDING
    })
    builder.addMatcher(isAnyOf(getUsersThunk.rejected, deleteUserThunk.rejected), (state, action) => {
      state.users.error = action.payload?.message
      state.users.status = RequestStatus.REJECTED
    })
  },
})

export default userSlice.reducer
