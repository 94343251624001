import React from "react"
import { TextField as UITextField } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { TextFieldProps } from "./TextField.types"

export const TextField: React.FC<TextFieldProps> = ({ name, label, ...rest }) => {
  const { register } = useFormContext()
  const errorMessage = useGetErrorMessage(name)
  const fieldLabel = useCreateFormFieldLabel(name, label)

  return (
    <UITextField
      label={fieldLabel}
      errorMessage={errorMessage}
      hasError={!!errorMessage}
      {...register(name, { setValueAs: (v: string | null) => v?.trim() })}
      {...rest}
    />
  )
}
