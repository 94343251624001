import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchSupportTickets, FetchSupportTicketsReturnValue, FetchTicketsParams } from "api/tickets"
import { StateError } from "state/types"

export const getSupportTicketsThunk = createAsyncThunk<FetchSupportTicketsReturnValue, FetchTicketsParams, StateError>(
  "app/getSupportTickets",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchSupportTickets(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
