import React from "react"
import { Card, Grid, Lozenge, Text } from "@paudigital/multidesk-ui-kit"
import { InvoiceItemType } from "api/invoices"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { downloadFile } from "utils/downloadFile"
import { useFormatDate } from "app/hooks/useFormateDate"
import { useLocaleNumberFormat } from "app/hooks/useLocaleNumberFormat"
import { StyledCheckmark, StyledFileThumbnail } from "./style"
import { InvoiceItemProps } from "./types"

export const InvoiceItem = ({ invoice }: InvoiceItemProps) => {
  const { t } = useTranslate()
  const { gtag } = useGoogleTagManager()
  const { formatDateString } = useFormatDate()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)

  const getFormattedDate = (date: string) => formatDateString(date, "dd/MM/yyyy")
  const amount = useLocaleNumberFormat(invoice.amount)
  const isInvoicePaid = invoice.status === "paid"
  const lozengeStatus = isInvoicePaid ? "success" : "error"
  const icon = isInvoicePaid ? StyledCheckmark : undefined

  const downloadHandler = (invoice: InvoiceItemType) => {
    downloadFile(invoice.name + ".pdf", `/customer_groups/${activeCustomerGroupId}/invoices/${invoice.id}`)
    gtag("Download_invoice")
  }

  return (
    <Card inset="m">
      <Grid
        gap={["s", { m: "2xs" }]}
        areas={[
          [
            ["date", "paid"],
            ["name", "name"],
            ["amount", "amount"],
            ["due_date", "due_date"],
            ["download", "download"],
          ],
          {
            s: [
              ["date", "paid"],
              ["name", "amount"],
              ["due_date", "download"],
            ],
          },
        ]}
      >
        <Grid.Item area="date" alignSelf="center">
          <Text variant="bodySmall" subdued>
            {getFormattedDate(invoice.created_at)}
          </Text>
        </Grid.Item>
        <Grid.Item area="paid" justifySelf="flex-end">
          <Lozenge icon={icon} variant={lozengeStatus}>
            {t(`system.${invoice.status}`)}
          </Lozenge>
        </Grid.Item>
        <Grid.Item area="name">
          <Text variant="display" subdued={false}>
            {invoice.name}
          </Text>
        </Grid.Item>
        <Grid.Item area="amount" justifySelf={["auto", { s: "flex-end" }]}>
          <Text variant="title">{amount}</Text>
        </Grid.Item>
        <Grid.Item area="due_date">
          <Text variant="bodySmall" subdued>
            {t("system.due_date")}: {getFormattedDate(invoice.due_date)}
          </Text>
        </Grid.Item>
        <Grid.Item area="download" justifySelf={["auto", { s: "flex-end" }]}>
          <StyledFileThumbnail
            showThumbnail={false}
            fileName={invoice.name}
            onDownload={() => downloadHandler(invoice)}
            fileType="application/pdf"
            truncate
          />
        </Grid.Item>
      </Grid>
    </Card>
  )
}
