import { useTranslate } from "app/hooks/translate"
import { getExistingRoutesFromPathname, GetExistingRoutesFromPathnameFunction } from "router/utils"

type UseGetExistingRoutesFromPathnameFunction = GetExistingRoutesFromPathnameFunction

export const useGetExistingRoutesFromPathname: UseGetExistingRoutesFromPathnameFunction = (...params) => {
  const { t } = useTranslate()
  const routes = getExistingRoutesFromPathname(...params)

  return routes.map(route => ({
    ...route,
    label: t(route.label),
  }))
}
