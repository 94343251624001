import { TicketItemType } from "api/tickets"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"
import { FetchAutoCompleteSupportTicketsReturnValue } from "./types"

const autoCompleteDataMapper = (data: FetchAutoCompleteSupportTicketsReturnValue) => {
  return data.items.map((ticket: TicketItemType) => {
    return {
      label: `${ticket.id} - ${ticket.title}`,
      value: ticket.id,
    }
  })
}

export const getAutoCompleteTickets = (state: RootState) => {
  return autoCompleteDataMapper(state.ticketAutoCompleteReducer.tickets.data)
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.ticketAutoCompleteReducer.tickets.status)
}
