import { OrderedButton } from "@paudigital/multidesk-ui-kit"
import styled, { css } from "styled-components"
import { StyledOrderedButtonProps } from "./types"

export const notVisibleCSS = css`
  opacity: 0.5;
`

export const StyledOrderedButton = styled(OrderedButton)<StyledOrderedButtonProps>`
  ${({ isVisible }) => !isVisible && notVisibleCSS}
`
