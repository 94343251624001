import React from "react"
import { useTranslate } from "app/hooks/translate"
import { TableActions } from "app/components/TableOverview/TableActions"
import CloseTicketDialog from "app/components/CloseTicketDialog"
import { TicketsTableActionsProps } from "./types"

export const TicketsTableActions: React.FC<TicketsTableActionsProps> = ({ ticketId, ticketStatus }) => {
  const { t } = useTranslate()
  const [isCloseTicketDialogOpen, setIsCloseTicketDialogOpen] = React.useState(false)
  const showCloseTicketDialog: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsCloseTicketDialogOpen(true)
  }

  return (
    <>
      <TableActions
        actions={[{ label: t("system.close_ticket"), onClick: showCloseTicketDialog }]}
        disabled={ticketStatus === "done"}
      />
      <CloseTicketDialog ticketId={ticketId} isOpen={isCloseTicketDialogOpen} setIsOpen={setIsCloseTicketDialogOpen} />
    </>
  )
}
