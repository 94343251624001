import styled from "styled-components"
import { Footer, Text, transformBreakpointsForProperty } from "@paudigital/multidesk-ui-kit"
import { LayoutProps } from "./Layout.types"

export const StyledLayout = styled.div<LayoutProps>`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme, mobileBreakpoint }) => theme.breakpoints.up(mobileBreakpoint)} {
    grid-template-columns: min-content 1fr;
  }
  ${({ theme, mobileBreakpoint }) => theme.breakpoints.down(mobileBreakpoint)} {
    grid-template-rows: min-content 1fr;
    min-height: 100vh;
    @supports (height: 100lvh) {
      min-height: 100lvh;
    }
  }
`

export const StyledLayoutContent = styled.div<LayoutProps>`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.space.xl} ${theme.space.l}`};
  ${({ maxHeight }) => transformBreakpointsForProperty("height", maxHeight, v => (v ? `100vh` : `auto`))}

  ${({ theme }) => theme.breakpoints.down("m")} {
    padding: ${({ theme }) => `${theme.space.l} ${theme.space.l}`};
  }

  ${({ theme }) => theme.breakpoints.down("s")} {
    padding: ${({ theme }) => `${theme.space.m} ${theme.space.m}`};
  }

  ${({ theme, mobileBreakpoint }) => theme.breakpoints.down(mobileBreakpoint)} {
    padding: ${({ theme }) => theme.space.m};
  }
`

export const StyledLayoutHeading = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space["2xs"]};
  flex: 0 0 auto;
`

export const StyledLayoutBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: ${({ theme }) => theme.space.m} 0;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-top: 0;
  }
`

export const StyledLayoutFooter = styled(Footer)`
  flex: 0 0 auto;
  align-self: flex-end;
`
