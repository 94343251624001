import React, { useState } from "react"
import { useTranslate } from "app/hooks/translate"
import { Button, IconChatFill, Stack, Timeline, TimelineItemProps } from "@paudigital/multidesk-ui-kit"
import { TicketHistoryTimelineItem } from "./TicketHistoryTimelineItem"
import { TicketHistoryTimelineProps } from "./types"
import { StyledIcon, StyledNoIcon } from "./style"

const MAX_TICKETS_SHOWN = 5

export const TicketHistoryTimeline: React.FC<TicketHistoryTimelineProps> = ({ history }) => {
  const [showAll, setShowAll] = useState(history.length <= MAX_TICKETS_SHOWN)
  const { t } = useTranslate()

  const toggleShowAll = () => setShowAll(!showAll)

  const items: TimelineItemProps[] = history
    .map(h => {
      return {
        key: h.timestamp,
        icon: h.event === "ticket_comment" ? IconChatFill : undefined,
        content: <TicketHistoryTimelineItem {...h} />,
      }
    })
    .filter((_, i) => showAll || i < MAX_TICKETS_SHOWN)

  return (
    <Stack direction="column" spacing="s" alignItems="start">
      <Timeline inset="s" icon={StyledIcon} noIcon={StyledNoIcon} items={items} />
      {!showAll && (
        <Button onClick={toggleShowAll} variant="tertiary">
          {t("system.show_more")}
        </Button>
      )}
    </Stack>
  )
}
