import React from "react"
import { Button, Spinner, Stack } from "@paudigital/multidesk-ui-kit"
import { SaveButtonProps } from "./types"

export const SaveButton: React.FC<SaveButtonProps> = ({ saving, children, disabled, ...props }) => {
  return (
    <Button disabled={saving || disabled} {...props}>
      <Stack alignItems="center" spacing="2xs">
        {children}
        {saving && <Spinner />}
      </Stack>
    </Button>
  )
}
