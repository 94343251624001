import React from "react"
import { Accordion, IconInvisible, IconVisible, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { getIsBaitTypeActive } from "../../utils"
import { BaitListProps } from "./types"
import { BaitButton } from "./components/BaitButton"

export const BaitList: React.FC<BaitListProps> = ({ traps, selection, onTrapGroupClick, onTrapClick }) => {
  const { t } = useTranslate()
  const extendedTrapsArray = Object.values(traps).reduce((currentTraps, currentTrap) => {
    return [...currentTraps, ...currentTrap]
  }, [])
  const inactiveGroups = [...new Set(extendedTrapsArray)].filter(({ enabled }) => !enabled).map(({ type }) => type)

  return (
    <Stack direction="column" spacing="2xs">
      {Object.entries(traps).map(([group, trapsByType]) => {
        return (
          <Accordion
            title={t(`bait_type_${group}`)}
            key={group}
            actions={[
              {
                icon: getIsBaitTypeActive(group, inactiveGroups) ? IconVisible : IconInvisible,
                onClick: () => onTrapGroupClick?.(group),
              },
            ]}
            isDefaultOpen={true}
          >
            <Stack direction="column" spacing="3xs">
              {trapsByType.map(trap => (
                <BaitButton key={trap.id} trap={trap} selection={selection} onTrapClick={onTrapClick} />
              ))}
            </Stack>
          </Accordion>
        )
      })}
    </Stack>
  )
}
