import { Icon } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledDetails = styled.details`
  cursor: pointer;
`

export const StyledSummary = styled.summary`
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: -1.25rem;
`

export const StyledChevronIcon = styled(Icon)`
  font-size: 1.25rem;
  --icon-color-primary: ${({ theme }) => theme.color.neutral["base-dark"]};
`

export const StyledContent = styled.div`
  margin-top: ${({ theme }) => theme.space.xs};
`
