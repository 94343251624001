import React, { SyntheticEvent } from "react"
import { Checkbox, IconChevronDown, IconChevronRight, Stack } from "@paudigital/multidesk-ui-kit"
import { useDispatch, useSelector } from "react-redux"
import { setFeatureFlags } from "state/app/slice"
import { getFeatureFlags } from "state/app/selectors"
import { StyledChevronIcon, StyledDetails, StyledSummary, StyledContent } from "./style"
import { FeatureFlagsProps } from "./types"

export const FeatureFlags: React.FC<FeatureFlagsProps> = ({ version }) => {
  const featureFlags = useSelector(getFeatureFlags)
  const { [version]: versionFlags } = featureFlags

  const activeKeys = Object.keys(versionFlags).filter(key => versionFlags[key as keyof typeof versionFlags]).length > 0

  const [isOpen, setIsOpen] = React.useState(activeKeys)
  const dispatch = useDispatch()

  const toggleKey = (key: string) => {
    dispatch(
      setFeatureFlags({
        ...featureFlags,
        [version]: {
          ...versionFlags,
          [key]: !versionFlags[key as keyof typeof versionFlags],
        },
      }),
    )
  }

  const flagCount = Object.keys(versionFlags).length

  const toggleSummary = (e: SyntheticEvent) => {
    e.preventDefault()
    setIsOpen(oldState => !oldState)
  }

  return (
    <StyledDetails open={isOpen}>
      <StyledSummary onClick={toggleSummary}>
        <StyledChevronIcon forwardedAs={isOpen ? IconChevronDown : IconChevronRight} />
        {version} ({flagCount})
      </StyledSummary>
      {flagCount > 0 && (
        <StyledContent>
          <Stack direction="column">
            {Object.keys(versionFlags).map(key => (
              <Checkbox
                key={key}
                id={key}
                label={key}
                onClick={() => toggleKey(key)}
                defaultChecked={versionFlags[key as keyof typeof versionFlags]}
              ></Checkbox>
            ))}
          </Stack>
        </StyledContent>
      )}
    </StyledDetails>
  )
}
