import React from "react"
import { UrgencyEnum } from "api/tickets"
import { useTranslate } from "app/hooks/translate"
import { urgencyToTime } from "utils/urgencyToTimeSpan"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { UrgencyRadioProps } from "../UrgencySelector.types"

export const UrgencyRadio: React.FC<UrgencyRadioProps> = ({ urgency }) => {
  const { t } = useTranslate()

  const time = urgencyToTime(urgency)
  const isByDeadline = urgency === UrgencyEnum.BY_DEADLINE
  const helperText = isByDeadline ? undefined : t(`system.urgency.${urgency}.help_text`, { time })

  return (
    <Radio id={`urgency-${urgency}`} label={t(`system.urgency.${urgency}`)} value={urgency} helperText={helperText} />
  )
}
