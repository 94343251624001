import { api } from "api"
import axios, { AxiosPromise } from "axios"
import {
  FetchedCustomerGroupActiveModulesReturnValue,
  FetchedAllPermissionsByModuleValue,
  FetchedAllRolesByModuleValue,
  PatchAllModuleRoles,
} from "./management.types"

export const fetchActiveModulesForCustomerGroup = (
  id: number,
): AxiosPromise<FetchedCustomerGroupActiveModulesReturnValue> => {
  return api(`/customer_groups/${id}/modules`, {
    method: "GET",
  })
}

export const fetchAllPermissions = (): AxiosPromise<FetchedAllPermissionsByModuleValue> => {
  return axios.get("/mock/permissions.mock.json").then(res => res)
  // return api(`/modules/permissions`, {
  //   method: "GET",
  // })
}

export const fetchAllRolesForCustomerGroup = (id: number): AxiosPromise<FetchedAllRolesByModuleValue> => {
  return axios.get(`/mock/roles.mock.json?${id}`).then(res => res)
  // return api(`/customer_groups/${id}/modules/roles`, {
  //   method: "GET",
  // })
}

export const patchRolesAndPermissions = ({ customerGroupId, data }: PatchAllModuleRoles): AxiosPromise<void> => {
  return api(`/customer_groups/${customerGroupId}/modules/roles`, {
    method: "PATCH",
    data,
  })
}
