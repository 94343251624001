import * as d3 from "d3"
import { TrapsType, TrapType } from "api/hygi"
import { MAP_HEIGHT, MAP_WIDTH } from "../constants"
import { ExtendedTrapsType, ExtendedTrapType } from "../types"

export const getTrapIsInView = (trap: TrapType, width: number, height: number, transform: d3.ZoomTransform) => {
  const isVisibleLeft = trap.location_x * transform.k + transform.x >= 0
  const isVisibleRight = trap.location_x * transform.k + transform.x <= width
  const isVisibleX = isVisibleLeft && isVisibleRight
  const isVisibleTop = trap.location_y * transform.k + transform.y >= 0
  const isVisibleBottom = trap.location_y * transform.k + transform.y <= height
  const isVisibleY = isVisibleTop && isVisibleBottom

  return isVisibleX && isVisibleY
}

export const getIsBaitTypeActive = (group: string, inactiveGroups: string[]) => !inactiveGroups.includes(group)

export const getBaitIsSelected = (trap: ExtendedTrapType, selection: number | undefined) => selection === trap.id

export const getPointStroke = (trap: ExtendedTrapType, selection: number | undefined) => {
  if (getBaitIsSelected(trap, selection)) {
    return "black"
  }
}

export const setInitialTrapsData = (traps: TrapsType) => {
  return Object.entries(traps).reduce((currentTrapGroups, currentTrapGroup) => {
    const [type, currentTraps] = currentTrapGroup
    const extendedCurrentTraps = currentTraps.map(trap => ({ ...trap, type, enabled: true }))

    return {
      ...currentTrapGroups,
      [type]: extendedCurrentTraps,
    }
  }, {})
}

export const setTrapsStatuses = (traps: ExtendedTrapsType, group: string) => {
  return Object.entries(traps).reduce((currentTrapGroups, currentTrapGroup) => {
    const [type, currentTraps] = currentTrapGroup
    const extendedCurrentTraps = currentTraps.map(trap => {
      return {
        ...trap,
        enabled: type === group ? !trap.enabled : trap.enabled,
      }
    })

    return {
      ...currentTrapGroups,
      [type]: extendedCurrentTraps,
    }
  }, {})
}

export const setTrapsVisibility = (traps: ExtendedTrapsType, updatedTransform: d3.ZoomTransform) => {
  return Object.entries(traps).reduce((currentTrapGroups, currentTrapGroup) => {
    const [type, currentTraps] = currentTrapGroup
    const extendedCurrentTraps = currentTraps.map(trap => ({
      ...trap,
      visible: getTrapIsInView(trap, MAP_WIDTH, MAP_HEIGHT, updatedTransform),
    }))

    return {
      ...currentTrapGroups,
      [type]: extendedCurrentTraps,
    }
  }, {})
}

export const filterD3ZoomEvents = (event: MouseEvent) => {
  // Only zoom on scroll when ctrl or cmd is pressed
  if (event.type === "wheel") {
    return event.ctrlKey || event.metaKey
  }
  return !event.button
}

export const D3Zoom = d3
  .zoom<SVGSVGElement, TrapType>()
  .scaleExtent([1, 5])
  .translateExtent([
    [0, 0],
    [MAP_WIDTH, MAP_HEIGHT],
  ])
  .filter(filterD3ZoomEvents)
