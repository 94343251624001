import React from "react"
import { Breadcrumbs as UIBreadcrumbs } from "@paudigital/multidesk-ui-kit"
import { useRouterContext } from "app/providers/RouterProvider"
import { useLocation } from "react-router-dom"
import { useGetExistingRoutesFromPathname } from "app/hooks/routeFromPathName"

export const Breadcrumbs: React.FC = () => {
  const { routes } = useRouterContext()
  const location = useLocation()
  const breadcrumbItems = useGetExistingRoutesFromPathname(routes, location)

  return breadcrumbItems.length > 1 ? <UIBreadcrumbs items={breadcrumbItems} /> : null
}
