import { useSelector } from "react-redux"
import { RouteObject } from "react-router-dom"
import { getFeatureFlags } from "state/app/selectors"
import { useCallback } from "react"
import { Flag, Version } from "featureFlags"

export const useHasFeatureFlagActive = () => {
  const featureFlags = useSelector(getFeatureFlags)

  const hasFeatureFlagActive = useCallback(
    (route: RouteObject) => {
      if (!route.featureFlag) return true
      // TODO: fix type
      const [version, flag] = route.featureFlag.split(":") as [Version, Flag<Version>]
      return featureFlags[version][flag]
    },
    [featureFlags],
  )

  return hasFeatureFlagActive
}
