import { Grid, IconNewTab, Lozenge, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { Link } from "react-router-dom"
import { getTicketStatusVariant } from "utils/getTicketStatusVariant"
import { StyledTitle, StyledNewTabIcon, StyledParentTicketWrapper } from "./style"
import { ParentTicketType } from "./types"

export const ParentTicket: React.FC<ParentTicketType> = ({ id, status, title }) => {
  const { t } = useTranslate()

  return (
    <div>
      <Text variant="sectionTitle" space="s">
        {t("system.ticket.parent")}
      </Text>
      <StyledParentTicketWrapper gap={["s", { m: "l" }]} columns={[1, { m: 2 }]}>
        <Grid.Item>
          <Stack spacing={["s", { m: "l" }]}>
            <Text>{id}</Text>
            <Lozenge variant={getTicketStatusVariant(status)}>{t("system.status." + status)}</Lozenge>
          </Stack>
        </Grid.Item>
        <Grid.Item order={[-1, { m: 0 }]}>
          <Link to={`/tickets/${id}`} target="_blank">
            <Stack spacing="s" alignItems="center">
              <StyledTitle variant="bodyBase">{title}</StyledTitle>
              <StyledNewTabIcon forwardedAs={IconNewTab} />
            </Stack>
          </Link>
        </Grid.Item>
      </StyledParentTicketWrapper>
    </div>
  )
}
