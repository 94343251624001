// import { TicketItemType } from "api/users"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"
// import { FetchAutoCompleteSupportUsersReturnValue } from "./types"

// const autoCompleteDataMapper = (data: FetchAutoCompleteSupportUsersReturnValue) => {
//   return data.items.map((user: TicketItemType) => {
//     return {
//       label: `${user.id} - ${user.title}`,
//       value: user.id,
//     }
//   })
// }

export const getAutoCompleteUsers = (state: RootState) => {
  return state.userAutoCompleteReducer.users.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userAutoCompleteReducer.users.status)
}
