import React, { useEffect, useState } from "react"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getUsersSitesThunk } from "state/userCreate/thunks"
import { getUsersSites } from "state/userCreate/selectors"
import { Button, Card, Dropdown, Grid, IconAdd, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { useFieldArray, useFormContext } from "react-hook-form"
import { UsersSite } from "api/users"
import { UserSite } from "../UserSite"
import { FormSites } from "../../../../pages/UserCreation/types"
import { useUserFormContent } from "../.."

export function UserSites() {
  const { t } = useTranslate()
  const { disabled } = useUserFormContent()
  const dispatch = useDispatch()
  const usersSites = useSelector(getUsersSites) || []
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { control, getValues } = useFormContext<FormSites>()
  const getIsSiteInForm = (siteId: UsersSite["id"]) => getValues().sites?.some(site => site.site_id === siteId)
  const [sitesInForm, sitesNotInForm] = usersSites?.reduce(
    ([sitesInForm, sitesNotInForm], site) => {
      return getIsSiteInForm(site.id)
        ? [[...sitesInForm, site], sitesNotInForm]
        : [sitesInForm, [...sitesNotInForm, site]]
    },
    [[], []],
  )

  const { append } = useFieldArray({
    control,
    name: "sites",
  })

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getUsersSitesThunk(activeCustomerGroupId))
  }, [activeCustomerGroupId, dispatch])

  return (
    <Card inset="l">
      <Grid gap="m" columns={1}>
        <Text as="h2" variant="display">
          {t("system.users.sites_with_access")}
        </Text>
        {sitesInForm?.map((site, siteIndex) => (
          <UserSite key={site.id} siteIndex={siteIndex} site={site} />
        ))}
        <div>
          <Button
            disabled={disabled || sitesNotInForm?.length === 0}
            ref={setReferenceElement}
            variant="secondary"
            icon={IconAdd}
            onClick={() => {
              setDropdownIsVisible(previousState => !previousState)
            }}
          >
            {t("system.users.add_site")}
          </Button>
        </div>
        <Dropdown
          placement="bottom-start"
          shadow="base"
          isVisible={dropdownIsVisible}
          onClose={() => {
            setDropdownIsVisible(false)
          }}
          referenceElement={referenceElement}
        >
          <Stack direction="column">
            {sitesNotInForm?.map(site => (
              <Button
                disabled={disabled}
                key={site.id}
                type="button"
                align="left"
                variant="tertiary"
                onClick={() => {
                  append({
                    site_id: site.id,
                  })
                  setDropdownIsVisible(false)
                }}
              >
                {site.name}
              </Button>
            ))}
          </Stack>
        </Dropdown>
      </Grid>
    </Card>
  )
}
