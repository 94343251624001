import { SearchParams } from "api/types"

export const setSearchParams = (params: SearchParams) => {
  const { page, perPage } = params
  return {
    ...params,
    page: page ? parseInt(page) : undefined,
    perPage: perPage ? parseInt(perPage) : undefined,
  }
}
