import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getAssistantEdit = (state: RootState) => {
  return state.assistantEditReducer.assistantEdit.data
}

export const getIsSaved = (state: RootState) => {
  return state.assistantEditReducer.assistantEdit.data?.isSaved
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.assistantEditReducer.assistantEdit.status)
}
