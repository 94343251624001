import React from "react"
import { Button, Card, Grid, Stack } from "@paudigital/multidesk-ui-kit"
import { UrgencyEnum } from "api/tickets"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { QuotationSelector } from "app/components/TicketDetail/QuotationSelector"
import { UrgencySelector } from "app/components/TicketDetail/UrgencySelector"
import { useTranslate } from "app/hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { createSupportTicketThunk } from "state/ticketCreate/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getRequestStatus } from "state/ticketCreate/selectors"
import { useNavigate } from "react-router-dom"
import { ticketCreateSlice } from "state/ticketCreate/slice"
import { TicketTitleField } from "app/components/TicketDetail/TicketTitleField"
import { TicketDescriptionField } from "app/components/TicketDetail/TicketDescriptionField"
import { TicketDivisionField } from "app/components/TicketDetail/TicketDivisionField"
import { TicketSiteField } from "app/components/TicketDetail/TicketSiteField"
import { TicketParentField } from "app/components/TicketDetail/TicketParentField"
import { TicketAttachments } from "app/components/TicketDetail/TicketAttachments"
import { CancelDialog } from "app/components/CancelDialog"
import { SubmitHandler } from "react-hook-form"
import { createFileThunk } from "state/fileCreate/thunks"
import { useCreateFormConfig, Form } from "app/components/Form"
import { useAppContext } from "app/providers/AppProvider"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { SaveButton } from "app/components/SaveButton"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import { FormCreateTicketType } from "./types"
import { createTicketSchema } from "./schema"
import { transformFormTicket } from "./utils"

const TicketCreationPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { gtag } = useGoogleTagManager()
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false)
  const { isPending } = useSelector(getRequestStatus)
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const dispatch = useDispatch()
  const { site, division } = useAppContext()
  const { t } = useTranslate()

  const defaultValues: FormCreateTicketType = {
    title: "",
    description: "",
    urgency: UrgencyEnum.MEDIUM,
    quote_requested: "false",
    site_id: site.id,
    deadline_on: null,
    division_id: division.id,
    attachments: [],
  }

  const formConfig = useCreateFormConfig({ defaultValues, schema: createTicketSchema })
  const { context, handleSubmit } = formConfig

  const {
    formState: { isDirty },
  } = context

  const saveTicket: SubmitHandler<FormCreateTicketType> = async payload => {
    const { attachments, ...formTicket } = payload
    const createFilePromises = [...attachments].map(file =>
      handleSubmit(createFileThunk({ file, subject_type: "SupportTicketAttachment" })),
    )
    const createdFiles = await Promise.all(createFilePromises)
    const attachmentIds = createdFiles.map(file => file.id)
    const ticket = transformFormTicket(formTicket, attachmentIds)
    const { id } = await handleSubmit(createSupportTicketThunk({ customerGroupId, ticket }))

    dispatch(ticketCreateSlice.actions.reset())
    gtag("New_ticket")
    navigate(`/tickets/${id}`)
  }

  const goBack = () => navigate(`/tickets`)

  const cancelAction = () => {
    if (!isDirty) goBack()
    else setCancelModalOpen(true)
  }

  return (
    <>
      <Form config={formConfig} onSubmit={saveTicket}>
        <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
          <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
            <UrgencySelector />
            <QuotationSelector />
          </Grid.FlexItem>
          <PageMainGridItem>
            <Stack direction="column" spacing="l">
              <Card inset="l">
                <TicketTitleField />
              </Card>
              <Card inset="l">
                <Stack direction="column" spacing="l">
                  <TicketSiteField />
                  <TicketDivisionField />
                </Stack>
              </Card>
              <Card inset="l">
                <TicketDescriptionField />
              </Card>
              <Card inset="l">
                <TicketParentField />
              </Card>
              <Card inset="l">
                <TicketAttachments />
              </Card>
            </Stack>
          </PageMainGridItem>
          <Grid.Item colSpan={[1, { m: 2 }]}>
            <ButtonWrapper>
              <Button variant="tertiary" onClick={cancelAction}>
                {t("system.cancel")}
              </Button>
              <SaveButton variant="primary" type="submit" saving={isPending}>
                {isPending ? t("system.saving") : t("system.save")}
              </SaveButton>
            </ButtonWrapper>
          </Grid.Item>
        </Grid>
      </Form>
      <CancelDialog
        isOpen={cancelModalOpen}
        setIsOpen={setCancelModalOpen}
        onConfirmCancel={goBack}
        cancelAction={{
          content: t("ticket.create.cancel.cancel"),
          onClick: () => setCancelModalOpen(false),
          variant: "tertiary",
        }}
      >
        {t("ticket.create.cancel.message")}
      </CancelDialog>
    </>
  )
}

export default TicketCreationPage
