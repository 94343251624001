import React, { useState } from "react"
import { Table, TableSortValues, TableSkeleton, LinkButton, Button, Stack } from "@paudigital/multidesk-ui-kit"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { sortArrayToString, sortStringToArray } from "utils/sorting"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { useTranslate } from "app/hooks/translate"
import { setHiddenColumns } from "state/app/slice"
import { useDispatch, useSelector } from "react-redux"
import { getHiddenColumns } from "state/app/selectors"
import PaginatedList from "../PaginatedList"
import { StyledTableCard, StyledTableWrapper } from "./style"
import { TableOverviewProps } from "./tableOverview.types"
import { TableHeader } from "./TableHeader"
import { TableActions } from "./TableActions"

const TableOverview: React.FC<TableOverviewProps> = ({
  id,
  heading,
  data,
  pagination,
  actionButton,
  isLoading,
  sortOptions,
}): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const { queryParams, updateQueryParams } = useUpdateQueryParams()
  const { gtag } = useGoogleTagManager()
  const currentSortValue = queryParams.get("sort") ?? ""
  const sort = sortStringToArray(currentSortValue)
  const hasEditMode = !!data.columns.find(column => column.canHide)
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const [editedColumns, setEditedColumns] = useState<string[]>([])
  const hiddenColumns = useSelector(getHiddenColumns)

  const handleSort = (sort: TableSortValues) => {
    updateQueryParams({
      page: "1",
      sort: sortArrayToString(sort),
    })
    gtag("Sort", {
      Sort_type: sort,
    })
  }

  const cancelEditMode = () => setEditModeEnabled(false)

  const enableEditMode = () => {
    setEditedColumns(hiddenColumns[id])
    setEditModeEnabled(previousEditModeEnabled => !previousEditModeEnabled)
  }

  const saveView = () => {
    dispatch(
      setHiddenColumns({
        ...hiddenColumns,
        [id]: editedColumns,
      }),
    )
    setEditModeEnabled(false)
  }

  const resetView = () => {
    dispatch(
      setHiddenColumns({
        ...hiddenColumns,
        [id]: [],
      }),
    )
  }

  return (
    <StyledTableCard inset="m">
      <PaginatedList pagination={pagination} isLoading={isLoading}>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <TableHeader heading={heading} search={!editModeEnabled} sort={!editModeEnabled} sortOptions={sortOptions}>
              {actionButton && (
                <LinkButton icon={actionButton.icon} to={actionButton.to}>
                  {actionButton.label}
                </LinkButton>
              )}
              {!editModeEnabled && hasEditMode && (
                <TableActions
                  actions={[
                    {
                      label: t("system.table.edit"),
                      onClick: enableEditMode,
                    },
                    {
                      label: t("system.table.reset"),
                      onClick: resetView,
                    },
                  ]}
                />
              )}
              {editModeEnabled && (
                <Stack spacing="m" justifyContent="flex-end">
                  <Button variant="tertiary" onClick={cancelEditMode}>
                    {t("system.cancel")}
                  </Button>
                  <Button onClick={saveView}>{t("system.table.save")}</Button>
                </Stack>
              )}
            </TableHeader>
            <StyledTableWrapper>
              <Table
                columns={data.columns}
                rows={data.rows}
                sort={sort}
                onSort={handleSort}
                editMode={editModeEnabled}
                hiddenColumns={editModeEnabled ? editedColumns : hiddenColumns[id]}
                onSetHiddenColumns={setEditedColumns}
              />
            </StyledTableWrapper>
          </>
        )}
      </PaginatedList>
    </StyledTableCard>
  )
}

export default TableOverview
