import { UrgencyEnum } from "api/tickets"

export const urgencyToHours = (urgency: UrgencyEnum): number => {
  switch (urgency) {
    case UrgencyEnum.LOW:
      return 24 * 7
    default:
    case UrgencyEnum.MEDIUM:
      return 48
    case UrgencyEnum.HIGH:
      return 24
    case UrgencyEnum.URGENT:
      return 2
  }
}

export const urgencyToTime = (urgency: UrgencyEnum): number => {
  switch (urgency) {
    case UrgencyEnum.LOW:
      return urgencyToHours(urgency) / 24
    default:
      return urgencyToHours(urgency)
  }
}
