import React from "react"
import { Dialog, IconSettings, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useSelector } from "react-redux"
import { getFeatureFlags } from "state/app/selectors"
import { getEnvVariable } from "utils/getEnvVariable"
import { StyledButton } from "./style"
import { FeatureFlags } from "./FeatureFlags"
import { FlagType, SubFlagType } from "./types"

export const DeveloperUI: React.FC = () => {
  const developerUIEnabled = getEnvVariable("REACT_APP_ENABLE_DEVELOPER_UI") === "true"
  const featureFlags = useSelector(getFeatureFlags)
  const [isDeveloperUIOpen, setIsDeveloperUIOpen] = React.useState(false)

  const toggleDeveloperUI = () => {
    setIsDeveloperUIOpen(oldState => !oldState)
  }

  const flagsActive = Object.keys(featureFlags).some((flag: FlagType) =>
    Object.keys(featureFlags[flag]).some((key: SubFlagType) => featureFlags[flag][key]),
  )

  return developerUIEnabled ? (
    <>
      <StyledButton icon={IconSettings} onClick={toggleDeveloperUI} flagsActive={flagsActive} />
      <Dialog title="Developer UI" isOpen={isDeveloperUIOpen} onClose={toggleDeveloperUI}>
        <Stack direction="column" spacing="s">
          <Text variant="sectionTitle">Feature flags</Text>
          <Stack spacing="3xs" direction="column">
            {Object.keys(featureFlags).map(key => (
              <FeatureFlags key={key} version={key as keyof typeof featureFlags} />
            ))}
          </Stack>
        </Stack>
      </Dialog>
    </>
  ) : null
}
