import { api } from "api"
import { AxiosPromise } from "axios"
import { Modules } from "router/types"
import {
  ContactPerson,
  Profile,
  UpdatePasswordParams,
  UpdatePasswordReturnValue,
  UpdateProfileParams,
  UpdateProfileReturnValue,
} from "./user.types"

export type FetchModulesReturnValue = Modules[]
export type FetchContactPersonReturnValue = ContactPerson
export type FetchProfileReturnValue = Profile

export const fetchModulesFromCustomer = (id: number): AxiosPromise<FetchModulesReturnValue> => {
  return api(`/customer_groups/${id}/modules`, {
    method: "GET",
  })
}

export const fetchContactPersonForUser = (id: number): AxiosPromise<FetchContactPersonReturnValue> => {
  return api(`/users/${id}/contact_person`, {
    method: "GET",
  })
}

export const fetchProfile = (id: number): AxiosPromise<FetchProfileReturnValue> => {
  return api(`/users/${id}`, {
    method: "GET",
  })
}

export const updateProfile = ({ userId, profile }: UpdateProfileParams): AxiosPromise<UpdateProfileReturnValue> => {
  return api(`/users/${userId}`, {
    method: "PATCH",
    data: profile,
  })
}

export const updatePassword = ({
  userId,
  "old-password": oldPassword,
  "new-password": newPassword,
}: UpdatePasswordParams): AxiosPromise<UpdatePasswordReturnValue> => {
  return api(`/users/${userId}/password`, {
    method: "PATCH",
    data: {
      "old-password": oldPassword,
      "new-password": newPassword,
    },
  })
}
