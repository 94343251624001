import { getDefaultLocale } from "i18n/utils/getDefaultLocale"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getModules = (state: RootState) => {
  return state.userReducer.modules.data
}

export const getModulesRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userReducer.modules.status)
}

export const getCustomerGroups = (state: RootState) => {
  return state.userReducer.customerGroups.data
}

export const getActiveCustomerGroup = (state: RootState) => {
  return getCustomerGroups(state).find(group => group.is_user_default_group)!
}

export const getUserIsGroupAdmin = (state: RootState) =>
  state.userReducer.customerGroups.data.some(item => item.is_user_default_group && item.user_is_group_admin)

export const getActiveCustomerGroupId = (state: RootState) => {
  return getActiveCustomerGroup(state)?.id!
}

export const getContactPerson = (state: RootState) => {
  return state.userReducer.contactPerson.data
}

export const getProfile = (state: RootState) => {
  return state.userReducer.profile.data
}

export const getProfileRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userReducer.profile.status)
}

export const getPasswordRequestStatus = (state: RootState) => {
  return getThunkStatus(state.userReducer.password.status)
}

export const getLocale = (state: RootState) => {
  return getProfile(state)?.preferred_language || getDefaultLocale()
}
