import React from "react"
import { Outlet, RouteObject } from "react-router-dom"
import RootPage from "app/pages/Root"
import DashboardPage from "app/pages/Dashboard"
import ProtectedPage from "app/pages/Protected"
import NotFound from "app/pages/NotFound"
import TicketsPage from "app/pages/Tickets"
import TicketDetailPage from "app/pages/TicketDetail"
import TicketCreationPage from "app/pages/TicketCreation"
import AssistantPage from "app/pages/Assistant"
import AssistantFilterSetCreation from "app/pages/AssistantFilterSetCreation"
import DocumentsPage from "app/pages/Documents"
import InvoicesPage from "app/pages/Invoices"
import AssistantFilterSetDetailPage from "app/pages/AssistantFilterSetEdit"
import DocumentCreationPage from "app/pages/DocumentCreation"
import DocumentDetailPage from "app/pages/DocumentDetail"
import NoAccess from "app/pages/NoAccess"
import AccountPage from "app/pages/Account"
import Logout from "app/pages/Logout"
import QMSPage from "app/pages/QMS"
import QMSYearsPage from "app/pages/QMS/Years"
import QMSResultsPage from "app/pages/QMS/Results"
import QMSAuditsPage from "app/pages/QMS/Audits"
import HygiLocationPage from "app/pages/HygiLocation"
import ManagementPage from "app/pages/Management"
import ManagementRolesAndPermissionsPage from "app/pages/RolesAndPermissions"
import ManagementRolesAndPermissionsTabPage from "app/pages/RolesAndPermissions/RolesAndPermissionsTab"
import UsersPage from "app/pages/Users"
import UserCreationPage from "app/pages/UserCreation"
import UserDetailPage from "app/pages/UserDetail"
import HygiLocationsPage from "app/pages/HygiLocations"
import HygiPage from "app/pages/Hygi"
import UserReplacePage from "app/pages/UserReplace"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedPage />,
    label: "system.home",
    children: [
      {
        index: true,
        element: <RootPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
        module: "dashboard",
        label: "system.dashboard",
        hideBreadcrumbs: true,
      },
      {
        path: "/tickets",
        element: <TicketsPage />,
        module: "support_tickets",
        label: "system.support_tickets",
      },
      {
        path: "/tickets/create",
        element: <TicketCreationPage />,
        label: "system.ticket.create",
      },
      {
        path: "/tickets/:ticketId",
        element: <TicketDetailPage />,
        label: "system.ticket.detail",
      },
      {
        path: "/documents",
        element: <DocumentsPage />,
        module: "documents",
        label: "system.documents",
      },
      {
        path: "/documents/new",
        element: <DocumentCreationPage />,
        label: "system.documents.create",
      },
      {
        path: "/documents/:documentId",
        element: <DocumentDetailPage />,
        label: "system.documents.detail",
      },
      {
        path: "/invoices",
        element: <InvoicesPage />,
        module: "invoices",
        label: "system.invoices",
      },
      {
        path: "/assistant",
        element: <AssistantPage />,
        module: "assistant",
        label: "system.assistant",
      },
      {
        path: "/assistant/:filterSetId",
        element: <AssistantFilterSetDetailPage />,
        label: "system.assistant.edit_subject",
      },
      {
        path: "/assistant/create",
        element: <AssistantFilterSetCreation />,
        label: "system.assistant.new_subject",
      },
      {
        path: "/qms",
        element: <QMSPage />,
        label: "system.qms",
        module: "qms",
        children: [
          {
            path: "years",
            element: <QMSYearsPage />,
            label: "system.qms.years",
          },
          {
            path: "audits",
            element: <QMSAuditsPage />,
            label: "system.qms.audits",
          },
          {
            path: "results",
            element: <QMSResultsPage />,
            label: "system.qms.results",
          },
        ],
      },
      {
        path: "/hygi",
        element: <HygiPage />,
        label: "system.hygi",
        module: "hygi",
      },
      {
        path: "/hygi/inspections/:inspectionId",
        element: <HygiLocationsPage />,
        label: "system.locations",
        module: "hygi",
      },
      {
        path: "/hygi/inspections/:inspectionId/locations/:locationId",
        element: <HygiLocationPage />,
        label: "system.locations.detail",
        maxHeight: [
          false,
          {
            m: true,
          },
        ],
      },
      {
        path: "/management",
        element: <ManagementPage />,
        label: "system.management",
        module: "user_management",
        children: [
          {
            path: "users",
            element: <Outlet />,
            label: "system.user_management",
            children: [
              {
                path: "",
                element: <UsersPage />,
              },
              {
                path: ":userId",
                element: <UserDetailPage />,
                label: "system.users.detail",
              },
              {
                path: "create",
                element: <UserCreationPage />,
              },
              {
                path: "replace/:userId",
                element: <UserReplacePage />,
              },
            ],
          },
          {
            path: "roles-and-permissions",
            element: <ManagementRolesAndPermissionsPage />,
            label: "system.roles_and_permissions",
            children: [
              {
                path: "documents",
                element: <ManagementRolesAndPermissionsTabPage module="documents" />,
                label: "system.documents",
                module: "documents",
              },
              {
                path: "tickets",
                element: <ManagementRolesAndPermissionsTabPage module="support_tickets" />,
                label: "system.support_tickets",
                module: "support_tickets",
              },
              {
                path: "invoices",
                element: <ManagementRolesAndPermissionsTabPage module="invoices" />,
                label: "system.invoices",
                module: "invoices",
              },
              {
                path: "qms",
                element: <ManagementRolesAndPermissionsTabPage module="qms" />,
                label: "system.qms",
                module: "qms",
              },
              {
                path: "hygi",
                element: <ManagementRolesAndPermissionsTabPage module="hygi" />,
                label: "system.hygi",
                module: "hygi",
              },
            ],
          },
        ],
      },
      {
        path: "/account",
        element: <AccountPage />,
        label: "system.settings",
        module: "settings",
      },
      {
        path: "/no-access",
        element: <NoAccess />,
        label: "",
        hideBreadcrumbs: true,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <NotFound />,
        label: "",
      },
    ],
  },
]

export default routes
