import { api } from "api"
import { setSearchParams } from "api/utils/searchParams"
import axios, { AxiosPromise } from "axios"
import {
  FetchUsersReturnValue,
  CreateUserReturnValue,
  CreateUserParams,
  DeleteUserReturnValue,
  DeleteUserPayload,
  FetchUsersSitesReturnValue,
  AssignRolesToUserParams,
  AssignRolesToUserReturnValue,
  FetchUserParams,
  FetchUserReturnValue,
  UpdateUserParams,
  FetchUsersParams,
  FetchUserRightsReturnValue,
  FetchUserRightsParams,
  ReplaceUserParams,
  ReplaceUserReturnValue,
} from "./users.types"

export const fetchUsers = ({
  activeCustomerGroupId,
  ...searchParams
}: FetchUsersParams): AxiosPromise<FetchUsersReturnValue> => {
  return api(`/customer_groups/${activeCustomerGroupId}/user_management/users/overview`, {
    method: "GET",
    params: setSearchParams(searchParams),
  })
}

export const postCreateUser = (_props: CreateUserParams): AxiosPromise<CreateUserReturnValue> => {
  // TODO: Remove /mock/user.mock.json and create POST
  return axios.get("/mock/user.mock.json").then(res => res)
}

export const deleteUser = ({ customerGroupId: _, userId }: DeleteUserPayload): DeleteUserReturnValue => {
  return userId
}

// export const deleteUser = ({ customerGroupId, userId }: DeleteUserParams): AxiosPromise<void> => {
//   api(`/customer_groups/${customerGroupId}/documents/${userId}`, {
//     method: "DELETE",
//   })
//   return userId
// export const postCreateUser = ({ customerGroupId, user }: CreateUserParams): AxiosPromise<CreateUserReturnValue> => {
//  return api(`/customer_group/{customerGroupId}/user_management/users`, {
//     method: "POST",
//     data: user,
//   })
// }

export const fetchUsersSites = (_id: number): AxiosPromise<FetchUsersSitesReturnValue> => {
  // TODO: Remove /mock/usersSites.mock.json
  // {baseUrl}/customer_group/{id}/user_management/sites
  return axios.get("/mock/usersSites.mock.json").then(res => res)
}

export const postAssignRolesToUser = (_props: AssignRolesToUserParams): AxiosPromise<AssignRolesToUserReturnValue> => {
  // TODO: Remove /mock/assignedUserSites.mock.json
  return axios.get("/mock/assignedUserSites.mock.json").then(res => res)
}

// export const postAssignRolesToUser = ({ customerGroupId, user }: CreateUserParams): AxiosPromise<CreateUserReturnValue> => {
//  return api(`/customer_group/{customerGroupId}/user_management/users`, {
//     method: "POST",
//     data: user,
//   })
// }

export const fetchUser = (_props: FetchUserParams): AxiosPromise<FetchUserReturnValue> => {
  return axios.get("/mock/user.mock.json").then(res => res)
}

export const updateUser = (_props: UpdateUserParams): AxiosPromise<void> => {
  // TODO: Remove /mock/user.mock.json and create POST
  return axios.get("/mock/user.mock.json").then(res => res)
}

// export const updateUser = ({
//   customerGroupId,
//   user,
// }: UpdateUserParams): AxiosPromise<UpdateUserReturnValue> => {
//   return api(`/customer_group/${customerGroupId}/user_management/users/${user.id}`, {
//     method: "PATCH",
//     data: user,
//   })
// }

export const fetchUserRights = (_props: FetchUserRightsParams): AxiosPromise<FetchUserRightsReturnValue> => {
  return axios.get("/mock/assignedUserSites.mock.json").then(res => res)
}

// export const fetchUserRights = ({
//   customerGroupId,
//   userId,
// }: GetUserRightsParams): AxiosPromise<FetchUserRightsReturnValue> => {
//   return axios.get(`/customer_group/${customerGroupId}/user_management/user/${userId}/roles`).then(res => res)
// }

export const postReplaceUser = (_props: ReplaceUserParams): AxiosPromise<ReplaceUserReturnValue> => {
  // TODO: Remove /mock/user.mock.json and create POST
  return axios.get("/mock/user.mock.json").then(res => res)
}
