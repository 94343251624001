import React, { PropsWithChildren } from "react"
import { useTheme } from "styled-components"
import { useBarChartContext } from ".."
import { getConfig } from "../config"
import { StyledLabelText } from "../../style"
import { BarLabelProps } from "../types"

export const Labels = <T,>({ data, groupBy }: PropsWithChildren<BarLabelProps<T>>) => {
  const theme = useTheme()
  const { barLabelFontSize, graphPaddingTop, graphPaddingY } = getConfig()
  const { areaHeight, scaleX } = useBarChartContext()
  const bars = data.map(d => d[groupBy])

  return (
    <g
      fill={theme.color.neutral.black}
      fontSize={barLabelFontSize}
      textAnchor="middle"
      transform={`translate(${graphPaddingY}, ${areaHeight - graphPaddingTop})`}
    >
      {bars.map(b => {
        return (
          <StyledLabelText key={`bar-${b}`} transform={`translate(${(scaleX(b) || 0) + scaleX.bandwidth() / 2}, 0)`}>
            {b}
          </StyledLabelText>
        )
      })}
    </g>
  )
}
