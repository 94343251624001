import { SearchParams } from "api/types"
import { PaginatedResult } from "state/types"

export enum DocumentVisibilityEnum {
  PERSONAL = "personal",
  LIBRARY = "library",
  AWAITING_PROMOTION_TO_LIBRARY = "awaiting_promotion_to_library",
}
export type DocumentVisibility = `${DocumentVisibilityEnum}`

export type DocumentUploadedBy = {
  id: number
  author: string
}

export interface DocumentItemType {
  id: number
  name: string
  category: string
  visibility: DocumentVisibility
  reason_promotion_to_library: string | null
  uploaded_by: DocumentUploadedBy
  file: {
    id: number
    type: string
    extension: string
  }
  preview_urls: {
    thumbnail: string
    desktop: string
    mobile: string
  }
  url: string
  created_at: string
  starred: boolean
  expires_at: string | null
}

export interface FetchDocumentsParams extends SearchParams {
  activeCustomerGroupId: number
  category?: string
  visibility?: string
  starred?: string
}

export interface CreateDocumentData {
  "file-id": number
  name: string
  category: string
  visibility: DocumentVisibility
  reason_promotion_to_library: string | null
}
export interface CreateDocumentParams {
  customerGroupId: number
  document: CreateDocumentData
}

export interface PostStarDocumentData {
  "document-id": number
  starred: boolean
}
export interface PostStarDocumentParams {
  customerGroupId: number
  data: PostStarDocumentData
}

export type FetchDocumentsReturnValue = PaginatedResult<DocumentItemType>
export type FetchSingleDocumentReturnValue = DocumentItemType
export type PostStarDocumentReturnValue = PostStarDocumentData

export interface FetchDocumentStatisticsData {
  activeCustomerGroupId: number
}

export interface DocumentStatisticsType {
  new: number
}

export type FetchDocumentStatisticsReturnValue = DocumentStatisticsType
export type CreateDocumentReturnValue = DocumentItemType

export interface UpdateDocumentParams {
  customerGroupId: number
  documentId: number
  document: Omit<Partial<CreateDocumentData>, "file-id">
}

export interface DeleteDocumentParams {
  customerGroupId: number
  documentId: number
}
export type DeleteDocumentThunkParameterType = {
  customerGroupId: number
  documentId: number
}

export type UpdateDocumentReturnValue = DocumentItemType
export type UpdateDocumentThunkParameterType = {
  customerGroupId: number
  documentId: number
  document: Partial<CreateDocumentData>
}

export type FetchDocumentCategoriesReturnValue = string[]
