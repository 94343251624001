import React from "react"
import { Radio as UIRadio } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { RadioProps } from "./Radios.types"
import { useRadioContext } from "./RadioProvider"

export const Radio: React.FC<RadioProps> = props => {
  const { register } = useFormContext()
  const { name } = useRadioContext()
  const errorMessage = useGetErrorMessage(name)
  return <UIRadio {...props} hasError={!!errorMessage} {...register(name)} />
}
