import React from "react"
import { useTranslate } from "app/hooks/translate"
import { Button } from "@paudigital/multidesk-ui-kit"
import { PresetReport } from "../PresetReport"
import { useAssistant, usePresetDropdown } from "../.."
import { PresetProps } from "./Preset.types"

export const Preset = ({ preset, subjectType }: PresetProps) => {
  const { onChange, filterSet } = useAssistant()
  const { setPresetDropdownIsOpen } = usePresetDropdown()
  const { t } = useTranslate()

  const onClick = () => {
    if (!onChange) return
    onChange({ ...filterSet, subject_type: subjectType, filter_set: preset.filter_set })
    setPresetDropdownIsOpen(false)
  }

  return (
    <Button onClick={onClick} variant="tertiary" align="left">
      {t(`system.${subjectType}`)}
      {preset.filter_set.map((filterItem, index) => (
        <PresetReport key={index} filterItem={filterItem} subject={subjectType} isFirstItem={index === 0} />
      ))}
    </Button>
  )
}
