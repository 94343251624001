import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { featureFlags } from "featureFlags"
import { getLocalStorage, setLocalStorage } from "utils/localStorage"
import { getSessionStorage, setSessionStorage } from "utils/sessionStorage"
import { AppState, HiddenColumnType } from "./types"

const initialState: AppState = {
  hasTranslationsExposed: false,
  featureFlags: featureFlags,
  hiddenColumns: {},
}

const FEATURE_FLAGS_STORAGE_KEY = "featureFlags"
const HIDDEN_TABLE_COLUMNS_STORAGE_KEY = "hiddenTableColumns"

export const appSlice = createSlice({
  name: "app",
  initialState: () => {
    const flags = getSessionStorage<typeof featureFlags>(FEATURE_FLAGS_STORAGE_KEY) ?? featureFlags
    const hiddenColumns = getLocalStorage<HiddenColumnType>(HIDDEN_TABLE_COLUMNS_STORAGE_KEY) ?? {}
    return { ...initialState, featureFlags: flags, hiddenColumns }
  },
  reducers: {
    toggleHasTranslationsExposed: state => {
      state.hasTranslationsExposed = !state.hasTranslationsExposed
    },
    setFeatureFlags: (state, action: PayloadAction<typeof featureFlags>) => {
      state.featureFlags = action.payload
      setSessionStorage(FEATURE_FLAGS_STORAGE_KEY, action.payload)
    },
    setHiddenColumns: (state, action: PayloadAction<HiddenColumnType>) => {
      state.hiddenColumns = action.payload
      setLocalStorage(HIDDEN_TABLE_COLUMNS_STORAGE_KEY, action.payload)
    },
  },
})

export const { toggleHasTranslationsExposed, setFeatureFlags, setHiddenColumns } = appSlice.actions

export default appSlice.reducer
