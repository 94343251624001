const sankeyChartConfig = {
  graphHeight: 450,
  graphWidth: 1400,
}

export const getConfig = () => {
  const defaultSankeyChartConfig = {
    nodeLabelFontSize: 14,
    nodeLabelIndent: 4,
    nodeWidth: 30,
    nodePadding: 16,
    nodeLegendHeight: 22,
    graphPaddingRight: 100,
  }

  const config = {
    ...defaultSankeyChartConfig,
    ...sankeyChartConfig,
  }

  const { graphWidth, graphHeight, nodeLegendHeight, graphPaddingRight } = config
  const areaWidth = graphWidth + graphPaddingRight
  const areaHeight = graphHeight + nodeLegendHeight

  return {
    ...config,
    areaWidth,
    areaHeight,
  }
}
