import React from "react"
import { FilesChangedEvent, FileUpload as UIFileUpload, Notification, Stack } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useTranslate } from "app/hooks/translate"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { triggerErrorNotification } from "utils/notifications"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { FileUploadProps, NotificationErrorProps } from "./FileUpload.types"
import { useMapErrorsToMessageTypes } from "./utils"

export const FileUpload: React.FC<FileUploadProps> = props => {
  const { name, label, accept, onChange, ...rest } = props
  const { multiple } = rest
  const { register } = useFormContext()
  const { t } = useTranslate()
  const { mapErrorsToMessageTypes } = useMapErrorsToMessageTypes(props)
  const errorMessage = useGetErrorMessage(name)
  const [notificationErrors, setNotificationErrors] = React.useState<NotificationErrorProps[]>([])

  const fieldLabel = useCreateFormFieldLabel(name, label)
  const { onChange: onRegisterChange, ...registeredField } = register(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRegisterChange(e)
    onChange?.(e)
  }

  const filesChangedHandler = (messages: FilesChangedEvent) => {
    if (!messages.length) {
      setNotificationErrors([])
      return
    }

    const [notificationErrors, toastErrors] = mapErrorsToMessageTypes(messages)
    setNotificationErrors(notificationErrors)
    toastErrors.forEach(error => triggerErrorNotification(error))
  }

  return (
    <Stack direction="column" spacing="l">
      <UIFileUpload
        label={fieldLabel}
        errorMessage={errorMessage}
        accept={accept}
        onFilesChanged={filesChangedHandler}
        hasError={!!errorMessage}
        onChange={handleChange}
        {...registeredField}
        {...rest}
      >
        {t("form.field.file_upload.label", { multiple: multiple ? 2 : 1 })}
      </UIFileUpload>
      {notificationErrors.length !== 0 && (
        <Stack direction="column" spacing="xs">
          {notificationErrors.map(error => (
            <Notification key={error.title} type="error" hideCloseButton {...error} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}
