import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchHygiLocations, FetchHygiLocationsDataReturnValue, FetchHygiLocationsParams } from "api/hygi"
import { StateError } from "state/types"

export const getHygiLocationsThunk = createAsyncThunk<
  FetchHygiLocationsDataReturnValue,
  FetchHygiLocationsParams,
  StateError
>("app/getHygiLocations", async (payload, thunkAPI) => {
  const { customerGroupId, inspectionId } = payload
  try {
    const { data } = await fetchHygiLocations({ customerGroupId, inspectionId })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
