import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getQMSYearlyGraphData = (state: RootState) => {
  return state.QMSReducer.yearly.graph.data
}

export const getQMSYearlyTableData = (state: RootState) => {
  return state.QMSReducer.yearly.table.data
}

export const getRequestStatusGraph = (state: RootState) => {
  return getThunkStatus(state.QMSReducer.yearly.graph.status)
}

export const getRequestStatusYearlyTable = (state: RootState) => {
  return getThunkStatus(state.QMSReducer.yearly.table.status)
}

export const getQMSAuditsTableData = (state: RootState) => {
  return state.QMSReducer.audits.table.data
}

export const getQMSAuditsGraphData = (state: RootState) => {
  return state.QMSReducer.audits.graph.data
}

export const getRequestStatusAuditTable = (state: RootState) => {
  return getThunkStatus(state.QMSReducer.audits.table.status)
}

export const getQMSResultsTableData = (state: RootState) => {
  return state.QMSReducer.results.table.data
}

export const getRequestStatusResultsTable = (state: RootState) => {
  return getThunkStatus(state.QMSReducer.results.table.status)
}

export const getQMSResultsGraphData = (state: RootState) => {
  return state.QMSReducer.results.graph.data
}

export const getRequestStatusResultsGraph = (state: RootState) => {
  return getThunkStatus(state.QMSReducer.results.graph.status)
}
