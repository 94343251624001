import { IndicatorColors } from "@paudigital/multidesk-ui-kit"
import { ETrapTrapType, TrapType } from "api/hygi"

export const checkIsETrap = (trap: TrapType): trap is ETrapTrapType => {
  return "state" in trap
}

export const getIndicatorColor = (trap: ETrapTrapType): IndicatorColors => {
  switch (trap.etrap_state) {
    case "open":
      return "green"
    case "closed":
      return "red"
    // TODO: ADD `orange` case
    default:
      return "purple"
  }
}
