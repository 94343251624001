import { IconButton, IconUser, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useAppContext } from "app/providers/AppProvider"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getContactPerson } from "state/user/selectors"
import { getContactPersonThunk } from "state/user/thunks"
import { StyledContactCard, StyledContactDetails } from "./style"

export const ContactCard: React.FC = () => {
  const { userId } = useAppContext()
  const contactPerson = useSelector(getContactPerson)
  const dispatch = useDispatch()
  const { t } = useTranslate()

  useEffect(() => {
    if (!userId) return
    dispatch(getContactPersonThunk(userId))
  }, [userId, dispatch])

  return (
    <StyledContactCard>
      <p>{t("system.ticket.edit.urgency.urgent_warning")}</p>
      {contactPerson && (
        <Stack spacing="s" alignItems="center">
          <a href={`tel:${contactPerson.telephone_number}`}>
            <IconButton icon={IconUser} />
          </a>
          <StyledContactDetails>
            {contactPerson.first_name} {contactPerson.last_name}
            <br />
            {contactPerson.telephone_number}
          </StyledContactDetails>
        </Stack>
      )}
    </StyledContactCard>
  )
}
