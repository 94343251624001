import React, { useEffect } from "react"
import { Dialog, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useAppContext } from "app/providers/AppProvider"
import { useDispatch, useSelector } from "react-redux"
import { addSupportTicketCommentThunk } from "state/ticketComment/thunks"
import { getActiveCustomerGroup } from "state/user/selectors"
import { getSingleSupportTicketThunk, patchSupportTicketThunk } from "state/ticketDetail/thunks"
import { getTicketDetail } from "state/ticketDetail/selectors"
import { TicketStatusEnum } from "api/tickets"
import { useCreateFormConfig, Form } from "../Form"
import { TicketHistoryCommentField } from "../TicketDetail/TicketHistoryCommentField"
import { TicketHistoryTimeline } from "../TicketHistoryTimeline"
import { commentSchema } from "./schema"
import { CloseTicketDialogProps, CloseTicketHandlerProps } from "./types"

const CloseTicketDialog: React.FC<CloseTicketDialogProps> = ({ isOpen, setIsOpen, ticketId }) => {
  const dispatch = useDispatch()
  const ticket = useSelector(getTicketDetail)
  const { id: customerGroupId } = useSelector(getActiveCustomerGroup)
  const { userId } = useAppContext()
  const { t } = useTranslate()

  const formConfig = useCreateFormConfig<CloseTicketHandlerProps>({
    defaultValues: { comment: "" },
    schema: commentSchema,
  })
  const { handleSubmit } = formConfig

  const closeTicketHandler = async ({ comment }: CloseTicketHandlerProps) => {
    if (!ticket) return
    setIsOpen(false)
    if (comment.length > 0) {
      await handleSubmit(
        addSupportTicketCommentThunk({
          customerGroupId,
          ticketId: ticket.id,
          comment: { user_id: userId, comment },
        }),
      )

      formConfig.context.reset()
    }
    await dispatch(
      patchSupportTicketThunk({ customerGroupId, ticketId: Number(ticketId), data: { status: TicketStatusEnum.DONE } }),
    )
  }

  useEffect(() => {
    if (!isOpen || ticket?.id === ticketId) return
    dispatch(getSingleSupportTicketThunk({ customerGroupId, ticketId }))
  }, [ticketId, isOpen, dispatch, ticket, customerGroupId])

  if (!ticket || ticket?.id !== ticketId) return null

  return (
    <Dialog
      actions={[
        {
          content: t("system.cancel"),
          onClick: () => setIsOpen(false),
          variant: "tertiary",
        },
        {
          content: t("system.close_ticket"),
          variant: "primary",
          onClick: () => formConfig.context.handleSubmit(closeTicketHandler)(),
        },
      ]}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t("system.close_ticket")}
    >
      <Stack direction="column" spacing="m">
        <Text>
          {t("ticket.close.confirm.text", {
            id: ticket.id,
            title: ticket.title,
          })}
        </Text>
        <Stack direction="column" spacing="s">
          <Form config={formConfig}>
            <TicketHistoryCommentField />
          </Form>
          <TicketHistoryTimeline history={ticket.history} />
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default CloseTicketDialog
