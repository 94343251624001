import React from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { AssistantConfiguration } from "state/assistantConfiguration/types"
import { Preset } from "../Preset"

export const PresetCategory = ({ presetCategory }: { presetCategory: AssistantConfiguration }) => {
  const presets = [...[{ filter_set: [] }], ...presetCategory.filter_sets]

  return (
    <Stack direction="column" spacing="s">
      {presets.map((preset, index) => (
        <Preset subjectType={presetCategory.subject_type} preset={preset} key={index} />
      ))}
    </Stack>
  )
}
