import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  deleteDocument,
  DeleteDocumentThunkParameterType,
  fetchSingleDocument,
  FetchSingleDocumentReturnValue,
  postStarDocument,
  PostStarDocumentParams,
  PostStarDocumentReturnValue,
  updateDocument,
  UpdateDocumentReturnValue,
  UpdateDocumentThunkParameterType,
} from "api/documents"
import { StateError } from "state/types"
import { GetSingleDocumentThunkParameterType } from "./types"

export const getSingleDocumentThunk = createAsyncThunk<
  FetchSingleDocumentReturnValue,
  GetSingleDocumentThunkParameterType,
  StateError
>("app/getSingleDocument", async (payload, thunkAPI) => {
  const { customerGroupId, documentId } = payload
  try {
    const { data } = await fetchSingleDocument(customerGroupId, documentId)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const updateDocumentThunk = createAsyncThunk<
  UpdateDocumentReturnValue,
  UpdateDocumentThunkParameterType,
  StateError
>("app/updateDocument", async (payload, thunkAPI) => {
  const { customerGroupId, documentId, document } = payload
  try {
    const { data } = await updateDocument({ customerGroupId, documentId, document })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const deleteDocumentThunk = createAsyncThunk<void, DeleteDocumentThunkParameterType, StateError>(
  "app/deleteDocument",
  async (payload, thunkAPI) => {
    try {
      const { customerGroupId, documentId } = payload
      await deleteDocument({ customerGroupId, documentId })
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const starDocumentFromDetailThunk = createAsyncThunk<
  PostStarDocumentReturnValue,
  PostStarDocumentParams,
  StateError
>("app/starDocumentFromDetail", async (payload, thunkAPI) => {
  try {
    await postStarDocument(payload)
    return payload.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
