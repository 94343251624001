import React from "react"
import { Navigation, NavigationProps } from "@paudigital/multidesk-ui-kit"
import { useRouterContext } from "app/providers/RouterProvider"
import { useTranslate } from "app/hooks/translate"
import { useDevice } from "utils/device"
import { Breadcrumbs } from "../Breadcrumbs"
import { MainWithAccess } from "../MainWithAccess"
import {
  StyledLayout,
  StyledLayoutBody,
  StyledLayoutFooter,
  StyledLayoutHeading,
  StyledLayoutContent,
} from "./Layout.style"
import { useGetNavigationItems } from "./hooks"

export const Layout: React.FC = ({ children }) => {
  const { t } = useTranslate()
  const { mobileBreakpoint } = useDevice()
  const { route, heading } = useRouterContext()
  const { top, bottom } = useGetNavigationItems()
  const { hideBreadcrumbs, maxHeight } = route
  const items = top || bottom ? ({ top, bottom } as NavigationProps["items"]) : undefined

  return items ? (
    <StyledLayout mobileBreakpoint={mobileBreakpoint}>
      <Navigation mobileBreakpoint={mobileBreakpoint} items={items} />
      <MainWithAccess>
        <StyledLayoutContent mobileBreakpoint={mobileBreakpoint} maxHeight={maxHeight}>
          {heading && (
            <StyledLayoutHeading variant="title" forwardedAs="h1">
              {t(heading)}
            </StyledLayoutHeading>
          )}
          {hideBreadcrumbs ?? <Breadcrumbs />}
          <StyledLayoutBody>{children}</StyledLayoutBody>
          <StyledLayoutFooter />
        </StyledLayoutContent>
      </MainWithAccess>
    </StyledLayout>
  ) : null
}
