import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getHygiInspections = (state: RootState) => {
  return state.hygiInspectionsReducer.inspections.data
}

export const getInspectionsRequestStatus = (state: RootState) => {
  return getThunkStatus(state.hygiInspectionsReducer.inspections.status)
}
