import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getDocuments = (state: RootState) => {
  return state.documentsReducer.documents.data
}

export const getDocumentById = (documentId: number) => (state: RootState) => {
  return state.documentsReducer.documents.data.items.find(item => item.id === documentId)
}

export const getDocumentsRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentsReducer.documents.status)
}

export const getDocumentsStarRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentsReducer.documentStar.status)
}
