import { IconButton } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"
import { StyledButtonProps } from "./types"

export const StyledButton = styled(IconButton)<StyledButtonProps>`
  position: fixed;
  cursor: pointer;
  bottom: ${({ theme }) => theme.space.s};
  right: ${({ theme }) => theme.space.s};
  border-radius: 50%;
  background: ${({ flagsActive }) => (flagsActive ? "#c59540" : "#a8acbd7d")};

  &:hover {
    background: ${({ flagsActive }) => (flagsActive ? "#ae863f" : "#a8acbdc4")};
  }
`
