import React from "react"
import Pagination from "../Pagination"
import { NoResults } from "../NoResults"
import { PaginationWrapper } from "./style"
import { PaginatedListProps } from "./types"

const PaginatedList: React.FC<PaginatedListProps> = ({ pagination, isLoading, children }): JSX.Element => {
  const noItems = pagination.total === 0 && !isLoading

  return (
    <>
      {children}
      <NoResults show={noItems} />
      {pagination && (
        <PaginationWrapper spacing="m" alignItems="center" justifyContent="flex-end">
          <Pagination last={pagination.last} current={pagination.current} />
        </PaginationWrapper>
      )}
    </>
  )
}

export default PaginatedList
