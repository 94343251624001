import React from "react"
import { Dropdown } from "@paudigital/multidesk-ui-kit"
import { useAssistant } from "../.."
import { AssistantDropdownProps } from "./AssistantDropdown.types"

export const AssistantDropdown = (props: AssistantDropdownProps) => {
  const { disabled } = useAssistant()

  return !disabled ? <Dropdown {...props} placement="bottom-start" shadow="soft" /> : null
}
