import { DeepPartial, FieldValues, UseFormReturn } from "react-hook-form"
import useDeepCompareEffect from "use-deep-compare-effect"
export const useDeferredDefaultValues = <T extends FieldValues>(
  context: UseFormReturn<T, any>,
  values?: DeepPartial<T> | undefined,
) => {
  const { reset } = context

  useDeepCompareEffect(() => {
    if (!values) return
    reset(values)
  }, [values, reset])
}
