import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getAutoCompleteSupportTicketsThunk } from "./thunks"
import { TicketState } from "./types"

const initialTicketData: TicketState["tickets"]["data"] = {
  items: [],
}

const initialState: TicketState = {
  tickets: { data: initialTicketData, status: RequestStatus.IDLE },
}

export const ticketAutoCompleteSlice = createSlice({
  name: "ticketAutoComplete",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAutoCompleteSupportTicketsThunk.pending, state => {
      state.tickets.status = RequestStatus.PENDING
    })
    builder.addCase(getAutoCompleteSupportTicketsThunk.fulfilled, (state, action) => {
      state.tickets.data.items = action.payload.items
      state.tickets.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAutoCompleteSupportTicketsThunk.rejected, (state, action) => {
      state.tickets.error = action.payload?.message
      state.tickets.status = RequestStatus.REJECTED
    })
  },
})

export default ticketAutoCompleteSlice.reducer
