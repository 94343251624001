import React from "react"
import { Card, Fieldset, Grid, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { Checkbox } from "../Form/components/Checkbox"
import { TextField } from "../Form/components/TextField"
import { UserSites } from "./components/UserSites"
import { useUserFormContent } from "."

export const UserFormContentConsumer: React.FC = ({ children }) => {
  const { t } = useTranslate()
  const { disabled } = useUserFormContent()

  return (
    <Stack direction="column" spacing="m">
      <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
        <Card inset="l">
          <Grid gap="m" columns={1}>
            <Text as="h2" variant="display">
              {t("system.user_data")}
            </Text>
            <Fieldset legend={t("system.user.status")}>
              <Checkbox disabled={disabled} name="is_active" id="isActive" label={t("system.user.active")} />
            </Fieldset>
            <Grid gap="m" columns={[1, { m: 2 }]}>
              <Grid.FlexItem colSpan={1} direction="column">
                <TextField disabled={disabled} name="first_name" id="firstName" label={t("system.first_name")} />
              </Grid.FlexItem>
              <Grid.FlexItem colSpan={1} direction="column">
                <TextField disabled={disabled} name="last_name" id="lastName" label={t("system.last_name")} />
              </Grid.FlexItem>
              <Grid.FlexItem colSpan={1} direction="column">
                <TextField disabled={disabled} type="email" name="email" id="email" label={t("system.email_address")} />
              </Grid.FlexItem>
              <Grid.FlexItem colSpan={1} direction="column">
                <TextField
                  disabled={disabled}
                  type="tel"
                  name="phonenumber"
                  id="phoneNumber"
                  label={t("system.phone_number")}
                />
              </Grid.FlexItem>
            </Grid>
          </Grid>
        </Card>
      </Grid.FlexItem>
      <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
        <UserSites />
      </Grid.FlexItem>
      {children}
    </Stack>
  )
}
