import styled from "styled-components"
import { Card } from "@paudigital/multidesk-ui-kit"

export const StyledTableCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const StyledTableWrapper = styled.div`
  overflow-y: auto;
`
