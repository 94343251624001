import styled from "styled-components"
import { Grid } from "@paudigital/multidesk-ui-kit"

export const StyledContactCardGrid = styled(Grid)`
  flex: 1;
  grid-template-columns: minmax(min-content, 80px) 1fr;
`

export const StyledContactCardLink = styled.a`
  overflow-wrap: anywhere;
`
