import { useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"

export default function Logout() {
  const { keycloak } = useKeycloak()
  const { logout } = keycloak

  useEffect(() => {
    logout({ redirectUri: window.location.origin })
  }, [logout])

  return null
}
