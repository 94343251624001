import { RequestStatus } from "state/types"

export const getThunkStatus = (status: RequestStatus) => {
  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED,
  }
}
