import { UrgencyEnum } from "api/tickets"
import { mixed, date, object, string, number } from "yup"

export const createTicketSchema = object({
  parent_id: string().nullable(),
  site_id: number().required(),
  division_id: number().required(),
  urgency: string().required().oneOf(Object.values(UrgencyEnum)),
  title: string().required().max(200),
  description: string().required().max(2000),
  deadline_on: date().nullable(),
  quote_requested: string().oneOf(["true", "false"]).required(),
  attachments: mixed().fileSize(),
})
