import { createAsyncThunk } from "@reduxjs/toolkit"
import { AssistantFilterSetReturnValue, createAssistantFilterSet } from "api/assistant"
import { StateError } from "state/types"
import { CreateAssistantFilterSetParams } from "./types"

export const createAssistantFilterSetThunk = createAsyncThunk<
  AssistantFilterSetReturnValue,
  CreateAssistantFilterSetParams,
  StateError
>("app/createAssistantFilterSet", async (payload, thunkAPI) => {
  try {
    const { data } = await createAssistantFilterSet(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
