import TagManager from "react-gtm-module"
import { isDevEnv } from "utils/getEnvVariable"

export const useGoogleTagManager = () => {
  const gtag = (name: string, data?: object) => {
    if (isDevEnv) return

    TagManager.dataLayer({
      dataLayer: {
        event: name,
        ...data,
      },
    })
  }

  return { gtag }
}
