import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getCustomerSitesThunk } from "./thunks"
import { SiteState } from "./types"

const initialState: SiteState = {
  sites: { data: [], status: RequestStatus.IDLE },
}

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCustomerSitesThunk.pending, state => {
      state.sites.status = RequestStatus.PENDING
    })
    builder.addCase(getCustomerSitesThunk.fulfilled, (state, action) => {
      state.sites.data = action.payload
      state.sites.status = RequestStatus.FULFILLED
    })
    builder.addCase(getCustomerSitesThunk.rejected, (state, action) => {
      state.sites.error = action.payload?.message
      state.sites.status = RequestStatus.REJECTED
    })
  },
})

export default sitesSlice.reducer
