import React from "react"
import { StyledImage, StyledLoadingScreen } from "./style"

const LoadingScreen: React.FC = (): JSX.Element => {
  return (
    <StyledLoadingScreen alignItems="center" justifyContent="center">
      <StyledImage src="/assets/springbok-loading-animation.svg" alt="loading animation" />
    </StyledLoadingScreen>
  )
}

export default LoadingScreen
