import {
  IconTicketFill,
  IconAssistantFill,
  IconDocumentFill,
  IconInvoiceFill,
  IconGridFill,
  IconGraphFill,
  IconBugFill,
  IconUsersFill,
  IconSettingsFill,
} from "@paudigital/multidesk-ui-kit"
import { NavigationItemsProps } from "./types"

export const navigationItems: NavigationItemsProps = {
  top: [
    {
      icon: IconGridFill,
      module: "dashboard",
    },
    {
      icon: IconTicketFill,
      module: "support_tickets",
    },
    {
      icon: IconDocumentFill,
      module: "documents",
    },
    {
      icon: IconInvoiceFill,
      module: "invoices",
    },
    {
      icon: IconAssistantFill,
      module: "assistant",
    },
    {
      icon: IconGraphFill,
      module: "qms",
    },
    {
      icon: IconBugFill,
      module: "hygi",
    },
  ],
  bottom: [
    {
      icon: IconUsersFill,
      module: "user_management",
    },
    {
      icon: IconSettingsFill,
      module: "settings",
    },
  ],
}
