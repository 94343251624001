import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getSingleDocumentThunk, starDocumentFromDetailThunk, updateDocumentThunk, deleteDocumentThunk } from "./thunks"
import { DocumentDetailState } from "./types"

const initialState: DocumentDetailState = {
  documentDetail: { data: null, status: RequestStatus.IDLE },
  documentDelete: { data: null, status: RequestStatus.IDLE },
  documentStar: { data: null, status: RequestStatus.IDLE },
}

export const documentDetailSlice = createSlice({
  name: "documentDetail",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getSingleDocumentThunk.pending, state => {
      state.documentDetail.status = RequestStatus.PENDING
    })
    builder.addCase(getSingleDocumentThunk.fulfilled, (state, action) => {
      state.documentDetail.data = action.payload
      state.documentDetail.status = RequestStatus.FULFILLED
    })
    builder.addCase(getSingleDocumentThunk.rejected, (state, action) => {
      state.documentDetail.error = action.payload?.message
      state.documentDetail.status = RequestStatus.REJECTED
    })
    builder.addCase(updateDocumentThunk.fulfilled, (state, action) => {
      state.documentDetail.data = action.payload
      state.documentDetail.status = RequestStatus.FULFILLED
    })
    builder.addCase(updateDocumentThunk.rejected, (state, action) => {
      state.documentDetail.error = action.payload?.message
      state.documentDetail.status = RequestStatus.REJECTED
    })
    builder.addCase(updateDocumentThunk.pending, state => {
      state.documentDetail.status = RequestStatus.PENDING
    })
    builder.addCase(starDocumentFromDetailThunk.pending, state => {
      state.documentStar.status = RequestStatus.PENDING
    })
    builder.addCase(starDocumentFromDetailThunk.fulfilled, (state, action) => {
      if (!(state.documentDetail.data?.id === action.payload["document-id"])) return
      state.documentDetail.data.starred = action.payload.starred
      state.documentStar.status = RequestStatus.FULFILLED
    })
    builder.addCase(starDocumentFromDetailThunk.rejected, (state, action) => {
      state.documentStar.error = action.payload?.message
      state.documentStar.status = RequestStatus.REJECTED
    })
    builder.addCase(deleteDocumentThunk.pending, state => {
      state.documentDelete.status = RequestStatus.PENDING
    })
    builder.addCase(deleteDocumentThunk.fulfilled, state => {
      state.documentDelete.status = RequestStatus.FULFILLED
    })
    builder.addCase(deleteDocumentThunk.rejected, (state, action) => {
      state.documentDelete.error = action.payload?.message
      state.documentDelete.status = RequestStatus.REJECTED
    })
    builder.addMatcher(isAnyOf(starDocumentFromDetailThunk.pending, starDocumentFromDetailThunk.rejected), state => {
      const document = state.documentDetail.data
      if (document) document.starred = !document.starred
    })
  },
})

export const { reset } = documentDetailSlice.actions
export default documentDetailSlice.reducer
