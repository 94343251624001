import React, { useEffect, useState } from "react"
import * as d3 from "d3"
import { useThrottledCallback } from "use-debounce"
import { D3ZoomEvent } from "d3"
import { Button, IconAdd, IconButton, IconSubtract, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useParams } from "react-router-dom"
import { getTrapsThunk } from "state/hygiTraps/thunks"
import { getTraps, getTrapsRequestStatus } from "state/hygiTraps/selectors"
import {
  StyledHygiGrid,
  StyledHygiMain,
  StyledHygiSVGMap,
  StyledHygiCard,
  StyledHygiAside,
  StyledHygiSVGMapContainer,
} from "./style"
import { setInitialTrapsData, setTrapsStatuses, setTrapsVisibility, D3Zoom } from "./utils"
import { ExtendedTrapsType, ExtendedTrapType } from "./types"
import { BaitList } from "./components/BaitList/BaitList"
import { LocationSelect } from "./components/LocationSelect/LocationSelect"
import { TrapDetail } from "./components/TrapDetail"
import { MAP_HEIGHT, MAP_WIDTH, MAP_ZOOM_FACTOR } from "./constants"
import { BaitMarkers } from "./components/BaitMarkers/BaitMarkers"

export const Hygi: React.FC = () => {
  const svgRef = React.useRef<SVGSVGElement>(null)
  const imgRef = React.useRef<SVGImageElement>(null)

  const dispatch = useDispatch()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const initialTraps = useSelector(getTraps)
  const { isFulfilled } = useSelector(getTrapsRequestStatus)
  const { t } = useTranslate()
  const { inspectionId, locationId } = useParams()

  const [extendedTraps, setExtendedTraps] = useState<ExtendedTrapsType>({})
  const [selection, setSelection] = useState<number>()
  const [transform, setTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity)
  const [trapDetailIsOpen, setTrapDetailIsOpen] = useState(false)

  const extendedTrapsArray = Object.values(extendedTraps).reduce((currentTraps, currentTrap) => {
    return [...currentTraps, ...currentTrap]
  }, [])
  const enabledTrapsArray = extendedTrapsArray.filter(({ enabled }) => enabled)

  useEffect(() => {
    if (!customerGroupId || !inspectionId || !locationId) return
    dispatch(getTrapsThunk({ customerGroupId, inspectionId: Number(inspectionId), locationId: Number(locationId) }))
  }, [dispatch, customerGroupId, inspectionId, locationId])

  useEffect(() => {
    if (!isFulfilled) return
    setExtendedTraps(setInitialTrapsData(initialTraps))
  }, [initialTraps, isFulfilled])

  const zoomIn = () => {
    if (!svgRef.current) return
    D3Zoom.scaleTo(d3.select(svgRef.current), transform.k + MAP_ZOOM_FACTOR)
  }

  const zoomOut = () => {
    if (!svgRef.current) return
    D3Zoom.scaleTo(d3.select(svgRef.current), transform.k - MAP_ZOOM_FACTOR)
  }

  const fitPlan = () => {
    if (!svgRef.current) return
    const bounds = imgRef.current!.getBBox()
    const parent = svgRef.current!
    const fullWidth = parent.clientWidth
    const fullHeight = parent.clientHeight
    const { width, height } = bounds

    const midX = bounds.x + width / 2,
      midY = bounds.y + height / 2

    const scale = 1 / Math.max(width / fullWidth, height / fullHeight)

    D3Zoom.translateTo(d3.select(svgRef.current), midX, midY)
    D3Zoom.scaleTo(d3.select(svgRef.current), scale)
  }

  const panToTrap = (trap: ExtendedTrapType) => {
    if (!svgRef.current) return
    D3Zoom.translateTo(d3.select(svgRef.current!), trap.location_x, trap.location_y)
  }

  const handleTrapClick = (trap: ExtendedTrapType) => {
    panToTrap(trap)
    setSelection(currentSelectionId => (trap.id === currentSelectionId ? undefined : trap.id))
    setTrapDetailIsOpen(true)
  }

  const handleGroupToggle = (group: string) => {
    setExtendedTraps(currentTraps => setTrapsStatuses(currentTraps, group))
  }

  const handleZoom = useThrottledCallback(
    ({ transform: updatedTransform }: D3ZoomEvent<SVGSVGElement, ExtendedTrapType>) => {
      setTransform(updatedTransform)
      setExtendedTraps(currentTraps => setTrapsVisibility(currentTraps, updatedTransform))
    },
    20,
    { leading: true },
  )

  useEffect(() => {
    D3Zoom.on("zoom", handleZoom)
    d3.select(svgRef.current).call(D3Zoom)
    return () => {
      D3Zoom.on("zoom", null)
    }
  }, [handleZoom])

  return (
    <StyledHygiCard inset="l">
      <StyledHygiGrid gap="m">
        <StyledHygiAside>
          <Stack direction="column" spacing="2xs">
            <LocationSelect />
            <BaitList
              traps={extendedTraps}
              selection={selection}
              onTrapGroupClick={handleGroupToggle}
              onTrapClick={handleTrapClick}
            />
          </Stack>
        </StyledHygiAside>
        <StyledHygiMain direction="column" spacing="s" alignItems="stretch">
          <TrapDetail
            selection={selection}
            isOpen={trapDetailIsOpen}
            onClose={() => {
              setTrapDetailIsOpen(false)
            }}
          />
          <StyledHygiSVGMapContainer justifyContent="stretch" alignItems="center">
            <StyledHygiSVGMap
              ref={svgRef}
              width={MAP_WIDTH}
              height={MAP_HEIGHT}
              viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}
              overflow="hidden"
            >
              <g transform={transform.toString()}>
                <image ref={imgRef} href="/mock/Sample_Floorplan.jpg" />
                <BaitMarkers traps={enabledTrapsArray} selection={selection} onTrapClick={handleTrapClick} />
              </g>
            </StyledHygiSVGMap>
          </StyledHygiSVGMapContainer>
          <Stack spacing="xs">
            <Button variant="secondary" onClick={fitPlan}>
              {t("system.map.zoom_to_fit")}
            </Button>
            <IconButton variant="secondary" icon={IconAdd} onClick={zoomIn} />
            <IconButton variant="secondary" icon={IconSubtract} onClick={zoomOut} />
          </Stack>
        </StyledHygiMain>
      </StyledHygiGrid>
    </StyledHygiCard>
  )
}
