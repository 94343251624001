import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslate } from "app/hooks/translate"
import { DocumentVisibilityEnum } from "api/documents"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { deleteDocumentThunk, getSingleDocumentThunk } from "state/documentDetail/thunks"
import { getDocumentDetail, getDocumentDetailRequestStatus } from "state/documentDetail/selectors"
import LoadingScreen from "app/components/LoadingScreen"
import { reset } from "state/documentDetail/slice"
import { triggerSuccessNotification } from "utils/notifications"
import FullPageError from "app/components/FullPageError"
import NotFound from "../NotFound"
import PersonalView from "./DocumentDetailViews/PersonalView"
import AwaitingPromotionView from "./DocumentDetailViews/AwaitingPromotionView"
import LibraryView from "./DocumentDetailViews/LibraryView"

const DocumentDetailPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { documentId: documentIdString } = useParams()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const document = useSelector(getDocumentDetail)
  const { isRejected } = useSelector(getDocumentDetailRequestStatus)
  const documentId = Number(documentIdString)

  useEffect(() => {
    if (!customerGroupId) return
    dispatch(getSingleDocumentThunk({ customerGroupId, documentId }))

    return () => {
      dispatch(reset())
    }
  }, [customerGroupId, documentId, dispatch])

  if (isRejected && !document) return <NotFound />
  if (!document) return <LoadingScreen />

  const deleteDocument = async () => {
    await dispatch(deleteDocumentThunk({ customerGroupId, documentId: document.id }))
    triggerSuccessNotification(t("system.documents.delete.success", { docName: document.name }))
    navigate("/documents")
  }

  switch (document.visibility) {
    case DocumentVisibilityEnum.LIBRARY:
      return <LibraryView document={document} />
    case DocumentVisibilityEnum.AWAITING_PROMOTION_TO_LIBRARY:
      return (
        <AwaitingPromotionView
          customerGroupId={customerGroupId}
          document={document}
          deleteDocumentAction={deleteDocument}
        />
      )
    case DocumentVisibilityEnum.PERSONAL:
      return (
        <PersonalView customerGroupId={customerGroupId} document={document} deleteDocumentAction={deleteDocument} />
      )
    default:
      return <FullPageError title={t("system.404.title")} message={t("system.404.message")} />
  }
}

export default DocumentDetailPage
