import React, { useEffect } from "react"
import { Checkbox, TableColumnProps } from "@paudigital/multidesk-ui-kit"
import { QMSResultsTableData } from "api/qms"

import { useTranslate } from "app/hooks/translate"
import { PaginatedResult } from "state/types"
import { useActiveColumnContext } from "app/providers/ActiveColumnProvider"
import { useFeatureFlags } from "app/hooks/featureFlags"

interface ColumnHeaderCheckboxProps {
  column: string
  label: string
}

const ColumnHeaderCheckbox: React.FC<ColumnHeaderCheckboxProps> = ({ column, label }): JSX.Element | null => {
  const {
    zapata: { qmsResultsTableCheckboxesEnabled },
  } = useFeatureFlags()
  const { columns, registerColumn, toggleColumn } = useActiveColumnContext()
  const currentColumn = columns.find(c => c.key === column)

  useEffect(() => {
    registerColumn(column)
  }, [column, registerColumn])

  return qmsResultsTableCheckboxesEnabled ? (
    <Checkbox
      onChange={() => toggleColumn(column)}
      id={`${column}_checkbox`}
      name={`${column}_checkbox`}
      label={label}
      labelHidden
      checked={currentColumn?.enabled}
    />
  ) : null
}

export const useGetColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "created_at",
      content: t("system.date"),
      prefixContent: <ColumnHeaderCheckbox column="date" label={t("system.date")} />,
      sortable: true,
    },
    {
      key: "building",
      content: t("system.building"),
      prefixContent: <ColumnHeaderCheckbox column="building" label={t("system.building")} />,
      sortable: true,
    },
    {
      key: "room_type",
      content: t("system.room_type"),
      prefixContent: <ColumnHeaderCheckbox column="room_type" label={t("system.room_type")} />,
      sortable: true,
      canHide: true,
    },
    {
      key: "element",
      content: t("system.component"),
      prefixContent: <ColumnHeaderCheckbox column="element" label={t("system.component")} />,
      sortable: true,
      canHide: true,
    },
    {
      key: "control_point",
      content: t("system.checkpoint"),
      sortable: false,
      canHide: true,
    },
    {
      key: "remarks",
      content: t("system.remarks"),
      sortable: false,
      canHide: true,
    },
    {
      key: "score",
      content: t("system.score"),
      prefixContent: <ColumnHeaderCheckbox column="score" label={t("system.score")} />,
      sortable: true,
      alignment: "right",
    },
  ]
}

export const useGetRows = (resultsTableData: PaginatedResult<QMSResultsTableData>) => {
  return resultsTableData.items.map(row => ({
    key: `row-${row.id}`,
    cells: [
      {
        key: `cell-${row.id}-created-at`,
        content: row.created_at,
      },
      {
        key: `cell-${row.id}-building`,
        content: row.building,
      },
      {
        key: `cell-${row.id}-room-type`,
        content: row.room_type,
      },
      {
        key: `cell-${row.id}-element`,
        content: row.element,
      },
      {
        key: `cell-${row.id}-control-point`,
        content: row.control_point,
      },
      {
        key: `cell-${row.id}-remarks`,
        content: row.remarks,
      },
      {
        key: `cell-${row.id}-score`,
        content: `${row.score}%`,
      },
    ],
  }))
}
