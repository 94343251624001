import styled from "styled-components"
import { TimelineIcon } from "@paudigital/multidesk-ui-kit"

export const StyledIcon = styled(TimelineIcon)`
  margin-top: ${({ theme }) => theme.space["xs"]};
  transform: translateY(-50%);
  --icon-color-primary: ${({ theme }) => theme.color.neutral.base};
  --icon-color-secondary: ${({ theme }) => theme.color.neutral.dark};
  background: ${({ theme }) => theme.color.neutral["base-light"]};
  padding: ${({ theme }) => theme.space["3xs"]};
  border-radius: 50%;
  border: ${({ theme }) => `${theme.space["3xs"]} solid ${theme.color.neutral.white}`};
  font-size: ${({ theme }) => theme.fontSize6};
`

export const StyledNoIcon = styled.div`
  margin-top: ${({ theme }) => theme.space["xs"]};
  transform: translateY(-50%);
  background: ${({ theme }) => theme.color.neutral["base-light"]};
  height: 0;
  padding: ${({ theme }) => theme.space["3xs"]};
  border: ${({ theme }) => `${theme.space["3xs"]} solid ${theme.color.neutral.white}`};
  border-radius: 50%;
`
