import React from "react"
import * as d3 from "d3"
import { BarsProps } from "./types"

export const Bars: React.FC<BarsProps> = ({ data, width, height, setTooltip }) => {
  const total = data.reduce((sum, d) => sum + d.value, 0)
  const xScale = d3.scaleLinear().domain([0, total]).range([0, width])

  const startX = (i: number) => xScale(data.slice(0, i).reduce((sum, d) => sum + d.value, 0))
  const endX = (i: number) => xScale(data.slice(0, i + 1).reduce((sum, d) => sum + d.value, 0))
  const tooltipX = (i: number) => (endX(i) - startX(i)) / 2 + startX(i)

  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(data.map(d => d.label))
    .range(data.map(d => d.color))

  return (
    <g>
      {data.map((d, i) => (
        <rect
          key={d.label}
          x={startX(i)}
          width={xScale(d.value)}
          height={`${height}px`}
          fill={colorScale(d.label)}
          onMouseEnter={() =>
            setTooltip({
              text: `${d.value}% ${d.label}`,
              x: tooltipX(i),
              y: 0,
            })
          }
          onMouseLeave={() => setTooltip(null)}
        />
      ))}
    </g>
  )
}
