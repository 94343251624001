import React, { PropsWithChildren } from "react"
import * as d3 from "d3"
import { useBarChartContext } from "app/components/Chart/BarChart"
import { useTheme } from "styled-components"
import { getConfig } from "../config"
import { BarChartDataKeys, BarPointsProps } from "../types"

export const Points = <T,>({ data, groupBy }: PropsWithChildren<BarPointsProps<T>>) => {
  const { pointLabelFontSize, pointSize } = getConfig()
  const theme = useTheme()
  const { scaleX, scaleY } = useBarChartContext()

  const points = data.map(e => {
    const percentage = e["percentage" as BarChartDataKeys<T>]
    const group = e[groupBy]
    return {
      group: e[groupBy],
      label: percentage,
      coordinates: [(scaleX(group) || 0) + scaleX.bandwidth() / 2, scaleY(Number(percentage))] as [number, number],
    }
  })
  const coordinates = points.map(({ coordinates }) => coordinates)
  const lineCoordinates = d3.line()(coordinates)

  return points && lineCoordinates ? (
    <g>
      <path fill="none" stroke={theme.color.neutral.black} strokeWidth="4" strokeLinecap="round" d={lineCoordinates} />
      {points.map(point => {
        const {
          group,
          label,
          coordinates: [x, y],
        } = point

        return (
          <React.Fragment key={`point-group-${group}`}>
            <circle r={pointSize} cx={x} cy={y} />
            <text x={x} y={y} fontSize={pointLabelFontSize} textAnchor="middle" dy="-1em">
              {label}%
            </text>
          </React.Fragment>
        )
      })}
    </g>
  ) : null
}
