import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { createSupportTicketThunk } from "./thunks"
import { TicketCreateState } from "./types"

const initialState: TicketCreateState = {
  createdTicket: { data: null, status: RequestStatus.IDLE },
}

export const ticketCreateSlice = createSlice({
  name: "ticketCreate",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(createSupportTicketThunk.pending, state => {
      state.createdTicket.status = RequestStatus.PENDING
    })
    builder.addCase(createSupportTicketThunk.fulfilled, (state, action) => {
      state.createdTicket.data = action.payload
      state.createdTicket.status = RequestStatus.FULFILLED
    })
    builder.addCase(createSupportTicketThunk.rejected, (state, action) => {
      state.createdTicket.error = action.payload?.message
      state.createdTicket.status = RequestStatus.REJECTED
    })
  },
})

export default ticketCreateSlice.reducer
