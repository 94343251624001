import React from "react"
import { IconInvoiceFill, Stack, Timeline, TimelineItemProps } from "@paudigital/multidesk-ui-kit"
import PaginatedList from "app/components/PaginatedList"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { FilterSetPicker } from "app/components/FilterSetPicker"
import { getInvoices, getRequestStatus } from "state/invoices/selectors"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { TableHeader } from "app/components/TableOverview/TableHeader"
import { InvoiceItem } from "./InvoiceItem"
import { useGetSortOptions } from "./InvoicesOverview.data"
import { InvoiceSkeletonLoaderItem } from "./InvoiceSkeletonLoaderItem"
import { StyledInvoicesIcon } from "./style"

export const InvoicesOverview: React.FC = () => {
  const { t } = useTranslate()
  const sortOptions = useGetSortOptions()
  const invoices = useSelector(getInvoices)
  const isPending = useGetPendingStatus(getRequestStatus)

  const items: TimelineItemProps[] = invoices.items.map(invoice => {
    return {
      key: invoice.id,
      icon: IconInvoiceFill,
      content: <InvoiceItem invoice={invoice} />,
    }
  })

  const skeletonItems = [...Array(2)].map((_, i) => ({
    key: i,
    icon: IconInvoiceFill,
    content: <InvoiceSkeletonLoaderItem />,
  }))

  return (
    <Stack direction="column" spacing="2xs">
      <FilterSetPicker subjectType="invoices" />
      <PaginatedList
        pagination={{
          last: invoices.last,
          current: invoices.page,
          total: invoices.total,
        }}
        isLoading={isPending}
      >
        <TableHeader heading={t("system.invoices")} search sort sortOptions={sortOptions} />
        <Timeline
          inset="xs"
          lineOffset={["l", { m: "2xl" }]}
          lineColor="base"
          icon={StyledInvoicesIcon}
          items={isPending ? skeletonItems : items}
        />
      </PaginatedList>
    </Stack>
  )
}
