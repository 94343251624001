import React, { useEffect } from "react"
import { Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { Radios } from "app/components/Form/components/Radios"
import { useTranslate } from "app/hooks/translate"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { useDispatch, useSelector } from "react-redux"
import { getDocumentCategoriesThunk } from "state/documentCategories/thunks"
import { getDocumentCategories, getRequestStatus } from "state/documentCategories/selectors"

export const CategorySelector: React.FC = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()

  const documentCategories = useSelector(getDocumentCategories)
  const { isFulfilled } = useSelector(getRequestStatus)
  const fieldLabel = useCreateFormFieldLabel("category", t("system.category"))

  useEffect(() => {
    if (!isFulfilled) dispatch(getDocumentCategoriesThunk())
  }, [dispatch, isFulfilled])

  return (
    <Fieldset legend={fieldLabel}>
      <Radios name="category">
        <Stack direction="column" spacing="s">
          {documentCategories.map(category => (
            <Radio
              key={category}
              id={`category-${category}`}
              label={t(`system.document_types.${category}`)}
              value={category}
            />
          ))}
        </Stack>
      </Radios>
    </Fieldset>
  )
}
