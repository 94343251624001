import React from "react"
import { Card, IconBulbFill, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { AssistantExplainerVideo } from "app/components/AssistantExplainerVideo"
import { useTranslate } from "app/hooks/translate"
import { BackdropIcon } from "app/components/BackdropIcon"

export const AssistantExplanation: React.FC = () => {
  const { t } = useTranslate()
  return (
    <Card inset="l">
      <Stack justifyContent="space-between" direction={["column", { m: "row" }]} spacing="m">
        <Stack.Item grow>
          <Stack direction="column" spacing="m">
            <Stack spacing="s" alignItems="center">
              <BackdropIcon icon={IconBulbFill} />
              <Text variant="display" subdued={false}>
                {t("assistant.explanation.title")}
              </Text>
            </Stack>
            <Text>{t("assistant.explanation.description")}</Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <AssistantExplainerVideo height="200" />
        </Stack.Item>
      </Stack>
    </Card>
  )
}
