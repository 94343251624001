import React from "react"
import { Card, Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { UrgencyEnum } from "api/tickets"
import { Radios } from "app/components/Form/components/Radios"
import { useTranslate } from "app/hooks/translate"
import { useFormContext } from "react-hook-form"
import { useFeatureFlags } from "app/hooks/featureFlags"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { ContactCard } from "../ContactCard"
import { UrgencyFormContextProps } from "./UrgencySelector.types"
import { UrgencyRadio } from "./components/UrgencyRadio"

export const UrgencySelector: React.FC = () => {
  const { watch } = useFormContext<UrgencyFormContextProps>()
  const {
    zapata: { ticketCreateUrgencyDeadlineEnabled },
  } = useFeatureFlags()
  const urgency = watch("urgency")
  const { t } = useTranslate()

  const urgencyKeys = Object.values(UrgencyEnum).filter(key =>
    ticketCreateUrgencyDeadlineEnabled ? true : key !== UrgencyEnum.BY_DEADLINE,
  ) as UrgencyEnum[]

  const fieldLabel = useCreateFormFieldLabel("urgency", t("system.urgency"))

  return (
    <Card inset="l">
      <Fieldset legend={fieldLabel}>
        <Radios name="urgency">
          <Stack direction="column" spacing="s">
            {urgencyKeys.map(urgency => (
              <UrgencyRadio key={urgency} urgency={urgency} />
            ))}
          </Stack>
        </Radios>
      </Fieldset>
      {urgency === UrgencyEnum.URGENT && <ContactCard />}
    </Card>
  )
}
