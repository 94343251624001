import styled from "styled-components"

export const StyledContactCard = styled.div`
  margin-top: ${({ theme }) => theme.space.s};
  background: ${props => props.theme.color.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: ${({ theme }) => theme.space.m};
`

export const StyledContactDetails = styled.span`
  color: ${({ theme }) => theme.color.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
