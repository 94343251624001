import React from "react"
import { Text, IconSearch, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useRouterContext } from "app/providers/RouterProvider"
import { StyledNoResults, StyledSearchIcon } from "./style"
import { NoResultsProps } from "./types"

export const NoResults: React.FC<NoResultsProps> = ({ show }) => {
  const { t } = useTranslate()
  const {
    route: { label },
  } = useRouterContext()

  if (!show) return null

  const title = t("system.no_results.title", { subject: t(label!).toLowerCase() })

  return (
    <StyledNoResults alignItems="center" direction="column" justifyContent="center" spacing="l">
      <StyledSearchIcon forwardedAs={IconSearch} />
      <Stack direction="column" alignItems="center" justifyContent="center" spacing="s">
        <Text variant="title">{title}</Text>
        <Text alignment="center" subdued variant="bodyBase">
          {t("system.no_results.message")}
        </Text>
      </Stack>
    </StyledNoResults>
  )
}
