import React from "react"
import { useTranslate } from "app/hooks/translate"
import { Link } from "react-router-dom"
import { TableColumnProps, Stack, Avatar } from "@paudigital/multidesk-ui-kit"
import { OverviewUser } from "api/users"
import { useSelector } from "react-redux"
import { getUsers } from "state/users/selectors"
import { getInitials } from "i18n/utils/getInitials"
import { UsersTableActions } from "../UsersTableActions"

export const useGetUsersColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "id",
      content: t("system.user"),
      sortable: false,
    },
    {
      key: "email",
      content: t("system.email_address"),
      sortable: false,
    },
    {
      key: "phone",
      content: t("system.phone_number"),
      sortable: false,
    },
    {
      key: "site",
      content: t("system.site"),
      sortable: false,
    },
    {
      key: "actions",
      content: t("system.actions"),
      width: 0,
      sortable: false,
      alignment: "right",
    },
  ]
}

export const useGetUsersRows = () => {
  const users = useSelector(getUsers)

  return users.items.map((user: OverviewUser) => {
    const name = `${user.first_name} ${user.last_name}`
    return {
      key: `row-${user.id}`,
      cells: [
        {
          key: `cell-${user.id}-name`,
          content: (
            <Stack alignItems="center" spacing="s">
              <Avatar>{getInitials(name)}</Avatar>
              <Link to={`/management/users/${user.id}`}>{name}</Link>
            </Stack>
          ),
        },
        {
          key: `cell-${user.id}-email`,
          content: user.email,
        },
        {
          key: `cell-${user.id}-phone-number`,
          content: user.phonenumber,
        },
        {
          key: `cell-${user.id}-sites`,
          content: user.sites.join(", "),
        },
        {
          key: `cell-${user.id}-actions`,
          content: <UsersTableActions userId={user.id} />,
        },
      ],
    }
  })
}
