import { useSelector } from "react-redux"
import { getModules } from "state/user/selectors"
import { Modules } from "router/types"
import { useRouterContext } from "app/providers/RouterProvider"
import { useLocation } from "react-router-dom"
import { getExistingRoutesFromPathname } from "router/utils"

export const useModuleAccess = () => {
  const modules = useSelector(getModules)
  const { routes } = useRouterContext()
  const location = useLocation()

  const getHasAccessByPathname = () => {
    const existingRoutes = getExistingRoutesFromPathname(routes, location)
    const rootModule = existingRoutes.find(route => route.module)

    return rootModule?.module ? modules.includes(rootModule.module) : true
  }

  const hasAccessByModuleName = (module: Modules) => {
    return modules.includes(module)
  }

  return { hasAccessByPathname: getHasAccessByPathname(), hasAccessByModuleName }
}
