import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getTrapHistoryThunk, getTrapThunk } from "./thunks"
import { TrapDetailState } from "./types"

const initialState: TrapDetailState = {
  trapDetail: { data: null, status: RequestStatus.IDLE },
  trapHistory: { data: null, status: RequestStatus.IDLE },
}

export const trapDetailSlice = createSlice({
  name: "trapDetail",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getTrapThunk.pending, state => {
      state.trapDetail.status = RequestStatus.PENDING
    })
    builder.addCase(getTrapThunk.fulfilled, (state, action) => {
      state.trapDetail.data = action.payload
      state.trapDetail.status = RequestStatus.FULFILLED
    })
    builder.addCase(getTrapThunk.rejected, (state, action) => {
      state.trapDetail.error = action.payload?.message
      state.trapDetail.status = RequestStatus.REJECTED
    })
    builder.addCase(getTrapHistoryThunk.pending, state => {
      state.trapHistory.status = RequestStatus.PENDING
    })
    builder.addCase(getTrapHistoryThunk.fulfilled, (state, action) => {
      state.trapHistory.data = action.payload
      state.trapHistory.status = RequestStatus.FULFILLED
    })
    builder.addCase(getTrapHistoryThunk.rejected, (state, action) => {
      state.trapHistory.error = action.payload?.message
      state.trapHistory.status = RequestStatus.REJECTED
    })
  },
})

export const { reset } = trapDetailSlice.actions
export default trapDetailSlice.reducer
