import React from "react"
import { useDevice } from "utils/device"
import { ActionsDropdown } from "./ActionsDropdown"
import { ActionsSlideIn } from "./ActionsSlideIn"
import { TableActionsProps } from "./TableActions.types"

export const TableActions: React.FC<TableActionsProps> = ({ actions, disabled }) => {
  const { isDesktop } = useDevice()

  return (
    <>
      {isDesktop ? (
        <ActionsDropdown actions={actions} disabled={disabled} />
      ) : (
        <ActionsSlideIn actions={actions} disabled={disabled} />
      )}
    </>
  )
}
