import React from "react"
import { Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { Form } from "app/components/Form"
import { Radios } from "app/components/Form/components/Radios"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { useTranslate } from "app/hooks/translate"
import { SortRadiosFieldProps } from "./types"

export const SortRadiosField: React.FC<SortRadiosFieldProps> = ({ formConfig, sortOptions }) => {
  const { t } = useTranslate()

  return (
    <Form config={formConfig}>
      <Fieldset legend={t("system.sort")}>
        <Radios name="table-overview-sort">
          <Stack direction="column" spacing="s">
            {sortOptions.map(({ sortKey, label }) => (
              <Radio key={sortKey} id={sortKey} label={label} value={sortKey} />
            ))}
          </Stack>
        </Radios>
      </Fieldset>
    </Form>
  )
}
