import { createSelector } from "@reduxjs/toolkit"
import { ModulePermissions, ModuleRoles } from "api/management"
import { Modules } from "router/types"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getActiveCustomerGroupModules = (state: RootState) => {
  return state.managementReducer.modules.data
}

export const getAllPermissions = (state: RootState) => {
  return state.managementReducer.permissionsByModule.data
}

export const getModulePermissions = (module: Modules) =>
  createSelector(
    (state: RootState) => getAllPermissions(state),
    (permissions: ModulePermissions[]) => {
      const selectedModule = permissions.find(
        (modulePermissions: ModulePermissions) => module === modulePermissions.name,
      )
      return selectedModule ? selectedModule.permissions : []
    },
  )

export const getAllRoles = (state: RootState) => {
  return state.managementReducer.rolesAndPermissions.data
}

export const getRolesByModule = (module: Modules) =>
  createSelector(
    (state: RootState) => getAllRoles(state),
    (roles: ModuleRoles[]) => {
      const selectedModule = roles.find((moduleRoles: ModuleRoles) => module === moduleRoles.name)
      return selectedModule ? selectedModule.roles : []
    },
  )

export const getModuleIdByName = (module: Modules) =>
  createSelector(
    (state: RootState) => getAllRoles(state),
    (roles: ModuleRoles[]) => {
      const selectedModule = roles.find((moduleRoles: ModuleRoles) => module === moduleRoles.name)
      return selectedModule ? selectedModule.module_id : null
    },
  )

export const getRolesAndPermissionsPatchRequestStatus = (state: RootState) => {
  return getThunkStatus(state.managementReducer.rolesAndPermissions.status)
}
