import React from "react"
import { useTranslate } from "app/hooks/translate"
import FullPageError from "app/components/FullPageError"

const NotFound: React.FC = (): JSX.Element => {
  const { t } = useTranslate()

  return <FullPageError title={t("system.404.title")} message={t("system.404.message")} />
}

export default NotFound
