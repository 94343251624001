import { defaultUserSchema } from "app/components/UserFormContent/schema"
import { object, string } from "yup"

export const userReplaceSchema = object({
  ...defaultUserSchema,
  type: string().oneOf(["replace_with_new", "replace_with_existing"]).required(),
  data: object()
    .when("type", {
      is: "replace_with_new",
      then: object({
        name: string().min(2).max(100).required(),
        email: string().required().email(),
        phonenumber: string().phone().required(),
      }),
    })
    .when("type", {
      is: "replace_with_existing",
      then: object({
        user_id: string().required(),
      }),
    }),
})
