import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQMSResultsTableDataThunk } from "state/qms/thunk"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getQMSResultsTableData, getRequestStatusResultsTable } from "state/qms/selectors"
import { Stack } from "@paudigital/multidesk-ui-kit"
import TableOverview from "app/components/TableOverview"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useTranslate } from "app/hooks/translate"
import { ActiveColumnContextProvider } from "app/providers/ActiveColumnProvider"
import { useGetColumns, useGetRows } from "./ResultsTable.data"
import { ResultsSankeyChart } from "./ResultsSankeyChart"

const QMSResultsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const resultsTableData = useSelector(getQMSResultsTableData)

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getQMSResultsTableDataThunk(activeCustomerGroupId))
  }, [activeCustomerGroupId, dispatch])

  const columns = useGetColumns()
  const rows = useGetRows(resultsTableData)
  const isPending = useGetPendingStatus(getRequestStatusResultsTable)

  return (
    <ActiveColumnContextProvider>
      <Stack direction="column" spacing="m">
        <ResultsSankeyChart />
        <TableOverview
          id="results"
          data={{
            columns,
            rows,
          }}
          heading={t("system.table.view")}
          isLoading={isPending}
          pagination={{
            last: resultsTableData.last,
            current: resultsTableData.page,
            total: resultsTableData.total,
          }}
        />
      </Stack>
    </ActiveColumnContextProvider>
  )
}

export default QMSResultsPage
