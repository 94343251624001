import { SingleTicketHistoryType } from "api/tickets"
import { useTranslate } from "./translate"

export const useTimeline = () => {
  const { t } = useTranslate()

  const formatTimelineMessage = ({ data, event }: SingleTicketHistoryType) => {
    const new_state = data.new_state ? t(`system.status.${data.new_state}`) : null
    return t(`ticket.detail.history.message.${event}`, {
      ...data,
      ...(new_state ? { new_state } : {}),
    })
  }

  return { formatTimelineMessage }
}
