import React from "react"
import { useSelector } from "react-redux"
import { getDocuments, getDocumentsRequestStatus } from "state/documents/selectors"
import { useTranslate } from "app/hooks/translate"
import { getActiveCustomerGroupId } from "state/user/selectors"
import TableOverview from "app/components/TableOverview"
import { FilterSetPicker } from "app/components/FilterSetPicker"
import { IconAdd, Stack } from "@paudigital/multidesk-ui-kit"
import { useDevice } from "utils/device"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useGetDocumentsColumns, useGetDocumentsRows, useGetSortOptions } from "./DocumentsTableOverview.data"

const DocumentsTableOverview: React.FC = () => {
  const { t } = useTranslate()
  const { isDesktop } = useDevice()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const documents = useSelector(getDocuments)
  const columns = useGetDocumentsColumns()
  const sortOptions = useGetSortOptions()
  const rows = useGetDocumentsRows({ customerGroupId: activeCustomerGroupId, documents })
  const isPending = useGetPendingStatus(getDocumentsRequestStatus)

  return (
    <Stack direction="column" spacing="2xs">
      <FilterSetPicker subjectType="documents" />
      <TableOverview
        id="documents"
        data={{
          columns,
          rows,
        }}
        heading={t("system.overview")}
        sortOptions={sortOptions}
        isLoading={isPending}
        pagination={{
          last: documents.last,
          current: documents.page,
          total: documents.total,
        }}
        actionButton={{
          icon: IconAdd,
          to: "/documents/new",
          label: isDesktop ? t("system.document.new") : t("system.new"),
        }}
      />
    </Stack>
  )
}

export default DocumentsTableOverview
