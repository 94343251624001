import React from "react"
import { Stack, Text } from "@paudigital/multidesk-ui-kit"
import { URLQuerySearch } from "app/components/URLQuerySearch"
import TableSort from "../TableSort"
import { HeaderOverviewStack } from "./style"
import { TableHeaderProps } from "./types"

export const TableHeader: React.FC<TableHeaderProps> = ({
  heading,
  search,
  sort,
  sortOptions,
  children,
}): JSX.Element => {
  return (
    <>
      <HeaderOverviewStack spacing="m" alignItems="center" justifyContent="space-between" wrap>
        <Text as="h2" variant="display" subdued>
          {heading}
        </Text>
        <Stack spacing={["2xs", { m: "m" }]} alignItems="center" wrap>
          {search && <URLQuerySearch />}
          {sort && sortOptions && <TableSort sortOptions={sortOptions} />}
          {children}
        </Stack>
      </HeaderOverviewStack>
    </>
  )
}
