import React from "react"
import { Stack, Text } from "@paudigital/multidesk-ui-kit"
import { ReadOnlyValueProps } from "./ReadOnlyValue.types"

export const ReadOnlyValue: React.VFC<ReadOnlyValueProps> = ({ label, value }) => {
  return (
    <Stack spacing="s" direction="column">
      <Stack.Item>
        <Text variant="sectionTitle">{label}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="bodyBase">{value}</Text>
      </Stack.Item>
    </Stack>
  )
}
