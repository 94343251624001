import styled from "styled-components"
import { ellipsisCSS, Grid, Icon, Text } from "@paudigital/multidesk-ui-kit"

export const StyledParentTicketWrapper = styled(Grid)`
  grid-template-columns: auto minmax(0, 1fr);
  ${({ theme }) => theme.breakpoints.down("m")} {
    grid-template-columns: minmax(0, 1fr);
  }
  padding: ${({ theme }) => theme.space.m} ${({ theme }) => theme.space.s};
  border: ${({ theme }) => theme.borderWidth.s} solid ${({ theme }) => theme.color.neutral.light};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`

export const StyledNewTabIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.fontSize.baseRem};
  flex-shrink: 0;
`

export const StyledTitle = styled(Text)`
  ${ellipsisCSS};
`
