import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchDocumentStatistics, FetchDocumentStatisticsData, FetchDocumentStatisticsReturnValue } from "api/documents"
import { StateError } from "state/types"

export const getDocumentStatisticsThunk = createAsyncThunk<
  FetchDocumentStatisticsReturnValue,
  FetchDocumentStatisticsData,
  StateError
>("app/getDocumentStatistics", async (payload, thunkAPI) => {
  try {
    const { data } = await fetchDocumentStatistics(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
