import { createSlice } from "@reduxjs/toolkit"
import { InvoiceStatisticsType } from "api/invoices"
import { RequestStatus } from "state/types"
import { getInvoiceStatisticsThunk } from "./thunks"
import { InvoiceStatisticsState } from "./types"

const initialInvoiceStatisticsData: InvoiceStatisticsType = {
  not_paid_invoices: 0,
  paid_invoices: 0,
  total_due_amount: 0,
  next_invoice_due_date: undefined,
}

const initialState: InvoiceStatisticsState = {
  invoiceStatistics: { data: initialInvoiceStatisticsData, status: RequestStatus.IDLE },
}

export const invoiceStatisticsSlice = createSlice({
  name: "invoiceStatistics",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInvoiceStatisticsThunk.pending, state => {
      state.invoiceStatistics.status = RequestStatus.PENDING
    })
    builder.addCase(getInvoiceStatisticsThunk.fulfilled, (state, action) => {
      state.invoiceStatistics.data = action.payload
      state.invoiceStatistics.status = RequestStatus.FULFILLED
    })
    builder.addCase(getInvoiceStatisticsThunk.rejected, (state, action) => {
      state.invoiceStatistics.error = action.payload?.message
      state.invoiceStatistics.status = RequestStatus.REJECTED
    })
  },
})

export default invoiceStatisticsSlice.reducer
