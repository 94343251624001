import { useMediaQuery } from "@paudigital/multidesk-ui-kit"
import { Breakpoints } from "@paudigital/multidesk-ui-kit/dist/theme/breakpoints"
import { theme } from "@paudigital/multidesk-ui-kit"

export const useDevice = () => {
  const mobileBreakpoint: Breakpoints = "s"

  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))

  return {
    isDesktop: !isMobile,
    isMobile,
    mobileBreakpoint,
  }
}
