import React, { useEffect } from "react"
import { TabsRouter } from "app/components/TabsRouter"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroup } from "state/user/selectors"
import { getAllPermissionsThunk, getCustomerGroupRolesThunk } from "state/management/thunks"

const ManagementPage = () => {
  const dispatch = useDispatch()
  const activeCustomerGroup = useSelector(getActiveCustomerGroup)

  useEffect(() => {
    dispatch(getAllPermissionsThunk())
    dispatch(getCustomerGroupRolesThunk(activeCustomerGroup.id))
  }, [activeCustomerGroup, dispatch])

  return <TabsRouter label="system.management" initialPath="users" />
}

export default ManagementPage
