import { SortType } from "app/components/TableOverview/TableSort/types"
import { useTranslate } from "app/hooks/translate"

export const useGetSortOptions = (): SortType[] => {
  const { t } = useTranslate()
  return [
    {
      label: t("system.sort.name_asc"),
      sortKey: "name:asc",
    },
    {
      label: t("system.sort.name_desc"),
      sortKey: "name:desc",
    },
    {
      label: t("system.sort.created_at_asc"),
      sortKey: "created_at:asc",
    },
    {
      label: t("system.sort.created_at_desc"),
      sortKey: "created_at:desc",
    },
    {
      label: t("system.sort.due_date_asc"),
      sortKey: "due_date:asc",
    },
    {
      label: t("system.sort.due_date_desc"),
      sortKey: "due_date:desc",
    },
    {
      label: t("system.sort.division_id_asc"),
      sortKey: "division_id:asc",
    },
    {
      label: t("system.sort.division_id_desc"),
      sortKey: "division_id:desc",
    },
    {
      label: t("system.sort.amount_asc"),
      sortKey: "amount:asc",
    },
    {
      label: t("system.sort.amount_desc"),
      sortKey: "amount:desc",
    },
    {
      label: t("system.sort.status_asc"),
      sortKey: "status:asc",
    },
    {
      label: t("system.sort.status_desc"),
      sortKey: "status:desc",
    },
  ]
}
