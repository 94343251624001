import React, { useEffect, useState } from "react"
import { TabList, Tabs as UITabs, Text } from "@paudigital/multidesk-ui-kit"
import { TabsProps } from "./Tabs.types"
import { StyledTabs } from "./Tabs.style"

export const Tabs: React.FC<TabsProps> = ({ align, children, title, activeTab = 0, activeTabChanged }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab)

  useEffect(() => {
    setActiveTabIndex(activeTab)
  }, [activeTab])

  const onTabChange = (id: number) => {
    if (!activeTabChanged) return
    setActiveTabIndex(id)
    activeTabChanged(id)
  }

  return (
    <StyledTabs align={align}>
      {title && (
        <Text variant="display" as="h3">
          {title}
        </Text>
      )}
      <UITabs selected={activeTabIndex} onSelect={id => onTabChange(id ?? 0)}>
        <TabList>{children}</TabList>
      </UITabs>
    </StyledTabs>
  )
}
