import React from "react"
import { useSelector } from "react-redux"
import { Radio } from "@paudigital/multidesk-ui-kit"
import { useAssistant } from "app/components/Assistant"
import { useTranslate } from "app/hooks/translate"
import { getAssistantSubjectComponentPossibleValues } from "state/assistantConfiguration/selectors"
import { OptionSingleProps } from "./OptionSingle.types"

export const OptionSingle = ({ filterItem }: OptionSingleProps) => {
  const { t } = useTranslate()
  const { onChange, filterSet } = useAssistant()

  const possibleValues = useSelector(
    getAssistantSubjectComponentPossibleValues(filterSet?.subject_type, filterItem.name),
  )

  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!filterSet || !onChange) return
    onChange({
      ...filterSet,
      filter_set: filterSet?.filter_set.map(setItem =>
        setItem.name === filterItem.name ? { ...setItem, value: event.target.value } : setItem,
      ),
    })
  }

  return (
    <>
      {possibleValues?.map(possibleValue => (
        <Radio
          value={possibleValue}
          onChange={onChangeRadio}
          checked={possibleValue === filterItem.value}
          key={possibleValue}
          id={possibleValue}
          label={t(`system.${filterItem.name}.${possibleValue}`)}
        />
      ))}
    </>
  )
}
