import React, { useEffect } from "react"
import { IconUserAlt, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { getContactPerson } from "state/user/selectors"
import { getContactPersonThunk } from "state/user/thunks"
import { useAppContext } from "app/providers/AppProvider"
import { useDispatch, useSelector } from "react-redux"
import { BackdropIcon } from "app/components/BackdropIcon"
import { DashboardCard } from "../DashboardCard"
import { StyledContactCardLink, StyledContactCardGrid } from "./style"

export const ContactCard: React.FC = () => {
  const { t } = useTranslate()
  const { userId } = useAppContext()
  const dispatch = useDispatch()
  const contactPerson = useSelector(getContactPerson)

  useEffect(() => {
    if (!contactPerson) dispatch(getContactPersonThunk(userId))
  }, [dispatch, userId, contactPerson])

  if (!contactPerson) return null

  const { first_name, last_name, address, email, telephone_number } = contactPerson

  return (
    <DashboardCard heading={t("dashboard.contact.title")} icon={<BackdropIcon icon={IconUserAlt} />}>
      <StyledContactCardGrid gap="s">
        <b>{t(`system.name`)}</b>
        <Text>
          {first_name} {last_name}
        </Text>
        <b>{t(`system.email`)}</b>
        <StyledContactCardLink href={`mailto:${email}`}>{email}</StyledContactCardLink>
        <b>{t(`system.address`)}</b>
        <Text>
          {address.street} {address.house_number}, {address.postal_code} {address.city}
        </Text>
        <b>{t(`system.phone`)}</b>
        <StyledContactCardLink href={`tel:${telephone_number}`}>{telephone_number}</StyledContactCardLink>
      </StyledContactCardGrid>
    </DashboardCard>
  )
}
