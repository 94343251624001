import React from "react"
import { Select as UISelect } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { SelectProps } from "./Select.types"

export const Select: React.FC<SelectProps> = ({ name, label, ...rest }) => {
  const { register } = useFormContext()
  const errorMessage = useGetErrorMessage(name)

  const fieldLabel = useCreateFormFieldLabel(name, label)

  return (
    <UISelect label={fieldLabel} errorMessage={errorMessage} hasError={!!errorMessage} {...register(name)} {...rest} />
  )
}
