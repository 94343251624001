import { createSelector } from "@reduxjs/toolkit"
import { AssistantFilterSetReturnValue } from "api/assistant/assistant.types"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getAssistantFilterSets = (state: RootState) => {
  return {
    allFilterSets: getAllAssistantFilterSets(state),
    activeFilterSets: getAssistantActiveFilterSets(state),
    inActiveFilterSets: getAssistantInactiveFilterSets(state),
  }
}

export const getAllAssistantFilterSets = (state: RootState) => state.assistantReducer.assistant.data

export const getAssistantActiveFilterSets = createSelector(
  (state: RootState) => getAllAssistantFilterSets(state),
  (sets: AssistantFilterSetReturnValue[]) => sets.filter(({ is_active }) => is_active),
)

export const getAssistantInactiveFilterSets = createSelector(
  (state: RootState) => getAllAssistantFilterSets(state),
  (sets: AssistantFilterSetReturnValue[]) => sets.filter(({ is_active }) => !is_active),
)

export const getAssistantFilterSetsThunkStatus = (state: RootState) => {
  return getThunkStatus(state.assistantReducer.assistant.status)
}
