import React, { Fragment } from "react"
import { Card, Fieldset, Grid, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { Radios } from "app/components/Form/components/Radios"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { TextField } from "app/components/Form/components/TextField"
import { useTranslate } from "app/hooks/translate"
import { useFormContext } from "react-hook-form"
import { UserReplaceWithExistingUserField } from "../UserReplaceWithExistingUserField"

export function UserReplaceFields() {
  const { t } = useTranslate()
  const { watch } = useFormContext<any>()
  const typeValue = watch("type")
  const [newUserOption, replaceUserOption] = [
    {
      label: t("system.user.new_user"),
      value: "replace_with_new",
      content: (
        <Grid gap="m" columns={[1, { m: 2 }]}>
          <Grid.FlexItem colSpan={1} direction="column">
            <TextField name="data.name" id="nameNewUser" label={t("system.user.name_new_user")} />
          </Grid.FlexItem>
          <Grid.FlexItem colSpan={1} direction="column">
            <TextField
              type="email"
              name="data.email"
              id="emailNewUser"
              label={t("system.user.email_address_new_user")}
            />
          </Grid.FlexItem>
          <Grid.FlexItem colSpan={1} direction="column">
            <TextField name="data.phonenumber" id="phonenumberNewUser" label={t("system.user.phone_number_new_user")} />
          </Grid.FlexItem>
        </Grid>
      ),
    },
    {
      label: t("system.user.existing_user"),
      value: "replace_with_existing",
      content: (
        <Grid gap="m" columns={[1, { m: 2 }]}>
          <Grid.FlexItem colSpan={1} direction="column">
            <UserReplaceWithExistingUserField />
          </Grid.FlexItem>
        </Grid>
      ),
    },
  ]

  return (
    <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
      <Card inset="l">
        <Grid gap="m" columns={1}>
          <Text as="h2" variant="display">
            {t("system.users.replace_user_with")}
          </Text>
          <Fieldset legend={t("system.user.select_option")}>
            <Radios name="type">
              <Stack direction="column" spacing="s">
                {[newUserOption, replaceUserOption].map(({ label, value, content }) => (
                  <Fragment key={value}>
                    <Radio
                      defaultChecked={value === "replace_with_new"}
                      label={label}
                      value={value}
                      id={`replaceOption-${value}`}
                    />
                    {value === typeValue && content}
                  </Fragment>
                ))}
              </Stack>
            </Radios>
          </Fieldset>
        </Grid>
      </Card>
    </Grid.FlexItem>
  )
}
