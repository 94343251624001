import React from "react"
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import { getLocale } from "state/user/selectors"
import translations from "../../../i18n"

const AppIntlProvider: React.FC = ({ children }) => {
  const locale = useSelector(getLocale)

  return (
    <IntlProvider messages={translations[locale]} locale={locale}>
      {children}
    </IntlProvider>
  )
}

export default AppIntlProvider
