import React from "react"
import { Card, Grid, LinkButton, Stack } from "@paudigital/multidesk-ui-kit"
import { FloorPlanThumbnail } from "app/components/FloorPlanThumbnail"
import { useSelector } from "react-redux"
import { getHygiLocations } from "state/hygiLocations/selectors"
import { useTranslate } from "app/hooks/translate"
import { useGetLocations } from "app/components/Hygi/hooks/useGetLocation"

const HygiLocationsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const locations = useSelector(getHygiLocations)

  useGetLocations()

  return (
    <Stack direction="column" spacing="m">
      <Card inset="m">
        <Grid columns={[1, { s: 2, m: 4 }]} gap="l">
          {locations.map(({ id, name, location_thumbnail_url }) => (
            <Grid.FlexItem key={id}>
              <FloorPlanThumbnail id={id} name={name} location_thumbnail_url={location_thumbnail_url} />
            </Grid.FlexItem>
          ))}
        </Grid>
      </Card>
      <Stack.Item grow alignSelf="end">
        <LinkButton to="/hygi">{t("system.back_to_overview")}</LinkButton>
      </Stack.Item>
    </Stack>
  )
}

export default HygiLocationsPage
