import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getHygiLocationsThunk } from "state/hygiLocations/thunk"
import { getActiveCustomerGroupId } from "state/user/selectors"

export const useGetLocations = () => {
  const dispatch = useDispatch()
  const { inspectionId } = useParams()
  const customerGroupId = useSelector(getActiveCustomerGroupId)

  useEffect(() => {
    if (!inspectionId) return
    dispatch(getHygiLocationsThunk({ customerGroupId, inspectionId: Number(inspectionId) }))
  }, [dispatch, customerGroupId, inspectionId])
}
