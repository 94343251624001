import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useNavigate } from "react-router-dom"
import { reset, setAssistantEdit } from "state/assistantEdit/slice"
import { getAssistantFilterSetThunk, patchAssistantEditThunk } from "state/assistantEdit/thunks"
import { getAssistantEdit, getIsSaved, getRequestStatus } from "state/assistantEdit/selectors"
import { AssistantDetailFrame } from "app/components/AssistantDetailFrame"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"

const AssistantFilterSetEdit: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const filterSet = useSelector(getAssistantEdit)
  const isSaved = useSelector(getIsSaved)
  const isPending = useGetPendingStatus(getRequestStatus)
  const { filterSetId } = useParams()

  useEffect(() => {
    if (!filterSetId || !customerGroupId) return
    dispatch(getAssistantFilterSetThunk({ customerGroupId, filterSetId: parseInt(filterSetId) }))
  }, [filterSetId, dispatch, customerGroupId])

  useEffect(() => {
    if (!isSaved) return
    dispatch(reset())
    navigate("/assistant")
  }, [isSaved, dispatch, navigate])

  const onClickSubmit = () => {
    if (!filterSet) return
    dispatch(
      patchAssistantEditThunk({
        customerGroupId,
        newFilterSet: filterSet,
      }),
    )
  }

  return (
    <AssistantDetailFrame
      disabledSubmit={isPending}
      filterSet={filterSet}
      onChange={filterSet => {
        dispatch(setAssistantEdit(filterSet))
      }}
      onClickSubmit={onClickSubmit}
      saving={isPending}
    />
  )
}

export default AssistantFilterSetEdit
