import { api } from "api"
import { AxiosPromise } from "axios"
import { createFormData } from "utils/createFormData"
import { PostFileParams, PostFileReturnValue } from "./files.types"

export const postFile = (params: PostFileParams): AxiosPromise<PostFileReturnValue> => {
  const data = createFormData(params)

  return api(`/files`, {
    method: "POST",
    data,
  })
}
