import { addHours } from "date-fns"
import { CreateTicketType } from "api/tickets"
import { convertToUTCString } from "utils/convertToUTCString"
import { urgencyToHours } from "utils/urgencyToTimeSpan"
import { FormCreateTicketType } from "./types"

export const transformFormTicket = (
  formTicket: Omit<FormCreateTicketType, "attachments">,
  attachments: number[],
): CreateTicketType => {
  const deadLineInHours = urgencyToHours(formTicket.urgency)
  const deadlineDate = addHours(Date.now(), deadLineInHours)
  const deadline_on = convertToUTCString(deadlineDate)

  return {
    ...formTicket,
    quote_requested: formTicket.quote_requested === "true",
    deadline_on,
    attachments,
  }
}
