import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDocumentStatistics } from "state/documentStatistics/selectors"
import { getDocumentStatisticsThunk } from "state/documentStatistics/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { ModuleCard } from "../../ModuleCard"

const DocumentsCard: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { new: newDocs } = useSelector(getDocumentStatistics)

  React.useEffect(() => {
    dispatch(getDocumentStatisticsThunk({ activeCustomerGroupId }))
  }, [dispatch, activeCustomerGroupId])

  return (
    <ModuleCard
      module="documents"
      highlightText={newDocs}
      link={{
        to: "/documents",
      }}
    />
  )
}

export default DocumentsCard
