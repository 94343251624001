import React, { ChangeEventHandler } from "react"
import { Select } from "@paudigital/multidesk-ui-kit"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { useTranslate } from "app/hooks/translate"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { SortProps, SortType } from "./types"

export const SortSelectField: React.FC<SortProps> = ({ sortOptions }) => {
  const { t } = useTranslate()
  const { gtag } = useGoogleTagManager()
  const { queryParams, updateQueryParams } = useUpdateQueryParams()

  const handleSortChange: ChangeEventHandler<HTMLSelectElement> = event => {
    const sort = event.target.value
    updateQueryParams({
      page: "1",
      sort,
    })
    gtag("Sort", {
      Sort_type: sort,
    })
  }

  const currentSortValue = queryParams.get("sort") ?? ""

  return (
    <Select
      id="table-overview-sort"
      placeholder={t("system.sort")}
      label={t("system.sort")}
      value={currentSortValue}
      labelHidden
      onChange={handleSortChange}
    >
      {sortOptions.map((option: SortType) => (
        <option key={option.sortKey} value={option.sortKey}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}
