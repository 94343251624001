import React from "react"
import { LinkButton, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { StyledFullPageError } from "./style"
import { FullPageErrorProps } from "./types"

const FullPageError: React.FC<FullPageErrorProps> = ({ title, message }): JSX.Element => {
  const { t } = useTranslate()

  return (
    <StyledFullPageError alignItems="center" direction="column" justifyContent="center" spacing="l">
      <Stack direction="column" alignItems="center" justifyContent="center" spacing="s">
        <Text alignment="center" variant="display" subdued={false}>
          {title}
        </Text>
        <Text alignment="center" subdued variant="bodyBase">
          {message}
        </Text>
      </Stack>
      <Stack direction="column" alignItems="center" justifyContent="center" spacing="l">
        <LinkButton to="/">{t("system.back_to_dashboard")}</LinkButton>
        <a href="mailto:multidesk@multimasters.be">{t("system.contact_us")}</a>
      </Stack>
    </StyledFullPageError>
  )
}

export default FullPageError
