import React from "react"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getHasTranslationsExposed } from "state/app/selectors"

export type TranslateValueFunction = (chunks: React.ReactNode[]) => React.ReactElement
export interface TranslateValues {
  [key: string]: string | number | JSX.Element | TranslateValueFunction
}

const defaultParsedTags = {
  b: (...chunks: React.ReactNode[]) => <b>{chunks}</b>,
  i: (...chunks: React.ReactNode[]) => <i>{chunks}</i>,
  small: (...chunks: React.ReactNode[]) => <small>{chunks}</small>,
  br: <br />,
}

export const useTranslate = () => {
  const { formatMessage } = useIntl()
  const hasTranslationsExposed = useSelector(getHasTranslationsExposed)

  const translate = (id: string, values: TranslateValues = {}): string => {
    return hasTranslationsExposed ? id : formatMessage({ id }, { ...values, ...defaultParsedTags } as {})
  }

  return { t: translate }
}
