import { useSearchParams } from "react-router-dom"

export interface QueryParams {
  [key: string]: string
}

export interface SetQueryParamsOptions {
  replace?: boolean
}

export const useUpdateQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams()

  const getAllQueryParams = () => {
    const params: QueryParams = {}

    queryParams.forEach((value, key) => {
      if (value) params[key] = value
    })

    return params
  }

  const setInternalQueryParams = (newParams: QueryParams, options?: SetQueryParamsOptions) => {
    const params: QueryParams = {}

    for (const key in newParams) {
      if (newParams[key]) params[key] = newParams[key]
    }

    setQueryParams(params, options)
  }

  const updateQueryParams = (newParams: QueryParams, options?: SetQueryParamsOptions) => {
    const params = getAllQueryParams()
    for (const key in newParams) {
      params[key] = newParams[key]
    }

    setQueryParams(params, options)
  }

  const removeQueryParams = (removeKeys: string[], options?: SetQueryParamsOptions) => {
    const params = getAllQueryParams()
    for (const keyToRemove of removeKeys) {
      delete params[keyToRemove]
    }

    setQueryParams(params, options)
  }

  return {
    queryParams,
    getAllQueryParams,
    updateQueryParams,
    removeQueryParams,
    setQueryParams: setInternalQueryParams,
  }
}
