import { useValidationContext } from "app/providers/FormValidationProvider"
import { SchemaDescription } from "yup/lib/schema"
import { useTranslate } from "./translate"

interface ValidationSchemaFieldDescribe {
  describe: () => SchemaDescription
}

interface ValidationSchemaField {
  [name: string]: ValidationSchemaFieldDescribe
}

interface ValidationSchemaType {
  fields: ValidationSchemaField
}

export const useCreateFormFieldLabel = (name: string, label: string) => {
  const { schema } = useValidationContext()
  const { t } = useTranslate()

  if (!schema || !schema.fields[name]) return label

  const required = (schema as ValidationSchemaType).fields[name].describe().tests.some(t => t.name === "required")

  if (required) return label

  return `${label} (${t("system.optional").toLowerCase()})`
}
