import React from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useModuleAccess } from "app/hooks/useModuleAccess"
import InvoiceStatisticsCard from "./ModuleCard/InvoiceStatisticsCard"
import DocumentsCard from "./ModuleCard/DocumentsCard"
import SupportTicketsCard from "./ModuleCard/SupportTicketsCard"
import AssistantCard from "./ModuleCard/AssistantCard"
import { WelcomeMessage } from "./WelcomeMessage"
import { ContactCard } from "./ContactCard"
import { StyledDashboardGrid } from "./Dashboard.style"

const DashboardPage: React.FC = (): JSX.Element => {
  const { hasAccessByModuleName } = useModuleAccess()

  return (
    <Stack spacing={["m", { m: "xl" }]} direction="column">
      <WelcomeMessage />
      <StyledDashboardGrid
        gap={["m", { m: "xl" }]}
        columns={[1, { m: 2, l: 3 }]}
        areas={[
          [],
          {
            m: [
              ["assistant", "."],
              ["assistant", "."],
              [".", "."],
            ],
            l: [
              ["assistant", ".", "."],
              ["assistant", ".", "."],
            ],
          },
        ]}
      >
        <AssistantCard />
        {hasAccessByModuleName("support_tickets") && <SupportTicketsCard />}
        {hasAccessByModuleName("documents") && <DocumentsCard />}
        {hasAccessByModuleName("invoices") && <InvoiceStatisticsCard />}
        <ContactCard />
      </StyledDashboardGrid>
    </Stack>
  )
}

export default DashboardPage
