import styled from "styled-components"
import { FileThumbnail, IconCheck } from "@paudigital/multidesk-ui-kit"

export const StyledCheckmark = styled(IconCheck)`
  --icon-color-primary: ${({ theme }) => theme.color.palette.green80};
  --icon-color-secondary: ${({ theme }) => theme.color.palette.green50};
`

export const StyledFileThumbnail = styled(FileThumbnail)`
  max-width: 15rem;
`
