import { createAsyncThunk } from "@reduxjs/toolkit"
import { postReplaceUser, ReplaceUserParams, ReplaceUserReturnValue } from "api/users"
import { StateError } from "state/types"

export const replaceUserThunk = createAsyncThunk<ReplaceUserReturnValue, ReplaceUserParams, StateError>(
  "app/replaceUser",
  async (payload, thunkAPI) => {
    try {
      const { data } = await postReplaceUser(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
