import { createAsyncThunk } from "@reduxjs/toolkit"
import { createSupportTicket, CreateSupportTicketReturnValue, SingleTicketType } from "api/tickets"
import { StateError } from "state/types"
import { CreateSupportTicketThunkParameterType } from "./types"

export const createSupportTicketThunk = createAsyncThunk<
  CreateSupportTicketReturnValue,
  CreateSupportTicketThunkParameterType,
  StateError
>("app/createSupportTicket", async (payload, thunkAPI) => {
  const { customerGroupId, ticket } = payload
  let createdTicketData: SingleTicketType | null = null

  try {
    const { data } = await createSupportTicket(customerGroupId, ticket)

    createdTicketData = data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }

  return createdTicketData
})
