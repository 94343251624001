import { useEffect } from "react"
import { useUpdateQueryParams } from "./updateQueryParams"

export const useSetDefaultSorting = (sort: string = "id:desc") => {
  const { getAllQueryParams, updateQueryParams } = useUpdateQueryParams()
  const queryParams = getAllQueryParams()

  useEffect(() => {
    if (queryParams.hasOwnProperty("sort")) return
    updateQueryParams({ sort }, { replace: true })
  }, [sort, queryParams, updateQueryParams])
}
