import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getTraps = (state: RootState) => {
  return state.hygiTrapsReducer.traps.data
}

export const getTrapsRequestStatus = (state: RootState) => {
  return getThunkStatus(state.hygiTrapsReducer.traps.status)
}
