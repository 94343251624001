import React, { createContext, useContext } from "react"
import { UserFormContentProps } from "./types"
import { UserFormContentConsumer } from "./UserFormContentConsumer"

export const UserFormContent: React.FC<UserFormContentProps> = ({ disabled, children }) => {
  return (
    <UserFormContentContext.Provider value={{ disabled }}>
      <UserFormContentConsumer>{children}</UserFormContentConsumer>
    </UserFormContentContext.Provider>
  )
}

export const UserFormContentContext = createContext<UserFormContentProps>({} as UserFormContentProps)
export const useUserFormContent = () => useContext(UserFormContentContext)
