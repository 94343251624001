import { NavigationProps } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useHasFeatureFlagActive } from "app/hooks/useHasFeatureFlagActive"
import { useRouterContext } from "app/providers/RouterProvider"
import { useSelector } from "react-redux"
import { navigationItems } from "router/navigation"
import { NavigationItemType } from "router/types"
import { getModules, getUserIsGroupAdmin } from "state/user/selectors"
import { MergedNavigationItemAndRoute } from "./Layout.types"

export const useGetNavigationItems = () => {
  const { t } = useTranslate()
  const { routes } = useRouterContext()
  const modules = useSelector(getModules)
  const hasFeatureFlagActive = useHasFeatureFlagActive()
  const userIsGroupAdmin = useSelector(getUserIsGroupAdmin)

  const determineIfItemIsAccessible = (item: MergedNavigationItemAndRoute) => {
    const { to, module: routeModule, label } = item
    const hasManagementAccess = !(routeModule === "user_management" && !userIsGroupAdmin)
    const hadModuleAccess = modules.some(module => routeModule === module)
    const hasLabelAndPath = !!label && !!to
    return hasFeatureFlagActive(item) && hasManagementAccess && hadModuleAccess && hasLabelAndPath
  }

  const items = Object.entries(navigationItems).reduce(
    (allItems: NavigationProps["items"], currentItem: [string, NavigationItemType[]]) => {
      const [key, items] = currentItem
      const newItems = items
        .map(item => {
          const match = routes.find(route => route.module === item.module)
          const { path, label } = match || {}
          return { ...item, ...match, to: path, label: t(label!) }
        })
        .filter(determineIfItemIsAccessible)

      return { ...allItems, [key]: newItems }
    },
    {} as NavigationProps["items"],
  )

  return items
}
