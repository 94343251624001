import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchInvoices, FetchInvoicesParams, FetchInvoicesReturnValue } from "api/invoices"
import { StateError } from "state/types"

export const getInvoicesThunk = createAsyncThunk<FetchInvoicesReturnValue, FetchInvoicesParams, StateError>(
  "app/getInvoices",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchInvoices(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
