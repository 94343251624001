import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchActiveModulesForCustomerGroup,
  fetchAllPermissions,
  fetchAllRolesForCustomerGroup,
  FetchedAllPermissionsByModuleValue,
  FetchedAllRolesByModuleValue,
  FetchedCustomerGroupActiveModulesReturnValue,
  PatchModuleRoles,
  patchRolesAndPermissions,
} from "api/management"
import { StateError } from "state/types"

export const getCustomerGroupActiveModulesThunk = createAsyncThunk<
  FetchedCustomerGroupActiveModulesReturnValue,
  number,
  StateError
>("app/getCustomerGroupActiveModules", async (payload, thunkAPI) => {
  try {
    const { data } = await fetchActiveModulesForCustomerGroup(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const getAllPermissionsThunk = createAsyncThunk<FetchedAllPermissionsByModuleValue, void, StateError>(
  "app/getAllPermissions",
  async (_, thunkAPI) => {
    try {
      const { data } = await fetchAllPermissions()
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getCustomerGroupRolesThunk = createAsyncThunk<FetchedAllRolesByModuleValue, number, StateError>(
  "app/getCustomerGroupRoles",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchAllRolesForCustomerGroup(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const patchRolesAndPermissionsThunk = createAsyncThunk<void, PatchModuleRoles, StateError>(
  "app/patchRolesAndPermissions",
  async (payload, thunkAPI) => {
    try {
      const apiPayload = thunkAPI.getState().managementReducer.rolesAndPermissions.data.map(modRoles => {
        if (modRoles.module_id !== payload.data.module_id) return modRoles
        return {
          ...modRoles,
          roles: payload.data.roles,
        }
      })
      const { data } = await patchRolesAndPermissions({
        customerGroupId: payload.customerGroupId,
        data: apiPayload,
      })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
