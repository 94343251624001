import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { addSupportTicketCommentThunk } from "./thunks"
import { AddTicketCommentState } from "./types"

const initialState: AddTicketCommentState = {
  addedTicketComment: { data: null, status: RequestStatus.IDLE },
}

export const ticketAddCommentSlice = createSlice({
  name: "ticketAddComment",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addSupportTicketCommentThunk.pending, state => {
      state.addedTicketComment.status = RequestStatus.PENDING
    })
    builder.addCase(addSupportTicketCommentThunk.fulfilled, (state, action) => {
      state.addedTicketComment.data = action.payload
      state.addedTicketComment.status = RequestStatus.FULFILLED
    })
    builder.addCase(addSupportTicketCommentThunk.rejected, (state, action) => {
      state.addedTicketComment.error = action.payload?.message
      state.addedTicketComment.status = RequestStatus.REJECTED
    })
  },
})

export default ticketAddCommentSlice.reducer
