import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getTrapsThunk } from "./thunks"
import { TrapsState } from "./types"

const initialState: TrapsState = {
  traps: {
    data: {},
    status: RequestStatus.IDLE,
  },
}

export const trapsSlice = createSlice({
  name: "traps",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTrapsThunk.pending, state => {
      state.traps.status = RequestStatus.PENDING
    })
    builder.addCase(getTrapsThunk.fulfilled, (state, action) => {
      state.traps.data = action.payload
      state.traps.status = RequestStatus.FULFILLED
    })
    builder.addCase(getTrapsThunk.rejected, (state, action) => {
      state.traps.error = action.payload?.message
      state.traps.status = RequestStatus.REJECTED
    })
  },
})

export default trapsSlice.reducer
