import styled from "styled-components"
import { Skeleton, TextSkeleton } from "@paudigital/multidesk-ui-kit"

export const StyledInvoicesDateSkeleton = styled(TextSkeleton)`
  max-width: 5rem;
`

export const StyledInvoicesStatusSkeleton = styled(TextSkeleton)`
  width: 5rem;
  && {
    &:before {
      ${({ theme }) => theme.status};
      padding: ${({ theme }) => `${theme.space["3xs"]} ${theme.space["xs"]}`};
    }
  }
`

export const StyledInvoicesNameSkeleton = styled(TextSkeleton)`
  max-width: 25rem;
`

export const StyledInvoicesAmountSkeleton = styled(TextSkeleton)`
  width: 9rem;
`

export const StyledInvoicesDueDateSkeleton = styled(TextSkeleton)`
  max-width: 9rem;
`

export const StyledInvoicesButtonSkeleton = styled(Skeleton)`
  width: 15rem;
  height: 3.75rem;
`
