import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { getEnvVariable } from "utils/getEnvVariable"
import keycloak from "../keycloak"
import { handleExternalError } from "./utils/handleExternalError"

export const API_URL: string = getEnvVariable("REACT_APP_API_URL")

const requestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    const { token } = keycloak
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    return config
  } catch (error) {
    console.error("Error: ", error)
  } finally {
    return config
  }
}

const createApiInstance = (url: string) => axios.create({ baseURL: url })
const createProtectedInstance = (url: string): AxiosInstance => {
  const instance = createApiInstance(url)
  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.response.use(
    response => response,
    error => Promise.reject(handleExternalError(error.response.data)),
  )
  return instance
}

export const api = createProtectedInstance(API_URL)
