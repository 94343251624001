import { Card, Text } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledCard = styled(Card)`
  flex: 1;
`

export const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.color.palette.green50};
  font-size: 2rem;
`
