import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchAssistantFilterSets, AssistantFilterSetReturnValue, updateAssistantFilterSet } from "api/assistant"
import { StateError } from "state/types"
import { FetchAssistantFilterSetsParams, UpdateAssistantFilterSetParams } from "./types"

export const getAssistantFilterSetsThunk = createAsyncThunk<
  AssistantFilterSetReturnValue[],
  FetchAssistantFilterSetsParams,
  StateError
>("app/getAssistantFilterSets", async (payload, thunkAPI) => {
  try {
    const { data } = await fetchAssistantFilterSets(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const patchAssistantFilterSetThunk = createAsyncThunk<
  AssistantFilterSetReturnValue,
  UpdateAssistantFilterSetParams,
  StateError
>("app/updateAssistantFilterSet", async (payload, thunkAPI) => {
  const { newFilterSet, customerGroupId } = payload
  try {
    const { data } = await updateAssistantFilterSet(customerGroupId, newFilterSet)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
