import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getDocumentDetail = (state: RootState) => {
  return state.documentDetailReducer.documentDetail.data
}

export const getDocumentDetailRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentDetailReducer.documentDetail.status)
}

export const getDocumentStarRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentDetailReducer.documentStar.status)
}

export const getRequestDeleteStatus = (state: RootState) => {
  return getThunkStatus(state.documentDetailReducer.documentDelete.status)
}
