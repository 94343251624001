import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQMSAuditsGraphDataThunk, getQMSAuditsTableDataThunk } from "state/qms/thunk"
import { getQMSAuditsGraphData, getQMSAuditsTableData, getRequestStatusAuditTable } from "state/qms/selectors"
import { useTheme } from "styled-components"
import { Stack } from "@paudigital/multidesk-ui-kit"
import TableOverview from "app/components/TableOverview"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { useTranslate } from "app/hooks/translate"
import { BarChart } from "app/components/Chart/BarChart"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useGetColumns, useGetRows } from "./AuditsTable.data"

const QMSAuditsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const auditsGraphData = useSelector(getQMSAuditsGraphData)
  const auditsTableData = useSelector(getQMSAuditsTableData)
  const theme = useTheme()

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getQMSAuditsGraphDataThunk(activeCustomerGroupId))
    dispatch(getQMSAuditsTableDataThunk(activeCustomerGroupId))
  }, [activeCustomerGroupId, dispatch])

  const columns = useGetColumns()
  const rows = useGetRows(auditsTableData)
  const isPending = useGetPendingStatus(getRequestStatusAuditTable)

  return (
    <Stack direction="column" spacing="m">
      <BarChart
        data={auditsGraphData}
        stacks={[
          ["< 25%", theme.color.palette.red50],
          ["25-50%", theme.color.palette.orange50],
          ["50-85%", theme.color.palette.yellow50],
          ["> 85%", theme.color.palette.green30],
        ]}
        groupBy="date"
      />
      <TableOverview
        id="audits"
        data={{
          columns,
          rows,
        }}
        heading={t("system.table.view")}
        isLoading={isPending}
        pagination={{
          last: auditsTableData.last,
          current: auditsTableData.page,
          total: auditsTableData.total,
        }}
      />
    </Stack>
  )
}

export default QMSAuditsPage
