import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { createUserThunk, getUserRightsThunk, getUsersSitesThunk } from "./thunks"
import { UserCreateState } from "./types"

const initialState: UserCreateState = {
  createdUser: { data: null, status: RequestStatus.IDLE },
  usersSites: { data: null, status: RequestStatus.IDLE },
  userRights: { data: null, status: RequestStatus.IDLE },
}

export const userCreateSlice = createSlice({
  name: "userCreate",
  initialState,
  reducers: {
    resetCreatedUser: state => {
      state.createdUser = initialState.createdUser
    },
    resetUserRights: state => {
      state.userRights = initialState.userRights
    },
  },
  extraReducers: builder => {
    builder.addCase(createUserThunk.pending, state => {
      state.createdUser.status = RequestStatus.PENDING
    })
    builder.addCase(createUserThunk.fulfilled, (state, action) => {
      state.createdUser.data = action.payload
      state.createdUser.status = RequestStatus.FULFILLED
    })
    builder.addCase(createUserThunk.rejected, (state, action) => {
      state.createdUser.error = action.payload?.message
      state.createdUser.status = RequestStatus.REJECTED
    })
    builder.addCase(getUsersSitesThunk.pending, state => {
      state.usersSites.status = RequestStatus.PENDING
    })
    builder.addCase(getUsersSitesThunk.fulfilled, (state, action) => {
      state.usersSites.data = action.payload
      state.usersSites.status = RequestStatus.FULFILLED
    })
    builder.addCase(getUsersSitesThunk.rejected, (state, action) => {
      state.usersSites.error = action.payload?.message
      state.usersSites.status = RequestStatus.REJECTED
    })
    builder.addCase(getUserRightsThunk.pending, state => {
      state.userRights.status = RequestStatus.PENDING
    })
    builder.addCase(getUserRightsThunk.fulfilled, (state, action) => {
      state.userRights.data = action.payload
      state.userRights.status = RequestStatus.FULFILLED
    })
    builder.addCase(getUserRightsThunk.rejected, (state, action) => {
      state.userRights.error = action.payload?.message
      state.userRights.status = RequestStatus.REJECTED
    })
  },
})

export const { resetUserRights } = userCreateSlice.actions
export default userCreateSlice.reducer
