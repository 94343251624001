import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import { GlobalStyle, theme, ThemeProvider } from "@paudigital/multidesk-ui-kit"
import { store } from "state/store"
import { Provider } from "react-redux"
import AppIntlProvider from "app/providers/intlProvider"
import "./validation"
import GlobalAppStyle from "style/global"
import { ToastContainer } from "react-toastify"
import { getEnvVariable, isDevEnv } from "utils/getEnvVariable"
import TagManager from "react-gtm-module"
import { DeveloperUI } from "app/components/DeveloperUI"
import keycloakClient from "./keycloak"
import App from "./App"
import "react-toastify/dist/ReactToastify.css"
import "@paudigital/multidesk-ui-kit/dist/theme/fonts.css"

if (!isDevEnv) {
  TagManager.initialize({
    gtmId: getEnvVariable("REACT_APP_GOOGLE_TAG_MANAGER_ID"),
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloakClient}
        initOptions={{
          checkLoginIframe: !isDevEnv,
        }}
      >
        <AppIntlProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <GlobalAppStyle />
              <App />
              <DeveloperUI />
              <ToastContainer />
            </ThemeProvider>
          </BrowserRouter>
        </AppIntlProvider>
      </ReactKeycloakProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)
