import { TicketStatusEnum } from "api/tickets"

export const getTicketStatusVariant = (status: TicketStatusEnum) => {
  switch (status) {
    case TicketStatusEnum.NOT_STARTED:
      return "error"
    case TicketStatusEnum.IN_PROGRESS:
      return "warning"
    case TicketStatusEnum.DONE:
      return "success"
  }
}
