import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchSingleSupportTicket,
  FetchSingleSupportTicketReturnValue,
  PatchSupportTicket,
  patchSupportTicket,
} from "api/tickets"
import { StateError } from "state/types"
import { GetSingleSupportTicketThunkParameterType } from "./types"

export const getSingleSupportTicketThunk = createAsyncThunk<
  FetchSingleSupportTicketReturnValue,
  GetSingleSupportTicketThunkParameterType,
  StateError
>("app/getSingleSupportTicket", async (payload, thunkAPI) => {
  const { customerGroupId, ticketId } = payload
  try {
    const { data } = await fetchSingleSupportTicket(customerGroupId, ticketId)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const patchSupportTicketThunk = createAsyncThunk<
  FetchSingleSupportTicketReturnValue,
  PatchSupportTicket,
  StateError
>("app/patchSupportTicket", async (payload, thunkAPI) => {
  try {
    const { data } = await patchSupportTicket(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
