import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAssistantFilterSetsThunk } from "state/assistant/thunks"
import { useTranslate } from "app/hooks/translate"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { AssistantFilterSetReturnValue } from "api/assistant"
import {
  AssistantSubjectTypeTranslationMapping,
  AssistantTranslationMappingType,
} from "utils/AssistantSubjectTypeTranslationMapping"
import { getAssistantFilterSets, getAssistantFilterSetsThunkStatus } from "state/assistant/selectors"
import { getAssistantConfiguration } from "state/assistantConfiguration/selectors"
import { Stack, LinkButton, IconAdd, Chip, Card, Text } from "@paudigital/multidesk-ui-kit"
import { useFeatureFlags } from "app/hooks/featureFlags"
import { useDevice } from "utils/device"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { FilterSetGroup } from "./FilterSetGroup"
import { AssistantExplanation } from "./AssistantExplanation"
import { FilterSetGroupItem } from "./FilterSetGroupItem"
import { FilterSetSkeletonLoader } from "./FilterSetSkeletonLoader"

const AssistantPage: React.FC = (): JSX.Element => {
  const { isDesktop } = useDevice()
  const {
    albatross: { assistantPageChipsEnabled, assistantPageDisabledFilterSetsEnabled },
  } = useFeatureFlags()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const isPending = useGetPendingStatus(getAssistantFilterSetsThunkStatus)
  const { t } = useTranslate()
  const { activeFilterSets, inActiveFilterSets } = useSelector(getAssistantFilterSets)
  const configuration = useSelector(getAssistantConfiguration)
  const [subjectType, setSubjectType] = React.useState<string | undefined>(undefined)

  const filterSetBySubjectType = (
    assistantFilterSet: AssistantFilterSetReturnValue[],
    subjectType: string | undefined,
  ) => {
    if (!subjectType) return assistantFilterSet
    return assistantFilterSet.filter(({ subject_type }) => subject_type === subjectType)
  }

  const activeFilterSetsBySubjectType = filterSetBySubjectType(activeFilterSets, subjectType)

  const obsolete_inactiveFilterSetsBySubjectType = filterSetBySubjectType(inActiveFilterSets, subjectType)
  const obsolete_filterSetGroups = [
    { title: t("system.assistant.active"), filterSets: activeFilterSetsBySubjectType },
    { title: t("system.assistant.inactive"), filterSets: obsolete_inactiveFilterSetsBySubjectType },
  ]

  const getSubjectTypeLabel = (subjectType: string) => {
    const translationKey = AssistantSubjectTypeTranslationMapping[subjectType as AssistantTranslationMappingType]
    if (!translationKey) return subjectType
    return t(translationKey)
  }

  useEffect(() => {
    activeCustomerGroupId &&
      dispatch(
        getAssistantFilterSetsThunk({
          customerGroupId: activeCustomerGroupId,
          params: { subject_type: subjectType },
        }),
      )
  }, [activeCustomerGroupId, dispatch, subjectType])

  return (
    <Stack spacing="l" direction="column">
      <AssistantExplanation />
      {assistantPageChipsEnabled && (
        <Stack spacing="m">
          <Chip
            outlined={subjectType !== undefined}
            onClick={() => setSubjectType(undefined)}
            label={t("system.all")}
          ></Chip>
          {configuration.map(({ subject_type }) => (
            <Chip
              key={subject_type}
              label={getSubjectTypeLabel(subject_type)}
              outlined={subjectType !== subject_type}
              onClick={() => setSubjectType(subject_type)}
            ></Chip>
          ))}
        </Stack>
      )}
      {assistantPageDisabledFilterSetsEnabled && (
        <>
          <Stack spacing="3xs" justifyContent="flex-end">
            <LinkButton to="create" icon={IconAdd}>
              {t("system.assistant.new_filter_set")}
            </LinkButton>
          </Stack>
          <Stack spacing="xl" direction="column">
            {obsolete_filterSetGroups.map((filterSetGroup, index) => {
              return <FilterSetGroup filterSetGroup={filterSetGroup} key={index} />
            })}
          </Stack>
        </>
      )}
      {!assistantPageDisabledFilterSetsEnabled && (
        <Stack spacing="m" direction="column">
          <Stack justifyContent="space-between" alignItems="flex-end">
            <Text as="h2" variant="display" subdued>
              {t("system.assistant.active")}
            </Text>
            <LinkButton to="create" icon={IconAdd}>
              {isDesktop ? t("system.assistant.new_filter_set") : t("system.new")}
            </LinkButton>
          </Stack>
          {isPending ? (
            <FilterSetSkeletonLoader />
          ) : (
            <>
              {activeFilterSetsBySubjectType.map(filterSet => (
                <Card key={filterSet.id} inset="m">
                  <FilterSetGroupItem filterSet={filterSet} />
                </Card>
              ))}
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default AssistantPage
