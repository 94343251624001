import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { createDocumentThunk } from "./thunks"
import { DocumentCreateState } from "./types"

const initialState: DocumentCreateState = {
  createdDocument: { data: null, status: RequestStatus.IDLE },
}

export const documentCreateSlice = createSlice({
  name: "documentCreate",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(createDocumentThunk.pending, state => {
      state.createdDocument.status = RequestStatus.PENDING
    })
    builder.addCase(createDocumentThunk.fulfilled, (state, action) => {
      state.createdDocument.data = action.payload
      state.createdDocument.status = RequestStatus.FULFILLED
    })
    builder.addCase(createDocumentThunk.rejected, (state, action) => {
      state.createdDocument.error = action.payload?.message
      state.createdDocument.status = RequestStatus.REJECTED
    })
  },
})

export default documentCreateSlice.reducer
