import React from "react"
import { useSelector } from "react-redux"
import { useTranslate } from "app/hooks/translate"
import TableOverview from "app/components/TableOverview"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { getHygiInspections, getInspectionsRequestStatus } from "state/hygiInspections/selectors"
import { useGetInspectionsColumns, useGetInspectionsRows } from "./InspectionsTableOverview.data"

const InspectionsTableOverview: React.FC = () => {
  const { t } = useTranslate()
  const inspections = useSelector(getHygiInspections)
  const columns = useGetInspectionsColumns()
  const rows = useGetInspectionsRows({ inspections })
  const isPending = useGetPendingStatus(getInspectionsRequestStatus)

  return (
    <Stack direction="column" spacing="2xs">
      <TableOverview
        id="inspections"
        data={{
          columns,
          rows,
        }}
        heading={t("system.overview")}
        isLoading={isPending}
        pagination={{
          last: inspections.last,
          current: inspections.page,
          total: inspections.total,
        }}
      />
    </Stack>
  )
}

export default InspectionsTableOverview
