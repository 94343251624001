import { Fieldset } from "@paudigital/multidesk-ui-kit"
import React from "react"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import RadioProvider from "./RadioProvider"
import { RadiosProps } from "./Radios.types"

export const Radios: React.FC<RadiosProps> = ({ name, legend, children }) => {
  const errorMessage = useGetErrorMessage(name)

  return (
    <Fieldset legend={legend} errorMessage={errorMessage}>
      <RadioProvider name={name}>{children}</RadioProvider>
    </Fieldset>
  )
}
