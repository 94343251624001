import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getHygiInspectionsThunk } from "./thunk"
import { HygiInspectionsState } from "./types"

const initialInspectionsData: HygiInspectionsState["inspections"]["data"] = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialState: HygiInspectionsState = { inspections: { data: initialInspectionsData, status: RequestStatus.IDLE } }

export const HygiInspectionsSlice = createSlice({
  name: "hygiInspections",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHygiInspectionsThunk.pending, state => {
      state.inspections.status = RequestStatus.PENDING
    })
    builder.addCase(getHygiInspectionsThunk.fulfilled, (state, action) => {
      state.inspections.data = action.payload
      state.inspections.status = RequestStatus.FULFILLED
    })
    builder.addCase(getHygiInspectionsThunk.rejected, (state, action) => {
      state.inspections.error = action.payload?.message
      state.inspections.status = RequestStatus.REJECTED
    })
  },
})

export default HygiInspectionsSlice.reducer
