import React from "react"
import { useModuleTheme } from "app/hooks/useModuleTheme"
import { getModuleIcon } from "utils/getModuleIcon"
import { BackdropIcon } from "../BackdropIcon"
import { ModuleIconProps } from "./types"

export const ModuleIcon: React.FC<ModuleIconProps> = ({ module }) => {
  const moduleTheme = useModuleTheme(module)
  const icon = getModuleIcon(module)

  if (!icon) return null

  return <BackdropIcon icon={icon} theme={moduleTheme} />
}
