import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getUsers = (state: RootState) => {
  return state.usersReducer.users.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.usersReducer.users.status)
}
