import axios, { AxiosPromise } from "axios"
import {
  FetchHygiInspectionsDataReturnValue,
  FetchHygiInspectionsParams,
  FetchHygiLocationsDataReturnValue,
  FetchHygiLocationsParams,
  FetchTrapHistoryParams,
  FetchTrapHistoryReturnValue,
  FetchTrapDetailsParams,
  FetchTrapDetailsReturnValue,
  FetchTrapsParams,
  FetchTrapsReturnValue,
} from "./hygi.types"

export const fetchHygiInspections = ({
  customerGroupId: _,
}: FetchHygiInspectionsParams): AxiosPromise<FetchHygiInspectionsDataReturnValue> => {
  // TODO: Remove /mock/hygi_inspections.mock.json
  return axios.get("/mock/hygi_inspections.mock.json").then(res => res)
}

// export const fetchHygiInspections = ({
//   customerGroupId,
// }: FetchHygiInspectionsParams): AxiosPromise<FetchHygiInspectionsDataReturnValue> => {
//   return api(`/customer_groups/${customerGroupId}/hygiene_inspections`, {
//     method: "GET",
//   })
// }

export const fetchHygiLocations = (
  _props: FetchHygiLocationsParams,
): AxiosPromise<FetchHygiLocationsDataReturnValue> => {
  // TODO: Remove /mock/hygi_locations.mock.json
  return axios.get("/mock/hygi_locations.mock.json").then(res => res)
}

// export const fetchHygiLocations = ({
//   customerGroupId,
//   inspectionId,
// }: FetchHygiLocationsParams): AxiosPromise<FetchHygiLocationsDataReturnValue> => {
//   return api(`/customer_groups/${customerGroupId}/hygiene_inspections/${inspectionId}`, {
//     method: "GET",
//   })
// }

export const fetchTraps = (_props: FetchTrapsParams): AxiosPromise<FetchTrapsReturnValue> => {
  return axios.get("/mock/hygi_location_traps.mock.json").then(res => res)
  // customer_groups/{id}/hygiene_inspection/{inspection_id}/location/{location_id}/traps
}

export const fetchTrapDetails = (_props: FetchTrapDetailsParams): AxiosPromise<FetchTrapDetailsReturnValue> => {
  // TODO: Remove /mock/trap.mock.json
  // return axios.get("/mock/hygi_trap_etrap.mock.json").then(res => res)
  return axios.get("/mock/hygi_trap_efk.mock.json").then(res => res)
}

export const fetchTrapHistory = (_props: FetchTrapHistoryParams): AxiosPromise<FetchTrapHistoryReturnValue> => {
  // TODO: Remove /mock/trap.mock.json
  return axios.get("/mock/hygi_trapHistory.mock.json").then(res => res)
}
