import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchQMSYearlyGraphData,
  FetchQMSYearlyGraphDataReturnValue,
  fetchQMSYearlyTableData,
  FetchQMSYearlyTableDataReturnValue,
  fetchQMSAuditsGraphData,
  FetchQMSAuditsGraphDataReturnValue,
  fetchQMSAuditsTableData,
  FetchQMSAuditsTableDataReturnValue,
  fetchQMSResultsGraphData,
  FetchQMSResultsGraphDataReturnValue,
  fetchQMSResultsTableData,
  FetchQMSResultsTableDataReturnValue,
} from "api/qms"
import { StateError } from "state/types"

export const getQMSYearlyGraphDataThunk = createAsyncThunk<FetchQMSYearlyGraphDataReturnValue, number, StateError>(
  "app/getQMSYearlyGraphData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSYearlyGraphData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getQMSYearlyTableDataThunk = createAsyncThunk<FetchQMSYearlyTableDataReturnValue, number, StateError>(
  "app/getQMSYearlyTableData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSYearlyTableData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getQMSAuditsGraphDataThunk = createAsyncThunk<FetchQMSAuditsGraphDataReturnValue, number, StateError>(
  "app/getQMSAuditsGraphData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSAuditsGraphData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getQMSAuditsTableDataThunk = createAsyncThunk<FetchQMSAuditsTableDataReturnValue, number, StateError>(
  "app/getQMSAuditsTableData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSAuditsTableData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getQMSResultsTableDataThunk = createAsyncThunk<FetchQMSResultsTableDataReturnValue, number, StateError>(
  "app/getQMSResultsTableData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSResultsTableData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getQMSResultsGraphDataThunk = createAsyncThunk<FetchQMSResultsGraphDataReturnValue, number, StateError>(
  "app/getQMSResultsGraphData",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchQMSResultsGraphData(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
