import React, { useState } from "react"
import { IconSearch, SlideIn } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useDevice } from "utils/device"
import { SearchField } from "./SearchField"
import { StyledSearchIconButton } from "./style"

export const URLQuerySearch: React.FC = () => {
  const { t } = useTranslate()
  const { isMobile } = useDevice()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isMobile ? (
        <>
          <StyledSearchIconButton variant="tertiary" icon={IconSearch} onClick={() => setIsOpen(true)} />
          <SlideIn title={t("system.search")} isOpen={isOpen} onClose={() => setIsOpen(false)} isFullHeight={true}>
            <SearchField autoFocus={true} />
          </SlideIn>
        </>
      ) : (
        <SearchField />
      )}
    </>
  )
}
