import React from "react"
import { MAP_MARKER_WIDTH, MAP_MARKER_HEIGHT, MAP_MARKER_DOT_RADIUS } from "../../constants"
import { StyledHygiMarker } from "../../style"
import { getBaitIsSelected } from "../../utils"
import { BaitMapProps } from "./types"

export const BaitMarkers: React.FC<BaitMapProps> = ({ traps, selection, onTrapClick }) => {
  return (
    <>
      {traps.map(trap => (
        <foreignObject
          key={trap.id}
          width={MAP_MARKER_WIDTH}
          height={MAP_MARKER_HEIGHT}
          x={trap.location_x - MAP_MARKER_WIDTH / 2}
          y={trap.location_y - MAP_MARKER_HEIGHT + MAP_MARKER_DOT_RADIUS}
          overflow="visible"
        >
          <StyledHygiMarker
            status={trap.state}
            type={trap.type}
            indicator={getBaitIsSelected(trap, selection)}
            variant="outlined"
            onClick={() => onTrapClick(trap)}
          />
        </foreignObject>
      ))}
    </>
  )
}
