import { createListenerMiddleware } from "@reduxjs/toolkit"
import { CONTACT_US_URL } from "app/constants"
import { parseLinks } from "i18n/utils/parseLinks"
import { NotificationMessageType, triggerNotification } from "utils/notifications"
import { translate } from "../i18n"

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  predicate: action => /rejected$/.test(action.type),
  effect: ({ payload }) => {
    const { message, translationKeys, title } = payload as NotificationMessageType

    const notification = translationKeys
      ? {
          title: payload.title ? translate(payload.title) : undefined,
          message: payload.message
            ? translate(payload.message, {
                ...(parseLinks({
                  link_contact_us: {
                    href: CONTACT_US_URL,
                    target: "_blank",
                  },
                }) as {}),
              })
            : "",
        }
      : { message, title }

    triggerNotification("error", notification)
  },
})

export default listenerMiddleware.middleware
