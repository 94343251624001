import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getAutoCompleteUsersThunk } from "./thunks"
import { UserState } from "./types"

const initialUserData: UserState["users"]["data"] = {
  items: [],
}

const initialState: UserState = {
  users: { data: initialUserData, status: RequestStatus.IDLE },
}

export const userAutoCompleteSlice = createSlice({
  name: "userAutoComplete",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAutoCompleteUsersThunk.pending, state => {
      state.users.status = RequestStatus.PENDING
    })
    builder.addCase(getAutoCompleteUsersThunk.fulfilled, (state, action) => {
      state.users.data.items = action.payload.items
      state.users.status = RequestStatus.FULFILLED
    })
    builder.addCase(getAutoCompleteUsersThunk.rejected, (state, action) => {
      state.users.error = action.payload?.message
      state.users.status = RequestStatus.REJECTED
    })
  },
})

export default userAutoCompleteSlice.reducer
