import React, { useState } from "react"
import { useAssistant } from "../.."
import { AssistantDropdown } from "../AssistantDropdown"
import { FilterItemValueInput } from "../FilterItemValueInput"
import { useAssistantGrammar } from "../../hooks/useAssistantGrammar"
import { AssistantButton } from "../AssistantButton"
import { FilterItemProps } from "./FilterItem.types"

export const FilterItem = ({ subject, filterItem, isFirstItem }: FilterItemProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const { name, value } = useAssistantGrammar({ filterItem, isFirstItem })
  const { disabled } = useAssistant()

  return (
    <>
      <span>{name}</span>
      <AssistantButton
        ref={setButtonElement}
        disabled={disabled}
        onClick={() => setDropdownIsOpen(previousState => !previousState)}
      >
        {value}
      </AssistantButton>
      <AssistantDropdown
        inset="m"
        onClose={() => setDropdownIsOpen(false)}
        referenceElement={buttonElement}
        isVisible={dropdownIsOpen}
      >
        <FilterItemValueInput subject={subject} filterItem={filterItem} />
      </AssistantDropdown>
    </>
  )
}
