import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getCreatedTicket = (state: RootState) => {
  return state.ticketCreateReducer.createdTicket.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.ticketCreateReducer.createdTicket.status)
}
