import { RootState } from "./store"

export enum RequestStatus {
  IDLE = "idle",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

export type DefineAppState<T> = {
  [P in keyof T]-?: {
    data: T[P]
    status: RequestStatus
    error?: ThunkErrors["message"]
  }
}

export interface StateError {
  state: RootState
  rejectValue: ThunkErrors
}

export type ThunkErrors = ApiError | ApiInputError | Error

export interface ApiInputError {
  message: string
  errors: {
    [key: string]: string[]
  }
}

export interface ApiError {
  message: string
}

export interface PaginatedResult<T> {
  total: number
  first: number
  last: number
  prev: number
  next: number
  page: number
  perPage: number
  items: T[]
}
