import React from "react"
import { Grid, Stack, Card, Text, Button, LinkButton, IconUser } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { updateProfileThunk, updatePasswordThunk } from "state/user/thunks"
import { useSelector } from "react-redux"
import { SubmitHandler } from "react-hook-form"
import { Form, useCreateFormConfig } from "app/components/Form"
import { TextField } from "app/components/Form/components/TextField"
import { getPasswordRequestStatus, getProfile, getProfileRequestStatus } from "state/user/selectors"
import { Password, Profile } from "api/user"
import { Select } from "app/components/Form/components/Select"
import { locales, nativeLanguageMapping, translate } from "i18n"
import { triggerSuccessNotification } from "utils/notifications"
import { SaveButton } from "app/components/SaveButton"
import keycloak from "../../../keycloak"
import { PasswordField } from "./PasswordField"
import { profileSchema } from "./schema"

const Account: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const profile = useSelector(getProfile)
  const defaultValues = profile || undefined
  const { tokenParsed } = keycloak
  const profileFormConfig = useCreateFormConfig({ defaultValues, schema: profileSchema })
  const { isPending: profileRequestIsPending } = useSelector(getProfileRequestStatus)
  const { isPending: passwordRequestIsPending } = useSelector(getPasswordRequestStatus)
  const passwordFormConfig = useCreateFormConfig<Password>({})
  const {
    context: { watch },
  } = passwordFormConfig

  const [newPassword, oldPassword] = watch(["new-password", "old-password"])

  const showPasswordButtons = newPassword?.length > 0 || oldPassword?.length > 0

  const updateProfile: SubmitHandler<Profile> = async payload => {
    await profileFormConfig.handleSubmit(
      updateProfileThunk({
        userId: tokenParsed?.userid,
        profile: payload,
      }),
    )

    triggerSuccessNotification(t("system.update_account.success"))
  }

  const updatePassword: SubmitHandler<Password> = async payload => {
    await passwordFormConfig.handleSubmit(
      updatePasswordThunk({
        userId: tokenParsed?.userid,
        ...payload,
      }),
    )

    triggerSuccessNotification(t("system.change_password.success"))
  }

  const updateLanguage: SubmitHandler<Profile> = async payload => {
    const { preferred_language } = payload

    await profileFormConfig.handleSubmit(
      updateProfileThunk({
        userId: tokenParsed?.userid,
        profile: {
          preferred_language,
        },
      }),
    )

    // This is a workaround to get the correct language before the app state is changed
    const message = translate("system.update_language.success", {}, preferred_language)
    triggerSuccessNotification(message, preferred_language)
  }

  return (
    <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 2 }]}>
      <Grid.FlexItem colSpan={1} gap={["m", { m: "xl" }]} direction="column">
        <Card inset="l">
          <Form config={profileFormConfig} onSubmit={updateProfile}>
            <Stack spacing="m" direction="column">
              <Text variant="display" subdued={false}>
                {t("system.profile")}
              </Text>
              <TextField name="last_name" id="lastName" label={t("system.last_name")} />
              <TextField name="first_name" id="firstName" label={t("system.first_name")} />
              <TextField name="email" id="email" label={t("system.email")} disabled />
              <div>
                <LinkButton icon={IconUser} variant="secondary" to="/logout">
                  {t("system.logout")}
                </LinkButton>
              </div>
              <Stack justifyContent="end" spacing="s">
                {profileFormConfig.context.formState.isDirty && (
                  <Button variant="tertiary" onClick={() => profileFormConfig.context.reset()}>
                    {t("system.cancel")}
                  </Button>
                )}
                <SaveButton type="submit" saving={profileRequestIsPending}>
                  {t("system.update_account")}
                </SaveButton>
              </Stack>
            </Stack>
          </Form>
        </Card>
        <Card inset="l">
          <Form config={profileFormConfig} onSubmit={updateLanguage}>
            <Stack spacing="m" direction="column">
              <Text variant="display" subdued={false}>
                {t("system.language")}
              </Text>
              <Select labelHidden name="preferred_language" id="prefferedLanguage" label={t("system.language")}>
                {locales.map(locale => (
                  <option key={locale} value={locale}>
                    {nativeLanguageMapping[locale]}
                  </option>
                ))}
              </Select>
              <Stack justifyContent="end" spacing="s">
                {profileFormConfig.context.formState.isDirty && (
                  <Button variant="tertiary" onClick={() => profileFormConfig.context.reset()}>
                    {t("system.cancel")}
                  </Button>
                )}
                <SaveButton type="submit" saving={profileRequestIsPending}>
                  {t("system.update_language")}
                </SaveButton>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Grid.FlexItem>
      <Grid.Item colSpan={1}>
        <Card inset="l">
          <Form config={passwordFormConfig} onSubmit={updatePassword}>
            <Stack spacing="m" direction="column">
              <Text variant="display" subdued={false}>
                {t("system.password")}
              </Text>
              <PasswordField name="old-password" id="oldPassword" label={t("system.current_password")} />
              <PasswordField name="new-password" id="newPassword" label={t("system.new_password")} />
              {showPasswordButtons && (
                <Stack justifyContent="end" spacing="s">
                  <Button variant="tertiary" onClick={() => passwordFormConfig.context.reset()}>
                    {t("system.cancel")}
                  </Button>
                  <SaveButton type="submit" saving={passwordRequestIsPending}>
                    {t("system.change_password")}
                  </SaveButton>
                </Stack>
              )}
            </Stack>
          </Form>
        </Card>
      </Grid.Item>
    </Grid>
  )
}

export default Account
