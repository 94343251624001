import { DocumentItemType } from "api/documents"
import { downloadFile } from "utils/downloadFile"

export const downloadDocument = async (document: DocumentItemType) => {
  const {
    name,
    url,
    file: { extension },
  } = document
  const hasExtension = name.endsWith(extension)
  const fileName = hasExtension ? name : `${name}.${extension}`

  return downloadFile(fileName, url)
}
