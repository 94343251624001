import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchAssistantConfiguration, fetchAssistantFilterSetItemValuesFromUrl } from "api/assistant"
import { StateError } from "state/types"
import { AssistantConfiguration } from "./types"

export const getAssistantConfigurationThunk = createAsyncThunk<AssistantConfiguration[], undefined, StateError>(
  "app/getAssistantConfiguration",
  async (_, thunkAPI) => {
    try {
      const { data } = await fetchAssistantConfiguration()
      const resolvedData = await Promise.all(
        data.map(async subject => {
          const resolvedComponents = await Promise.all(
            subject.components.map(async component => {
              if (!component.possible_values_url) return component
              const { data: resolvedValues } = await fetchAssistantFilterSetItemValuesFromUrl(
                component.possible_values_url,
              )
              return {
                ...component,
                possible_values: resolvedValues,
                default: resolvedValues.length > 0 ? resolvedValues[0] : undefined,
              }
            }),
          )

          return {
            ...subject,
            components: resolvedComponents,
          }
        }),
      )

      return resolvedData
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
