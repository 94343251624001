import React from "react"

type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

interface ParseTagsParams {
  [key: string]: AnchorProps & { htmlElement?: string }
}

type ParseTagsFunctionReturn = () => JSX.Element

type ParseTagsFunction = (tags?: ParseTagsParams) => {
  [key: string]: JSX.Element | ParseTagsFunctionReturn
}

export const parseTags: ParseTagsFunction = (tags = {}) => {
  return Object.entries(tags).reduce((prev, [tag, attrs]) => {
    return {
      ...prev,
      [tag]: (chunks: React.ReactNode[]) => {
        const [chunk] = chunks as [string]
        const { htmlElement, ...elementAttr } = attrs
        return React.createElement(htmlElement || tag, elementAttr, chunk)
      },
    }
  }, {})
}
