import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchDocuments,
  FetchDocumentsParams,
  FetchDocumentsReturnValue,
  postStarDocument,
  PostStarDocumentParams,
  PostStarDocumentReturnValue,
} from "api/documents"
import { StateError } from "state/types"

export const getDocumentsThunk = createAsyncThunk<FetchDocumentsReturnValue, FetchDocumentsParams, StateError>(
  "app/getDocuments",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchDocuments(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const starDocumentThunk = createAsyncThunk<PostStarDocumentReturnValue, PostStarDocumentParams, StateError>(
  "app/starDocument",
  async (payload, thunkAPI) => {
    try {
      await postStarDocument(payload)
      return payload.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
