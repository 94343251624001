import React from "react"
import { Text, Stack, Card } from "@paudigital/multidesk-ui-kit"
import { FilterSetGroupItem } from "../FilterSetGroupItem"
import { FilterSetGroupProps } from "./FilterSetGroup.types"

export const FilterSetGroup = ({ filterSetGroup }: FilterSetGroupProps) => {
  return (
    <div>
      <Stack>
        <Text as="h2" variant="display" space="m" subdued>
          {filterSetGroup.title}
        </Text>
      </Stack>
      <Stack spacing="m" direction="column">
        {filterSetGroup.filterSets.map(filterSet => (
          <Card key={filterSet.id} inset="m">
            <FilterSetGroupItem filterSet={filterSet} />
          </Card>
        ))}
      </Stack>
    </div>
  )
}
