import React, { useEffect } from "react"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { Select } from "app/components/Form/components/Select"
import { useFormContext } from "react-hook-form"
import { FormCreateTicketType } from "app/pages/TicketCreation/types"
import { stringToNumber } from "utils/stringToNumber"
import { getSiteDivisionsByModule } from "state/sites/selectors"

export const TicketDivisionField: React.FC = () => {
  const { t } = useTranslate()
  const { watch, setValue } = useFormContext<FormCreateTicketType>()
  const siteId = watch("site_id")
  const divisionId = watch("division_id")

  const siteIdAsNumber = typeof siteId === "string" ? parseInt(siteId) : siteId
  const divisions = useSelector(getSiteDivisionsByModule(siteIdAsNumber, "support_tickets"))

  useEffect(() => {
    if (!divisions.find(division => division.id === divisionId)) setValue("division_id", divisions[0].id)
  }, [divisions, divisionId, setValue])

  return (
    <Select
      name="division_id"
      id="division"
      label={t("system.division")}
      onChange={e => {
        const { name, value } = stringToNumber<FormCreateTicketType>(e)
        setValue(name, value)
      }}
    >
      {divisions.map(division => (
        <option key={division.id} value={division.id}>
          {division.name}
        </option>
      ))}
    </Select>
  )
}
