const barChartConfig = {
  graphHeight: 235,
  barWidth: 72,
  barSpacing: 62,
  graphPaddingX: 6,
}

export const getConfig = () => {
  const defaultBarChartConfig = {
    graphPaddingX: 0,
    graphPaddingY: 0,
    barLabelFontSize: 14,
    barLabelHeight: 22,
    pointSize: 6,
    pointLabelFontSize: 14,
  }

  // Constants; Should not be overwritten
  const barChartConstants = {
    graphPaddingTop: 32,
  }

  return {
    ...defaultBarChartConfig,
    ...barChartConfig,
    ...barChartConstants,
  }
}
