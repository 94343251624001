import React, { useState } from "react"
import { IconButton, IconVisible, IconInvisible } from "@paudigital/multidesk-ui-kit"
import { TextField } from "app/components/Form/components/TextField"
import { useFormContext } from "react-hook-form"
import { TextFieldProps } from "app/components/Form/components/TextField/TextField.types"

export const PasswordField = (props: TextFieldProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const { watch } = useFormContext()

  const showIcon = !!watch(props.name)
  const endAction: TextFieldProps["endAction"] = buttonProps => (
    <IconButton
      icon={isVisible ? IconInvisible : IconVisible}
      onClick={() => setIsVisible(previousState => !previousState)}
      {...buttonProps}
    />
  )
  const conditionalProps = showIcon && { endAction }

  return <TextField type={isVisible ? "text" : "password"} {...conditionalProps} {...props} />
}
