import React from "react"
import { Stack, StackProps } from "@paudigital/multidesk-ui-kit"

export const ButtonWrapper: React.FC<StackProps> = ({ children, justifyContent = "right", ...rest }) => {
  return (
    <Stack justifyContent={justifyContent} spacing="s" direction={["column", { m: "row" }]} {...rest}>
      {children}
    </Stack>
  )
}
