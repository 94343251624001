import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getCreatedFile = (state: RootState) => {
  return state.fileCreateReducer.createdFile.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.fileCreateReducer.createdFile.status)
}
