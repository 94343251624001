import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchTrapDetails,
  FetchTrapDetailsParams,
  FetchTrapDetailsReturnValue,
  fetchTrapHistory,
  FetchTrapHistoryParams,
  FetchTrapHistoryReturnValue,
} from "api/hygi"
import { StateError } from "state/types"

export const getTrapThunk = createAsyncThunk<FetchTrapDetailsReturnValue, FetchTrapDetailsParams, StateError>(
  "app/getTrap",
  async ({ customerGroupId, trapId, locationId, inspectionId }, thunkAPI) => {
    try {
      const { data } = await fetchTrapDetails({ customerGroupId, trapId, locationId, inspectionId })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const getTrapHistoryThunk = createAsyncThunk<FetchTrapHistoryReturnValue, FetchTrapHistoryParams, StateError>(
  "app/getTrapHistory",
  async ({ customerGroupId, trapId, locationId, inspectionId }, thunkAPI) => {
    try {
      const { data } = await fetchTrapHistory({ customerGroupId, trapId, locationId, inspectionId })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
