import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchInvoiceStatistics, FetchInvoiceStatisticsData, FetchInvoiceStatisticsReturnValue } from "api/invoices"
import { StateError } from "state/types"

export const getInvoiceStatisticsThunk = createAsyncThunk<
  FetchInvoiceStatisticsReturnValue,
  FetchInvoiceStatisticsData,
  StateError
>("app/getInvoiceStatistics", async (payload, thunkAPI) => {
  try {
    const { data } = await fetchInvoiceStatistics(payload)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
