import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchDocumentCategories, FetchDocumentCategoriesReturnValue } from "api/documents"
import { StateError } from "state/types"

export const getDocumentCategoriesThunk = createAsyncThunk<FetchDocumentCategoriesReturnValue, undefined, StateError>(
  "app/getDocumentCategories",
  async (_, thunkAPI) => {
    try {
      const { data } = await fetchDocumentCategories()
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
