import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getDocumentCategories = (state: RootState) => {
  return state.documentCategoriesReducer.documentCategories.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.documentCategoriesReducer.documentCategories.status)
}
