import { format, differenceInDays } from "date-fns"
import { nlBE, fr, enGB } from "date-fns/locale"
import { useSelector } from "react-redux"
import { useTranslate } from "app/hooks/translate"
import { Locales } from "i18n"
import { useCallback } from "react"
import { getLocale } from "state/user/selectors"

interface FormatDateVocabularyParams {
  format?: string
  precise?: boolean
}

const localeMapping = {
  [Locales.EN]: enGB,
  [Locales.FR]: fr,
  [Locales.NL]: nlBE,
}

export const useFormatDate = () => {
  const { t } = useTranslate()
  const locale = useSelector(getLocale)
  const currentLocale = localeMapping[locale]

  const formatDateString = useCallback(
    (timestamp: string, dateFormat: string = "dd/MM/yyyy") => {
      return format(new Date(timestamp), dateFormat, { locale: currentLocale })
    },
    [currentLocale],
  )

  const formatDateVocabulary = useCallback(
    (timestamp: string, opts?: FormatDateVocabularyParams) => {
      const { format: formatDefault = "dd/MM/yyyy", precise = false } = opts || {}

      const diffDays = differenceInDays(new Date(), new Date(timestamp))
      const formatSuffix = "HH:mm"
      const format = precise ? `${formatDefault} - ${formatSuffix}` : formatDefault
      const formattedSuffix = precise ? ` - ${formatDateString(timestamp, formatSuffix)}` : ``

      if (diffDays === 0) return `${t("system.today")}${formattedSuffix}`
      if (diffDays === 1) return `${t("system.yesterday")}${formattedSuffix}`

      return formatDateString(timestamp, format)
    },
    [t, formatDateString],
  )

  return { formatDateString, formatDateVocabulary }
}
