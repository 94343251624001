import React from "react"
import { useTranslate } from "app/hooks/translate"
import { FileUpload } from "app/components/Form/components/FileUpload"
import { useFormContext } from "react-hook-form"
import { FormCreateDocumentType } from "../types"

export const DocumentFile: React.FC = () => {
  const { t } = useTranslate()
  const { setValue } = useFormContext<FormCreateDocumentType>()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    const { name } = file
    setValue("name", name)
  }

  return (
    <FileUpload
      name="files"
      helperText={t("system.file_upload.help_text")}
      id="file_upload"
      label={`${t("system.file")}`}
      multiple={false}
      onChange={handleFileChange}
    >
      {t("system.file_upload.text")}
    </FileUpload>
  )
}
