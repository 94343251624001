import { NotificationMessageType } from "utils/notifications"
import { translate } from "../../i18n"

export const handleExternalError = (error: NotificationMessageType | undefined): NotificationMessageType => {
  if (!error || typeof error === "string") {
    return {
      translationKeys: true,
      title: "system.unexpected_error.title",
      message: "system.unexpected_error.message",
    }
  } else
    return {
      title: translate("system.unexpected_error.title"),
      message: error.message || translate("system.unexpected_error.message"),
      errors: error.errors,
    }
}
