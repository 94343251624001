import { Checkbox, Stack, Table } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useUserFormContent } from "../.."
import { FormAssignRolesToUser } from "../../../../pages/UserCreation/types"
import { UserSiteRoleSelect } from "../UserSiteRoleSelect"
import { UserSiteDivisionProps } from "./UserSiteDivision.types"

export function UserSiteDivision({ siteIndex, division }: UserSiteDivisionProps) {
  const { control, watch } = useFormContext<FormAssignRolesToUser>()
  const { disabled } = useUserFormContent()
  const { t } = useTranslate()
  const { append, replace, fields } = useFieldArray({
    control,
    name: `sites.${siteIndex}.roles`,
  })
  const sites = watch(`sites.${siteIndex}`)
  const divisionIsInForm = fields.some(field => field.division_id === division.id)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      append(
        division.activated_modules.map(module => ({
          division_id: division.id,
          module_id: module.id,
        })),
      )
    } else {
      const updatedFields = sites.roles.filter(role => role.division_id !== division.id)
      replace(updatedFields)
    }
  }

  return (
    <>
      <Stack>
        <Checkbox
          disabled={disabled}
          checked={divisionIsInForm}
          onChange={handleChange}
          label={division.name}
          id={`${division.id}-division`}
        />
      </Stack>
      {divisionIsInForm && (
        <Table
          columns={[
            { key: "module", content: t("system.module") },
            { key: "role", content: t("system.role") },
          ]}
          rows={division.activated_modules.map(module => {
            return {
              key: `cell-id-${module.id}`,
              cells: [
                {
                  key: `module-${module.id}`,
                  content: module.name,
                },
                {
                  key: `role-${module.id}`,
                  content: <UserSiteRoleSelect siteIndex={siteIndex} module={module} division={division} />,
                },
              ],
            }
          })}
        />
      )}
    </>
  )
}
