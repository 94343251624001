import { createSlice } from "@reduxjs/toolkit"
import { patchSupportTicketThunk } from "state/ticketDetail/thunks"
import { RequestStatus } from "state/types"
import { getSupportTicketsThunk } from "./thunks"
import { TicketState } from "./types"

const initialTicketData: TicketState["tickets"]["data"] = {
  total: 0,
  first: 0,
  last: 0,
  prev: 0,
  next: 0,
  page: 0,
  perPage: 10,
  items: [],
}

const initialState: TicketState = {
  tickets: { data: initialTicketData, status: RequestStatus.IDLE },
}

export const userSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSupportTicketsThunk.pending, state => {
      state.tickets.status = RequestStatus.PENDING
    })
    builder.addCase(getSupportTicketsThunk.fulfilled, (state, action) => {
      state.tickets.data = action.payload
      state.tickets.status = RequestStatus.FULFILLED
    })
    builder.addCase(getSupportTicketsThunk.rejected, (state, action) => {
      state.tickets.error = action.payload?.message
      state.tickets.status = RequestStatus.REJECTED
    })
    builder.addCase(patchSupportTicketThunk.fulfilled, (state, action) => {
      const ticketIndex = state.tickets.data.items.findIndex(ticket => ticket.id === action.payload.id)
      if (ticketIndex >= 0) {
        state.tickets.data.items[ticketIndex] = action.payload
      }
    })
  },
})

export default userSlice.reducer
