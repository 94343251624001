import React from "react"
import { IconSearch, TextFieldProps } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { useDebouncedCallback } from "use-debounce"
import { StyledSearchTextField } from "./style"

export const SearchField: React.FC<Partial<TextFieldProps>> = props => {
  const { t } = useTranslate()
  const { queryParams, updateQueryParams } = useUpdateQueryParams()
  const handleSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    updateQueryParams({
      page: "1",
      q: event.target.value,
    })
  }
  const debouncedChangeHandler = useDebouncedCallback(handleSearchQueryChange, 300)
  const currentSearchQuery = queryParams.get("q") ?? ""

  return (
    <StyledSearchTextField
      {...props}
      id="table-overview-search"
      label={t("system.search")}
      icon={IconSearch}
      placeholder={`${t("system.search")}..`}
      onChange={debouncedChangeHandler}
      defaultValue={currentSearchQuery}
      labelHidden
    />
  )
}
