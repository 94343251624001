import styled from "styled-components"
import { Text } from "@paudigital/multidesk-ui-kit"
import { Link } from "react-router-dom"

export const StyledImage = styled.img`
  max-width: 100%;
`

export const StyledFloorPlanThumbnail = styled(Link)`
  cursor: pointer;
  border: ${({ theme }) => `${theme.borderWidth.s} solid ${theme.color.neutral.light}`};
  border-radius: ${({ theme }) => `${theme.borderRadius.s}`};
  &:hover {
    text-decoration: none;
  }
  color: ${({ theme }) => theme.color.neutral.dark};
`

export const StyledText = styled(Text)`
  margin: ${({ theme }) => `${theme.space.s} ${theme.space["2xs"]}`};
`
