import { Skeleton, TextSkeleton } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledAssistantIconSkeleton = styled(Skeleton)`
  width: 2.5rem;
  aspect-ratio: 1 / 1;
`

export const StyledAssistantText = styled(TextSkeleton)`
  width: 6rem;
`
