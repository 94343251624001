import React from "react"
import { StyledActionButton } from "./ActionButton.style"
import { ActionButtonProps } from "./ActionButton.types"

export const ActionButton: React.FC<ActionButtonProps> = ({ children, ...rest }) => {
  return (
    <StyledActionButton variant="tertiary" {...rest}>
      {children}
    </StyledActionButton>
  )
}
