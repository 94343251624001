import React, { useEffect } from "react"
import { useTranslate } from "app/hooks/translate"
import { matchRoutes, useLocation, useRoutes } from "react-router-dom"
import routingData from "router"
import { getAllRoutes } from "router/utils"
import { useGoogleTagManager } from "app/hooks/googleTagManager"
import { RouterContextProps } from "./RouterProvider.types"

const RouterProvider: React.FC = () => {
  const { t } = useTranslate()
  const { gtag } = useGoogleTagManager()
  const routes = useRoutes(routingData)
  const location = useLocation()

  const matchingRoutes = matchRoutes(routingData, location)
  const allRoutes = getAllRoutes(routingData)

  const routeMatch = matchingRoutes!.find(route => route.pathname === location.pathname)!
  const { route } = routeMatch
  const { path } = route
  const firstRouteWithLabel = matchingRoutes?.reverse().find(({ route }) => !!route.label)
  const parentRouteLabel = firstRouteWithLabel?.route?.label
  const moduleRoute = matchingRoutes?.reverse().find(({ route }) => !!route.module)
  const moduleLabel = moduleRoute?.route?.label
  const label = moduleLabel || parentRouteLabel

  useEffect(() => {
    if (path === "*") return

    const title = "Multidesk" + (label ? ` - ${t(label)}` : "")
    document.title = title

    gtag("Pageview", {
      page: {
        url: path,
        title,
      },
    })
  }, [label, path, t, gtag])

  return (
    <RouterContext.Provider
      value={{
        route,
        heading: label,
        routes: allRoutes,
        config: routingData,
        matchingRoutes: matchingRoutes,
      }}
    >
      {routes}
    </RouterContext.Provider>
  )
}

export const RouterContext = React.createContext<RouterContextProps>({} as RouterContextProps)
export const useRouterContext = () => React.useContext(RouterContext)

export default RouterProvider
