import React from "react"
import { RadioContextProps } from "./Radios.types"

const RadioProvider: React.FC<RadioContextProps> = ({ name, children }) => {
  return <RadioContext.Provider value={{ name }}>{children}</RadioContext.Provider>
}

export const RadioContext = React.createContext<RadioContextProps>({} as RadioContextProps)
export const useRadioContext = () => React.useContext(RadioContext)

export default RadioProvider
