import { IconButton, IconClose, Stack, Tag, Text } from "@paudigital/multidesk-ui-kit"
import { useGetTrapStatusColor } from "app/components/Marker/utils"
import { useTranslate } from "app/hooks/translate"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getTrapDetail } from "state/hygiTrapDetail/selectors"
import { getTrapThunk } from "state/hygiTrapDetail/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { convertToUTCString } from "utils/convertToUTCString"
import { EfkDetails } from "./components/EfkDetails"
import { TrapHistory } from "./components/TrapHistory"
import { StyledBoldTrapDetailText, StyledTrapDetail, StyledTrapDetailTitle } from "./style"
import { TrapDetailProps } from "./types"
import { checkIsETrapDetail } from "./utils"

export const TrapDetail: React.FC<TrapDetailProps> = ({ isOpen, onClose, selection }) => {
  const customerGroupId = useSelector(getActiveCustomerGroupId)
  const trap = useSelector(getTrapDetail)
  const dispatch = useDispatch()
  const { inspectionId, locationId } = useParams()
  const { t } = useTranslate()
  const isETrap = checkIsETrapDetail(trap)
  const color = useGetTrapStatusColor(trap?.state)

  useEffect(() => {
    if (!customerGroupId || !inspectionId || !locationId || typeof selection === "undefined") return
    dispatch(getTrapThunk({ customerGroupId, inspectionId, locationId, trapId: selection }))
  }, [customerGroupId, dispatch, inspectionId, locationId, selection])

  if (!isOpen || !trap) return null

  return (
    <StyledTrapDetail inset="l">
      <Stack spacing="m" direction="column">
        <Stack justifyContent="space-between">
          <Stack spacing="2xs">
            <StyledTrapDetailTitle>{trap.type}</StyledTrapDetailTitle>
          </Stack>
          <IconButton spacing="compact" onClick={onClose} variant="tertiary" icon={IconClose} />
        </Stack>
        <Stack.Item alignSelf="flex-start">
          <Tag variant="default" color={color}>
            {trap.state}
          </Tag>
        </Stack.Item>
        {!isETrap && (
          <Stack justifyContent="space-between">
            <StyledBoldTrapDetailText>{t("system.hygi.trap.type")}</StyledBoldTrapDetailText>
            <Text>{trap.type}</Text>
          </Stack>
        )}
        <Stack spacing="2xs" direction="column">
          <Text>{convertToUTCString(new Date(trap.details.logged_at), "dd/MM/yyyy")}</Text>
          <Text>
            {t("system.hygi.trap.logged_by")} {trap.details.logged_by}
          </Text>
        </Stack>
        {isETrap && (
          <>
            <Stack justifyContent="space-between">
              <StyledBoldTrapDetailText>{t("system.hygi.trap.current_state")}</StyledBoldTrapDetailText>
              <Text>{trap.details.state}</Text>
            </Stack>
            <Stack justifyContent="space-between">
              <StyledBoldTrapDetailText>{t("system.hygi.trap.temperature")}</StyledBoldTrapDetailText>
              <Text>{trap.details.temperature}</Text>
            </Stack>
            <Stack justifyContent="space-between">
              <StyledBoldTrapDetailText>{t("system.hygi.trap.voltage")}</StyledBoldTrapDetailText>
              <Text>{trap.details.voltage}</Text>
            </Stack>
          </>
        )}
        <TrapHistory selection={selection} />
        {trap.type === "EFK" && <EfkDetails />}
      </Stack>
    </StyledTrapDetail>
  )
}
