import axios, { AxiosPromise } from "axios"
import {
  FetchQMSAuditsGraphDataReturnValue,
  FetchQMSResultsTableDataReturnValue,
  FetchQMSAuditsTableDataReturnValue,
  FetchQMSYearlyGraphDataReturnValue,
  FetchQMSYearlyTableDataReturnValue,
  FetchQMSResultsGraphDataReturnValue,
} from "./qms.types"

export const fetchQMSYearlyGraphData = (_id: number): AxiosPromise<FetchQMSYearlyGraphDataReturnValue> => {
  // TODO: Remove /mock/qms.mock.json
  return axios.get("/mock/qms_years_graph.mock.json").then(res => res)
}

// export const fetchQMSYearlyGraphData = (id: number): AxiosPromise<FetchQMSYearlyGraphDataReturnValue> => {
//   return api(`/customer_groups/${id}/graphs/quality_inspections/yearly`, {
//     method: "GET",
//   })
// }

export const fetchQMSYearlyTableData = (_id: number): AxiosPromise<FetchQMSYearlyTableDataReturnValue> => {
  // TODO: Remove /mock/qms.mock.json
  return axios.get("/mock/qms_years_table.mock.json").then(res => res)
}

export const fetchQMSAuditsGraphData = (_id: number): AxiosPromise<FetchQMSAuditsGraphDataReturnValue> => {
  // TODO: Remove /mock/qms.mock.json
  return axios.get("/mock/qms_audits_graph.mock.json").then(res => res)
}

// export const fetchQMSQualityInspectionsGraphData = (id: number): AxiosPromise<FetchQMSQualityInspectionsGraphDataReturnValue> => {
//   return api(`/customer_groups/${id}/graphs/quality_inspections`, {
//     method: "GET",
//   })
// }

export const fetchQMSAuditsTableData = (_id: number): AxiosPromise<FetchQMSAuditsTableDataReturnValue> => {
  // TODO: Remove /mock/qms.mock.json
  return axios.get("/mock/qms_audits_table.mock.json").then(res => res)
}

export const fetchQMSResultsTableData = (_id: number): AxiosPromise<FetchQMSResultsTableDataReturnValue> => {
  // TODO: Remove /mock/qms.mock.json
  return axios.get("/mock/qms_results_table.mock.json").then(res => res)
}

export const fetchQMSResultsGraphData = (_id: number): AxiosPromise<FetchQMSResultsGraphDataReturnValue> => {
  // TODO: Remove /mock/qms_results_graph.mock.json
  return axios.get("/mock/qms_results_graph.mock.json").then(res => res)
}
