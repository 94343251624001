import React, { useState } from "react"
import { useSelector } from "react-redux"
import { IconButton, IconAdd, Stack, Button, Dropdown } from "@paudigital/multidesk-ui-kit"
import { ComponentType } from "state/assistantConfiguration/types"
import { useTranslate } from "app/hooks/translate"
import { getAssistantSubjectComponentPossibleParameters } from "state/assistantConfiguration/selectors"
import { useAssistant } from "../.."
import { StyledText } from "./AddFilterItem.style"

export const AddFilterItem = () => {
  const { t } = useTranslate()
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const { filterSet, onChange } = useAssistant()

  const possibleParameters = useSelector(
    getAssistantSubjectComponentPossibleParameters(filterSet?.subject_type, filterSet),
  )

  const onClickParameter = (parameter: ComponentType) => {
    if (!filterSet || !onChange) return
    onChange({
      ...filterSet,
      filter_set: [...filterSet.filter_set, { name: parameter.name, value: parameter.default ?? "" }],
    })
    setDropdownIsOpen(false)
  }

  return (
    <>
      <IconButton
        ref={setButtonElement}
        onClick={() => setDropdownIsOpen(previousState => !previousState)}
        icon={IconAdd}
        variant="tertiary"
      />
      <Dropdown
        inset="m"
        shadow="soft"
        placement="bottom-start"
        onClose={() => setDropdownIsOpen(false)}
        referenceElement={buttonElement}
        isVisible={dropdownIsOpen}
      >
        <StyledText forwardedAs="p" subdued variant="sectionTitle">
          {t("system.assistant.select_parameter")}
        </StyledText>
        <Stack direction="column" spacing="s">
          {possibleParameters?.map(parameter => (
            <Button onClick={() => onClickParameter(parameter)} key={parameter.name} variant="tertiary" align="left">
              {t(`system.${parameter.name}`)}
            </Button>
          ))}
        </Stack>
      </Dropdown>
    </>
  )
}
