import { createAsyncThunk } from "@reduxjs/toolkit"
import { UpdateAssistantFilterSetParams } from "state/assistant/types"
import { AssistantFilterSetReturnValue, updateAssistantFilterSet, fetchAssistantFilterSet } from "api/assistant"
import { StateError } from "state/types"
import { FetchAssistantFilterSetParams } from "./types"

export const getAssistantFilterSetThunk = createAsyncThunk<
  AssistantFilterSetReturnValue,
  FetchAssistantFilterSetParams,
  StateError
>("app/getAssistantFilterSet", async (payload, thunkAPI) => {
  const { customerGroupId, filterSetId } = payload
  try {
    const { data } = await fetchAssistantFilterSet({ customerGroupId, filterSetId })
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const patchAssistantEditThunk = createAsyncThunk<
  AssistantFilterSetReturnValue,
  UpdateAssistantFilterSetParams,
  StateError
>("app/updateAssistantEdit", async (payload, thunkAPI) => {
  const { newFilterSet, customerGroupId } = payload
  try {
    const { data } = await updateAssistantFilterSet(customerGroupId, newFilterSet)
    return data
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})
