import { Icon } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"
import { StyledBackdropIconProps } from "./types"

export const StyledBackdropIcon = styled(Icon)<StyledBackdropIconProps>`
  ${({ primary }) => primary && `--icon-color-primary: ${primary}`};
  ${({ secondary }) => secondary && `--icon-color-secondary: ${secondary}`};
  display: inline-flex;
  padding: ${({ theme }) => theme.space["2xs"]};
  background: ${({ theme, tertiary }) => tertiary || theme.color.neutral.light};
  border-radius: 100%;
  font-size: 2.5rem;
`
