import React from "react"
import { useSelector } from "react-redux"
import { Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { FilterSetItem } from "state/assistantConfiguration/types"
import { getAssistantSubjectComponentItemType } from "state/assistantConfiguration/selectors"
import { OptionSingle } from "./inputTypes/OptionSingle"
import { FilterItemValueInputProps } from "./FilterItemValueInput.types"

const renderElementForItemType = (filterItemType: "option_single" | undefined, filterItem: FilterSetItem) => {
  if (!filterItemType) return <></>
  switch (filterItemType) {
    case "option_single":
      return <OptionSingle filterItem={filterItem} />
    default:
      return <></>
  }
}

export const FilterItemValueInput = ({ subject, filterItem }: FilterItemValueInputProps) => {
  const { t } = useTranslate()
  const legend = `${t("system.assistant.select")} ${t(`system.${filterItem.name}`).toLocaleLowerCase()}`

  const filterItemType = useSelector(getAssistantSubjectComponentItemType(subject, filterItem.name))

  return (
    <Fieldset legend={legend}>
      <Stack direction="column" spacing="s">
        {renderElementForItemType(filterItemType, filterItem)}
      </Stack>
    </Fieldset>
  )
}
