import { createSelector } from "@reduxjs/toolkit"
import { AssistantFilterSet } from "api/assistant"
import { Modules } from "router/types"
import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"
import { AssistantConfiguration, ComponentType } from "./types"

export const getAssistantConfiguration = (state: RootState) => {
  return state.assistantConfigurationReducer.configuration.data
}

export const getAssistantConfigurationBySubject = (subject: Modules) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): AssistantConfiguration | undefined =>
      assistantConfiguration.find(({ subject_type }) => subject_type === subject),
  )

export const getAssistantSubjectComponents = (subject: Modules) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): ComponentType[] | undefined =>
      assistantConfiguration.find(({ subject_type }) => subject_type === subject)?.components,
  )

export const getAssistantSubjectComponentPossibleParameters = (
  subject: Modules | undefined,
  filterSet: AssistantFilterSet | undefined,
) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): ComponentType[] | undefined =>
      assistantConfiguration
        .find(({ subject_type }) => subject_type === subject)
        ?.components.filter(component => !filterSet?.filter_set.some(setItem => setItem.name === component.name)),
  )

export const getAssistantSubjectComponentPossibleValues = (subject: Modules | undefined, componentName: string) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): string[] | undefined =>
      assistantConfiguration
        .find(({ subject_type }) => subject_type === subject)
        ?.components.find(component => component.name === componentName)?.possible_values,
  )

export const getAssistantSubjectHasValues = (subject: Modules | undefined) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): boolean | undefined =>
      assistantConfiguration
        .find(({ subject_type }) => subject_type === subject)
        ?.components.some(c => c.possible_values && c.possible_values.length > 0),
  )

export const getAssistantSubjectComponentItemType = (subject: Modules, componentName: string) =>
  createSelector(
    (state: RootState) => getAssistantConfiguration(state),
    (assistantConfiguration: AssistantConfiguration[]): "option_single" | undefined =>
      assistantConfiguration
        .find(({ subject_type }) => subject_type === subject)
        ?.components.find(component => component.name === componentName)?.type,
  )

export const getAssistantConfigurationThunkStatus = (state: RootState) => {
  return getThunkStatus(state.assistantConfigurationReducer.configuration.status)
}
