import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateDocumentParams, postCreateDocument, CreateDocumentReturnValue } from "api/documents"
import { StateError } from "state/types"

export const createDocumentThunk = createAsyncThunk<CreateDocumentReturnValue, CreateDocumentParams, StateError>(
  "app/createDocument",
  async (payload, thunkAPI) => {
    try {
      const { data } = await postCreateDocument(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
