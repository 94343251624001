import React from "react"
import { Link } from "react-router-dom"
import { useTranslate } from "app/hooks/translate"
import { TableColumnProps, theme } from "@paudigital/multidesk-ui-kit"
import { HygiInspection } from "api/hygi"
import { StatusGraph } from "../StatusGraph"
import { GetInspectionRowsProps } from "./types"

export const useGetInspectionsColumns = (): TableColumnProps[] => {
  const { t } = useTranslate()

  return [
    {
      key: "date",
      content: t("system.date"),
      width: "150px",
      sortable: true,
    },
    {
      key: "locations",
      content: t("system.name"),
      sortable: false,
    },
    {
      key: "inspector",
      content: t("system.hygi.inspector"),
      width: "15%",
      sortable: true,
    },
    {
      key: "bait_results",
      content: t("system.hygi.bait_results"),
      width: "15%",
      sortable: false,
    },
    {
      key: "etrap_states",
      content: t("system.hygi.etrap_states"),
      width: "15%",
      sortable: false,
    },
  ]
}

export const useGetInspectionsRows = ({ inspections }: GetInspectionRowsProps) => {
  const { t } = useTranslate()
  return inspections.items.map((inspection: HygiInspection) => ({
    key: `row-${inspection.id}`,
    cells: [
      {
        key: `cell-${inspection.id}-date`,
        content: <Link to={`/hygi/inspections/${inspection.id}`}>{inspection.date}</Link>,
      },

      {
        key: `cell-${inspection.id}-locations`,
        content: inspection.locations.join(", "),
      },
      {
        key: `cell-${inspection.id}-inspector`,
        content: inspection.inspector,
      },
      {
        key: `cell-${inspection.id}-bait_results`,
        content: (
          <StatusGraph
            data={[
              { label: "OK", value: inspection["bait_results"].ok, color: theme.color.palette.green50 },
              { label: "NOK", value: inspection["bait_results"].nok, color: theme.color.palette.red50 },
            ]}
          />
        ),
      },
      {
        key: `cell-${inspection.id}-etrap_states`,
        content: (
          <StatusGraph
            data={[
              { label: t("system.open"), value: inspection["etrap_states"].open, color: theme.color.palette.green50 },
              { label: t("system.closed"), value: inspection["etrap_states"].closed, color: theme.color.palette.red50 },
            ]}
          />
        ),
      },
    ],
  }))
}
