import React from "react"
import { useTranslate } from "app/hooks/translate"
import FullPageError from "app/components/FullPageError"

const NoAccess: React.FC = (): JSX.Element => {
  const { t } = useTranslate()

  return <FullPageError title={t("system.no_access.title")} message={t("system.no_access.message")} />
}

export default NoAccess
