import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchCustomerSites, FetchCustomerSitesReturnValue } from "api/sites"
import { StateError } from "state/types"

export const getCustomerSitesThunk = createAsyncThunk<FetchCustomerSitesReturnValue, number, StateError>(
  "app/getCustomerSites",
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchCustomerSites(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
