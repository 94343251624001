import React, { useEffect } from "react"
import { ChartScoreColorKeys } from "app/components/Chart/types"
import { useDispatch, useSelector } from "react-redux"
import { getQMSResultsGraphData, getRequestStatusResultsGraph } from "state/qms/selectors"
import { getQMSResultsGraphDataThunk } from "state/qms/thunk"
import { useTheme } from "styled-components"
import { useGetPendingStatus } from "app/hooks/useGetPendingStatus"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { StyledResultsSankeyChart } from "./style"

export const ResultsSankeyChart: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const resultsGraphData = useSelector(getQMSResultsGraphData)
  const isPending = useGetPendingStatus(getRequestStatusResultsGraph)

  // TODO: Fetch disabled columns through `useActiveColumnContext()`
  // And pass them to `getQMSResultsGraphDataThunk()`

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getQMSResultsGraphDataThunk(activeCustomerGroupId))
  }, [activeCustomerGroupId, dispatch])

  const linkColorScale: [ChartScoreColorKeys, string][] = [
    ["< 25%", theme.color.palette.redTransparent25],
    ["25-50%", theme.color.palette.orangeTransparent25],
    ["50-85%", theme.color.palette.yellowTransparent25],
    ["> 85%", theme.color.palette.greenTransparent25],
  ]

  const nodeColorScale: [ChartScoreColorKeys, string][] = [
    ["< 25%", theme.color.palette.red50],
    ["25-50%", theme.color.palette.orange50],
    ["50-85%", theme.color.palette.yellow50],
    ["> 85%", theme.color.palette.green50],
  ]

  return isPending ? null : (
    <StyledResultsSankeyChart data={resultsGraphData} linkColorScale={linkColorScale} nodeColorScale={nodeColorScale} />
  )
}
