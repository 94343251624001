import React from "react"
import { Button, Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { useSelector } from "react-redux"
import { addSupportTicketCommentThunk } from "state/ticketComment/thunks"
import { useAppContext } from "app/providers/AppProvider"
import { useCreateFormConfig, Form } from "app/components/Form"
import { getCreateTicketCommentStatus } from "state/ticketComment/selectors"
import { TicketHistoryCommentField } from "../TicketHistoryCommentField"
import { TicketHistoryNewCommentFieldWithButtonProps, TicketHistoryNewCommentFormProps } from "./types"
import { commentSchema } from "./schema"

export const TicketHistoryCommentForm: React.FC<TicketHistoryNewCommentFieldWithButtonProps> = ({
  customerGroupId,
  ticketId,
}) => {
  const { isPending } = useSelector(getCreateTicketCommentStatus)
  const { userId } = useAppContext()
  const { t } = useTranslate()

  const defaultValues: TicketHistoryNewCommentFormProps = {
    comment: "",
  }
  const formConfig = useCreateFormConfig({ defaultValues, schema: commentSchema })
  const { context, handleSubmit } = formConfig
  const { watch } = context

  const comment = watch("comment")
  const isButtonVisible = comment.length > 0

  const postComment = async ({ comment }: TicketHistoryNewCommentFormProps) => {
    if (isPending) return
    await handleSubmit(
      addSupportTicketCommentThunk({
        customerGroupId,
        ticketId: ticketId,
        comment: { user_id: userId, comment },
      }),
    )
    context.reset()
  }

  return (
    <Form config={formConfig} onSubmit={postComment}>
      <Stack direction="column" spacing="m">
        <TicketHistoryCommentField />
        {isButtonVisible && (
          <Stack.Item alignSelf="flex-end">
            <Button variant="secondary" type="submit">
              {t("system.post_comment")}
            </Button>
          </Stack.Item>
        )}
      </Stack>
    </Form>
  )
}
