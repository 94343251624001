import React from "react"
import { Textarea as UITextarea } from "@paudigital/multidesk-ui-kit"
import { useFormContext } from "react-hook-form"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useGetErrorMessage } from "../../hooks/getErrorMessage"
import { TextareaProps } from "./Textarea.types"

export const Textarea: React.FC<TextareaProps> = ({ name, label, ...rest }) => {
  const { register } = useFormContext()
  const errorMessage = useGetErrorMessage(name)

  const fieldLabel = useCreateFormFieldLabel(name, label)

  return (
    <UITextarea
      label={fieldLabel}
      errorMessage={errorMessage}
      hasError={!!errorMessage}
      {...register(name, { setValueAs: (v: string) => v.trim() })}
      {...rest}
    />
  )
}
