import React, { useEffect } from "react"
import { Fieldset, Stack } from "@paudigital/multidesk-ui-kit"
import { UrgencyEnum } from "api/tickets"
import { Radios } from "app/components/Form/components/Radios"
import { Radio } from "app/components/Form/components/Radios/Radio"
import { useTranslate } from "app/hooks/translate"
import { useCreateFormFieldLabel } from "app/hooks/createFormFieldLabel"
import { useFormContext } from "react-hook-form"
import { StyledCard, StyledQuotationText } from "./style"
import { QuotationFormContextProps } from "./types"

export const QuotationSelector: React.FC = () => {
  const { watch, setValue } = useFormContext<QuotationFormContextProps>()
  const urgency = watch("urgency")
  const { t } = useTranslate()

  const quotationLabelText = (value: string) => {
    return `${t("system.quotation")} ${t("system.needed." + value).toLowerCase()}`
  }

  const isUrgent = urgency === UrgencyEnum.URGENT

  useEffect(() => {
    if (!isUrgent) return
    setValue("quote_requested", "false")
  }, [isUrgent, setValue])

  const fieldLabel = useCreateFormFieldLabel("quote_requested", t("system.quotation"))

  return (
    <StyledCard disabled={isUrgent} inset="l">
      <Fieldset legend={fieldLabel}>
        <Radios name="quote_requested">
          <Stack direction="column" spacing="s">
            {["false", "true"].map((quotation, i) => (
              <Radio id={`quotation-${i}`} key={i} label={quotationLabelText(quotation)} value={quotation} />
            ))}
          </Stack>
        </Radios>
      </Fieldset>
      {isUrgent && (
        <StyledQuotationText>
          <p>{t("system.ticket.edit.quotation.urgent_warning")}</p>
        </StyledQuotationText>
      )}
    </StyledCard>
  )
}
