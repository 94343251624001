import React from "react"
import { Button, ButtonVisual } from "@paudigital/multidesk-ui-kit"
import { AssistantButtonProps } from "./AssistantButton.types"

export const AssistantButton = React.forwardRef<HTMLButtonElement, AssistantButtonProps>(
  ({ disabled, onClick, ...rest }, ref) => {
    return disabled ? (
      <ButtonVisual variant="secondary" {...rest} disabled />
    ) : (
      <Button onClick={onClick} variant="secondary" {...rest} ref={ref} />
    )
  },
)
