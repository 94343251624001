import React, { useEffect } from "react"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getUsersThunk } from "state/users/thunks"
import { UsersTableOverview } from "./components/UsersTableOverview"

const UsersPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { getAllQueryParams } = useUpdateQueryParams()
  const queryParams = getAllQueryParams()
  const { q, page, perPage } = queryParams

  useEffect(() => {
    if (!activeCustomerGroupId) return
    dispatch(getUsersThunk({ activeCustomerGroupId, q, page, perPage }))
  }, [activeCustomerGroupId, q, page, perPage, dispatch])

  return <UsersTableOverview />
}

export default UsersPage
