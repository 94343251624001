import { IconStar, IconStarFill } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledIconStar = styled(IconStar)`
  --icon-color-primary: ${({ theme }) => theme.color.primary.base};
`

export const StyledIconStarFill = styled(IconStarFill)`
  --icon-color-primary: ${({ theme }) => theme.color.primary.base};
  --icon-color-secondary: ${({ theme }) => theme.color.primary["base-light"]};
`
