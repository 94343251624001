import { FileThumbnail } from "@paudigital/multidesk-ui-kit"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getDocumentDetail } from "state/documentDetail/selectors"
import { checkExpiry } from "utils/checkExpiry"
import { downloadDocument } from "../utils/downloadDocument"
import { getProtectedImage } from "../utils/getProtectedImages"
import { DocumentDetailFileThumbnailProps } from "./DocumentDetailFileThumbnail.types"

export function DocumentDetailFileThumbnail({ onClickThumbnail }: DocumentDetailFileThumbnailProps) {
  const document = useSelector(getDocumentDetail)
  const [protectedThumbnail, setProtectedThumbnail] = useState<string>()
  const thumbnailURL = document?.preview_urls?.thumbnail
  const isExpired = checkExpiry(document?.expires_at)

  useEffect(() => {
    if (!document || !thumbnailURL) return
    getProtectedImage(thumbnailURL).then(value => setProtectedThumbnail(value))
  }, [document, thumbnailURL])

  return document ? (
    <FileThumbnail
      onClickThumbnail={onClickThumbnail}
      thumbnail={protectedThumbnail}
      fileName={document.name}
      onDownload={() => downloadDocument(document)}
      fileType={document.file.type}
      disabled={isExpired}
    />
  ) : null
}
