import { RootState } from "state/store"
import { getThunkStatus } from "utils/thunkStatus"

export const getCreatedAssistant = (state: RootState) => {
  return state.assistantCreateReducer.createdAssistantFilterSet.data
}

export const getRequestStatus = (state: RootState) => {
  return getThunkStatus(state.assistantCreateReducer.createdAssistantFilterSet.status)
}
