import React from "react"
import { Card, Grid } from "@paudigital/multidesk-ui-kit"
import { StyledInvoicesGrid } from "../style"
import {
  StyledInvoicesAmountSkeleton,
  StyledInvoicesButtonSkeleton,
  StyledInvoicesDateSkeleton,
  StyledInvoicesDueDateSkeleton,
  StyledInvoicesNameSkeleton,
  StyledInvoicesStatusSkeleton,
} from "./style"

export const InvoiceSkeletonLoaderItem: React.FC = () => {
  return (
    <Card inset="m">
      <StyledInvoicesGrid
        gap={["s", { m: "2xs" }]}
        areas={[
          [
            ["date", "status"],
            ["name", "name"],
            ["amount", "amount"],
            ["due_date", "due_date"],
            ["download", "download"],
          ],
          {
            s: [
              ["date", "status"],
              ["name", "amount"],
              ["due_date", "download"],
            ],
          },
        ]}
      >
        <Grid.Item area="date" alignSelf="center">
          <StyledInvoicesDateSkeleton variant="bodySmall" />
        </Grid.Item>
        <Grid.Item area="status" justifySelf="flex-end">
          <StyledInvoicesStatusSkeleton />
        </Grid.Item>
        <Grid.Item area="name">
          <StyledInvoicesNameSkeleton variant="display" />
        </Grid.Item>
        <Grid.Item area="amount" justifySelf={["auto", { s: "flex-end" }]}>
          <StyledInvoicesAmountSkeleton variant="title" />
        </Grid.Item>
        <Grid.Item area="due_date">
          <StyledInvoicesDueDateSkeleton variant="bodySmall" />
        </Grid.Item>
        <Grid.Item area="download" justifySelf={["auto", { s: "flex-end" }]}>
          <StyledInvoicesButtonSkeleton />
        </Grid.Item>
      </StyledInvoicesGrid>
    </Card>
  )
}
