import React from "react"
import { Card, Grid, Text, Stack, Notification, IconDelete } from "@paudigital/multidesk-ui-kit"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "app/hooks/translate"
import { useFormatDate } from "app/hooks/useFormateDate"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { ReadOnlyValue } from "app/components/ReadOnlyValue"
import { updateDocumentThunk } from "state/documentDetail/thunks"
import { DocumentVisibilityEnum } from "api/documents"
import { triggerSuccessNotification } from "utils/notifications"
import { getDocumentDetailRequestStatus, getRequestDeleteStatus } from "state/documentDetail/selectors"
import { SaveButton } from "app/components/SaveButton"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import { DocumentDetailFileField } from "../../DocumentDetailFileField"
import { AwaitingPromotionViewProps } from "./AwaitingPromotionView.types"

const AwaitingPromotionView: React.FC<AwaitingPromotionViewProps> = ({
  customerGroupId,
  document,
  deleteDocumentAction,
}): JSX.Element => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const { formatDateVocabulary } = useFormatDate()
  const { isPending: isSavingPending } = useSelector(getDocumentDetailRequestStatus)
  const { isPending: isDeletingPending } = useSelector(getRequestDeleteStatus)

  const cancelDocumentPromotion = async () => {
    await dispatch(
      updateDocumentThunk({
        customerGroupId,
        documentId: document.id,
        document: { visibility: DocumentVisibilityEnum.PERSONAL },
      }),
    )

    triggerSuccessNotification(t("system.document.edit.success"))
  }

  return (
    <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
      <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
        <Card inset="l">
          <Stack direction="column" spacing="l">
            <Stack.Item>
              <Text variant="sectionTitle" space="s">
                {t("system.publication_date")}
              </Text>
              <Text>{formatDateVocabulary(document.created_at)}</Text>
            </Stack.Item>
          </Stack>
        </Card>
        <Card inset="l">
          <Text variant="sectionTitle" space="s">
            {t("system.author")}
          </Text>
          <Text>{document.uploaded_by.author}</Text>
        </Card>
      </Grid.FlexItem>
      <PageMainGridItem>
        <Card inset="l">
          <Stack spacing="l" direction="column">
            <Notification
              type="warning"
              title={t("system.document_library.awaiting_promotion")}
              message={t("system.document_library.awaiting_promotion.helper_text")}
              hideIcon={true}
              hideCloseButton={true}
            />
            <ButtonWrapper justifyContent="flex-start">
              <SaveButton saving={isSavingPending} type="button" variant="secondary" onClick={cancelDocumentPromotion}>
                {t("system.documents.cancel_promotion_to_library")}
              </SaveButton>
            </ButtonWrapper>
            <DocumentDetailFileField />
            <ReadOnlyValue label={t("system.document.name")} value={document.name} />
            <ReadOnlyValue label={t("system.category")} value={t(`system.document_types.${document.category}`)} />
            <ReadOnlyValue label={t("system.visibility")} value={t(`system.visibility.${document.visibility}`)} />
          </Stack>
        </Card>
      </PageMainGridItem>
      <Grid.Item colSpan={[1, { m: 2 }]}>
        <ButtonWrapper>
          <SaveButton
            saving={isDeletingPending}
            icon={IconDelete}
            type="button"
            variant="tertiary"
            onClick={deleteDocumentAction}
          >
            {t("system.documents.delete")}
          </SaveButton>
        </ButtonWrapper>
      </Grid.Item>
    </Grid>
  )
}

export default AwaitingPromotionView
