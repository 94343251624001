import React from "react"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { ModuleCard } from "../../ModuleCard"
import { AssistantDialog } from "./AssistantDialog"
import { StyledImage, StyledLink } from "./style"

const AssistantCard: React.FC = (): JSX.Element => {
  const { t } = useTranslate()
  const [isAssistantDialogOpen, setIsAssistantDialogOpen] = React.useState(false)

  const toggleVideoPlayer = () => {
    setIsAssistantDialogOpen(!isAssistantDialogOpen)
  }

  return (
    <ModuleCard module="assistant" gridArea="assistant">
      <Stack alignItems="center" justifyContent="center">
        <StyledImage src="/assets/assistant.png" alt="assistant" />
      </Stack>
      <StyledLink onClick={toggleVideoPlayer}>{t(`dashboard.assistant.link`)}</StyledLink>
      <AssistantDialog isOpen={isAssistantDialogOpen} setIsOpen={setIsAssistantDialogOpen} />
    </ModuleCard>
  )
}

export default AssistantCard
