import React, { PropsWithChildren } from "react"
import { useBarChartContext } from ".."
import { BarsProps } from "../types"

export const Bars = <T,>({ bars, color, groupBy }: PropsWithChildren<BarsProps<T>>) => {
  const { scaleX, scaleY } = useBarChartContext()

  return (
    <>
      {bars.map(d => {
        return (
          <g key={`bar-${d.key}`} fill={String(color(d.key))} transform={`translate(0, ${0})`}>
            {d.map((r, ri) => {
              return (
                <rect
                  key={ri}
                  x={scaleX(r.data[groupBy])}
                  y={scaleY(r[1])}
                  height={scaleY(r[0]) - scaleY(r[1])}
                  width={scaleX.bandwidth()}
                />
              )
            })}
          </g>
        )
      })}
    </>
  )
}
