import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ValidationContextProvider from "app/providers/FormValidationProvider"
import { AsyncThunkAction } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { ThunkErrors } from "state/types"
import { CreateFormContextProps, FormProps } from "./Form.types"
import { parseThunkErrors } from "./utils/parseThunkErrors"
import { useDeferredDefaultValues } from "./utils/useDeferredDefaultValues"

export function useCreateFormConfig<T extends FieldValues>({ defaultValues, schema }: CreateFormContextProps<T>) {
  const dispatch = useDispatch()
  const context = useForm<T>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  })

  useDeferredDefaultValues(context, defaultValues)

  const handleSubmit = async <R, A>(thunk: AsyncThunkAction<R, A, any>) => {
    return await dispatch(thunk).unwrap()
  }

  return { context, handleSubmit }
}

export function Form<T extends FieldValues>({ config, onSubmit, children, ...rest }: FormProps<T>) {
  const { context, schema } = config
  const { handleSubmit, setError } = context

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    if (!onSubmit) return
    handleSubmit(onSubmit)(e).catch((errors: ThunkErrors) => parseThunkErrors<T>({ errors, setError }))
  }

  return (
    <ValidationContextProvider schema={schema}>
      <FormProvider {...context}>
        <form onSubmit={handleFormSubmit} {...rest}>
          {children}
        </form>
      </FormProvider>
    </ValidationContextProvider>
  )
}
