import React from "react"
import { Card, Grid, Text, Stack, IconDelete } from "@paudigital/multidesk-ui-kit"
import { SubmitHandler } from "react-hook-form"
import { useSelector } from "react-redux"
import { useTranslate } from "app/hooks/translate"
import { useFormatDate } from "app/hooks/useFormateDate"
import { Form, useCreateFormConfig } from "app/components/Form"
import { ButtonWrapper } from "app/components/ButtonWrapper"
import { SaveButton } from "app/components/SaveButton"
import { DocumentItemType } from "api/documents"
import { updateDocumentThunk } from "state/documentDetail/thunks"
import { getRequestDeleteStatus, getDocumentDetailRequestStatus } from "state/documentDetail/selectors"
import { triggerSuccessNotification } from "utils/notifications"
import { PageMainGridItem } from "app/components/Page/PageMainGridItem"
import { VisibilitySelector } from "../../../DocumentCreation/VisibilitySelector"
import { CategorySelector } from "../../../DocumentCreation/CategorySelector"
import { DocumentNameField } from "../../../DocumentCreation/DocumentNameField"
import { DocumentDetailFileField } from "../../DocumentDetailFileField"
import { updateDocumentSchema } from "../../schema"
import { PersonalViewProps } from "./PersonalView.types"

const PersonalView: React.FC<PersonalViewProps> = ({
  customerGroupId,
  document,
  deleteDocumentAction,
}): JSX.Element => {
  const { t } = useTranslate()
  const { formatDateVocabulary } = useFormatDate()
  const { isPending: isSavingPending } = useSelector(getDocumentDetailRequestStatus)
  const { isPending: isDeletingPending } = useSelector(getRequestDeleteStatus)

  const defaultValues = document
  const formConfig = useCreateFormConfig({ defaultValues, schema: updateDocumentSchema })
  const { handleSubmit } = formConfig

  const updateDocument: SubmitHandler<DocumentItemType> = async payload => {
    const { visibility, category, name, reason_promotion_to_library } = payload
    await handleSubmit(
      updateDocumentThunk({
        customerGroupId,
        documentId: document.id,
        document: { name, category, visibility, reason_promotion_to_library },
      }),
    )
    triggerSuccessNotification(t("system.document.edit.success"))
  }

  return (
    <Form config={formConfig} onSubmit={updateDocument}>
      <Grid gap={["m", { m: "xl" }]} columns={[1, { m: 3 }]}>
        <Grid.FlexItem colSpan={1} rowSpan={[1, { m: 2 }]} gap={["m", { m: "xl" }]} direction="column">
          <Card inset="l">
            <Stack direction="column" spacing="l">
              <Stack.Item>
                <Text variant="sectionTitle" space="s">
                  {t("system.publication_date")}
                </Text>
                <Text>{formatDateVocabulary(document.created_at)}</Text>
              </Stack.Item>
            </Stack>
          </Card>
          <Card inset="l">
            <Text variant="sectionTitle" space="s">
              {t("system.author")}
            </Text>
            <Text>{document.uploaded_by.author}</Text>
          </Card>
        </Grid.FlexItem>
        <PageMainGridItem>
          <Card inset="l">
            <Stack spacing="l" direction="column">
              <DocumentDetailFileField />
              <DocumentNameField />
              <CategorySelector />
              <VisibilitySelector />
            </Stack>
          </Card>
        </PageMainGridItem>
        <Grid.Item colSpan={[1, { m: 2 }]}>
          <ButtonWrapper justifyContent={["center", { m: "flex-end" }]}>
            <SaveButton
              saving={isDeletingPending}
              icon={IconDelete}
              type="button"
              variant="tertiary"
              onClick={deleteDocumentAction}
            >
              {t("system.documents.delete")}
            </SaveButton>
            <SaveButton type="submit" variant="primary" saving={isSavingPending}>
              {isSavingPending ? t("system.editing") : t("system.document.edit")}
            </SaveButton>
          </ButtonWrapper>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default PersonalView
