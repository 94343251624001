import keycloak from "../keycloak"

export function fetchWithAuthentication(url: string) {
  const headers = new Headers()
  headers.set("Authorization", `Bearer ${keycloak.token}`)
  return fetch(url, { headers })
}

export const getBlobByUrl = async (url: string, onReject?: any) => {
  const response = await fetchWithAuthentication(url)
  if (response.status !== 200 && onReject) {
    return onReject
  }
  return response.blob()
}

export const downloadFile = async (name: string, url: string): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      const blob = await getBlobByUrl(url, () => reject("File failed to download"))
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
