import React, { ChangeEvent } from "react"
import { IconLinkButton, IconEdit, Stack, Switch } from "@paudigital/multidesk-ui-kit"
import { ModuleIcon } from "app/components/ModuleIcon"
import { Modules } from "router/types"
import { Assistant } from "app/components/Assistant"
import { patchAssistantFilterSetThunk } from "state/assistant/thunks"
import { useDispatch, useSelector } from "react-redux"
import { AssistantFilterSetReturnValue } from "api/assistant"
import { useTranslate } from "app/hooks/translate"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { useFeatureFlags } from "app/hooks/featureFlags"
import { FilterSetGroupItemProps } from "./FilterSetGroupItem.types"

export const FilterSetGroupItem = ({ filterSet }: FilterSetGroupItemProps) => {
  const {
    albatross: { assistantToggleActiveFilterSetEnabled },
  } = useFeatureFlags()
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const customerGroupId = useSelector(getActiveCustomerGroupId)

  const onChangeSwitch = (event: ChangeEvent, filterSet: AssistantFilterSetReturnValue) => {
    dispatch(
      patchAssistantFilterSetThunk({
        customerGroupId,
        newFilterSet: { ...filterSet, is_active: !!(event.target as HTMLInputElement).checked },
      }),
    )
  }

  return (
    <Stack alignItems={["stretch", { m: "center" }]} spacing="s" direction={["column", { m: "row" }]}>
      <ModuleIcon module={filterSet.subject_type as Modules} />
      <Stack.Item grow>
        <Assistant filterSet={filterSet} disabled />
      </Stack.Item>
      <Stack spacing="m" justifyContent="space-between">
        {assistantToggleActiveFilterSetEnabled && (
          <Switch
            disabled={filterSet.isLoading}
            id={filterSet.id.toString()}
            label={t("system.assistant.on")}
            onChange={event => onChangeSwitch(event, filterSet)}
            checked={filterSet.is_active}
          />
        )}
        <IconLinkButton to={filterSet.id.toString()} variant="secondary" icon={IconEdit} />
      </Stack>
    </Stack>
  )
}
