import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Stack } from "@paudigital/multidesk-ui-kit"
import { useTranslate } from "app/hooks/translate"
import { getAssistantSubjectComponentPossibleParameters } from "state/assistantConfiguration/selectors"
import { FilterItem } from "./components/FilterItem"
import { Presets } from "./components/Presets"
import { AddFilterItem } from "./components/AddFilterItem"
import { AssistantDropdown } from "./components/AssistantDropdown"
import { AssistantButton } from "./components/AssistantButton"
import { useAssistant, usePresetDropdown } from "."

export const Consumer = () => {
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslate()
  const { filterSet, disabled } = useAssistant()
  const { setPresetDropdownIsOpen, presetDropdownIsOpen } = usePresetDropdown()
  const subject = filterSet?.subject_type ? t(`system.${filterSet.subject_type}`) : t("system.assistant.choose_subject")
  const possibleParameters = useSelector(
    getAssistantSubjectComponentPossibleParameters(filterSet?.subject_type, filterSet),
  )
  const canAddFilterItems = possibleParameters && possibleParameters.length > 0 && !disabled

  return (
    <Stack spacing="2xs" alignItems="center" wrap>
      <AssistantButton
        onClick={() => setPresetDropdownIsOpen(previousState => !previousState)}
        ref={setButtonElement}
        disabled={disabled}
      >
        {subject}
      </AssistantButton>
      <AssistantDropdown
        onClose={() => setPresetDropdownIsOpen(false)}
        referenceElement={buttonElement}
        isVisible={presetDropdownIsOpen}
      >
        <Presets subjectType={filterSet?.subject_type} />
      </AssistantDropdown>
      {filterSet?.filter_set.map((filterItem, index) => (
        <FilterItem key={index} filterItem={filterItem} subject={filterSet.subject_type} isFirstItem={index === 0} />
      ))}
      {canAddFilterItems && <AddFilterItem />}
    </Stack>
  )
}
