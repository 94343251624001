import { Modules } from "router/types"
import { useTheme } from "styled-components"

export interface ModuleTheme {
  primary: string
  secondary: string
  tertiary: string
}

export const useModuleTheme = (module: Modules): ModuleTheme => {
  const theme = useTheme()
  switch (module) {
    case "support_tickets":
      return {
        primary: theme.color.palette.purple80,
        secondary: theme.color.palette.purple50,
        tertiary: theme.color.palette.purple20,
      }
    case "documents":
      return {
        primary: theme.color.palette.orange80,
        secondary: theme.color.palette.orange50,
        tertiary: theme.color.palette.orange20,
      }
    case "invoices":
      return {
        primary: theme.color.palette.green80,
        secondary: theme.color.palette.green50,
        tertiary: theme.color.palette.green20,
      }
    case "assistant": {
      return {
        primary: theme.color.primary.dark,
        secondary: theme.color.primary.base,
        tertiary: theme.color.primary.light,
      }
    }
    default:
      return {
        primary: theme.color.neutral.dark,
        secondary: theme.color.neutral.base,
        tertiary: theme.color.neutral.light,
      }
  }
}
