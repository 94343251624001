import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { replaceUserThunk } from "./thunks"
import { UserReplaceState } from "./types"

const initialState: UserReplaceState = {
  replacedUser: { data: null, status: RequestStatus.IDLE },
}

export const userReplaceSlice = createSlice({
  name: "userReplace",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(replaceUserThunk.pending, state => {
      state.replacedUser.status = RequestStatus.PENDING
    })
    builder.addCase(replaceUserThunk.fulfilled, (state, action) => {
      state.replacedUser.data = action.payload
      state.replacedUser.status = RequestStatus.FULFILLED
    })
    builder.addCase(replaceUserThunk.rejected, (state, action) => {
      state.replacedUser.error = action.payload?.message
      state.replacedUser.status = RequestStatus.REJECTED
    })
  },
})

export const { reset } = userReplaceSlice.actions
export default userReplaceSlice.reducer
