import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { addSupportTicketCommentThunk } from "state/ticketComment/thunks"
import { RequestStatus } from "state/types"
import { getSingleSupportTicketThunk, patchSupportTicketThunk } from "./thunks"
import { TicketDetailState } from "./types"

const initialState: TicketDetailState = {
  ticketDetail: { data: null, status: RequestStatus.IDLE },
}

export const ticketDetailSlice = createSlice({
  name: "ticketDetail",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(addSupportTicketCommentThunk.fulfilled, (state, action) => {
      state.ticketDetail!.data!.history = action.payload
    })
    builder.addCase(getSingleSupportTicketThunk.rejected, (state, action) => {
      state.ticketDetail.error = action.payload?.message
      state.ticketDetail.status = RequestStatus.REJECTED
    })
    builder.addMatcher(isAnyOf(getSingleSupportTicketThunk.pending, patchSupportTicketThunk.pending), state => {
      state.ticketDetail.status = RequestStatus.PENDING
    })
    builder.addMatcher(
      isAnyOf(getSingleSupportTicketThunk.fulfilled, patchSupportTicketThunk.fulfilled),
      (state, action) => {
        state.ticketDetail.data = action.payload
        state.ticketDetail.status = RequestStatus.FULFILLED
      },
    )
    builder.addMatcher(
      isAnyOf(getSingleSupportTicketThunk.rejected, patchSupportTicketThunk.rejected),
      (state, action) => {
        state.ticketDetail.error = action.payload?.message
        state.ticketDetail.status = RequestStatus.REJECTED
      },
    )
  },
})

export const { reset } = ticketDetailSlice.actions
export default ticketDetailSlice.reducer
