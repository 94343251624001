import React from "react"
import { useTranslate } from "app/hooks/translate"
import { differenceInDays, differenceInHours, differenceInMinutes } from "date-fns"
import { DeadlineTypes } from "./Deadline.types"

const DeadLine: React.FC<DeadlineTypes> = ({ date }): JSX.Element => {
  const { t } = useTranslate()
  const now = new Date()

  const remainingDays = differenceInDays(date, now)
  const remainingHours = differenceInHours(date, now) % 24
  const remainingMinutes = differenceInMinutes(date, now) % 60

  if (remainingDays > 0) {
    return (
      <>
        {remainingDays} {t("system.time.days_short")}{" "}
        {remainingHours > 0 && remainingHours + " " + t("system.time.hours_short")}
      </>
    )
  } else if (remainingHours > 0) {
    return (
      <>
        {remainingHours} {t("system.time.hours_short")}{" "}
        {remainingMinutes > 0 && remainingMinutes + " " + t("system.time.minutes_short")}
      </>
    )
  } else if (remainingMinutes > 0) {
    return (
      <>
        {remainingMinutes} {t("system.time.minutes_short")}
      </>
    )
  } else return <>0 {t("system.time.minutes_short")}</>
}

export default DeadLine
