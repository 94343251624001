import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "state/types"
import { getDocumentCategoriesThunk } from "./thunks"
import { DocumentCategoriesState } from "./types"

const initialState: DocumentCategoriesState = {
  documentCategories: { data: [], status: RequestStatus.IDLE },
}

export const documentCategoriesSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDocumentCategoriesThunk.pending, state => {
      state.documentCategories.status = RequestStatus.PENDING
    })
    builder.addCase(getDocumentCategoriesThunk.fulfilled, (state, action) => {
      state.documentCategories.data = action.payload
      state.documentCategories.status = RequestStatus.FULFILLED
    })
    builder.addCase(getDocumentCategoriesThunk.rejected, (state, action) => {
      state.documentCategories.error = action.payload?.message
      state.documentCategories.status = RequestStatus.REJECTED
    })
  },
})

export default documentCategoriesSlice.reducer
