import { useSetDefaultSorting } from "app/hooks/defaultQueryParams"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import TicketsTableOverview from "app/pages/Tickets/components/TicketsTableOverview"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "state/ticketDetail/slice"
import { getSupportTicketsThunk } from "state/tickets/thunks"
import { getActiveCustomerGroupId } from "state/user/selectors"

const TicketsPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { getAllQueryParams } = useUpdateQueryParams()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const queryParams = getAllQueryParams()
  const { sort, q, page, perPage, status, urgency } = queryParams

  useSetDefaultSorting()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (!activeCustomerGroupId || !sort) return
    dispatch(getSupportTicketsThunk({ activeCustomerGroupId, sort, q, page, perPage, status, urgency }))
  }, [activeCustomerGroupId, sort, q, page, perPage, status, urgency, dispatch])

  return <TicketsTableOverview />
}

export default TicketsPage
