import { createAsyncThunk } from "@reduxjs/toolkit"

import { postFile, PostFileParams, PostFileReturnValue } from "api/files"
import { StateError } from "state/types"

export const createFileThunk = createAsyncThunk<PostFileReturnValue, PostFileParams, StateError>(
  "app/createFile",
  async (payload, thunkAPI) => {
    try {
      const { data } = await postFile(payload)
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)
