import { api } from "api"
import { setSearchParams } from "api/utils/searchParams"
import { AxiosPromise } from "axios"
import { createFormData } from "utils/createFormData"
import {
  FetchDocumentsParams,
  FetchDocumentsReturnValue,
  FetchDocumentStatisticsData,
  FetchSingleDocumentReturnValue,
  CreateDocumentParams,
  CreateDocumentReturnValue,
  PostStarDocumentParams,
  UpdateDocumentParams,
  UpdateDocumentReturnValue,
  DeleteDocumentParams,
} from "./documents.types"

export const fetchDocuments = ({
  activeCustomerGroupId,
  ...searchParams
}: FetchDocumentsParams): AxiosPromise<FetchDocumentsReturnValue> => {
  return api(`/customer_groups/${activeCustomerGroupId}/documents`, {
    method: "GET",
    params: setSearchParams(searchParams),
  })
}

export const fetchSingleDocument = (
  customerGroupId: number,
  documentId: number,
): AxiosPromise<FetchSingleDocumentReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/documents/${documentId}`, {
    method: "GET",
  })
}

export const postStarDocument = ({ customerGroupId, data }: PostStarDocumentParams): AxiosPromise => {
  return api(`/customer_groups/${customerGroupId}/documents/star`, {
    method: "POST",
    data,
  })
}

export const fetchDocumentStatistics = ({ activeCustomerGroupId }: FetchDocumentStatisticsData): AxiosPromise => {
  return api(`/customer_groups/${activeCustomerGroupId}/documents/statistics`, {
    method: "GET",
  })
}

export const fetchDocumentCategories = (): AxiosPromise => {
  return api(`/documents/categories`, {
    method: "GET",
  })
}

export const postCreateDocument = ({
  customerGroupId,
  document,
}: CreateDocumentParams): AxiosPromise<CreateDocumentReturnValue> => {
  const data = createFormData(document)

  return api(`/customer_groups/${customerGroupId}/documents`, {
    method: "POST",
    data,
  })
}

export const updateDocument = ({
  customerGroupId,
  documentId,
  document,
}: UpdateDocumentParams): AxiosPromise<UpdateDocumentReturnValue> => {
  return api(`/customer_groups/${customerGroupId}/documents/${documentId}`, {
    method: "PATCH",
    data: document,
  })
}

export const deleteDocument = ({ customerGroupId, documentId }: DeleteDocumentParams): AxiosPromise<void> => {
  return api(`/customer_groups/${customerGroupId}/documents/${documentId}`, {
    method: "DELETE",
  })
}
