import React from "react"
import { Card, Stack, Text } from "@paudigital/multidesk-ui-kit"
import { TabsRouter } from "app/components/TabsRouter"
import { useTranslate } from "app/hooks/translate"

const ManagementRolesAndPermissionsPage: React.FC = () => {
  const { t } = useTranslate()

  return (
    <Card inset="s">
      <Stack spacing="s" direction="column">
        <TabsRouter align="start" initialPath="documents">
          <Text variant="display" as="h2">
            {t("system.roles")}
          </Text>
        </TabsRouter>
      </Stack>
    </Card>
  )
}

export default ManagementRolesAndPermissionsPage
