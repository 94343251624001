import { createSlice } from "@reduxjs/toolkit"
import { SupportTicketStatisticsType } from "api/tickets"
import { RequestStatus } from "state/types"
import { getSupportTicketStatisticsThunk } from "./thunks"
import { SupportTicketStatisticsState } from "./types"

const initialSupportTicketStatisticsData: SupportTicketStatisticsType = {
  not_started: 0,
  in_progress: 0,
  done: 0,
}

const initialState: SupportTicketStatisticsState = {
  supportTicketStatistics: { data: initialSupportTicketStatisticsData, status: RequestStatus.IDLE },
}

export const supportTicketsStatisticsSlice = createSlice({
  name: "supportTicketStatistics",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSupportTicketStatisticsThunk.pending, state => {
      state.supportTicketStatistics.status = RequestStatus.PENDING
    })
    builder.addCase(getSupportTicketStatisticsThunk.fulfilled, (state, action) => {
      state.supportTicketStatistics.data = action.payload
      state.supportTicketStatistics.status = RequestStatus.FULFILLED
    })
    builder.addCase(getSupportTicketStatisticsThunk.rejected, (state, action) => {
      state.supportTicketStatistics.error = action.payload?.message
      state.supportTicketStatistics.status = RequestStatus.REJECTED
    })
  },
})

export default supportTicketsStatisticsSlice.reducer
