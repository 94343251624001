import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getActiveCustomerGroupId } from "state/user/selectors"
import { getDocumentsThunk } from "state/documents/thunks"
import DocumentsTableOverview from "app/pages/Documents/components/DocumentsTableOverview"
import { useUpdateQueryParams } from "app/hooks/updateQueryParams"
import { useSetDefaultSorting } from "app/hooks/defaultQueryParams"

const DocumentsPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const activeCustomerGroupId = useSelector(getActiveCustomerGroupId)
  const { getAllQueryParams } = useUpdateQueryParams()
  const queryParams = getAllQueryParams()
  const { sort, q, page, perPage, category, visibility, starred } = queryParams

  useSetDefaultSorting("created_at:desc")

  useEffect(() => {
    if (!activeCustomerGroupId || !sort) return
    dispatch(
      getDocumentsThunk({
        activeCustomerGroupId,
        sort,
        q,
        page,
        perPage,
        category,
        visibility,
        starred,
      }),
    )
  }, [activeCustomerGroupId, sort, q, page, perPage, category, visibility, starred, dispatch])

  return <DocumentsTableOverview />
}

export default DocumentsPage
