import { Icon } from "@paudigital/multidesk-ui-kit"
import styled from "styled-components"

export const StyledInvoiceIcon = styled(Icon)`
  --icon-color-primary: ${({ theme }) => theme.color.palette.green80};
  --icon-color-secondary: ${({ theme }) => theme.color.palette.green50};
  background: ${({ theme }) => theme.color.palette.green20};
  border-radius: 50%;
  font-size: 2.5rem;
  padding: ${({ theme }) => theme.space["2xs"]};
`
